import React, { Children, useState } from "react";
import classes from './signInModal.module.css';
import CheckMail from "./checkMail";
import ForgotPassword from "./forgotPassword";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Page5, Page6, Page8 } from "./login";
import Api from "../../services/Api";
import { useLoginStore } from "./store";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { SECRET_KEY, encryptData } from "../../services/fileCode";
import useStore from "../store";
import { hiringRoutePath } from '../Homepage/components/menuData';
import uselocalStorageStore from "../localStorageStore";
import useLoginStorageStore from "./loginStorageStore";
import { useWindowDimensions } from "../../components/windowDimention/windowDimension";
import credentials2 from "../../credentials2";



const SignInModal=({afterSignin})=>{
    const {curPage,resendPhone,setResendPhone,setCurPage,setIsSignIn,setIsSignInModal,isSignIn,isSignInModal, notificationModal,activeTab,setActiveTab,referrerURL,setUrl,setClickedJob,updateRender}=useLoginStore();
    const [loginBy,setLoginBy]=useState("");
    const [mobOTPResponse,setMobOTPResponse]=useState("");
    const [countryCode,setCountryCode]=useState("+91");
    const {updateToken,updateUser,updateNewUser } = useStore(state=>state);
    const [userCredential,setUserCredential]=useState({name:"",email:"",phone:""});
    const {setProfileImage}=uselocalStorageStore();
    const {isLogin, setIsLogin,isCandidate,setIsCandidate}=useLoginStorageStore();
    const navigate=useNavigate();
    const [loginPass,setLoginPass]=useState('');
    const {width}=useWindowDimensions();
    function containsOnlyDigits(str) {
        // Regular expression to match only digits
        var regex = /^[0-9]+$/;
        // Test the string against the regex
        return regex.test(str);
      }

    const verifyMobOTP=(events)=>{
        Api.post('/auth-auth/verifyMobileOTP').params({
          phone:userCredential.phone,
          otp:events.OTP,
          sessionId:mobOTPResponse?.show?.sessionId
      
        }).send(res=>{
          if(res?.show?.type==="success")
          {
            Api.post(`/auth-auth/signIn`).params({signInData:{
              phoneNo:loginBy,
              web:true,
              countryCode:countryCode
            }}).send(res=>{
              if(res?.show?.type === "success" )
              {
                setIsSignIn(false);
              }
      
              if(res?.show?.verifyEmail===false)
        {
          // navigate("/checkMail")
         // setIsLogin(false);
         setIsSignIn(true);
         setResendPhone(res?.show?.phone)
          setCurPage(9);
        }
        else if(res?.newResourceUserValidation=== true){
          console.log("xxxxxxx12344",res)
        
          navigate(`/auth-auth/checktoken?userId=${res?.authUserLoginId}&token=new`);
          // setCurPage(11);
          return;
          // to show the reset password page;
        }
           else if ( res?.show?.type === "success" && res?.show?.data) 
            {
      
              
      
              console.log("Response",res);  
              Api.header(res?.show?.data?.accessToken)
              const encryptedToken = encryptData(res?.show?.data?.accessToken, SECRET_KEY);
              const encryptedUser = encryptData(res?.show?.data?.payload, SECRET_KEY);
              const encryptedLoginToken = encryptData(res?.show?.data?.encryptedLoginId, SECRET_KEY);
              // const encryptedRole=encryptData(res?.show?.data?.userRole,SECRET_KEY);
             
              localStorage.setItem('loginToken', encryptedLoginToken);
              localStorage.setItem('token', encryptedToken);
              localStorage.setItem('user', encryptedUser);    
              updateToken(res?.show?.data?.accessToken);
              updateUser(res?.show?.data?.payload);
              updateNewUser(res?.show?.data?.newUser);
              setProfileImage(res?.show?.data?.payload?.profileImage);
              console.log("GUEEEESSSTT")
              if(referrerURL?.curr === "" && referrerURL.pre ===""){
                  setUrl({
                    curr:"login",
                    pre:"NotLink"
                  })
              }
              if (res?.show?.data?.payload?.hiringUser?.role?.includes("Agency")||res?.show?.data?.payload?.hiringUser?.role?.includes("College-Admin")) {
                hiringRoutePath().then((result) => {
                  window.location.replace(result)
                }).catch((error) => {
                  console.error('Error:', error);
                });
              }
              setIsLogin(true);
              setCurPage(1);
              console.log("GUEEEESSSTT")
                if(res?.show?.data?.payload?.authRole==="Associate")
                {
                    Api.get(`/appraisal-associate/userData?userViewId=${res?.show?.data?.payload?.resourceUser?.id}`)
                    .params({
                      tokenId: res?.show?.data?.accessToken,
                    })
                    .send((response, error) => {
                      console.log("appraisalDaaata",response)
                      if (typeof response != "undefined") {
                        let user = {
                          ...response.appraisalUsers,
                          email: response.email,
                          name: response.name,
                        };
                        const encryptedAppraisalData = encryptData(JSON.stringify(user),SECRET_KEY)
                        localStorage.setItem('AppraisalUserData',encryptedAppraisalData)
                      }
              
                }
                 )
                   setActiveTab(undefined);
                   setIsSignInModal(false)
                  //  navigate("/homePage"); 
                 
                }
                else if (res?.show?.data?.payload?.authRole==="Guest")
                  {
                    console.log("GUEEEESSSTT")
                   setIsSignInModal(false)
                    setActiveTab(1);
                    // if(res?.show?.data?.payload?.hiringCandidate)
                    // {
                    //   setIsCandidate(true);
                    // }
                    // navigate(-1)
                  }
                else if(res?.show?.data?.payload?.authRole==="Candidate")
                  {
                   setIsSignInModal(false)
                    setIsCandidate(true);
                    setActiveTab(1);
                    // navigate(-1)
                  }
                        // else if(res?.show?.data?.payload?.authRole==="Associate")
                    // {
                    //   setActiveTab(undefined);
                    //   navigate("/homePage"); 
      
                    // }
                    // if(isComingfromLidar){
                    //   let  lidarUrl=credentials2?.lidarUrl;
                    //   let token=res?.show?.data?.accessToken;
                    //   let navUrl=lidarUrl+'/verify?token='+token
                      
                    //   window.location(navUrl)
                    // }
             
        }
            })
          }
        })
      }
      const onResend=(callback)=>{
        Api.post('/auth-auth/sendMobileOTP').params({
          phone:loginBy
        }).send(res=>{
          setMobOTPResponse(res);
          setCurPage(8);
          callback();
        });
      }

      const signIn=(events)=>{
        if(!containsOnlyDigits(loginBy))
        {
          setLoginPass(events.password);
          Api.post(`/auth-auth/signIn`).params({signInData:{
            email:loginBy,
            password:events.password,
            web:true,
          }}).send(res=>{
            console.log("xxxxxxx1",res);
            if(res?.show?.type === "success" )
            {
              setIsSignIn(false);
            }
      
            if(res?.show?.verifyEmail===false)
        {
          // navigate("/checkMail")
         // setIsLogin(false);
         setIsSignIn(true);
         setResendPhone(res?.show?.phone)
          setCurPage(9);
        }
        else if(res?.newResourceUserValidation=== true){
          console.log("xxxxxxx12344",res);
        
          navigate(`/auth-auth/checktoken?userId=${res?.authUserLoginId}&token=new`);
          // setCurPage(11);
          return;
          // to show the reset password page;
        }
           else if ( res?.show?.type === "success" && res?.show?.data) 
            {
               
      
               
      
              console.log("Response",res);  
              Api.header(res?.show?.data?.accessToken)
              const encryptedToken = encryptData(res?.show?.data?.accessToken, SECRET_KEY);
              const encryptedUser = encryptData(res?.show?.data?.payload, SECRET_KEY);
              const encryptedLoginToken = encryptData(res?.show?.data?.encryptedLoginId, SECRET_KEY);
              // const encryptedRole=encryptData(res?.show?.data?.userRole,SECRET_KEY);
              // let userLoginToken = decryptData(encryptedLoginToken,SECRET_KEY);
              // console.log("userLoginTokenuserLoginToken",userLoginToken)
              localStorage.setItem('loginToken', encryptedLoginToken);
              localStorage.setItem('token', encryptedToken);
              localStorage.setItem('user', encryptedUser);    
              updateToken(res?.show?.data?.accessToken);
              updateUser(res?.show?.data?.payload);
              updateNewUser(res?.show?.data?.newUser);
               setProfileImage(res?.show?.data?.payload?.profileImage);
              if(referrerURL?.curr === "" && referrerURL.pre ===""){
                  setUrl({
                    curr:"login",
                    pre:"NotLink"
                  })
              }
            
              if (res?.show?.data?.payload?.hiringUser?.role?.includes("Agency")||res?.show?.data?.payload?.hiringUser?.role?.includes("College-Admin")) {
                hiringRoutePath().then((result) => {
                  window.location.replace(result)
                }).catch((error) => {
                  console.error('Error:', error);
                });
              }
            
              setIsLogin(true);
              setCurPage(1);
              console.log("GUEEEESSSTT")
                if(res?.show?.data?.payload?.authRole==="Associate")
                {
                    Api.get(`/appraisal-associate/userData?userViewId=${res?.show?.data?.payload?.resourceUser?.id}`)
                    .params({
                      tokenId: res?.show?.data?.accessToken,
                    })
                    .send((response, error) => {
                      console.log("appraisalDaaata",response)
                      if (typeof response != "undefined") {
                        let user = {
                          ...response.appraisalUsers,
                          email: response.email,
                          name: response.name,
                        };
                        const encryptedAppraisalData = encryptData(JSON.stringify(user),SECRET_KEY)
                        localStorage.setItem('AppraisalUserData',encryptedAppraisalData)
                      }
              
                }
                 )
                 setIsSignInModal(false)
                   setActiveTab(undefined);
                  //  navigate("/homePage"); 
                 
                }
                else if (res?.show?.data?.payload?.authRole==="Guest")
                  {
                   setIsSignInModal(false)
                    setActiveTab(1);
                    // if(res?.show?.data?.payload?.hiringCandidate)
                    // {
                    //   setIsCandidate(true);
                    // }
                    // navigate(-1)
                  }
                else if(res?.show?.data?.payload?.authRole==="Candidate")
                  {
                    setIsSignInModal(false)
                    setIsCandidate(true);
                    setActiveTab(1);
                    // navigate(-1)
                  }
                        // else if(res?.show?.data?.payload?.authRole==="Associate")
                    // {
                    //   setActiveTab(undefined);
                    //   navigate("/homePage"); 
      
                    // }
                    // if(isComingfromLidar){
                    //   let  lidarUrl=credentials2?.lidarUrl;
                    //   let token=res?.show?.data?.accessToken;
                    //   let navUrl=lidarUrl+'/verify?token='+token
                      
                    //   window.location(navUrl)
                    // }
             
        }
        
       
      })
        }
      }

    return(
        <div className={classes.signup}>
              {isSignIn&&<Modal open={isSignInModal}
        width={width>700?"30vw":"95vw"}
        centered
        onCancel={()=>{setIsSignInModal(false);
          // setCurPage(1);
    /*       navigate(-1); */
        }}
        footer={false}
        > 
        <div style={{display:"flex",flexDirection:"column"}} >
         {curPage!==1&&curPage!==5&&<div className={classes.backDiv} 
        
             onClick={()=>{
              if(isSignIn&&curPage===5)
              {
                setCurPage(1);
                setIsSignIn(false);
              }
              else if((loginBy?.includes('.com')||loginBy?.includes('.in'))&&curPage===9)
              {
                  setCurPage(6);
              }
              else if (curPage===8)
              {
                setCurPage(5);
              }
              else
              {
                setCurPage(curPage-1);
              }
              }}>
              <div>
                <ArrowLeftOutlined style={{color:"#000"}}/>
              </div>
              <span style={{color:"#000"}}>Back</span>
            </div>}
         {curPage===5&&<Page5 
              isSignInModal={isSignInModal}
              setCountryCode={setCountryCode} countryCode={countryCode}
              setCurPage={setCurPage} setLoginBy={setLoginBy} loginBy={loginBy} setMobOTPResponse={setMobOTPResponse}/>}
              {curPage===6&&<Page6 setCurPage={setCurPage} onFinish={signIn} isSignInModal={isSignInModal}/>}
              {curPage===7&&<ForgotPassword/>}
              {curPage===8&&<Page8 setCurPage={setCurPage} onFinish={verifyMobOTP} onResend={onResend} isSignInModal={isSignInModal}/>}
              {curPage===9 
                &&<CheckMail onResend={()=>{
                //backned team asked to hit signin api with phone on clicking the resend button. which they are providing in sign in api response.
                
                    
      Api.post(`/auth-auth/signIn`).params({signInData:{
        phoneNo:resendPhone,
        web:true,
      }}).send(res=>{
                  })
                  
              }}/>}
              </div>

        </Modal>}
            </div>
    )
}
export default SignInModal;