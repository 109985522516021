
import ProjectIcon from "../../../assets/Project.svg";
import {  Form, Input, Select } from "antd";
import { ModalPrimaryButton, ModalSecondaryButton } from "../../../components/Button/Button";
import classes from "./Project.module.css";
import { useEffect, useState } from "react";
import { AddProjectApi, EditProjectApi, getFilterApi, getFilterData } from "./ProjectAction";
import moment from "moment";
import Notification from "../../../services/Notification";
import DatePickerCompo from "../../../components/DatePicker/DatePickerCompo.tsx";
import locale from "antd/es/date-picker/locale/en_US";
import Modal from "../../../components/Modal/Modal";
import viewClass from "../../../services/ViewRole";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";
function AddProject({modal,setModal,refresh}){

    const Option=Select.Option;
    const [department,setDepartment]=useState();
    const [filter,setFilter]=useState();
    const [endDate,setEndDate]=useState();
    const [startDate,setStartDate]=useState();
    const [selectedManager,setSelectedManager]=useState();
    const [deptId,setDeptId]=useState();
    const [form]=Form.useForm();
    const {width}=useWindowDimensions();
    useEffect(()=>{
        if(modal?.type==="add"){
        let param={}
        getFilterData(param,(res)=>{
            setDepartment(res?.departments);
        })
    }
    },[])

let modalHeader=<div style={{display:'flex',justifyContent:"flex-start",alignItems:'center',gap:'4px',width:"100%"}}>
                        <img src={ProjectIcon}  style={{height:'36px',width:'36px'}}/>
                        <div style={{fontSize:"1.1rem",fontWeight:"700",color:"#0086FF"}}>{modal?.type==="add"?"Add Project":modal?.type==="edit"?"Edit Projects":''}</div>
                    </div>

    const ProjectHandler=(val)=>{
        console.log(val,"[[[[[[[[[[[[[[[[[[[")
        if(modal?.type==="add"){
            if(val?.name?.trim()?.length===0){
                Notification.error("error","please give the project name");
                return;
            }
        let param={name:val?.name,departmentId:val?.departmentId,teamId:val?.teamId,managerId:val?.managerId,startDate:val?.startDate,dueDate:val?.dueDate,comment:val?.comment,coManagerId:val?.coManagerId}
        console.log(param,val);
        AddProjectApi(param,(res)=>{
            if(res){
                setModal();
                refresh();
            }
        })
    }
    else if(modal?.type==="edit"){
        let param={}
        if(modal?.data?.projectName!==val?.name){
            if(val?.name?.trim()?.length===0){
                Notification.error("Error","Please Enter Project Name")
                return;
            }
            param={...param,name:val?.name}
        }
        if(moment(modal?.data?.dueDate).isSame(moment(val?.dueDate))===false){
            param={...param,dueDate:endDate}
        }
        if(val?.comment!==modal?.data?.comment){
            param={...param,comment:val?.comment}
        }
        console.log(param)
        if(Object.keys(param).length!==0){
        EditProjectApi(modal?.data?.projectId,param,(res)=>{
            if(res){
                setModal();
                refresh();
            }
        })
        }
        else{
            setModal();
        }
        
    }

    }                
    return(
        <>
            <Modal header={modalHeader} show={modal!=undefined} onCancel={()=>{setModal()}} closableRight={true} style={{width:width<700?"90%":'75vw',height:'70vh',overflowY:'auto',}} >
          <div style={{height:450, width:"100%", display:"flex", flexWrap:"wrap", overflow:"auto"}}>

           <Form form={form} layout="vertical" style={{width:'100%',padding:"1rem 2rem"}} onFinish={ProjectHandler} 
           initialValues={modal?.type!=="add"?{
            name:modal?.data?.projectName,
            departmentId:modal?.data?.departmentName,
            managerId:modal?.data?.managerName,
            coManagerId:modal?.data?.coManager,
            teamId:modal?.data?.teamName,
            startDate:moment(modal?.data?.startDate),
            dueDate:moment(modal?.data?.dueDate),
            comment:modal?.data?.comment,

            }:null}>
            <Form.Item  label={<span className={classes.heading1}>Project Name</span>} name="name" rules={[{required: true,message: "Please enter project name",whitespace:true },]}>
                <Input placeholder="Enter Project name" size="large"/>
            </Form.Item>
            <Form.Item  label={<span className={classes.heading1}>Department</span>} name="departmentId" rules={[{required: true,message: "Please select department ",},]}>
                        <Select disabled={modal?.type!=="add"}  placeholder="Select" size="large" onChange={(val)=>{ setDeptId(val); setSelectedManager(); getFilterApi(true,{departmentId:val},(res)=>{setFilter(res)})}}>
                            {department?.map((item,index)=>{
                                return(
                                    <Option value={item?.id} key={index}>{item?.name}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    {
                        (viewClass.manager && !(viewClass.superAdmin|| viewClass.hod))?null
                        :
                    <Form.Item label={<span className={classes.heading1}>Manager</span>} name="managerId" rules={[{required: true,message: "Please select manager",},]}>
                        <Select disabled={modal?.type!=="add" || deptId===undefined} size="large"  placeholder="Select"
                        onChange={(val)=>{ setSelectedManager(val); getFilterApi(true,{departmentId:deptId,managerId:val},(res)=>{setFilter(res)})}}
                        >
                        {filter?.managers?.map((item,index)=>{
                                return(
                                    <Option value={item?.id} key={index}>{item?.name}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>

                    }

                 {selectedManager&&<Form.Item label={<span className={classes.heading1}>Co-Manager</span>} name="coManagerId">
                        <Select disabled={modal?.type!=="add"  || selectedManager===undefined} size="large"   placeholder="Select">
                            {filter?.coManager?.map((item,index)=>{
                                return(
                                    <Option value={item?.id} key={index}>{item?.name}</Option>
                                )
                            })}

                        </Select>
                    </Form.Item>}
                    <Form.Item label={<span className={classes.heading1}>Team</span>} name="teamId" rules={[{required: true,message: "Please select team",},]} >
                        <Select disabled={(viewClass.manager)?modal?.type!=="add" ||deptId===undefined: modal?.type!=="add" ||selectedManager===undefined} size="large" placeholder="Select">
                        {filter?.teams?.map((item,index)=>{
                                return(
                                    <Option value={item?.id} key={index}>{item?.name}</Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                            
                    <Form.Item label={<span className={classes.heading1}>Start Date</span>} name="startDate" rules={[{required: true,message: "Please select startdate",},]}>
                        <DatePickerCompo locale={locale} onChange={(date, dateString)=>{setStartDate(dateString)}} disabled={modal?.type!=="add"} size="large" format="YYYY-MM-DD" style={{width:"100%"}}/>
                    </Form.Item>

                    <Form.Item label={<span className={classes.heading1}>Due Date</span>} name="dueDate" rules={[{required: true,message: "Please select endDate",},]}>
                        <DatePickerCompo  disabledDate={(current) => {
                                return current && current < moment(startDate);
                            }} size="large" locale={locale} onChange={(date, dateString)=>{setEndDate(dateString)}} format="YYYY-MM-DD" style={{width:"100%"}} />
                    </Form.Item>
                    <Form.Item name="comment" label={<span className={classes.heading1}>Comments</span>} rules={[{required: true,message: "Please enter comments",whitespace:true},]}>
                        <Input.TextArea rows={5} placeholder="Enter text" />
                    </Form.Item>
                    <div style={{display:'flex',justifyContent:"flex-end",gap:'1rem'}}>                       
                        <Form.Item>
                        <ModalSecondaryButton   style={{backgroundColor:"#FFFFFF"}} onCancel={()=>{setModal();}}>Cancel</ModalSecondaryButton>
                        </Form.Item>
                     <Form.Item >
                     <ModalPrimaryButton  htmlType="submit">Save</ModalPrimaryButton>
                        </Form.Item>
                        </div>
           </Form>
          </div>
          
            </Modal>
        </>
    )           
}
export default AddProject;