import React from 'react';

import "./Backdrop.css";

const Backdrop = (props) => {
    const cssClasses = ['Backdrop', props.show ? 'BackdropOpen' : 'BackdropClosed',props.right?"Right":"Center"];

    return <div className={cssClasses.join(" ")}>{props.children}</div>;
};

export default Backdrop;