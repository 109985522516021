import ModalCompo from "../../../design/ModalCompo";
import ProjectIcon from "../../../assets/Project.svg";
import classes from './Project.module.css';
import { Form, Input, Select } from "antd";
import DatePickerCompo from "../../../components/DatePicker/DatePickerCompo.tsx";
import { ModalPrimaryButton, ModalSecondaryButton } from "../../../components/Button/Button";
import { postLogEffort } from "../action/action";
import moment from "moment"


function LogEffort({modal,setModal,role}){
    console.log(modal,role,"modalmodalmodal");
    let modalHeader=<div style={{display:'flex',justifyContent:"center",alignItems:'center',gap:'4px',width:"100%",color:"#0086ff",fontSize:"1.1rem",fontWeight:"600"}}>
    <img src={ProjectIcon}  style={{height:'36px',width:'36px'}}/>
    <div>{modal?.name}</div>
</div>



const LogEffortHandler=(val)=>{
    console.log(val?.effort,"oooopppppoooopp");
    if(val?.effort<=24)
    {
        postLogEffort({date:moment(val?.date).format("YYYY-MM-DD"),logHrs:val?.effort,userId:role?.id,taskId:modal?.id},()=>{setModal()})
      
    }
    else{
        alert("Effort should be less than or equal to 24 hours");
    }
}

    return(
    <ModalCompo open={modal!==undefined}  header={modalHeader} contentStyle={{width:'70vw',top:'10vh'}}>
        <Form layout="vertical" style={{width:'100%',padding:'0.5rem 2rem 1rem 2rem'}} onFinish={LogEffortHandler}>
            {/* <Form.Item  label={<span className={classes.heading1}>Progress(%)</span>} name="progress" >
                <Input   type="number"  placeholder="e.g. 90%" />
            </Form.Item> */}
            <Form.Item label={<span className={classes.heading1}>Select Date</span>} name="date" rules={[{required: true,message: "Please select startDate",},]}>
                <DatePickerCompo disabledDate={(current)=>{ return current && current < moment().subtract(5, 'days')}} format="YYYY-MM-DD"  style={{width:"100%",}}  />
            </Form.Item>
            <Form.Item  label={<span className={classes.heading1}>Effort(hrs)</span>} name="effort" rules={[{required: true,message: "Please enter effort",},]} >
                <Input   type="number"  placeholder="e.g. 5 hrs" />
            </Form.Item>
            <div style={{display:'flex',justifyContent:"flex-end",gap:'1rem'}}> 
                <Form.Item>
                    <ModalSecondaryButton onCancel={()=>{setModal();}}>Cancel</ModalSecondaryButton>
                </Form.Item>
                <Form.Item >
                    <ModalPrimaryButton htmlType="submit">Save</ModalPrimaryButton>
                </Form.Item>
                
            </div>
           </Form>
            </ModalCompo>
    )
}
export default LogEffort;