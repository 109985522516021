import backendUrl  from "../../../credentials";
import  Api from "../../../services/Api";
import { SECRET_KEY, decryptData } from "../../../services/fileCode";

export const baseUrl=backendUrl;
// export const baseUrl="https://newpmt.brigosha.com";
// export const baseUrl="https://eb35-49-249-133-226.ngrok-free.app";
export const updateHeader=()=>{
    let tt=localStorage.getItem('token');
    if(tt){
    let token=decryptData(tt,SECRET_KEY)
    if (token) {
      Api.header(token);
    }
  }
}

export const getAuthId=()=>{
    let us=localStorage.getItem('user');
    if(us){
    let user=decryptData(us,SECRET_KEY)
    if (user) {  
        console.log('kaushal',user)  
  return user?.authUserLoginId;
  } 
}
}
export const getAllJobApi=(param,callback)=>{
Api.get("/hiring-mobile/getAllJobs").params(param).send((res)=>{
    if(res){

        console.log("KAUSHAL KUMAR KESHARVANI in actionjs",res);
        callback(res)
    }
})
}

export const getJobDataApi=(param,callback)=>{
    
    Api.get("/hiring-publicapi/job-description/").params(param).send((res)=>{
        if(res){
            console.log("KAUSHAL KUMAR KESHARVANI in actionjs",res);
            callback(res)
        }
    })
    }

export const ApplyJobApi=(expType,param,callback)=>{

Api.post(`/hiring-mobile/applyJob/?expType=${expType}`).params(param).send((res)=>{
    console.log('Job Applied',res)
    callback(res)
})
}
export const SaveJobApi=(param,callback)=>{
    Api.post('/auth-auth/saveJobs').params(param).send((res)=>{
        callback(res);
    })
}
export const getAllSavedJobApi=(authId,param,callback)=>{
Api.post(`/hiring-mobile/getSavedJobs/?loginId=${authId}`).params(param).send((res)=>{
    callback(res);
})
}

export const UploadFile=(param,callback)=>{

    // Api.post('/hiring-mobile/upload').upload(param,(per,res)=>{
    //     if (res!==undefined && per===100) { 
    //     callback(res?.data,per);
    //     }
    // })

    Api.uploadAndPost('/hiring-mobile/upload',param,(per,res)=>{
        callback(res?.data,per)
    })

}
export const ApplyJobGuestApi=(param,callback)=>{


    Api.uploadAndPost('/hiring-poc/applyForJob',param,(per,res)=>{
        console.log('Job Applied',per,res)
        callback(per,res)
    })
    
}

export const getAccessToken=()=>{
    let tt=localStorage.getItem('token');
    if(tt){
    let token=decryptData(tt,SECRET_KEY)
    if (token) {
     return token;
    }
  }
}


export const getProfileDetailsApi=(email,callback)=>{

    Api.get(`/hiring-mobile/getProfile?email=${email}`).send((res)=>{
        console.log('kaushal htttttpppp',res);
        callback(res);
    })
}
export const getJobDetailsApi=(id,callback)=>{

    Api.get(`/hiring-mobile/gotoApplication?sourceId=${id}`).send((res)=>{
        console.log('kaushal htttttpppp',res);
        callback(res);
    })
}
export const UpdateJobApi=(param,callback)=>{

    Api.patch(`/hiring-mobile/updateApllication`).params(param).send((res)=>{
    console.log('Job Updateddd',res)
    callback(res)
})
}

export const referApi=(param,callback)=>{
    Api.patch('/auth-auth/referAFriend').params(param).send((res)=>{
        callback(res);
    })
}