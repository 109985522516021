import React, { useEffect, useState } from 'react';
import classes from "./attendance.module.css";
import ButtonCompo from '../../design/ButonCompo';
import poweredBy_icon from "../../assets/poweredBy_icon.svg";
import dashboardIcon from "../../assets/Dashboard.svg";
import AttendenceModal from './AttendenceModal';
import OptionalHolidayModal from './OptionalHolidayModal';
import { getCalenderDataApi, getOHDApi } from './Action';
import moment from 'moment';
import Calender from './Calender';
import { SECRET_KEY, decryptData } from '../../services/fileCode';
import { Button } from 'antd';
import { useWindowDimensions } from '../../components/windowDimention/windowDimension';
import { useHeaderStore } from '../Header/store';
import MobileNavModal from '../../components/MobileNavModal/MobileNavModal';

const Attendance = () => {
  const uu=decryptData(localStorage.getItem('user'),SECRET_KEY);
  const user = uu?.resourceUser ;
  const [attendenceModal,setAttendenceModal]=useState(false);
  const [ohdModal,setOhdModal]=useState(false)
  const [calendarTime,setCalenderTime]=useState(moment())
  const [calendarData,setCalenderData]=useState();
  const [webAccess,setWebAccess]=useState(false);
  const [cardData,setCardData]=useState([{type:"Present",count:0,color:'#DAEEFF'},{type:"Leave",count:0,color:'#FFEDD9'},{type:"Lop",count:0,color:'#FFD7D7'},{type:"Manual",count:0,color:'#FFF7D8'},{type:"Half Day",count:0,color:'#EEEEEE'},{type:"Holidays",count:0,color:'#F7D7FF'},{type:"Advanced Leave",count:0,color:'#F7D7FF'},{type:"WFH",count:0,color:'#F7D7FF'},{type:"WFO",count:0,color:'#F7D7FF'}]);
  const [punchId,setPunchId]=useState(null)
  const [total,setTotal]=useState();
  const [location,setLocation]=useState('');
const [loader,setLoader]=useState(false)
const [holidays,setHolidays]=useState([]);
const {width}=useWindowDimensions();
const {sidemenu,setSidemenu}=useHeaderStore();


useEffect(()=>{  
  setLoader(true);
  let param={year:moment(calendarTime).year(),userId:user?.id,month:moment(calendarTime).month()+1}
  getCalenderDataApi(param,(res)=>{
    if(res!==undefined){
      setCalenderData(res);
      setWebAccess(res?.webAccess);
      setPunchId(res?.punchId);
      setCardData([{type:"Present",count:res?.present,color:'#DAEEFF'},{type:'Leave',count:res?.leave,color:'#FFEDD9'},{type:'Lop',count:res?.absent,color:'#FFD7D7'},{type:'Manual',count:res?.manual,color:'#FFF7D8'},{type:'Half',count:res?.half,color:'#EEEEEE'},{type:'Holiday',count:res?.holiday,color:'#F7D7FF'},{type:'Advanced Leave',count:res?.advanced,color:'#F7D7FF'},{type:'WFO',count:res?.wfo,color:'#F7D7FF'},{type:'WFH',count:res?.wfh,color:'#F7D7FF'}])
     
    }
    setLoader(false)
  })
},[calendarTime])

  const refreshCalender=()=>{
    setLoader(true)
  let param={year:moment(calendarTime).year(),userId:user?.id,month:moment(calendarTime).month()+1}
    getCalenderDataApi(param,(res)=>{
      if(res!==undefined){
        setCalenderData(res);
        setWebAccess(res?.webAccess);
        setPunchId(res?.punchId);
        setCardData([{type:"Present",count:res?.present,color:'#DAEEFF'},{type:'Leave',count:res?.leave,color:'#FFEDD9'},{type:'Lop',count:res?.absent,color:'#FFD7D7'},{type:'Manual',count:res?.manual,color:'#FFF7D8'},{type:'Half',count:res?.half,color:'#EEEEEE'},{type:'Holidays',count:res?.holiday,color:'#F7D7FF'},{type:'WFO',count:res?.wfo,color:'#F7D7FF'},{type:'WFH',count:res?.wfh,color:'#F7D7FF'}])
        
      }
      setLoader(false)
    })
  }
  useEffect(()=>{
    let param={};
    getOHDApi(param,(res)=>{
        setHolidays(res?.OptionalHoliday);
        setTotal(res?.userOptionalHoliday);
        setLocation(res?.location)
    })
  },[])
 const reverseDateFormat = (dateString) => {
    const [day, month, year] = dateString.split('-');
    return `${year}-${month}-${day}`;
  }
  
  return (
    <>
    {width<800&&<MobileNavModal setSidemenu={setSidemenu} sidemenu={sidemenu}>
      <div className={classes.sider} onClick={()=>{
        setSidemenu(false);
        window.location.reload();
      }}>
                  <div>
                      <img src={dashboardIcon}/>
                        <span>Dashboard</span>
                </div>
                <img src={poweredBy_icon}></img>
          </div>
    </MobileNavModal>}
    <div className={classes.attendenceContainer}>
          {width>800&&<div className={classes.sider}>
                  <div onClick={()=>{
                    window.location.reload();
                  }}>
                      <img src={dashboardIcon}/>
                        <span>Dashboard</span>
                </div>
                <img src={poweredBy_icon}></img>
          </div>}
    
        <div className={classes.mainContent}> 
           {width > 700 ?
              <div style={{borderBottom:"1px solid #DFDFDF",background:"#fff", padding:width>700?"1rem 2rem":"1rem 0.6rem",display:"flex",flexDirection:"column",gap:"1rem"}}>
                        <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>
                                  {width>700&&<h3 style={{ color:'#162432',fontSize:"1.4rem",fontWeight:"600",width:"100%"}}>Hello, {user?.name}</h3>}
                                  <div style={{display:"flex",justifyContent:width>700?"flex-end":"space-between",flexDirection:"row",gap:"1rem",width:"100%"}}>
                                        <div style={{display:'flex',justifyContent:'center',alignItems:'center',gap:'16px',fontSize:"14px"}}>
                                                {webAccess?<Button title={"Update attendance"}  style={{color:'#0086FF', fontSize:width>700?"0.9rem":"1rem",backgroundColor:'#fff',borderRadius:"5px",height:width>700?"2.2rem":"2.3rem",border:"1px solid #0086ff"}} 
                                                  onClick={()=>{setAttendenceModal(true)}}>Update attendance</Button>:''}
                                        </div>
                                        <Button  style={{color:'white',backgroundColor:'#0086FF',borderRadius:"5px",height:width>700?"2.2rem":"2.3rem",fontSize:width>700?"0.9rem":"1rem"}} 
                                        onClick={()=>{setOhdModal(true)}}>Apply optional holiday</Button>
                                  </div>
                        </div>
                        <div className={classes.cardContainer} style={{gap:"1rem"}}>
                            {cardData?.map((val,id)=>{
                              return(
                                <div className={classes.attendenceCard} 
                                // style={{backgroundColor:val?.color}}
                                style={{width:150, padding:5}}
                                >
                                    <div>{val?.type}</div>
                                    <div>{val?.count?val?.count:0}</div>
                                </div>
                              )
                            })}
                        </div>
              </div>
              :
            <div style={{background:"#fff",display:"flex",flexDirection:"column",gap:"rem"}}>
                <div style={{display:"flex",margin:"1rem 0.6rem 0 0.6rem",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>
                          {width>700&&<h3 style={{ color:'#162432',fontSize:"1.4rem",fontWeight:"600"}}>Hello, {user?.name}</h3>}
                          <div style={{display:"flex",justifyContent:width>700?"flex-end":"space-between",overflow:"auto",flexDirection:"row",gap:"1rem",width:"100%"}}>
                                <div style={{display:'flex',justifyContent:'center',alignItems:'center',gap:'16px',fontSize:"14px"}}>
                                        {webAccess?<Button title={"Update attendance"}  style={{color:'#0086FF', fontSize:width>700?"0.9rem":"1rem",backgroundColor:'#fff',borderRadius:"5px",height:width>700?"2.2rem":"2.3rem",border:"1px solid #0086ff"}} 
                                          onClick={()=>{setAttendenceModal(true)}}>Update attendance</Button>:''}
                                </div>
                                <Button  style={{color:'white',backgroundColor:'#0086FF',borderRadius:"5px",height:width>700?"2.2rem":"2.3rem",fontSize:width>700?"0.9rem":"1rem"}} 
                                onClick={()=>{setOhdModal(true)}}>Apply optional holiday</Button>
                          </div>
                </div>
                <div style={{background:"#F9F9F9", padding:width>700?"1rem 2rem":"1rem 0.7rem",display:"flex",flexDirection:"column",}}>
                  <div className={classes.cardContainers2}>
                  {cardData?.map((val,id)=>{
                    return(
                      <div className={classes.attendenceCard} 
                      // style={{backgroundColor:val?.color}}
                      >
                          <div style={{fontSize:"16px"}}>{val?.type}</div>
                          <div style={{fontSize:"24px"}}>{val?.count?val?.count:0}</div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            }
            
                <div style={{display:'flex', flexDirection:'column',height:'99vh',overflowY:'scroll',gap:'rem',backgroundColor:"#F9F9f9",marginTop:width<700&&"-1rem",}}>
                          <div style={{width:"100%", height:"100%",marginTop:width>700&&'0.1rem',padding:width>700?"0 2rem 2rem 2rem":"1rem",display:"flex",flexDirection:width>700?"row":"column",gap:width>700?"2rem":"1rem"}}>
                                <div style={{display:'flex',flexDirection:"column",width:width>700?"65%":"100%",gap:"1rem"}}>                                    
                                        <span style={{fontWeight:width<700&&550,fontSize:width<700&&"18px"}}>Check your calendar and update attendance.</span>
                                        <Calender userId={user?.uid} loader={loader} calendarTime={calendarTime} setCalenderTime={setCalenderTime} data={calendarData} webAccess={webAccess} punchId={punchId} refresh={refreshCalender.bind(this)} />                                                                              
                                </div>
                                <div style={{display:"flex",gap:"1rem",width:width>700?"35%":"100%",flexDirection:"column"}}>
                                       <span style={{fontWeight:"600",fontSize:"1rem",color:width>700&&"#162432"}}>OPTIONAL HOLIDAYS</span>
                                        <div className={classes.ohdContainer} style={{marginBottom:width<700&&"1rem",height:"",width:width<700&&"100%"}}>
                                        {holidays?.filter(item=>item.checked)?.length===0?<div style={{display:"flex",justifyContent:"center",alignItems:"center",fontWeight:"600",height:"100%"}}>Data not Found</div>:''}
                                      
                                          {holidays?.map((val,id)=>{
                                            let date=reverseDateFormat(val?.date)
                                            if(val?.checked){
                                            return(
                                              <div style={{display:'flex',alignItems:'center',gap:'12px',flexDirection:'row',}}  key={id}>
                                              <div style={{display:'flex',justifyContent:'center',gap:'px',alignItems:'center',padding:'px',flexDirection:'column'}}>
                                              <div> {moment(date).format("Do")}</div>
                                                <div> {moment(date).format("MMM")}</div>
                                              </div>
                                              <div style={{fontSize:"15px",fontWeight:"590"}}>{val?.accountOf}</div>
                                              </div>
                                            )
                                          }
                                          })}
                                        </div>
                                </div> 
                            </div>              
                </div>
        </div>

          {attendenceModal? <AttendenceModal calendarData={calendarData} id={user?.id} modal={attendenceModal} setModal={setAttendenceModal} refresh={refreshCalender.bind(this)} />:''}
          {ohdModal? <OptionalHolidayModal location={location} holidays={holidays} setHolidays={setHolidays} modal={ohdModal} setModal={setOhdModal} total={total} />:''}
    </div>
    </>
  )
}
export default Attendance;