import React, { useState } from "react";
import { Outlet, NavLink, useLocation } from "react-router-dom";
import "../design/framework.css";
import poweredBy_icon from '../assets/poweredBy_icon.svg';
import { useWindowDimensions } from "./windowDimention/windowDimension";
import { Modal } from "antd";
import MobileNavModal from "./MobileNavModal/MobileNavModal";
import { useHeaderStore } from "../pages/Header/store";

function SiderLayout(props) {
  console.log(props.navList,"helloooooo");
  const NavList = props.navList;
  const {width}=useWindowDimensions();
  const {sidemenu,setSidemenu,roleModal, setRoleModal}=useHeaderStore();
  const location=useLocation();
  const activeTabStyle = {
    // width: "90%",
    height: "6vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration:'none',
    background: "#DBF4FF",
    color:'#0086FF',
    fontSize:'0.9rem',
    borderRadius:"10px",
    margin:"0 1rem",
    borderRight:"5px solid #0086FF",
    paddingLeft:"0.5rem"
  };
  const activeTabStyleMob={
    height: "6vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration:'none',
    background: "#DBF4FF",
    color:'#0086FF',
    fontSize:'0.9rem',
    borderRadius:"10px",
    margin:"0 1rem",
    paddingLeft:"0.4rem"
  };

  const inactiveTabStyle = {
    // width: "95%",
    height: "6vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration:'none',
    color: "#ABABAB",
    fontSize:'0.9rem',
    margin:"0 1rem",
  };

  const imageStyle = { width: "2rem", height: "2rem", marginRight: "1rem" };
  const imageStyleYear = { width:width<700?"1.2rem":"1.1rem",marginRight:width<700&&"1.5rem",height:width<700?"1.2rem":"1.1rem",backgroundColor:"white",borderRadius:"50%",opacity:0.6};
  return (
    <div style={{background:'#fff'}}>
    {width<800&&<div>
    <MobileNavModal sidemenu={sidemenu} setSidemenu={setSidemenu}>
      <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",height:"75vh",}}>
    <nav
        style={{
          // width: "24%",
          width:"100%",
          minWidth: "100%",
          background: "#fff",
          display: "flex",
          flexDirection: "column",
          alignItems:"center",
          gap: "1rem",
          paddingTop: "2.5rem",
          justifyContent:"space-between",
          marginBottom:"1rem"
         
        }}
      >
        <div>

        {
            NavList.map((element) => (
                <NavLink
                  key={element.url}
                  to={element.url}
                  onClick={()=>{
                    setSidemenu(false);
                    if(element.url===location.pathname.split('/')[location.pathname.split('/')?.length-1])
                    {
                      window.location.reload();
                    }
                    else if(location.pathname==="/documents")
                    {
                      window.location.reload();
                    }
                  }}
                  style={({ isActive, isPending }) => {
                    return isActive ? activeTabStyleMob : inactiveTabStyle;
                  }}
                  children={({ isActive }) => (
                    <div
                      className="r-ac"
                      style={{backgroundColor:(element.title === "Yearly Logs"&&width>700)&&"white",
                      borderRadius:(element.title === "Yearly Logs"&&width>700)&&"50%",
                      height:(element.title === "Yearly Logs"&&width>700)&&"2rem",
                      width:(element.title === "Yearly Logs"&&width>700)&&"2rem",
                      padding:(element.title === "Yearly Logs"&&width>700)&&"0.4rem",
                      paddingLeft:(element.title === "Yearly Logs"&&width<700)&&"0.4rem",
                      width:"20rem",
                      // border:"2px solid",
                      height:"6vh",
                      // margin:(element.title === "Yearly Logs"&&width>700)&&"0.4rem",
                      // marginRight:element.title === "Yearly Logs"&&"1rem"
                    }}
                    >
                      <img
                        style={element.title === "Yearly Logs" ? imageStyleYear : imageStyle}
                        src={isActive ? element.active : element.inactive}
                        alt={element.title}
                      />
                      <h4 style={{fontWeight:"600",width:"100%"}}>{element.title}</h4>
                    </div>
                  )}
                />
              ))}
        </div>

       
      </nav>
      <div style={{justifyContent:"center",alignItems:"center",display:"flex",marginBottom:"2rem"}}>
          <img src={poweredBy_icon}></img>
      </div>
      </div>
    </MobileNavModal>
    </div>}
    <div
      style={{
        width: "100%",
        height: "90vh",
        display:width<700? "":"flex",
        justifyContent: "space-between",
     
        // border:"2px solid red"
        
      }}
    >
      {props.noNavBar ? <></> :


      width>700?
      <nav
        style={{
          // width: "24%",
          width:"19vw",
          minWidth: "19vw",
          background: "#fff",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          paddingTop: "3rem",
          justifyContent:"space-between",
          borderRight:'1px solid #DFDFDF'
        }}
      >
        <div>

        {
            NavList.map((element) => (
                <NavLink
                  key={element.url}
                  to={element.url}
                  onClick={()=>{
                    console.log("sssspElement",element)
                    if(element.title === "Role lock"){
                      setRoleModal(prevState => !prevState);
                    }else{
                    if(element.url===location.pathname.split('/')[location.pathname.split('/')?.length-1])
                    {
                      window.location.reload();
                    }
                    else if(location.pathname==="/documents")
                    {
                      window.location.reload();
                    }
                    }
                  }}
                  style={({ isActive, isPending }) => {
                    console.log("sssspisActive",isActive)
                    return (element.title === "Role lock" && roleModal) || element.title !== "Role lock" && isActive 
                    ? activeTabStyle : inactiveTabStyle;
                  }}
                  children={({ isActive }) => (
                    <div
                      className="r-ac"
                      style={{ width: "100%",}}
                    >
                      <div style={{backgroundColor:element.title === "Yearly Logs"&&"white",borderRadius:element.title === "Yearly Logs"&&"50%",height:element.title === "Yearly Logs"&&"2rem",width:element.title === "Yearly Logs"&&"2rem",padding:element.title === "Yearly Logs"&&"0.5rem",marginRight:element.title === "Yearly Logs"&&"1rem"}}>
                      <img
                        style={element.title === "Yearly Logs" ? imageStyleYear : imageStyle}
                        src={(element.title === "Role lock" && roleModal) || element.title !== "Role lock" &&isActive ? element.active : element.inactive}
                        alt={element.title}
                      /></div>
                      <h4 style={{fontWeight:"600"}}>{element.title}</h4>
                    </div>
                  )}
                />
              ))}
        </div>

        <div style={{justifyContent:"center",alignItems:"center",display:"flex",marginBottom:"2rem"}}>
          <img src={poweredBy_icon}></img>
        </div>
      </nav>:
      null
      }
      <div
        style={{
          width: width>700?"81vw":"",
          background: "#fff",
          maxWidth:width>700?"81vw":"",
        }}
      >
        {
          props?.customComponenet?.customComp?
          props?.customComponenet?.component:
          <Outlet />

        }
      </div>
    </div>
    </div>
  );
}
export default SiderLayout;