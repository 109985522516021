import Api from "../../../../services/Api";


export const dashboardData = (params, callback) => {
  Api.get("/resource-dashboard/overview").params(params).send((response) => {
    if (response) {
      callback(response);
    }
  });
};
export const projectDashBoard=(params,callback)=>{
  Api.get('/project-dashboard').params(params).send((response)=>{
    if(response){
      callback(response);
    }
  })
}

export const getHodsData = (params, callback) => {
  
  Api.get("/resource-user/dashboardHods", params).send((response) => {
    if (response) {
      callback(response);
    }
  });
};

export const getManagersData = (params, callback) => {
  
  Api.get("/resource-user/dashboardManagers", params).send((response) => {
    if (response) {
      callback(response);
    }
  });
};

export const getTableData = (params, callback) => {
  
  Api.get("/resource-user/allUsers", params).send((response) => {
    if (response) {
      callback(response);
    }
  });
};

export const getDepartmentData = (params, callback) => {
  
  Api.get("/resource-user/dashboardDepts", params).send((response) => {
    if (response) {
      callback(response);
    }
  });
};

export const getTeamData = (params, callback, selectedTeamsDepartmentId) => {
 let departmentFilter = ``

if(selectedTeamsDepartmentId!==undefined && selectedTeamsDepartmentId !== 'All' ){
  departmentFilter = `departmentId=${selectedTeamsDepartmentId}`
}

  Api.get(`/resource-user/teams?${departmentFilter}`).params(params).send((response) => {
    if (response) {
      callback(response);
    }
  });
};

export const getTeamListData = (params, callback, selectedTeamsDepartmentId) => {
  Api.get(`/resource-user/teams`, params).send((response) => {
    if (response) {
      callback(response);
    }
  });
}

export const getProjectByID=(param,callback)=>{
  Api.get(`/project-projects/getProjectById?projectId=${param.projectId}`).send((res)=>{
    if(res!==undefined){
      callback(res);
    }
  })
}