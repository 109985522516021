import { create } from "zustand";
import {persist} from "zustand/middleware";

const localStorageStore=(set)=>({
  profileImage:'',
    setProfileImage:(payload)=>{
        set((state)=>{
            console.log("kaushal",state.profileImage,payload)
            return{
                profileImage:payload
            }
        })
    },

})

const uselocalStorageStore=create(
        persist(localStorageStore,{name:"localData",})
);
export default uselocalStorageStore;