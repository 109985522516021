import React, { useEffect, useState } from 'react'
import ButtonCompo from '../../../../design/ButonCompo';
import AddAssociateModal from './AddAssociateModal';
import { Button, Select, Dropdown, Spin } from 'antd'
import EditProfile from './EditProfile';
import ProfileDetails from './ProfileDetails';
import deleteIcon from "../../../../assets/DeleteBlack.svg";
import calendarIcon from "../../../../assets/CalendarBlack.svg";
import sendIcon from "../../../../assets/Send.svg"
import addAssociate from "../../../../assets/addAssociate.svg";
import userIcon from "../../../../assets/profileIcon.svg"
import cancelIcon from "../../../../assets/RejectIcon.svg";
import classes from "./employee.module.css";
import plusIcon from "../../../../assets/add.svg";
import ascIcon from "../../../../assets/AscendingSortIcon.svg";
import dscIcon from "../../../../assets/DescendingSortIcon.svg";
import YellowEdit from "../../../../assets/editYellow.svg";
import GreenEdit from "../../../../assets/editGreen.svg";
import CalenderIcon from "../../../../assets/calendarIcon.svg";
import DeleteIcon from "../../../../assets/DeleteIcon.svg";
import SendLinkIcon from "../../../../assets/sendLinkIcon.svg";
import { IoIosSend } from "react-icons/io";


import AssignDepartment from './AssignDepartment';
import LeaveAssignModal from './leaveAssignModal';
import DeleteEmployeeModal from './deleteEmployeeModal';
import '../../../../index.css';
import { DownloadApi, getAllDepartment, getAllEmployeeApi, sendMailApi } from './employeeAction';
import moment from 'moment';
import downloadIcon from "../../../../assets/Download Icon Blue.svg";
import HistoryModal from './HistoryModal';
import ActiveModal from './ActiveModal';
import { useResourceStore } from '../../store/store';
import viewClass from '../../../../services/ViewRole';
import DatePickerCompo from '../../../../components/DatePicker/DatePickerCompo.tsx';
import { Pagination, Typography } from 'antd/lib';
import {EditOutlined, MoreOutlined,} from '@ant-design/icons';
import DropDownIcon from '../../../../assets/DropDownIcon.svg';
import FilterIcon from "../../../../assets/filterIcon.svg";
import ModalCompo from '../../../../design/ModalCompo';
import { KeyIsNotEmpty } from './formData';
import SearchCompo from './SearchCompo';
function Employees() {
  const [loader,setLoader]=useState(false);
  const {page,setPage,setTab,setOpenPage}=useResourceStore(state=>state);
  const [addAssociateModal,setAddAssociateModal]=useState(false);
  const [search,setSearch]=useState('');
  const Tab=["Active Users","Inactive Users"];
  const [allEmployee,setAllEmployee]=useState([]);
  const [activeTab,setActiveTab]=useState(0);
  const [sort,setSort]=useState('ASC');
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

 
  const EmailSender=(id)=>{
    let param={userId:id}
      sendMailApi(param,(res)=>{
        console.log(res);
      })
  }
  const DropdownHandler = (key,record) =>{
    if(key?.key==="edit"){
      // setSearch('');
      setPage({curr:"editProfile",prev:'employee',PD:'Profile',});
      setOpenPage(record?.id); 
      setTab("Basic Profile");
      

    }
    else if(key?.key==="assign"){
      setLeaveAssign(record?.id)
    }
    else if(key?.key==="send"){
      EmailSender(record?.id)
  }
  else if(key?.key==="delete"){
    if(record?.status==="Joined" || record?.status==="Released" || (record?.status==="On Notice" && record?.department===null)){
    setDeleteModal({type:'delete',id:record?.id,name:record?.name,releaseDate:record?.departmentReleaseDate,joined:record?.joined})
    }
  }
  else if(key?.key==="activate"){
    setActivateModal({id:record?.id,name:record?.name}) 
  }
}

const InActiveItems=[
  {
    key:'activate',
    label:'Activate',
  },
]
  const ActiveItems=(record)=>
  
  [
    {
        key:"edit",
        label:<div style={{cursor:'pointer',display:'flex',alignItems:'center',gap:'8px'}}>
        <EditOutlined />
         <div>Edit</div>
         </div>
    },
    {
        key:"assign",
        label:<div style={{cursor:'pointer',display:'flex',gap:'8px',alignItems:'center'}}> 
        <img src={calendarIcon} style={{height:'16px',width:'16px'}}></img>
        <div>Approver</div>
        </div>
    },
    {
      key:"send",
      label:<div style={{cursor:'pointer',display:'flex',gap:'8px',alignItems:'center'}}> 
      <img src={sendIcon} style={{height:'16px',width:'16px'}}></img>
      <div> Send Profile</div>
      </div>
  },
  {
      key:"delete",
      label:<div style={{cursor:'pointer',display:'flex',gap:'8px',alignItems:'center'}} className={(record?.status==="Joined" || record?.status==="Released" || (record?.status==="On Notice" && record?.department===null))?"":classes.disabled}> 
      <img src={deleteIcon} style={{height:'16px',width:'16px'}}></img>
      <div> Delete</div>
      </div>
  },
]
const [filterModal,setFilterModal]=useState(false);
  const [deptAssign,setDeptAssign]=useState();
  const [leaveAssign,setLeaveAssign]=useState();
  const [deleteModal,setDeleteModal]=useState();
  const [activateModal,setActivateModal]=useState();
  const [status,setStatus]=useState();
  const [startDate,setStartDate]=useState();
  const [endDate,setEndDate]=useState();
  const [department,setDepartment]=useState();
  const [departmentList,setDepartmentList]=useState([]);
const  statusList=['All','Joined','Assigned','Training','On Job Training','Deployed','Released','On Notice','Exit']
const [historyModal,setHistoryModal]=useState();

const downloadHandler=()=>{
 /*  if(viewClass?.hrAssociate)
  {
    return;
  } */
  let user=activeTab===0?'Active':'InActive';
  let param={search:search,download:true,...getRandomuserParams(tableParams),tab:user,uidFilter:sort}
  if(startDate){
    param={...param,startDate:startDate}
  }
  if(endDate){
    param={...param,endDate:endDate}
  }
  if(status!==undefined && status!=="All"){
    param={...param,status:status==="Assigned"?"Waiting for Approval":status}
  }
  if(department!==undefined && department?.id!=="all"){
    param={...param,departmentId:department}
  }
  DownloadApi(param,(res)=>{
    console.log(res)
    let fileName = res?.split('/')[4];
    const link = document.createElement('a');
    link.download = fileName;
    link.href = res;
    link.click();
  })
}



  const getRandomuserParams = (params) => ({
    limit: params.pagination?.pageSize,
    page: params.pagination?.current,
    
  });
 
  
  const handleTableChange = (page,pageSize ) => {
    
    setTableParams({
      pagination:{
        current:page,
        pageSize:pageSize,
        total:tableParams.pagination.total,
      },         
    });
    let user=activeTab===0?'Active':'InActive';
    let param={search:search,page:page,limit:pageSize,tab:user,uidFilter:sort}
    if(startDate){
      param={...param,startDate:startDate}
    }
    if(endDate){
      param={...param,endDate:endDate}
    }
    if(status!==undefined && status!=="All"){
      param={...param,status:status==="Assigned"?"Waiting for Approval":status}
    }
    if(department!==undefined && department!=="All"){
      param={...param,departmentId:department}
    }
    setLoader(true)
  getAllEmployeeApi(param,(res)=>{
  setAllEmployee(res?.data);
  setLoader(false)
  setTableParams({
    pagination: {
        pageSize:pageSize,
        current:page,
        total:res?.meta?.totalItems,
    },
  });
  })
  };
  const refreshEmployee=()=>{
    setLoader(true);
      let user=activeTab===0?'Active':'InActive';
        let param={search:search,...getRandomuserParams(tableParams),tab:user,uidFilter:sort}
        if(startDate){
          param={...param,startDate:startDate}
        }
        if(endDate){
          param={...param,endDate:endDate}
        }
        
        if(status!==undefined && status!=="All"){
          param={...param,status:status==="Assigned"?"Waiting for Approval":status}
        }
        if(department!==undefined && department!=="All"){
          param={...param,departmentId:department}
        }
        getAllEmployeeApi(param,(res)=>{
          setAllEmployee(res?.data);
          setLoader(false)
          setTableParams({
            ...tableParams,
            pagination: {
              ...tableParams.pagination,
                total:res?.meta?.totalItems,
            },
          });
          })
  
    }
  
    const getNewEmployee=()=>{
      setSearch('');
      setLoader(true);
      let user=activeTab===0?'Active':'InActive';
        let param={search:'',...getRandomuserParams(tableParams),tab:user,uidFilter:sort}
        if(startDate){
          param={...param,startDate:startDate}
        }
        if(endDate){
          param={...param,endDate:endDate}
        }
        
        if(status!==undefined && status!=="All"){
          param={...param,status:status==="Assigned"?"Waiting for Approval":status}
        }
        if(department!==undefined && department!=="All"){
          param={...param,departmentId:department}
        }
        getAllEmployeeApi(param,(res)=>{
          setAllEmployee(res?.data);
          setLoader(false)
          setTableParams({
            ...tableParams,
            pagination: {
              ...tableParams.pagination,
                total:res?.meta?.totalItems,
            },
          });
          })
    
      }
  const  SearchHandler=(search)=>{
    let user=activeTab===0?'Active':'InActive';
    let param={search:search,page:1,limit:tableParams?.pagination?.pageSize,tab:user}
    if(startDate){
      param={...param,startDate:startDate}
    }
    if(endDate){
      param={...param,endDate:endDate}
    }
    
    if(status!==undefined && status!=="All"){
      param={...param,status:status==="Assigned"?"Waiting for Approval":status}
    }
    if(department!==undefined && department!=="All"){
      param={...param,departmentId:department}
    }
    setLoader(true)
  getAllEmployeeApi(param,(res)=>{
  setAllEmployee(res?.data);
  setLoader(false);
  setTableParams({
    pagination: {
        pageSize:tableParams?.pagination?.pageSize,
        current:1,
        total:res?.meta?.totalItems,
    },
  });
  })

  }

  useEffect(()=>{

    if((page.prev==='editProfile' || page.prev==='profileDetail') && page.curr==='employee'){
      refreshEmployee();
    }
  },[page])

  useEffect(()=>{
    viewClass.updateView("employee se")
  if(page.curr==="employee"){
    let user=activeTab===0?'Active':'InActive';
    let param={search:search,page:1,limit:tableParams?.pagination?.pageSize,tab:user,uidFilter:sort}
    if(startDate){
      param={...param,startDate:startDate}
    }
    if(endDate){
      param={...param,endDate:endDate}
    }
    
    if(status!==undefined && status!=="All"){
      param={...param,status:status==="Assigned"?"Waiting for Approval":status}
    }
    if(department!==undefined && department!=="All"){
      param={...param,departmentId:department}
    }
    setLoader(true)
  getAllEmployeeApi(param,(res)=>{
  setAllEmployee(res?.data);
  setLoader(false)
  setTableParams({
    pagination: {
        current:1,
        pageSize:tableParams?.pagination?.pageSize,
        total:res?.meta?.totalItems,
    },
  });
  })
  }
  },[startDate,endDate,status,department,activeTab,sort])
  
  useEffect(()=>{
    getAllDepartment((res)=>{
      if(Array.isArray(res?.allDepartments)){
        setDepartmentList(res?.allDepartments);
      }
    })
  },[])

  return (
    <>
    {page?.curr!=="profileDetail"&&page?.curr!=="editProfile"&&<div className={classes.employeeContainer}>
    <div className={classes.mainDiv}>
          {page?.curr==="employee"?<div style={{display:'flex',flexDirection:'column',gap:'1rem'}}>
            <div className={classes.AssociateHeaderAndSearchAdd} >
             <div>
             <div className='header1Font'>List of Associates</div>
             </div>
          <div style={{display:'flex',gap:'16px',alignItems:'center',}}>
             <div className={classes.SearchDiv}> <SearchCompo value={search}  placeholder="Search by name" style={{minWidth:'30vw',fontWeight:"500",height:"2rem"}} setSearch={setSearch} onSearch={((res)=>{SearchHandler(res)})} ></SearchCompo></div>
            {activeTab===0?<Button disabled={viewClass.hrAssociate} title={"Add Associate"} 
            style={{backgroundColor:viewClass.hrAssociate?'rgb(241, 240, 240)':'#0086FF',color:viewClass.hrAssociate?'#A0A0A0':'white',padding:'10px 15px', height:'100%', borderRadius:"4px",display:"flex",flexDirection:"row",justifyContent:"center",}} 
            onClick={()=>{setAddAssociateModal(true)}} prefixIcon={addAssociate}>Add Associate</Button>:''}
          </div>
            </div>
            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginBottom:'0.4rem',padding:'0rem 2rem'}}>
            <div className={classes.tabContainer}>
              {Tab?.map((val,id)=>{
                return(
                  <div className={activeTab===id?classes.activeTab:''} key={id} onClick={()=>{
                    // setSearch('');
                    setStartDate();
                    setEndDate();
                    setStatus(undefined)
                    setActiveTab(id);
                    setAllEmployee([])
                    setTableParams({
                        pagination: {
                        showSizeChanger:true,
                        current: 1,
                        pageSize: 10,
                      },
                    })
                    setDepartment(undefined);
                    }}>{val}</div>
                )
              })}
            </div>
              <div className={classes.FilterLineWebView} >
                <div>Filter By:</div>
                <DatePickerCompo value={KeyIsNotEmpty(startDate)?moment(startDate):undefined} onChange={(date,dateString)=>{setStartDate(dateString)}} placeholder='Start date of joining' style={{borderRadius:'5px'}} />
                <DatePickerCompo value={KeyIsNotEmpty(endDate)?moment(endDate):undefined} onChange={(date,dateString)=>{setEndDate(dateString)}} placeholder='End date of joining' style={{borderRadius:'5px'}} />
                <Select
                allowClear={true}
                  placeholder="Status" 
                  value={status}
                onChange={(val)=>{setStatus(val)}} style={{width:'10vw',borderRadius:'5px'}} >
                  {statusList?.map((val,id)=>{
                    return (
                      <Select.Option value={val} key={id} >{val}</Select.Option>
                    )
                  })}
                </Select>

                {activeTab===0&&<Select value={department} onChange={(val)=>{setDepartment(val)}} placeholder="Department" style={{width:'10vw',borderRadius:'5px'}}>
                  {[{id:'All',name:'All'}, ...departmentList]?.map((val,id)=>{
                    return(
                      <Select.Option value={val?.id} key={id} >{val?.name}</Select.Option>
                    )
                  })}
                </Select>}
                <ButtonCompo title={"Download"}  style={{color:'#0086ff',width:"6rem"}} suffixIcon={downloadIcon} onClick={downloadHandler}/>


              </div>
              <div className={classes.FilterLineMobView}>
              <img src={FilterIcon} style={{width:"26px",height:'26px',cursor:'pointer'}} onClick={()=>{setFilterModal(true)}}></img>
              <img  style={{width:"26px",height:'26px',cursor:'pointer'}} src={downloadIcon} onClick={downloadHandler}/>
              </div>
            </div>
            <div>
            {/* This is for website handled by mediaquerry setting dispay none on width<700px */}
              <div  className={classes.WebViewList} style={{background:'#f8f8f8',height:'80vh'}}>
                <div className={classes.ListContainer}>
                    <div className={activeTab===0 ?classes.ListHeader:classes.ListHeader2}>
                          
                          <div style={{display:'flex',}}>Emp. ID 
                          {sort === 'ASC' ? <img src={ascIcon} alt='' onClick={()=>{setSort('DESC')}} 
                            style={{cursor:"pointer",marginLeft:"0.2rem"}} /> 
                            :<img src={dscIcon} alt='' onClick={()=>{setSort('ASC');}} style={{cursor:"pointer",marginLeft:"0.2rem"}}/>}
                          </div>
                          <div>Associate Details</div>
                          <div> </div>
                          <div >Date of Joining</div>
                          <div >Status</div>
                      {activeTab===0 && <>
                        <Typography.Text className='header3Font' ellipsis={{tooltip:true}} style={{maxWidth:'6vw'}}>Deployment Date</Typography.Text>
                          {/* <div >Deployment Date</div> */}
                          <div >Department</div>
                          <div >Action</div>
                          </>}
                      {activeTab===1 && <>
                        <div >Last Working Date</div>
                        <div >Action</div>
                      </>
                      }
                    </div>
                    <div className={classes.ListScroll}>
                    <div className={classes.ListBodyContainer} >
                     
                     {loader?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'20vh'}}><Spin /></div>:
                     allEmployee?.length===0?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'20vh'}}><div>No data found !</div></div>:
                      allEmployee?.map((record,id)=>{
                        let n=record?.userFlow?.length;
                        
                        let lastDate=KeyIsNotEmpty(record?.lastWorkingDate)?record?.lastWorkingDate:(record?.userFlow[n-1]?.flowStatus===null?record?.userFlow[n-1]?.date:record?.updatedAt);
                        return(
                          <div className={activeTab===0 ?classes.rowContainer:classes.rowContainer2}>
                          
                          <div>
                          <div className='header4Font'>{record?.uid}</div>
                          </div>
                          <div >
                            <div style={{display:'flex',alignItems:'center',gap:'2px',flexDirection:'row',cursor:'pointer',width:"18vw"}}
                                onClick={()=>{
                                    setPage({curr:"profileDetail",prev:'employee',PD:'Profile',});
                                    setTab("Basic Profile");
                                    setOpenPage(record?.id);
                                    // setSearch('');
                                    }} >
                                  <img src={record?.profileImage?record?.profileImage:userIcon} alt='image' style={{height:'36px',width:'36px',borderRadius:'50%'}}></img>
                                  <div style={{display:'flex',justifyContent:'center',gap:'2px',flexDirection:'column'}}>        
                                  <Typography.Text className='header3Font' ellipsis={{tooltip:true}} style={{maxWidth:'15vw'}}>{record?.name}</Typography.Text>
                                  <div>
                              <Typography.Text ellipsis={{tooltip:true}} style={{maxWidth:'15vw',}} className='header3Font'>{record?.email}</Typography.Text>
                              <div className='header3Font'>{record?.phone}</div>
                              </div>
                                  </div>
                              </div>
                          </div>
                          <div >
                           
                          </div>
                          <div >
                            <div className='header3Font' >{moment(record?.joined).format("DD-MMM-YYYY")}</div>
                          </div>
                          <div >
                          <div onClick={()=>{setHistoryModal({data:record?.userFlow,joined:record?.joined,name:record?.name})}} >
                            <div   ellipsis={{tooltip:true}} className={`header3Font ${record?.status==="On Job Training"?"onJobTraining":record?.status==="On Notice"?'onNotice':record?.status==="Waiting for Approval"?"waiting-for-approval":record?.status}`}> {record?.status}</div>
                            </div> 
                          </div>
                      { activeTab===0  && <>
                        <div >
                            {(record?.dept_assign_date && record?.status!=='Released' )?<div  className='header3Font'>{moment(record?.dept_assign_date).format("DD-MMM-YYYY")}</div>:<span style={{textAlign:"center",width:'65%'}}>--</span>}
                          </div>
                          <div >
                            <div style={{display:'flex',alignItems:'center',width:"100%",}} >
                                {(record?.department===null && activeTab===0)?<div style={{display:"flex",justifyContent:"center",marginLeft:"20%"}}><img src={plusIcon} style={{height:'24px',width:'24px',cursor:'pointer'}} onClick={()=>{ setDeptAssign({id:record?.id,joined:record?.joined})}}/></div>: <div> <div><Typography.Text className='header3Font' ellipsis={{tooltip:true}} style={{maxWidth:'10vw'}}>{record?.department}</Typography.Text></div>{ ((viewClass.hrAdmin || viewClass.hrAssociate) && !viewClass.superAdmin && record?.status==='Waiting for Approval') ?<img src={cancelIcon} onClick={()=>{setDeleteModal({type:'cancelRequest',name:record?.name,id:record?.id})}} style={{height:'18px',width:'18px'}} ></img>:''}</div>
                                }
                            </div>
                          </div>
                          <div >
                            {/* <div >
                              <Dropdown onClick={(e)=>{ console.log('clicked');  e.stopPropagation()}} trigger="hover" menu={{ items:ActiveItems ,onClick:(key)=>{DropdownHandler(key,record)}}}>
                              <MoreOutlined onClick={(e)=>{e.stopPropagation()}}  style={{fontSize:'20px',fontWeight:'600',}} />
                              </Dropdown>
                            </div> */}
                            <div style={{display:'flex', gap:'4px',cursor:'pointer',}}>
                            <img src={record?.submitStatusOfLinkSent?GreenEdit:YellowEdit} alt='1' onClick={()=>{
                                setPage({curr:"editProfile",prev:'employee',PD:'Profile',});
                                setOpenPage(record?.id); 
                                setTab("Basic Profile");
                            }} style={{height:'30px',width:'30px'}}></img>
                            <img style={{height:'30px',width:'30px'}} src={CalenderIcon} alt='2'onClick={()=>{setLeaveAssign(record?.id)}} ></img>
                            {
                              record?.submitStatusOfLinkSent?
                              <img style={{height:'30px',width:'30px'}} src={SendLinkIcon} alt='3' onClick={()=>{EmailSender(record?.id)}} ></img>
                              :
                              <IoIosSend style={{height:'20px',width:'20px', marginTop:5}}  onClick={()=>{EmailSender(record?.id)}} color='orange'/>
                            }
                            <img style={{height:'30px',width:'30px'}} src={DeleteIcon} className={(record?.status==="Joined" || record?.status==="Released" || (record?.status==="On Notice" && record?.department===null))?"":classes.disabled} alt='4' onClick={()=>{setDeleteModal({type:'delete',id:record?.id,name:record?.name,releaseDate:record?.departmentReleaseDate,joined:record?.joined })}}></img>
                          </div>
                          </div> </>}
                      {activeTab===1 && <>

                        <div >
                          <div className="header3Font">
                            {KeyIsNotEmpty(lastDate)?moment(lastDate).format("DD-MMM-YYYY"):'NA'}
                          </div>
                        </div>
                        <div >
                        <div >
                            {/* <Dropdown onClick={(e)=>{ console.log('clicked');  e.stopPropagation()}} trigger="hover" menu={{items:InActiveItems,onClick:(key)=>{DropdownHandler(key,record)}}}>
                            <MoreOutlined onClick={(e)=>{e.stopPropagation()}}  style={{fontSize:'20px',fontWeight:'600'}} />
                            </Dropdown> */}

                            <ButtonCompo onClick={()=>{setActivateModal({id:record?.id,name:record?.name});}} title={"Activate"} style={{color:'#fff',background:'#0086ff',}} />
                            </div>
                        </div>

                      </>}
                          </div>
                        )
                      })}
                    </div>
                    
                    </div>
                <div style={{display:'flex',justifyContent:'flex-end',width:'100%',padding:'0.5rem 2rem 0rem 2rem'}} >
                      <Pagination 
                      onChange={handleTableChange}
                      showSizeChanger={true}
                      showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                       {...tableParams.pagination}
                      />
                </div>
               

                </div>

              </div>
            {/* This is for mobile handled by mediaquerry setting dispay none on width>=700px */}
            
              <div className={classes.MobViewList}>
            <div className={classes.ListScrollMob}>
              <div className={classes.ListContainerMob}>
              {loader?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'20vh'}}><Spin /></div>:
                     allEmployee?.length===0?<div style={{display:'flex',justifyContent:'center',alignItems:'center', height:'20vh'}}><div>No data found !</div></div>:
                      allEmployee?.map((record,id)=>{
                        let n=record?.userFlow?.length;
                        let lastDate=KeyIsNotEmpty(record?.lastWorkingDate)?record?.lastWorkingDate:(record?.userFlow[n-1]?.flowStatus===null?record?.userFlow[n-1]?.date:record?.updatedAt);                       
                        return(
                          <div className={classes.MobListBody}>
                             <div style={{display:'flex',justifyContent:'space-between',width:'100%',alignItems:'center',paddingBottom:'6px'}}>

                                <div style={{display:'flex',alignItems:'center',gap:'8px',flexDirection:'row',cursor:'pointer'}}
                                  onClick={()=>{
                                      setPage({curr:"profileDetail",prev:'employee',PD:'Profile',});
                                      setTab("Basic Profile");
                                      setOpenPage(record?.id);
                                      // setSearch('');
                                      }} >
                                    <img src={record?.profileImage?record?.profileImage:userIcon} alt='image' style={{height:'50px',width:'50px',borderRadius:'50%'}}></img>
                                    <div style={{display:'flex',justifyContent:'center',gap:'8px',flexDirection:'column'}}>        
                                    <Typography.Text className='header3Font' ellipsis={{tooltip:true}} style={{maxWidth:'40vw'}}>{record?.name}</Typography.Text>
                                    <div className='header4Font'>{record?.uid}</div>
                                    </div>
                                    </div>
                                { activeTab===0  && <>    <div >
                                <Dropdown onClick={(e)=>{ console.log('clicked');  e.stopPropagation()}} trigger="hover" menu={{ items:ActiveItems(record) ,onClick:(key)=>{DropdownHandler(key,record,)}}}>
                                <img src={DropDownIcon} onClick={(e)=>{e.stopPropagation()}}   />
                                </Dropdown>
                              </div> </>}
                              {activeTab===1 && <>
                                <div >
                              <Dropdown onClick={(e)=>{ console.log('clicked');  e.stopPropagation()}} trigger="hover" menu={{items:InActiveItems,onClick:(key)=>{DropdownHandler(key,record)}}}>
                              <img src={DropDownIcon} onClick={(e)=>{e.stopPropagation()}}   />
                              </Dropdown>
                              </div></>}
                                </div>
                                <div className={classes.listLine}>
                                  <div className='header4Font'>Date of joining: </div>
                                  <div>{moment(record?.joined).format("DD-MMM-YYYY")}</div>
                                </div>
                                <div className={classes.listLine}>
                                  <div className='header4Font'>Status: </div>
                                  <div className={`header3Font ${record?.status==="On Job Training"?"onJobTraining":record?.status==="On Notice"?'onNotice':record?.status}`}
                                  onClick={()=>{setHistoryModal({data:record?.userFlow,joined:record?.joined,name:record?.name})}}
                                  > {record?.status}</div>
                                </div>
                                { activeTab===0  && <>       <div className={classes.listLine}>
                                  <div className='header4Font'>Deployment Date: </div>
                                  { ( record?.dept_assign_date  && record?.status!=='Released')?<div>{moment(record?.dept_assign_date).format("DD-MMM-YYYY")}</div>:'NA'}
                                </div>
                                <div className={classes.listLine}>
                                  <div className='header4Font'>Department: </div>
                                  <div style={{display:'flex',justifyContent:'center',alignItems:'center'}} >
                                  {(record?.department===null && activeTab===0)?<img src={plusIcon} style={{height:'24px',width:'24px',cursor:'pointer'}} onClick={()=>{ setDeptAssign({id:record?.id,joined:record?.joined})}}/>: <div> <div><Typography.Text className='header3Font' ellipsis={{tooltip:true}} style={{maxWidth:'40vw'}}>{record?.department}</Typography.Text></div>{ ((viewClass.hrAdmin || viewClass.hrAssociate) && !viewClass.superAdmin && record?.status==='Waiting for Approval') ?<img src={cancelIcon}  style={{height:'18px',width:'18px'}} onClick={()=>{setDeleteModal({type:'cancelRequest',name:record?.name,id:record?.id})}} ></img>:''}</div>
                                  }
                            </div>
                              </div>
                              </>}
                              {activeTab===1 && <>
                                <div className={classes.listLine}>
                                <div className='header4Font'>Last working Date: </div>
                                <div>{KeyIsNotEmpty(lastDate)?moment(lastDate).format("DD-MMM-YYYY"):'NA'}</div>
                              </div>
                            
                              </>}

                        </div>)
                      })  } 
                    </div> 
              
                      <div style={{display:'flex',justifyContent:'flex-end',width:'100%',}} >
                      <Pagination 
                       size="small"
                      onChange={handleTableChange}
                      showSizeChanger={true}
                      showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                       {...tableParams.pagination}
                      />
                </div> 
                </div> 
              </div>
            </div>
            </div>:
            ""}
      </div>
    {deleteModal!==undefined? <DeleteEmployeeModal modal={deleteModal} setModal={setDeleteModal} refresh={getNewEmployee.bind(this)} />:''}
    {leaveAssign!==undefined? <LeaveAssignModal modal={leaveAssign} setModal={setLeaveAssign}  />:''}
    {deptAssign!==undefined?<AssignDepartment modal={deptAssign} setModal={setDeptAssign} list={departmentList} refresh={getNewEmployee.bind(this)} />:'' }
   {addAssociateModal===true?<AddAssociateModal open={addAssociateModal} setCloseModal={setAddAssociateModal} refresh={getNewEmployee.bind(this)}></AddAssociateModal>:''}
   {historyModal!==undefined?<HistoryModal modal={historyModal} setModal={setHistoryModal}/>:'' }
    {activateModal!==undefined?<ActiveModal modal={activateModal} setModal={setActivateModal} refresh={getNewEmployee.bind(this)} />:''}
   
    </div>}
    {page?.curr==="profileDetail"?<ProfileDetails departmentList={departmentList} ></ProfileDetails>:""}
    {page?.curr==="editProfile"?<EditProfile  ></EditProfile>:""}
    {filterModal && 
    <ModalCompo contentStyle={{width:'95vw'}} open={filterModal} onCancel={()=>{setFilterModal(false)}} closableRight={true} >

      <div style={{display:'flex',flexDirection:'column',gap:'16px',width:'100%'}}>
                <div className='header4Font'>Select start date of joining</div>
                <DatePickerCompo value={ KeyIsNotEmpty(startDate)?moment(startDate):undefined} onChange={(date,dateString)=>{setStartDate(dateString)}} placeholder='Start date of joining' style={{borderRadius:'5px'}} />
                <div className='header4Font'>Select end date of joining</div>
                <DatePickerCompo value={KeyIsNotEmpty(endDate)?moment(endDate):undefined} onChange={(date,dateString)=>{setEndDate(dateString)}} placeholder='End date of joining' style={{borderRadius:'5px'}} />
                <div className='header4Font'>Select status</div>
                <Select placeholder="Status" onChange={(val)=>{setStatus(val)}} style={{width:'100%',borderRadius:'5px'}} defaultValue={status} >
                  {statusList?.map((val,id)=>{
                    return (
                      <Select.Option value={val} key={id} >{val}</Select.Option>
                    )
                  })}
                </Select>
                
                <div className='header4Font'>Select department</div>
                <Select onChange={(val)=>{setDepartment(val)}} placeholder="Department" style={{width:'100%',borderRadius:'5px'}} defaultValue={department}>
                  {[{id:'All',name:'All'}, ...departmentList]?.map((val,id)=>{
                    return(
                      <Select.Option value={val?.id} key={id} >{val?.name}</Select.Option>
                    )
                  })}
                </Select>
      </div>
    </ModalCompo>
    
    }
    </>
  )
}

export default Employees