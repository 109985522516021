import ModalCompo from "../../../../design/ModalCompo";
import calenderIcon from "../../../../assets/calendarIcon.svg";
import { Form, Select,Spin  } from "antd";
import { useEffect, useState } from "react";
import classes from "./employee.module.css";
import { ModalPrimaryButton, ModalSecondaryButton, ResetButton } from "../../../../components/Button/Button";
import { getLeaveApprovarApi, leaveApproverApi, userListApi } from "./employeeAction";
import axios from "axios";
import Notification from "../../../../services/Notification";
import viewClass from "../../../../services/ViewRole";
function LeaveAssignModal({modal,setModal}){
    let header=<div style={{display:'flex',justifyContent:"flex-start",width:"100%",alignItems:'center',gap:'8px',marginLeft:"-0.5rem"}}>
        <img src={calenderIcon} style={{width:'24px',height:'24px' }}></img>
        <div className={classes.headerApprover} style={{color:'#0FB3FF'}}>Assign Leave Management Approver</div>
    </div>
    const [data,setData]=useState([]);
    const [data1,setData1]=useState([]);
    const [data2,setData2]=useState([]);
    const [search1,setSearch1]=useState("");
    const [search2,setSearch2]=useState("")
    const [defaultApprover,setDefaultApprover]=useState([{},{}]);
    const [isReset,setIsReset]=useState(false);
    const [loader,setloader]= useState(false)
    const refreshApprover=()=>{
        getLeaveApprovarApi({userId:modal},(res)=>{
            setDefaultApprover(res);
        })
    }
const resetHandler=()=>{

    console.log("reset")
    let param={action:'reset',userId:modal,approvers:{}}
    leaveApproverApi(param,(res)=>{
        if(res){
            refreshApprover();
            setIsReset(false);
             setModal();
        }
    })
}

const saveHandler=(val)=>{
    if(isReset)
    {
        resetHandler();
        return;
    }
    else if(val['first']===defaultApprover?.approverOne?.id&&val['second']===defaultApprover?.approverTwo?.id)
    {
        Notification.error("No changes have been done!")
        return;
    }
    console.log("save",val)
    let approvers={};
    let param={approvers:[val?.first],action:'set',userId:modal}
    if(val?.first){
       approvers={...approvers,approverOne:val?.first}
    }
    if(val?.second){
       approvers={...approvers,approverTwo:val?.second}
    }
    param={...param,approvers}
    leaveApproverApi(param,(res)=>{
        if(res){
            refreshApprover();
            setModal();
        }
    })
}

useEffect(()=>{
    // setloader(true)
    getLeaveApprovarApi({userId:modal},(res)=>{
        setDefaultApprover(res);
        // setloader(false);
    })
},[])

useEffect(()=>{
    setloader(true)
    let param={search:search1}
    userListApi(param,(res)=>{
        setloader(false);
        setData(res);
    })
},[])
    useEffect(()=>{
    setData1(
        data.filter((val)=>val?.name?.toLowerCase()?.includes(search1))
    );
    },[search1,data])

    useEffect(()=>{
        setData2(
            data.filter((val)=>val?.name?.toLowerCase()?.includes(search2))
        );
    },[search2,data])

    


    return(
        <ModalCompo open={modal} onCancel={()=>{setModal()}} header={header} closableRight={true} className={classes.AssignLeaveModal} >
            <>
                {
                    loader?
                    <div style={{display:"flex", width:"100%", alignItems:"center", justifyContent:"center",maxHeight:'70vh' }}>
                    <Spin></Spin>
                    </div>
                    :
                    <Form  fields={[{name:'first',value:defaultApprover?.approverOne?.id},{name:'second',value:defaultApprover?.approverTwo?.id}]} layout="vertical" style={{width:'90%',maxHeight:'70vh'}} onFinish={saveHandler}>
                    <Form.Item style={{width:'100%'}} name="first" label={<div className={classes.labelHeading}>Approver 1</div>} >
                         <Select
                         placeholder="type name"
                         onSearch={(val)=>{setSearch1(val)}}
                         showArrow={false}
                         showSearch
                         filterOption={false}
                         // defaultValue={4}
                          >
                             {data1?.map((val,id)=>{
                                 return(
                                     <Select.Option value={val?.id} key={id}>
                                         {val?.name}
                                     </Select.Option>
                                 )
                             })}
                          </Select>
                            
                     </Form.Item>
                     <Form.Item style={{width:'100%'}} name="second" label={<div className={classes.labelHeading}>Approver 2</div>} >
                         <Select
                         placeholder="type name"
                         onSearch={(val)=>{setSearch2(val)}}
                         showArrow={false}
                         showSearch
                         filterOption={false}
                          >
                          {data2?.map((val,id)=>{
                                 return(
                                     <Select.Option value={val?.id} key={id}>
                                         {val?.name}
                                     </Select.Option>
                                 )
                             })}
                             </Select>
                            
                     </Form.Item>
                    <div style={{display:'flex',justifyContent:'center',width:"100%",flexDirection:"row",gap:'1rem',alignItems:'center',marginTop:'20px'}}>
                    
                     <Form.Item>
                     <ResetButton title={"Reset"} disabled={viewClass?.hrAssociate} onClick={()=>{setIsReset(true)}}></ResetButton>
                     </Form.Item>
                         <Form.Item>
                         <ModalSecondaryButton style={{backgroundColor:"white"}} onCancel={()=>{setModal()}}>Cancel</ModalSecondaryButton>
                     </Form.Item>
                     <Form.Item>
                         <ModalPrimaryButton disabled={viewClass?.hrAssociate} htmlType="submit">Save</ModalPrimaryButton>
                         </Form.Item>
                     </div>
                    </Form>

                }
            </>
  
        </ModalCompo>
    )
}
export default LeaveAssignModal