import {  Checkbox, Form, Input, Select } from "antd";
import ProjectIcon from "../../../assets/Project.svg";
import classes from "./Project.module.css";
import { ModalPrimaryButton, ModalSecondaryButton } from "../../../components/Button/Button";
import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { AddTaskApi, calculateHrs, updateTaskApi, } from "./ProjectAction";
import Notification from "../../../services/Notification";
import DatePickerCompo from "../../../components/DatePicker/DatePickerCompo.tsx";
import Modal from "../../../components/Modal/Modal";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";


function AddTask({modal,setModal,refresh}){
    const Option=Select.Option;
    const [startDate,setStartDate]=useState();
    const [endDate,setEndDate]=useState();
    const [effort,setEffort]=useState();
    const [userId, setuserId] = useState();
    const [availabeHours,setaval]=useState(0);
    const [taskId, settask] =useState();
const  statusList=['New',"Completed","On Hold","Suspended",]
const [checkedValues, setCheckedValues] = useState([]);
const [workingHours,setWorkingHours]=useState(8.5);
const {width}=useWindowDimensions();

useEffect(()=>{
    if(modal?.type==="edit"){
        setEndDate(modal?.data?.dueDate);
        setStartDate(modal?.data?.startDate);
        setEffort(modal?.data?.plannedEffort);
        setuserId(modal?.data?.userId);
        settask(modal?.data?.id)
        setaval(modal?.data?.actualEffort)
        setWorkingHours(modal?.data?.weekend!==null?modal?.data?.weekend?.rate:8.5)   
        if(modal?.data?.weekend?.saturday){
            setCheckedValues([...checkedValues,"Saturday"]);
        }
        if(modal?.data?.weekend?.sunday){
            setCheckedValues([...checkedValues,"Sunday"]);
        }


    }
},[])
const disabledEndDate = (endValue) => {
    if (!startDate || !endValue) {
      return false;
    }
    return endValue.isBefore(startDate, 'day');
  };
  function disabledPast7Days(current) {
    return current && current < moment().endOf('day').subtract(7, 'days');
  }

  const Calculate=()=>{
    let weekEnd=[];
    checkedValues?.forEach(element => {
        if(element==="Sunday")
        {
            weekEnd.push(parseInt(0))
        }
        else if(element==="Saturday")
        {
            weekEnd.push(parseInt(6));
        }
        
    });
    if(((endDate!==undefined && endDate) && userId!==undefined &&( startDate!==undefined && startDate))){

        calculateHrs({userId:userId!==undefined&&userId,startDate:startDate!==undefined&&moment(startDate).format("YYYY-MM-DD"),endDate:endDate!==undefined&&moment(endDate).format("YYYY-MM-DD"),taskId:modal?.type==="edit"?taskId:undefined,
        day:weekEnd,
        rate:workingHours,
    },(res)=>{
            setaval(res)
        })
    }
  }
    // useEffect(()=>{
    //     let weekEnd=[];
    //     checkedValues?.forEach(element => {
    //         if(element==="Sunday")
    //         {
    //             weekEnd.push(parseInt(0))
    //         }
    //         else if(element==="Saturday")
    //         {
    //             weekEnd.push(parseInt(6));
    //         }
            
    //     });
    //     if(((endDate!==undefined && endDate) && userId!==undefined &&( startDate!==undefined && startDate))){

    //         calculateHrs({userId:userId!==undefined&&userId,startDate:startDate!==undefined&&moment(startDate).format("YYYY-MM-DD"),endDate:endDate!==undefined&&moment(endDate).format("YYYY-MM-DD"),taskId:modal?.type==="edit"?taskId:undefined,
    //         day:weekEnd,
    //         rate:workingHours,
    //     },(res)=>{
    //             setaval(res)
    //         })

    //     }
    // },[endDate,userId,startDate,workingHours,checkedValues])

    const effortPercent=useMemo(()=>{
        let ans= Math.round((effort*100)/availabeHours)
        return ans;
        
    },[effort,availabeHours])

let modalHeader=<div style={{display:'flex',justifyContent:"center",alignItems:'center',gap:'4px',width:"100%",color:"#0086ff",fontSize:"1.1rem",fontWeight:"600"}}>
                        <img src={ProjectIcon}  style={{height:'36px',width:'36px'}}/>
                        <div>{modal?.type==="add"?"Add Task":modal?.type==="edit"?"Edit Task":''}</div>
                    </div>

    const TaskHandler=(val)=>{
        if(modal?.type==="add"){
            if(val?.name?.trim()?.length===0){
                Notification.error("error","please enter task name")
            }
            // actualEffort:val?.actualEffort,
            console.log(val,"89898989898700000");
            let weekEnd=[];
            checkedValues?.forEach(element => {
                if(element==="Sunday")
                {
                    weekEnd.push(parseInt(0))
                }
                else if(element==="Saturday")
                {
                    weekEnd.push(parseInt(6));
                }})
        let param={
            taskName:val?.name,userId:val?.assignTo,startDate:startDate,dueDate:endDate,plannedEffort:val?.plannedEffort,comment:val?.comment,actualEffort:val?.actualEffort,
             allocation:val?.allocation,
             day:weekEnd,
             rate:workingHours,
            }
        console.log(val,param)
        AddTaskApi(modal?.id,param,(res)=>{
            if(res){
                setModal();
                refresh();
            }
        })
        }
        else if (modal?.type==="edit"){
            console.log("editing",val)
            let param={}
            if(val?.name!==modal?.data?.name){
                if(val?.name?.trim()?.length===0){
                    Notification.error("error","please enter task name");
                    return;
                }
                param={...param,taskName:val?.name}
            }
            if(val?.status!==modal?.data?.status){
                param={...param,status:val?.status}
            }
            if(moment(val?.dueDate).isSame(moment(modal?.data?.dueDate))===false){
                
                param={...param,dueDate:endDate,}
                // plannedEffort:val?.plannedEffort,comment:val?.comment,actualEffort:val?.actualEffort, allocation:val?.allocation
                param={...param,allocation:val?.allocation,actualEffort:val?.actualEffort}
            }
            if(val?.plannedEffort!=modal?.data?.plannedEffort){
                param={...param,plannedEffort:val?.plannedEffort}
            }
            if(workingHours)
            {
                param={...param, rate:workingHours,}
            }
            if(checkedValues)
            {
                let weekEnd=[];
                checkedValues?.forEach(element => {
                    if(element==="Sunday")
                    {
                        weekEnd.push(parseInt(0))
                    }
                    else if(element==="Saturday")
                    {
                        weekEnd.push(parseInt(6));
                    }})
                    param={...param,day:weekEnd}
            }
            if(!checkedValues)
            {
                param={...param,day:[]}
            }
            if(val?.comment!==modal?.data?.comment){
                param={...param,comment:val?.comment}
            }
         
            console.log(param);
            if(Object.keys(param).length!==0){
            updateTaskApi(modal?.data?.id,param,(res)=>{
                if(res){
                        setModal();
                        refresh();
                }
            })}
            else{
                setModal();
            }
        }

    }        
    console.log(modal?.data?.allocation?.slice(0,2),"effortPercent:modal?.data?.allocation")        
    return(
        <>
            <Modal header={modalHeader} show={modal!=undefined} onCancel={()=>{setModal()}} closableRight={true} style={{width:width<700?"95vw":'75vw',height:'85vh',overflowY:"auto"}} >
           <Form 
           fields={[{name:"day",value:checkedValues},{ name: "actualEffort", value: availabeHours },{name:"allocation",value:(modal?.type==="edit"&& effortPercent===Infinity)?modal?.data?.allocation?.slice(0,2):effortPercent}]} 
           initialValues={modal?.type==="edit"?{name:modal?.data?.name,status:modal?.data?.status,assignTo:modal?.data?.associate, startDate:moment(modal?.data?.startDate),dueDate:moment(modal?.data?.dueDate),comment:modal?.data?.comment,plannedEffort:parseFloat(modal?.data?.plannedEffort)}:null} 
           layout="vertical" style={{width:'100%',padding:'0.5rem 2rem 1rem 2rem'}} onFinish={TaskHandler}>
            <Form.Item  label={<span className={classes.heading1}>Name</span>} name="name" rules={[{required: true, message: "Please enter task name",},]}>
                <Input placeholder="Enter task name"  />
            </Form.Item>
            {modal?.type==="edit"? <Form.Item name="status"  label={<span className={classes.heading1}>Status</span>} >
                <Select >
                {statusList?.map((val,id)=>{
                    return(
                        <Select.Option key={id} value={val} >{val}</Select.Option>
                    )
                })}
                </Select>
            </Form.Item>:null}
            <Form.Item  label={<span className={classes.heading1}>Assign To</span>} name="assignTo" rules={[{required: true,message: "Please select assignee"},]}>
                        <Select disabled={modal?.type!=="add"}  onChange={(value)=>{
                            setuserId(value)
                        }} placeholder="Select">
                            {modal?.userList?.map((item,index)=>{
                                return(
                                    <Option value={item?.MembersId} key={index}>{item?.membersName}</Option>
                                )
                            })}
                        </Select>
            </Form.Item>
            <Form.Item label={<span className={classes.heading1}>Start Date</span>} name="startDate" rules={[{required: true,message: "Please select startdate",},]}>
                <DatePickerCompo disabledDate={disabledPast7Days} disabled={modal?.type!=="add"} style={{width:"100%",}} format="YYYY-MM-DD"  onChange={(date,dateString)=>{setStartDate(dateString);}} />
            </Form.Item>

            <Form.Item label={<span className={classes.heading1}>Due Date</span>} name="dueDate" rules={[{required: true,message: "Please select endDate",},]}>
                <DatePickerCompo disabledDate={disabledEndDate} format="YYYY-MM-DD"  style={{width:"100%",}} onChange={(date,dateString)=>{setEndDate(dateString);}} />
            </Form.Item>

            <Form.Item label={<span className={classes.heading1}>Include Weekends</span>} name="day">
                            <Checkbox.Group
                            className={classes.weekend}
                        options={['Saturday', 'Sunday']} // Provide an array of options
                        onChange={(checkedValue)=>{
                            console.log("checkedValue",checkedValue)
                         
                            
                       setCheckedValues(checkedValue)
                        }}
                        />
                        {console.log("checkedValues",checkedValues)}

            </Form.Item>

            <div className={classes.workingHours}>
                <span  className={classes.heading1}>Working Hours (hrs) 
                <span
                style={{fontSize:"10px",fontWeight:"500"}}
                >minimum hours: 8.5</span></span>
                <div>
                        <span 
                        onClick={()=>{
                            setWorkingHours(prev=>{
                             
                                if(prev>8.5)
                                {
                                    return prev-0.5;
                                }
                                return prev;
                            })
                        }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="2" viewBox="0 0 18 2" fill="none">
                                    <path d="M17 1H1" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                        </span>
                        <span>{workingHours}</span>
                        <span
                         onClick={()=>{
                            setWorkingHours(prev=>{
                                if(prev<24){
                                    return prev+0.5;

                                }else{
                                    return prev
                                }
                            })
                        }}
                        >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                    <path d="M15.9375 7.4375H9.5625V1.0625C9.5625 0.780707 9.45056 0.510457 9.2513 0.311199C9.05204 0.111942 8.78179 0 8.5 0C8.21821 0 7.94796 0.111942 7.7487 0.311199C7.54944 0.510457 7.4375 0.780707 7.4375 1.0625V7.4375H1.0625C0.780707 7.4375 0.510457 7.54944 0.311199 7.7487C0.111942 7.94796 0 8.21821 0 8.5C0 8.78179 0.111942 9.05204 0.311199 9.2513C0.510457 9.45056 0.780707 9.5625 1.0625 9.5625H7.4375V15.9375C7.4375 16.2193 7.54944 16.4895 7.7487 16.6888C7.94796 16.8881 8.21821 17 8.5 17C8.78179 17 9.05204 16.8881 9.2513 16.6888C9.45056 16.4895 9.5625 16.2193 9.5625 15.9375V9.5625H15.9375C16.2193 9.5625 16.4895 9.45056 16.6888 9.2513C16.8881 9.05204 17 8.78179 17 8.5C17 8.21821 16.8881 7.94796 16.6888 7.7487C16.4895 7.54944 16.2193 7.4375 15.9375 7.4375Z" fill="#162432"/>
                                    </svg>
                        </span>

                        <div 
                        onClick={()=>{
                            Calculate();
                        }}
                        >Calculate</div>
                </div>
            </div>

            <Form.Item  label={<span className={classes.heading1}>Available Hours (hrs)</span>} name="actualEffort" >
                <Input readOnly  placeholder="e.g.k. 28" value={availabeHours} />

            </Form.Item>
            <Form.Item  label={<span className={classes.heading1}>Planned Effort (hrs)</span>} name="plannedEffort" rules={[{required: true,message: "Please enter planned hours"},]}>
                <Input disabled={!availabeHours} type="number"  placeholder="e.g. 28" onChange={(val)=>{setEffort(val?.target?.value)}} />
            </Form.Item>
            <Form.Item  label={<span className={classes.heading1}>Allocation(%)</span>} name="allocation" >
                <Input  readOnly type="number"  placeholder="e.g. 28" />
            </Form.Item>
                   
            <Form.Item name="comment" label={<span className={classes.heading1}>Comments</span>}>
                <Input.TextArea rows={5} placeholder="type here" />
            </Form.Item>
            <div style={{display:'flex',justifyContent:"center",gap:'1rem'}}> 
                <Form.Item >
                    <ModalPrimaryButton htmlType="submit">Save</ModalPrimaryButton>
                </Form.Item>
                <Form.Item>
                    <ModalSecondaryButton onCancel={()=>{setModal();}}>Cancel</ModalSecondaryButton>
                </Form.Item>
            </div>
           </Form>
            </Modal>
        </>
    )   
}
export default AddTask;
