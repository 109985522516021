import React, { useEffect, useState } from 'react'
import classes from './DeatilsModal.module.css'
import Button from '../../../components/buttonCustom'

import { useProjecteStore } from '../store/store'
import { Checkbox, Switch, Space, Spin, Tooltip } from 'antd'
import { postTaskLog } from '../action/action'
import { getTasks } from './Action'
import { useLogStore } from './Store'
import profileIcon from '../../../assets/Profile Icon.svg';
import moment from 'moment'
import viewClass from '../../../services/ViewRole'
import Notification from '../../../services/Notification'
import { useWindowDimensions } from '../../../components/windowDimention/windowDimension'
import { NoData } from '../../Training_essentialDocuments'
const Label = ({ text }) => {
  return <h5 style={{ color: '#999999', margin: '10px 0 10px 0', fontWeight: '600' }}>{text}</h5>;
};

const ResText = ({ text, textWidth = 5 }) => {
  return (
    <Tooltip title={text}>
      <h5 style={{ color: 'black', margin: '10px 0 10px 0', fontWeight: '600', width: `${textWidth}rem`, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{text}</h5>
    </Tooltip>)
};



const DetailsModal = ({ open, close, logDate, isCoManager, onAction, tabs }) => {
  const { recordStore, taskstore, setTaskstore } = useLogStore();

  const [selectAll, setSelectAll] = useState(false)
  const [loading, setloading] = useState(true);
  const [completeTask, setCompleteTask] = useState();
  const [selectedList, setSelectedList] = useState([]);
  const [data, setdata] = useState([]);
  const { width } = useWindowDimensions();
  useEffect(() => {

    console.log("selected", taskstore)
  }, [completeTask, selectedList, selectAll])

  const CompleteHandler = (checked, taskId, index) => {

    console.log("completed,", checked, taskId, taskstore?.tableData[index])
    if (taskstore?.tableData[index]?.completed !== checked) {
      setCompleteTask({ ...completeTask, [taskId]: checked })
    }
    else {
      console.log("idhrrr 1")
      setCompleteTask((curr) => {
        console.log("idhrrr 2", curr)
        const copy = { ...curr };
        delete copy[taskId];
        console.log("idhrrr 3", copy)
        return copy;
      })
    }
    // const updatedTableData = taskstore.tableData.map((item, i) => {
    //   if (i === index) {
    //     return {
    //       ...item,
    //       selected: checked,
    //     };
    //   }
    //   return item;
    // });

    // setTaskstore((prevTaskstore) => ({
    //   ...prevTaskstore,
    //   tableData: updatedTableData,
    // }));

    // if (checked) {
    //   setSelectedTask((prevSelected) => [...prevSelected, taskId]);
    // } else {
    //   setSelectedTask((prevSelected) =>
    //     prevSelected.filter((id) => id !== taskId)
    //   );
    // }
  };
  const SelectHandler = (checked, taskId, index) => {
    console.log("alll", checked)
    if (checked === 'all') {
      console.log("alll", checked)
      let data = [];
      taskstore?.tableData?.forEach(val => {
        data.push(val?.taskLogId)
      });
      setSelectedList(data);
    }
    else if (checked === true) {
      setSelectedList([...selectedList, taskId])
    }
    else if (checked === false) {
      setSelectedList((curr) =>
        curr?.filter((val) => val !== taskId))
    }

  }




  useEffect(() => {
    setloading(true)
    let param = {
      userId: recordStore?.userId,
      startDate: logDate?.start, endDate: logDate?.end
    }
    getTasks(param, (res) => {
      {
        setTaskstore(res)
        const update = res?.tableData?.map((item) => ({
          ...item,
          selected: false,
          show: item.isApproved ? 'approved' : 'Pending',
          enable: item.isApproved ? true : false,
        }))
        setdata(update)
        setloading(false);
      }
    }

    )

  }, [])



  const approveHandler = () => {
    let check = data?.some(item => (item?.selected))

    if (!check) {
      Notification.error("error", "Please Select any item")
    } else {

      const selectedTaskLogIds = data?.filter((item) => item.selected).map((item) => item.taskLogId);
      console.log(selectedTaskLogIds);
      const completedTasksObj = data?.reduce((acc, item) => {
        if (item.selected) {
          acc[item.taskLogId] = item.completed;
        }
        return acc;
      }, {});

      postTaskLog({ action: true, data: completedTasksObj, status: selectedTaskLogIds }, (res) => {
        if (res) {
          setCompleteTask();
          setSelectedList([]);
          close(false)
          onAction();

        }
      })

      // setSelectedTask([])
    }
  }
  const rejectHandler = () => {
    let check = data?.some(item => (item?.selected))
    console.log(check);
    if (!check) {
      Notification.error("error", "Please Select any item")
    } else {
      const selectedTaskLogIds = data?.filter((item) => item.selected).map((item) => item.taskLogId);

      const completedTasksObj = data?.reduce((acc, item) => {
        if (item.selected) {
          acc[item.taskLogId] = item.completed;
        }
        return acc;
      }, {});
      postTaskLog({ action: false, status: selectedTaskLogIds, data: completedTasksObj }, (res) => {
        if (res) {
          setCompleteTask();
          setSelectedList([]);
          close(false)
          onAction();

        }
      })

    }
  }



  return (
    <div style={{ paddingLeft: 10, paddingRight: 10 }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '12px', backgroundColor: 'white', paddingLeft: 15 }}>
        <img src={profileIcon} style={{ width: '24px', height: '24px' }}></img>
        <div>{recordStore?.name}</div>
      </div>
      <div className={classes.infoRow} style={{ flexDirection: width < 700 ? "column" : "row" }}>
        <div style={{ display: 'flex', gap: '10px', paddingLeft: 16 }}>
          <Label text="Date Range" />

          {
            tabs === "Log" ?
              <>
                <ResText textWidth={15} text={moment(taskstore?.startDate).format("YYYY-MM-DD") + " to " + moment(taskstore?.endDate).format("YYYY-MM-DD")} />

              </>
              :
              <>
                <ResText textWidth={15} text={moment().subtract(1, 'months').format("YYYY-MM-DD") + " to " + moment().format("YYYY-MM-DD")} />

              </>
          }
        </div>
        <div style={{ justifyContent: width < 700 ? "flex-end" : null, width: width < 700 ? "100%" : null }}>
          {
            taskstore?.tableData?.length ?
              <div>
                {(viewClass.superAdmin || viewClass.hod || viewClass.manager) ? <Button name={selectAll ? 'Unselect All' : "Select All"} background={'#0086FF'} onclick={() => {
                  SelectHandler("all");
                  setSelectAll(!selectAll)
                  const update = data?.map((item) => ({
                    ...item,
                    selected: item?.enable ? item?.selected : !selectAll
                  }))
                  // console.log("jddjdjdj",update);
                  setdata(update)
                }} fontColor={'#FFFFFF'} fontSize={12} /> : null}
              </div>
              : null
          }
        </div>
      </div>
      {
        loading && <Spin style={{ alignSelf: 'center', alignItems: 'center', marginLeft: '50%', height: 400 }} size="large" />
      }
      <div style={{ marginTop: '5px', height: data?.length ? 400 : 0, display: "flex", flexWrap: "wrap", overflow: "auto", gap: 40 }}>



        {false ? (
          <></>
        ) : (
          data.map((item, index) => (
            <>
              <div style={{ width: width < 700 ? "100%" : "45%", height: 200, marginLeft: 20 }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>

                  <ResText textWidth={10} text={item?.projectName} />

                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginRight: 10 }}>
                    <Label style={{ fontColor: "black" }} text="Select" />
                    <div style={{ marginLeft: 5 }}>


                      <Checkbox disabled={item?.enable} checked={item?.selected} onChange={(e) => {
                        const updatedData = data.map((dataItem, dataIndex) => ({
                          ...dataItem,
                          selected: dataIndex === index ? e.target.checked : dataItem.selected
                        }));
                        setdata(updatedData);

                        SelectHandler(e.target.checked, item?.taskLogId, index);
                      }} />


                    </div>
                  </div>
                </div>
                <div style={{ width: "100%", height: width < 700 ? "fit-content" : 150, backgroundColor: "#E9E9EB", padding: 10, display: "flex", flexWrap: "wrap", overflow: "auto", gap: 5, flexDirection: width < 700 ? "column" : "row" }}>
                  <div style={{ width: width < 700 ? "100%" : "45%", height: "fit-content", display: "flex", justifyContent: "space-between", marginRight: 10 }}>
                    <div style={{ color: '#999999', fontWeight: '600', fontSize: 12 }}>Date</div>
                    <Tooltip title={item?.date}>
                      <div style={{ fontWeight: '600', fontSize: 12 }}>{item?.date}</div>
                    </Tooltip>
                  </div>
                  <div style={{ width: width < 700 ? "100%" : "45%", height: "fit-content", display: "flex", justifyContent: "space-between", marginRight: 10 }}>
                    <div style={{ color: '#999999', fontWeight: '600', fontSize: 12 }}>Project</div>
                    <Tooltip title={item?.projectName}>
                      <div style={{ fontWeight: '600', fontSize: 12, width: "6rem", textOverflow: "ellipsis", overflow: "hidden", textAlign: "right", whiteSpace: "nowrap" }}>{item?.projectName}</div>
                    </Tooltip>
                  </div>
                  <div style={{ width: width < 700 ? "100%" : "45%", height: "fit-content", display: "flex", justifyContent: "space-between", marginRight: 10 }}>
                    <div style={{ color: '#999999', fontWeight: '600', fontSize: 12 }}>Task</div>
                    <Tooltip title={item?.taskName}>
                      <div style={{ fontWeight: '600', fontSize: 12, width: "6rem", textOverflow: "ellipsis", overflow: "hidden", textAlign: "right", whiteSpace: "nowrap" }}>{item?.taskName}</div>
                    </Tooltip>
                  </div>
                  <div style={{ width: width < 700 ? "100%" : "45%", height: "fit-content", display: "flex", justifyContent: "space-between", marginRight: 10 }}>
                    <div style={{ color: '#999999', fontWeight: '600', fontSize: 12 }}>Effort</div>
                    <Tooltip title={item?.effort}>
                      <div style={{ fontWeight: '600', fontSize: 12 }}>{item?.effort}</div>
                    </Tooltip>
                  </div>
                  <div style={{ width: width < 700 ? "100%" : "45%", height: "fit-content", display: "flex", justifyContent: "space-between", marginRight: 10 }}>
                    <div style={{ color: '#999999', fontWeight: '600', fontSize: 12 }}>Progress</div>
                    <Tooltip title={item?.progress}>
                      <div style={{ fontWeight: '600', fontSize: 12 }}>{item?.progress}</div>
                    </Tooltip>
                  </div>
                  <div style={{ width: width < 700 ? "100%" : "45%", height: "fit-content", display: "flex", justifyContent: "space-between", marginRight: 10 }}>
                    <div style={{ color: '#999999', fontWeight: '600', fontSize: 12 }}>Working Hours</div>
                    <Tooltip title={item?.workingHours}>
                      <div style={{ fontWeight: '600', fontSize: 12 }}>{item?.workingHours}</div>
                    </Tooltip>
                  </div>

                  <div style={{ width: width < 700 ? "100%" : "45%", height: "fit-content", display: "flex", justifyContent: "space-between" }}>
                    <div style={{ color: '#999999', fontWeight: '600', fontSize: 12 }}>Completed</div>
                    <Switch defaultChecked={item?.completed} disabled={item?.enable} onChange={(checked) => {

                      const updatedData = data.map((dataItem, dataIndex) => ({
                        ...dataItem,
                        completed: dataIndex === index ? checked : dataItem.completed
                      }));
                      console.log(updatedData);
                      setdata(updatedData);
                      CompleteHandler(checked, item?.taskLogId, index)
                    }} />
                  </div>
                  <div style={{ width: width < 700 ? "100%" : "45%", height: "fit-content", display: "flex", justifyContent: "space-between", marginLeft: 10 }}>
                    <div style={{ color: '#999999', fontWeight: '600', fontSize: 12 }}>Status</div>
                    <Tooltip title={item?.show}>

                      <div style={{ fontWeight: '600', fontSize: 12 }}>{item?.show}</div>
                    </Tooltip>
                  </div>
                </div>

              </div>
            </>
          ))
        )}

      </div>
      {taskstore?.tableData?.length === 0 ? <div >
        <NoData />
      </div> : <>
        {/*  { (viewClass.superAdmin || viewClass.hod || viewClass.manager)?<div >
          <Button name="Approve" onclick={approveHandler} background={'#21BA45'} fontColor={'#FFFFFF'} fontSize={12} />
          <Button name="Reject" onclick={rejectHandler} background={'#FA4E63'} fontColor={'#FFFFFF'} fontSize={12} />
        </div>:null} */}
      </>
      }
      {
        taskstore?.tableData?.length ?
          <div style={{ display: "flex", height: 50, width: "100%" }}>
            {!isCoManager ?
              (viewClass.manager) || (viewClass.superAdmin) || (viewClass.hod) ?
                <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                  <Button name="Approve" onclick={approveHandler} background={'#21BA45'} fontColor={'#FFFFFF'} fontSize={12} />
                  <Button name="Reject" onclick={rejectHandler} background={'#FA4E63'} fontColor={'#FFFFFF'} fontSize={12} />
                </div> : null : null}
          </div>
          :
          null
      }

    </div>

  )
}

export default DetailsModal

{/* !loading&&{
          data.map((item, index) => (
            <>
              <div style={{ width: "45%", height: 200, marginLeft: 20 }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <ResText text={item?.projectName} />
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginRight: 10 }}>
                    <Label style={{ fontColor: "black" }} text="Select" />
                    <div style={{ marginLeft: 5 }}>
                     

                      <Checkbox disabled={item?.enable} checked={item?.selected} onChange={(e) => {
                        const updatedData = data.map((dataItem, dataIndex) => ({
                          ...dataItem,
                          selected: dataIndex === index ? e.target.checked : dataItem.selected
                        }));
                        setdata(updatedData);

                        SelectHandler(e.target.checked, item?.taskLogId, index);
                      }} />

                    
                    </div>
                  </div>
                </div>
                <div style={{ width: "100%", height: 150, backgroundColor: "#E9E9EB", padding: 10, display: "flex", flexWrap: "wrap", overflow: "auto", gap: 5 }}>
                  <div style={{ width: "45%", height: "fit-content", display: "flex", justifyContent: "space-between", marginRight: 10 }}>
                    <div style={{ color: '#999999', fontWeight: '600', fontSize: 12 }}>Date</div>
                    <div style={{ fontWeight: '600', fontSize: 12 }}>{item?.date}</div>
                  </div>
                  <div style={{ width: "45%", height: "fit-content", display: "flex", justifyContent: "space-between", marginRight: 10 }}>
                    <div style={{ color: '#999999', fontWeight: '600', fontSize: 12 }}>Project</div>
                    <div style={{ fontWeight: '600', fontSize: 12 }}>{item?.projectName}</div>
                  </div>
                  <div style={{ width: "45%", height: "fit-content", display: "flex", justifyContent: "space-between", marginRight: 10 }}>
                    <div style={{ color: '#999999', fontWeight: '600', fontSize: 12 }}>Task</div>
                    <div style={{ fontWeight: '600', fontSize: 12 }}>{item?.taskName}</div>
                  </div>
                  <div style={{ width: "45%", height: "fit-content", display: "flex", justifyContent: "space-between", marginRight: 10 }}>
                    <div style={{ color: '#999999', fontWeight: '600', fontSize: 12 }}>Effort</div>
                    <div style={{ fontWeight: '600', fontSize: 12 }}>{item?.effort}</div>
                  </div>
                  <div style={{ width: "45%", height: "fit-content", display: "flex", justifyContent: "space-between", marginRight: 10 }}>
                    <div style={{ color: '#999999', fontWeight: '600', fontSize: 12 }}>Progress</div>
                    <div style={{ fontWeight: '600', fontSize: 12 }}>{item?.progress}</div>
                  </div>
                  <div style={{ width: "45%", height: "fit-content", display: "flex", justifyContent: "space-between", marginRight: 10 }}>
                    <div style={{ color: '#999999', fontWeight: '600', fontSize: 12 }}>Working Hours</div>
                    <div style={{ fontWeight: '600', fontSize: 12 }}>{item?.workingHours}</div>
                  </div>

                  <div style={{ width: "45%", height: "fit-content", display: "flex", justifyContent: "space-between" }}>
                    <div style={{ color: '#999999', fontWeight: '600', fontSize: 12 }}>Completed</div>
                    <Switch defaultChecked={item?.completed} disabled={item?.enable} onChange={(checked) => {
                     
                      const updatedData = data.map((dataItem, dataIndex) => ({
                        ...dataItem,
                        completed: dataIndex === index ? checked : dataItem.completed
                      }));
                      console.log(updatedData);
                      setdata(updatedData);
                      CompleteHandler(checked, item?.taskLogId, index)
                    }} />
                  </div>
                  <div style={{ width: "45%", height: "fit-content", display: "flex", justifyContent: "space-between", marginLeft: 10 }}>
                    <div style={{ color: '#999999', fontWeight: '600', fontSize: 12 }}>Status</div>
                    <div style={{ fontWeight: '600', fontSize: 12 }}>{item?.show}</div>
                  </div>
                </div>

              </div>
            </>
          ))
        } */}