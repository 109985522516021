import React, { useEffect, useState } from 'react';
import classes from './userProfile.module.css';
import back_icon from '../../assets/backIcon.svg';
import { useNavigate } from 'react-router-dom';
import addteam_icon from './../../assets/addteam_icon.svg';
import project_icon from './../../assets/project_completed_icon.svg';
import reward_icon from './../../assets/reward_icon.svg';
import profile_icon from "../../assets/Profile Icon.svg";
import edit_blue_icon from './../../assets/edit_blue_icon.svg';
import akash_icon from './../../assets/akash_icon.svg';
import agni_icon from './../../assets/agni_icon.svg';
import jal_icon from './../../assets/jal_icon.svg';
import prithvi_icon from './../../assets/prithvi_icon.svg';
import vayu_icon from './../../assets/vayu_icon.svg';
import { Spin, Typography, Upload } from 'antd';
import { SECRET_KEY, decryptData } from '../../services/fileCode';
import Api from '../../services/Api';
import moment from 'moment';
// import eventBus from '../Header/EventBus';
import { useHeaderStore } from '../Header/store';
import uselocalStorageStore from '../localStorageStore';


const UserProfile=()=>{
    const navigate=useNavigate();
    const [userData,setUserData]=useState();
    const [storefile, setstorefile]= useState();
    const [loader,setLoader]=useState(false);
    const {setProfileImage}=uselocalStorageStore();
    const [house,setHouse]=useState(false);
    let u=localStorage.getItem('user');
    let user;
    if(u){
        user=decryptData(u,SECRET_KEY);
    }
    console.log("USERUSER",user)

   
    const refresh=()=>{
        setLoader(true);
        if(user?.resourceUser?.id){
            Api.get(`/resource-user/dataProfile?userId=${user?.resourceUser?.id}`).send(res=>{
                if(res)
                {
                    setUserData(res);
                    setProfileImage(res?.profileImage);
                    setLoader(false);
                }
            })
        }
    }
    
    useEffect(()=>{
        refresh();
    },[])

    useEffect(()=>{
        console.log("inside useeffect");
        //6450b09811a5c_20220710
        fetch(`https://ibelong.brigosha.com/api/auth/signIn?hash=${user?.resourceUser?.passwordToken}_${user?.resourceUser?.uid}`).then(response => {
    // Check if the request was successful
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    // Parse the JSON data from the response
    return response.json();
  })
  .then(data => {
    // Work with the JSON data
    console.log("Network response was not ok",data);
    setHouse(data?.user?.house)
  })
  .catch(error => {
    // Handle errors during the request
    console.error('There has been a problem with your fetch operation:', error);
  });

    },[user?.resourceUser?.uid])


    useEffect(()=>{

        if(storefile)
        {
            setLoader(true);

        console.log("here1");
        Api.patch(`/resource-user/editProfileImage?userId=${user?.resourceUser?.id}`).uploadAndPatch({files:storefile}, (percentCompleted, response) => {
        console.log("here2");
            
            if (typeof response != "undefined") 
            {
              refresh();       
            }
            //setLoader(false);
          });
        }
        
    },[storefile,user?.resourceUser?.id])

    let house_icon;

    if(house==="AKASH")
    {
         house_icon=akash_icon;
    }
   else if(house==="VAYU")
    {
         house_icon=vayu_icon;
    }
    else if(house==="JAL")
    {
         house_icon=jal_icon;
    }
   else if(house==="PRITHVI")
    {
         house_icon=prithvi_icon;
    }
   else if(house==="AGNI")
    {
         house_icon=agni_icon;
    }
    return(
        <>
        {loader?<Spin style={{margin:"20% auto",display:"flex",alignItems:"center",justifyContent:"center"}}></Spin>:<div className={classes.profile}>
                <div className={classes.header}>
                    <div>
                            <img src={back_icon} onClick={()=>{
                                navigate(-1);
                            }}/>
                            <h3>Profile</h3>
                    </div>
                    <span></span>
                </div>
                <div>
                        <div>

                                <div className={classes.profileImage}>
                                        <img src={userData?.profileImage?userData?.profileImage:profile_icon}/>  
                                        
                                                                   
                                                                       
                                    </div>
                                    <div className={classes.nameDiv}>
                                        <Upload
                                        accept='.svg, .png, .jpeg, .jpg'
                                         showUploadList={false} onChange={(e)=>{                                            
                                                setstorefile(e.file.originFileObj)                                                                                             
                                            }}>
                                                <span>Edit Photo</span>
                                        </Upload>
                                        <span>{userData?.name?userData?.name:"NA"}</span>
                                        <span>brigosha technologies</span>
                                        <span>{userData?.email?userData?.email:"NA"}</span>
                                        <span>{userData?.uid?userData?.uid:"NA"}</span>
                                    </div>

                                    {/* <div className={classes.email}>
                                        <span>Email</span>
                                        <span>{userData?.email?userData?.email:"NA"}</span>
                                    </div>
                                    <div className={classes.email}>
                                        <span>Employee ID</span>
                                        <span>{userData?.uid?userData?.uid:"NA"}</span>
                                    </div> */}

                        </div>
                        <div>
                            <div className={classes.gridItem}>
                                <div>
                                    <div>
                                                <img src={addteam_icon} />
                                    </div>
                                    
                                    <div>
                                        <span>Team</span>
                                        <Typography.Text style={{width:"10vw",fontSize:"1rem",fontWeight:"700",fontFamily:"Lato"}} ellipsis={{tooltip:true}}>
                                       {userData?.team?.length>0?userData?.team?.map((item,index)=>{
                                        return(
                                            <span>{item?.name}</span>
                                        )
                                       }):"NA"}
                                        </Typography.Text>
                                    </div>

                                </div>
                                <div>
                                    <div>
                                            <img src={project_icon}/>
                                    </div>
                                  
                                    <div>
                                        <span>Assigned Projects</span>
                                        <h3>{userData?.projectCount}</h3>                                 
                                    </div>

                                </div>
                                <div>
                                    <div>
                                             <img src={reward_icon}/>
                                    </div>
                                
                                    <div>
                                        <span>Experience in brigosha</span>
                                        {/* <h3>{((moment(moment()?.format('YYYY-MM-DD')).diff(userData?.joined,'days'))/365).toFixed(2)}</h3> */}
                                        <h3>{userData?.experienceInBrigosha?userData?.experienceInBrigosha:"NA"}</h3>
                                    </div>

                                </div>
                                <div>

                                    <div>
                                    <img src={house_icon} />
                                    </div>
                                    <div>
                                        <span>Team Ibelong</span>
                                        <h3>{house}</h3>
                                     
                                    </div>

                                </div>                              
                            </div>

                            <div className={classes.personalInfo}>

                                <h4>Personal Information</h4>
                                <div>
                                        <div>
                                            <div>
                                                <span>Birthday</span>                                         
                                                <span>{userData?.dob?userData?.dob?.split('T1')?.[0]:"NA"}</span>
                                            </div>
                                            <div>
                                                    <span>Blood Group</span>                                      
                                                    <span>{userData?.bloodGroup?userData?.bloodGroup:"NA"}</span>
                                            </div>
                                            <div>
                                                <span> External Experience</span>                                         
                                                <span>{userData?.experience}</span>
                                            </div>
                                        
                                        
                                        </div>   
                                        <div>                                                                             
                                                <div>
                                                        <span>Contact</span>                                        
                                                        <span>{userData?.phone?userData?.phone:"NA"}</span>
                                                    </div>
                                                <div>
                                                        <span>Current Address</span>  
                                                       <Typography.Text style={{maxWidth:"35vw",}} ellipsis={{tooltip:true}}>                        
                                                                    <span>{userData?.address?userData?.address:"NA"}</span>
                                                        </Typography.Text> 
                                                </div> 
                                    </div>                            
                                </div>
                                

                            </div>
                            <div className={classes.currentPosition}>
                                <span>Current Position</span>
                                <div>
                                    <div>
                                        <div>
                                            <span>Department</span>                                       
                                            <span>{userData?.departmentName?.name?userData?.departmentName?.name:"NA"}</span>
                                        </div>
                                        <div>
                                            <span>Designation</span>                                        
                                            <span>{userData?.designation?userData?.designation:"NA"}</span>
                                        </div>
                                       
                                    </div>   
                                    <div>
                                    <div>
                                            <span>Location</span>                                        
                                            <span>Bangalore</span>
                                        </div>
                                        <div>
                                            <span>Reporting To</span>                                      
                                            <span>{userData?.manager?.length>0?userData?.manager[0]?.name:"NA"}</span>
                                        </div>
                                    </div>                              
                                </div>
                              

                            </div>
                            <div className={classes.teamAsign}>
                                <span>Joining Details</span>
                                <div>
                                    <div>
                                        <span>Joined On</span>
                                        <span>{userData?.joined?userData?.joined:"NA"}</span>
                                    </div>
                                    <div>
                                        <span>Status</span>
                                        <span>{userData?.status?userData?.status:"NA"}</span>
                                    </div>
                                </div>

                            </div>
                            
                        </div>
                </div>
        </div>}
        </>
    )
}
export default UserProfile;
