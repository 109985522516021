import { create } from "zustand";
export const useReleaseStore=create(set=>({
    department:[],
    releasableMembers:[],
    loader:false,
    releaseModal:false,
    departmentHandler:(val)=>set(state=>({department:val})),
    releasableMembersHandler:(val)=>set(state=>({releasableMembers:val})),
    loaderHandler:(val)=>set(state=>({loader:val})),
    releaseModalHandler:(val)=>set(state=>({releaseModal:val})),
}))