import React from "react";
import classes from './profileDocument.module.css';
import { useResourceStore } from "../../store/store";
import pdf_doc_icon from './../../../../assets/pdf_doc_logo.svg';
import { EditOutlined } from "@ant-design/icons";
import { KeyIsNotEmpty } from "./formData";
import { getLink } from "../../../../Config";
const ProfileDocument=(props)=>{
    const {page,setPage,setTab}=useResourceStore(state=>state)
    let documentInfo=props?.documents;
    let allDocuments=props?.allDocuments;
    console.log("documentsdocuments",allDocuments,KeyIsNotEmpty(allDocuments))
    const documentsInfo=[
        {
            title:"Document Details",
            key1:"Aadhaar No.",
            value1:`${documentInfo?.aadhar?.aadharNumber ? documentInfo?.aadhar?.aadharNumber :"NA"}`,
            key2:"Pan No.",
            value2:`${documentInfo?.pan?.panNumber ? documentInfo?.pan?.panNumber :"NA"}`,
            key3:"Driving License",
            value3:`${documentInfo?.dl?.dlNumber ? documentInfo?.dl?.dlNumber :"NA"}`,
            key4:"UAN No.",
            value4:`${documentInfo?.uan?.uanNumber ? documentInfo?.uan?.uanNumber : "NA"}`,
        },
    ]
    return(
        <div className={classes.cardContainer}>
        
            {documentsInfo?.map((item,index)=>{
                return(
                    <div key={index} className={classes.card}>
                        <div>
                                    <div style={{font:'normal 600 18px/24px Lato',color:'162432'}}>{item.title}</div>
                                    <div style={{display:'flex',gap:'6px',color:'#0086ff'}} onClick={()=>{
                                        setPage({prev:page?.curr, curr:'editProfile',PD:'Documents'})
                                        setTab("Documents");
                                    }}>
                                        <EditOutlined />
                                        <span>Edit</span>
                                    </div>
                        </div>
                        <div>
                                <div>
                                        <div>
                                                <span>{item.key1}</span>
                                                <span>{item.value1}</span>
                                        </div>
                                        <div>
                                                <span>{item.key2}</span>
                                                <span>{item.value2}</span>
                                        </div>
                                </div>

                                <div>
                                        <div>
                                                <span>{item.key3}</span>
                                                <span>{item.value3}</span>
                                        </div>
                                        <div>
                                                <span>{item.key4}</span>
                                                <span>{item.value4}</span>
                                        </div>
                                </div>
                        </div>
                    </div>
                )
            })}
            <div className={classes.documents}>
                <div>
                <div style={{font:'normal 600 18px/24px Lato',}}>Documents Uploaded</div>
                </div>
                <div>
                {
                    (!KeyIsNotEmpty(allDocuments) || allDocuments?.length===0) && <div> No data found!</div>
                      }
                    {allDocuments?.map((item,index)=>{
                        if(item?.link && typeof(item?.link)==='string')
                        {
                            let link=getLink(item?.link)
                        return(
                            <div key={index}>
                            {link?.includes('.pdf')?<img src={pdf_doc_icon} style={{cursor:"pointer",}} onClick={()=>{
                                    window.open(link,'_blank')?.focus();
                                }}/>:<img src={link} style={{cursor:"pointer",}} onClick={()=>{
                                    // window.open(link,'_blank')?.focus();
                                    if(item?.link.startsWith("https://")){
                                        window.open(item?.link, "_blank")?.focus();
                                    }
                                    else{
                                        window.open(getLink(item?.link), "_blank")?.focus();   
                                    }
                                }}/>}
                                <div>
                                    <span>{item.name}</span>
                                </div>
                            </div>
                        )
                            }
                           

                    })}

                </div>

            </div>
        </div>
    )
}
export default ProfileDocument;