import React, { useEffect, useState } from "react";
import classes from "./ReleaseModal.module.css";
// import ModalCompo from "../../../../design/ModalCompo";
// import { useTeamStore } from "../Store/Store";
import release_red_icon from "../../../../assets/release_red_icon.svg";
import {getCheckTaskApi, getTeamByDepartment } from "../Action";
import {
  ModalPrimaryButton,
  ModalSecondaryButton,
} from "../../../../components/Button/Button";
import { DatePicker, Form, Select,Input, Spin } from "antd";
import Api from "../../../../services/Api";
import {
  getApplyFilterData,
  getTeamDropDataForRelease,
  memberReleaseFromTeamApi,
} from "../Action";
import { useGlobalStore } from "../../../../store";
import Modal from "../../../../components/Modal/Modal";
const ReleaseModal = ({ team, setLoader, department, role, memberId,deptId,teamsByDepartment,refresh }) => {
  const {
    isReleaseModal,
    releaseModalHandler,
    teamDropDataForRelease,
    teamDropDataForReleaseHanlder,
    applyFilterDataHandler,
    checkTaskHandler,
    checkTask,
  } = useGlobalStore();
  // const {TextArea} = Input;
  const [reason, setReason] = useState("");
  const [page, setPage] = useState(1);
  const [assignToId, setAssignToId] = useState("");
  const [form] = Form.useForm();
  const[pageLoader,setPageLoader]=useState();
  console.log("checkTask",isReleaseModal);
  useEffect(() => {
    if(isReleaseModal)
    {
      getTeamDropDataForRelease(
        teamDropDataForReleaseHanlder,
        isReleaseModal?.teamId,
        isReleaseModal?.id,
        Api
      );

    }
   
  }, [isReleaseModal]);

  useEffect(()=>{
    setPageLoader(true);
    if(memberId&&isReleaseModal)
    {
      getCheckTaskApi(memberId,Api,(res)=>{
        checkTaskHandler(res);
        setPageLoader(false);
      });
    } 
  },[memberId,isReleaseModal])
  const contentStyle = {
    width: "35rem",
    marginTop: "10%",
  };
  const handleReasonChange = (e) => {
    setReason(e.target.value);
    console.log("reason", e.target.value);
  };
  console.log("qqqqqq",team);
  return (
    <Modal
      show={isReleaseModal}
      onCancel={() => {
        releaseModalHandler(false);
      }}
      //contentStyle={contentStyle}
      closableRight={true}
      header={
        <div className={classes.modalHeader}>
          <img src={release_red_icon} />
          <h3>Release</h3>
        </div>
      }
    >
      <div className={classes.wrapper}>
       {pageLoader?<Spin style={{margin:"15.4% 50%"}}></Spin>:
      <>
      {(page === 1&&checkTask) && (
        <div className={classes.container}>
          <h3 style={{ textAlign: "center",fontSize:"1rem" }}>
            Please close all the Task or reassign the task to another associate and then release.
          </h3>
          <Form
            layout="vertical"
            style={{
              display: "flex",
              flexDirection: "column",
              width: "90%",
              marginTop: "1rem",
            }}
            form={form}
            onFinish={(event) => {
              event = { ...event, date: event["date"]?.format("YYYY-MM-DD") };
              console.log("DatePicker", event);
            }}
          >
            <Form.Item name="team_member"  rules={[{required:true,message:"Please select a member!"}]} label={<span style={{fontWeight:"600"}}
            >Team Members</span>}
            style={{width:"40%",margin:"1rem auto"}}
            >
              <Select
              size="large"
              allowClear={true}
                placeholder="Select"
                onChange={(event) => {
                  setAssignToId(event);
                }}
              >
                {teamDropDataForRelease?.members?.map((item, index) => (
                  <Select.Option value={item.id}>{item.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <div className={classes.buttonDiv} style={{margin:"3rem auto 5rem auto"}}>
              <ModalPrimaryButton
                htmlType="submit"
                onSave={() => {
                  if(form.getFieldValue(["team_member"]))
                  {
                    setPage(2);

                  }
                 
                }}
              >
                Next
              </ModalPrimaryButton>
              <ModalSecondaryButton
                onCancel={() => {
                  releaseModalHandler(false);
                }}
              >
                Cancel
              </ModalSecondaryButton>
            </div>
          </Form>
        </div>
      )}
      {(page === 2||(page===1&&!checkTask))&& (
        <div className={classes.container}>
          <Form
          layout="vertical"
            className={classes.form}
            form={form}
            onFinish={(event) => {
              event = { ...event, date: event["date"]?.format("YYYY-MM-DD") };
              memberReleaseFromTeamApi(
                reason,
                isReleaseModal?.teamId,
                memberId,
               checkTask? assignToId:undefined,
                event["date"],
                Api
              );
              getApplyFilterData(
                  applyFilterDataHandler,
                  1,
                  department?.id,
                  role,
                  team?.manager?.id,
                  team?.id,
                  "",
                  "",
                  setLoader,
                  Api
                  );
            getTeamByDepartment(teamsByDepartment, deptId, Api);
            releaseModalHandler(false);
            refresh()
            }}
          >
            <Form.Item name="date" label={<span style={{fontWeight:"600"}}>Select Date to Release</span>} rules={[{required:true,message:"Please select the date !"}]}>
              <DatePicker className={classes.datePicker} size="large" />
            </Form.Item>
           
            <Form.Item name="reason" label={<span style={{fontWeight:"600"}}>Reason</span>} rules={[{required:true,message:"Please enter the reason!"}]}>
              <Input.TextArea
                rows={4}
                value={reason}
                onChange={handleReasonChange}
              />
            </Form.Item>
            <div className={classes.buttonDiv}>
              <ModalPrimaryButton style={{ width: "6rem" }} htmlType="submit">
                Save
              </ModalPrimaryButton>
              <ModalSecondaryButton
                style={{ width: "6rem" }}
                onCancel={() => {
                  if((page===1&&!checkTask))
                  {
                    releaseModalHandler(false);
                  }
                  else
                  {
                    setPage(1);
                  }
                 
                }}
              >
                {(page===1&&!checkTask)?"Cancel":"Back"}
              </ModalSecondaryButton>
            </div>
          </Form>
        </div>
      )}
      </>}
      </div>
   
    </Modal>
  );
};
export default ReleaseModal;
