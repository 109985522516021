import React, { useState } from "react";
import { Space, Popover, Modal, Radio } from "antd";
import brigoshaLogo from "../../assets/brigosha_logo.svg";
import NotificationRequest from "../Notification/NotificationRequest";
import annoouncementImage from '../../assets/loudspeakerHome.svg';
import PolyIcon from "../../assets/Polygon 2.svg";
import profileIcon from "../../assets/Profile Icon.svg";
import {  useLocation, useNavigate } from "react-router-dom";
import newsletter_icon from '../../assets/newsletter_icon.svg';
import calendar from '../../assets/calendar.svg';
import { getNotification } from "../Notification/action";
import project_icon from '../../assets/project_icon.svg';
import resource_icon from '../../assets/resource_icon.svg';
import attendance_icon from '../../assets/attendance_icon.svg';
import feedbackIcon from "../../assets/Feedback_icon.svg";
import training_doc_icon from '../../assets/report.svg';
import essential_doc_icon from '../../assets/documents.svg';
import healthcare from './../../assets/healthcare.svg';
import appraisal from "../../assets/appraisal.svg";
import CEoImage from '../../assets/ceo-_1_.svg';
import useStore from "../store";
import { useEffect } from "react";
import { decryptData, SECRET_KEY } from "../../services/fileCode";
import { useNotificationStore } from "../Notification/store";
import Api from "../../services/Api";
// import eventBus from "./EventBus";
import classes from './MainHeader.module.css';
import { useWindowDimensions } from "../../components/windowDimention/windowDimension";
import { useHeaderStore } from "./store";
import uselocalStorageStore from "../localStorageStore";
import { useResourceStore } from "../ResourceManagement/store/store";
export let updateHeader ;

function Header() {
  const { Setuser } = useNotificationStore();
  const { HeaderLeft} = useStore()

  const [openModal, setModal] = useState(false);
  const [notification, setNotification] = useState('')
  const { updateToken } = useStore(state => state)
  const [userData, setUserData] = useState({})
  const [gotUser, setGotUser] = useState(false);
  const { user, updateUser } = useStore();
  const [renderHeader, setHeader] = useState(null)
  const [showPopover,setShowPopover]=useState(false);
  const {setPage}=useResourceStore()
  // const [profile,setProfile]=useState();
  console.log("UpdatedUSER",user)
  let location = useLocation();
  const navigate = useNavigate();
  const {width}=useWindowDimensions();
  const {sidemenu,setSidemenu}=useHeaderStore();
  const {profileImage}=uselocalStorageStore();
  const [appraisalHeading,setAppraisalHeading] = useState('')

  const currentPath = window.location.pathname
  const appraisalUserData = localStorage.getItem("AppraisalUserData", SECRET_KEY);
  let decrypteduserData = {};
  if (appraisalUserData) {
    decrypteduserData = JSON.parse(decryptData(appraisalUserData, SECRET_KEY));
  }
  const appraisalOptions = [
  {
    value:'associate',
    label:'Associate'
  },
  {
    value:'manager',
    label:'Manager'
  },
  decrypteduserData?.role?.includes('Admin') ? 
  {
    value:'admin',
    label:'Admin'
  }:null,
]

const handleRoleChange = (value) => {
  let data = value.target.value
   
  if (data === "admin") {
    navigate("/appraisal/admin/users");
  console.log("Role changed to", data);
  }
  if (data === "manager") {
    navigate("/appraisal/manager");
  console.log("Role changed to", data);
  }
  if (data === "associate") {
    navigate("/appraisal/associate");
  console.log("Role changed to", data);
  }
  navigate(0)
};

  updateHeader = (component)=>{
    setHeader(component);
  }



  useEffect(()=>{
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    if (currentMonth >= 4 && currentMonth < 10) {
      setAppraisalHeading("October");
    } else {
      setAppraisalHeading("April");
    }
  },[])
  
  useEffect(() => {
    let token = localStorage.getItem('user');
    if (token) {
      let decryptedData = decryptData(token, SECRET_KEY);
      if (decryptedData) {
        setUserData(decryptedData?.resourceUser);
        updateUser(decryptedData);
        Setuser(decryptedData)
      } else {
        console.error("Decryption failed. Invalid token or secret key.");
      }
    }
  }, [localStorage.getItem('user')]);

  console.log("Decrypted Users", profileImage,);

  useEffect(()=>{
    console.log(HeaderLeft,"HeaderLeftHeaderLeftHeaderLeft");
  },[HeaderLeft])
  const onCancel = () => {
    setModal(false);
  };

  const Logout = () => {
    window.localStorage.clear()
    window.location.replace('/')
    updateToken(null);
    setShowPopover(false);
  };

  
  let currentURL = window.location.href;
  let param = currentURL?.split('=')[1]?.split('%')[0];
  console.log("pamasss", param)
  currentURL = currentURL.split('/')[3];
  console.log("currentURL", currentURL)

  let logoDescription = <img style={{height:"8vh"}} src={brigoshaLogo} />;
  if (currentURL === "newsletter") {
    logoDescription = <div style={{ display: "flex", alignItems: "center", flexDirection: "row", gap:"0.5rem", }}>
    <img src={newsletter_icon} style={{ width: "2rem", height: "2rem" }}></img>
    <h4 style={{fontWeight:"600"}}>Newsletter</h4>
  </div>
   
  }
  if (currentURL === "health_insurance") {
    logoDescription =
    <div  style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"row",gap:"0.5rem"}}>
       <img src={healthcare}  style={{width:"2rem",height:"2rem",}}></img>
       <h4>Health Insurance Card</h4>
    </div>
  }
  if (currentURL === "attendance") {
    logoDescription =
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row", gap:"0.5rem"}}>
        <img src={calendar} style={{ width: "2rem", height: "2rem" }}></img>
        <h4>Attendance</h4>
      </div>
  }
  if (currentURL === "attendancePortal") {
    logoDescription =
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row",gap:"0.5rem" }}>
        <img src={attendance_icon} style={{ width: "2rem", height: "2rem" }}></img>
        <h4>Attendance Portal</h4>
      </div>
  }

  if (currentURL === "project") {
    logoDescription =
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row",gap:"0.5rem" }}>
        <img src={project_icon} style={{ width: "2rem", height: "2rem" }}></img>
        <h4>Project Management</h4>
      </div>
  }
  if (currentURL === "rmanagement") {
    logoDescription =
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row",gap:"0.5rem" }}>
        <img src={resource_icon} style={{ width: "2rem", height: "2rem" }}></img>
        <h4>Resource Management</h4>
      </div>
  }
  if (currentURL === "feedbackmanagement") {
    logoDescription =
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row", gap:"0.5rem" }}>
        <img src={feedbackIcon} style={{ width: "2rem", height: "2rem" }}></img>
        <h4>Feedback Management</h4>
      </div>
  }
  if (currentURL === "announcement") {
    logoDescription = 
    <div  style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"row",gap:"0.5rem"}}>
    <img src={annoouncementImage}  style={{width:"2rem",height:"2rem"}}></img>
    <h4>Announcement</h4>
</div>
  }
  if (currentURL === "ceoDesk") {
    logoDescription =
    <div  style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"row",gap:"0.5rem"}}>
       <img src={CEoImage}  style={{width:"2rem",height:"2rem"}}></img>
       <h4>CEO's Desk</h4>
    </div>
  }
  if (param === "Training") {
    logoDescription =
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row", gap:"0.5rem"}}>
        <img src={training_doc_icon} style={{ width: "2rem", height: "2rem" }}></img>
        <h4>Training Documents</h4>
      </div>
  }

  if (param === "Essential") {
    logoDescription =
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row",gap:"0.5rem" }}>
        <img src={essential_doc_icon} style={{ width: "2rem", height: "2rem" }}></img>
        <h4>Essential Documents</h4>
      </div>
  }

 let mobileNav=<svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20" fill="none">
 <path d="M20.8389 8.77979H1.16111C0.519847 8.77979 0 9.46039 0 10.3V10.46C0 11.2996 0.519847 11.9802 1.16111 11.9802H20.8389C21.4802 11.9802 22 11.2996 22 10.46V10.3C22 9.46039 21.4802 8.77979 20.8389 8.77979Z" fill="#162432"/>
 <path d="M20.8389 16.7808H1.16111C0.519847 16.7808 0 17.4614 0 18.3009V18.461C0 19.3005 0.519847 19.9811 1.16111 19.9811H20.8389C21.4802 19.9811 22 19.3005 22 18.461V18.3009C22 17.4614 21.4802 16.7808 20.8389 16.7808Z" fill="#162432"/>
 <path d="M20.8389 0.778809H1.16111C0.519847 0.778809 0 1.45941 0 2.29899V2.459C0 3.29858 0.519847 3.97918 1.16111 3.97918H20.8389C21.4802 3.97918 22 3.29858 22 2.459V2.29899C22 1.45941 21.4802 0.778809 20.8389 0.778809Z" fill="#162432"/>
 </svg>

  if(currentURL === "appraisal"){
    logoDescription=
    <div  style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
       <img src={appraisal}  style={{width:"2rem",height:"2rem"}}></img>
       <h4>Appraisal</h4>
    </div>
  }
  
  return (
    <>
    <div
     className={classes.mainHeader}
    >
      <div style={{display:"flex", alignItems:"center",  justifyContent:"space-between",flex:"1 1 10%"}}>
      {currentURL!=="homePage"&&width<700?
      <div style={{cursor:"pointer"}}
      onClick={()=>{
        setSidemenu(true)
      }}
      >{mobileNav}</div>
      :
      <div>
        {logoDescription}
      </div>}
      <div>
        {renderHeader}
      </div>
      </div>
{
        currentURL === "appraisal" &&(
      width>1000&&<div 
        style={{ 
          // marginLeft: "9.5rem",
          flex:"1 1 30%",
          display:"flex",
          height:'100%',
          display:'flex',
          justifyContent:'flex-start',
          alignItems:'center',
           }}>
        <h2>{appraisalHeading} Month Appraisal</h2>
</div>
        ) 
      }
      <div style={{ display: "flex", flexDirection: "row", gap: "1rem",flex:width>1000?"1 1 50%":"1 1 70%",justifyContent:"right",alignItems:"center",}}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            alignItems:"center",
          }}
        >
          <svg
            style={{ cursor: "pointer" }}
            onClick={() => {
              setPage({curr:"employee",prev:'',next:'',PD:''})
              navigate("/homePage");
            }} xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 30 30" fill="none">
            <path d="M3.20314 17.8005C2.7815 17.7537 2.39734 17.763 2.05065 17.66C-0.0388087 17.0416 -0.685324 14.6148 0.832581 13.0407C1.95696 11.8694 3.12818 10.7451 4.27129 9.59258C7.16656 6.69731 10.0525 3.80204 12.9477 0.916145C13.8753 -0.0114649 15.0559 -0.25508 16.1522 0.278999C16.4708 0.428916 16.7706 0.644421 17.0236 0.897406C21.0619 4.91705 25.091 8.94606 29.1106 12.9844C30.6941 14.5773 30.0757 17.0603 27.9487 17.6693C27.6021 17.7724 27.2179 17.763 26.8056 17.8005C26.8056 17.9973 26.8056 18.1753 26.8056 18.3627C26.8056 21.08 26.8056 23.7972 26.8056 26.5144C26.8056 28.632 25.4376 30 23.3294 30C21.8771 30 20.4248 30 18.9725 30C18.2042 30 17.9043 29.6908 17.9043 28.9225C17.9043 26.7206 17.9043 24.5187 17.9043 22.3168C17.9043 21.183 17.3328 20.6021 16.2178 20.5927C15.3932 20.5834 14.5593 20.5834 13.7348 20.5927C12.6947 20.6115 12.1138 21.2018 12.1138 22.2324C12.1138 24.4625 12.1138 26.7018 12.1138 28.9318C12.1138 29.7002 11.8046 30 11.0363 30C9.53711 30 8.03795 30 6.53878 30C4.64608 29.9906 3.22188 28.5852 3.22188 26.6831C3.21251 23.919 3.22188 21.1549 3.22188 18.3908C3.20314 18.2034 3.20314 18.016 3.20314 17.8005Z" fill="#162432" />
          </svg>
          <svg onClick={() => {
            if(width>800)
            {
              setModal(true);
            }
            else
            {
              navigate("/notification");
            }
           
          }}
            style={{ cursor: "pointer" }}
            xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 27 31" fill="none">
            <path d="M17.5191 27.2316C17.062 28.9664 15.4318 30.2402 13.5002 30.2402C11.5686 30.2402 9.93837 28.9664 9.48121 27.2316H17.5191ZM24.4038 21.2144H23.8846V12.1887C23.8846 6.66293 19.222 2.16016 13.5 2.16016C7.77802 2.16016 3.11538 6.66293 3.11538 12.1887V21.2144H2.59615C1.1632 21.2144 0 22.3378 0 23.7216C0 24.2832 0.19726 24.8146 0.519231 25.2259H26.4808C26.8027 24.8147 27 24.2832 27 23.7216C27 22.3378 25.8368 21.2144 24.4038 21.2144Z" fill="#162432" />
            <circle cx="21.0613" cy="3.78" r="3.78" fill="#FF5555" />
          </svg>
        </div>
        <div style={{ display: "flex", flexDirection: "row", gap: "0.8rem", }}>
          {(decrypteduserData?.role?.includes('Admin') ||
          decrypteduserData?.role?.includes('Manager')) &&
          (currentPath.startsWith('/appraisal/admin') || 
          currentPath.startsWith('/appraisal/manager') || 
          currentPath.startsWith('/appraisal/associate')) ? 
          <Popover
          placement="bottomRight"
          content={
            <div
              style={{ 
                fontSize:"0.85rem",
                fontWeight:"600",
                display:"flex",
                flexDirection:"column",
                gap:"0.5rem",
                justifyContent:"center" ,
              }}              
            >
                <div style={{
                  width:'10rem',
                }}>
                  <h3>Role</h3>
                  <Radio.Group onChange={handleRoleChange} 
                    defaultValue={currentPath.startsWith('/appraisal/admin')? 'admin': currentPath.startsWith('/appraisal/manager')?'manager':'associate'}>
                    
             {decrypteduserData?.role?.includes('Admin') && <Radio value="admin" label="Admin">Admin</Radio>}      
              <Radio value="manager" label="Manager" title="Manager">Manager</Radio>      
              <Radio value="associate" label="Associate" >Associate</Radio>      
                    {/* {appraisalOptions.map((item)=>(<Radio value={item.value}>{item
                      .label}</Radio>))} */}
                  </Radio.Group> 
                  </div>              
                  <div style={{marginLeft:'0.5rem',cursor:'pointer'}}>
                    <span onClick={Logout}>Logout</span>
                  </div>
            </div>
          }
          background="#F6F6F6"
          trigger="click"
        >
          <Space
            style={{ fontWeight: "550", cursor: "pointer", fontSize: "1rem" }}
          >
            {userData?.name} <img src={PolyIcon} alt="" />
          </Space>
        </Popover> : 
          <Popover
          open={showPopover}
          onOpenChange={(e)=>{
            setShowPopover(e);
          }}
            placement="bottomRight"
            content={
              <div
                style={{ width: "4rem", fontSize: "0.85rem", fontWeight: "600", display: "flex", flexDirection: "column", gap: "0.5rem", alignItems: "center", justifyContent: "center" }}
              >
                
                <span onClick={() => {
                  navigate('/user/userProfile');
                  setShowPopover(false)
                }} style={{ cursor: "pointer", }} > Profile</span>
                <span onClick={Logout} style={{ cursor: "pointer", }}> Logout</span>
              </div>
            }
            trigger="click"
          >
            <div
              style={{ fontWeight: "550", cursor: "pointer", fontSize: "1rem",display:"flex",flexDirection:"row",gap:"1rem",alignItems:"center" }}
            >
            
          
            <img
              style={{ width: "2rem", height: "2rem",borderRadius:"50%" }}
              src={profileImage?profileImage:profileIcon}
              alt=""
            />
        
              {width>800&&<div>{userData?.name} <img src={PolyIcon} alt="" /></div>}
            </div>
          </Popover>}
          
        </div>
      </div>
      {openModal && (
        <Modal
          open={openModal}
          onCancel={onCancel}
          centered
          width={"75vw"}
          bodyStyle={{ height: "90vh" }}
          footer={false}
        >
          <NotificationRequest setModal={setModal} />
        </Modal>
      )}
      
    </div>
   
     </>
  );
}
export default Header;