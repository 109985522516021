import { useRef, useState } from "react";
import ButtonCompo from "../../design/ButonCompo";
import classes from "./attendance.module.css"
import moment from "moment";
import "./Calender.css"
import { punchInApi, CeoFeedbackApi, FeedbackSubmitApi } from "./Action";
import ModalCompo from "../../design/ModalCompo";
import PunchIcon from "../../assets/PunchInIcon.svg";
import { Spin } from "antd/lib";
import { getLocation } from "../../services/fileCode";
import UploadIcon from "../../assets/uploadIcon.svg";
import { Button, Checkbox, DatePicker, Divider, Form, Input, Modal, Progress, Rate, Select, Typography, Upload } from "antd";
import { useWindowDimensions } from '../../components/windowDimention/windowDimension';
import TextArea from "antd/es/input/TextArea";
import { geolocated, useGeolocated } from "react-geolocated";
import Api from "../../services/Api";
import { UploadOutlined } from '@ant-design/icons';
import Notification from "../../services/Notification";
import { useEffect } from "react";
import FeedbackCeo from "../../assets/feedbackCeo.svg";
import FeedbackCeo2 from "../../assets/feedbackCeo 2.svg";
import NextIcon from "../../assets/NextIcon.svg";
import NextIconBlue from "../../assets/nextIconsBlue.svg";
import PreviousIcon from "../../assets/backBlueicon.svg";
import SmileyIcon1 from "../../assets/SadFeedback.svg";
import SmileyIcon2 from "../../assets/SadMediumFeedback.svg";
import SmileyIcon3 from "../../assets/SpeechlessFeedback.svg";
import SmileyIcon4 from "../../assets/SimileFeedback.svg";
import SmileyIcon5 from "../../assets/LaughingFeedback.svg";
import BackIcon from "../../assets/mobileBackIcon.svg";
import PdfIcon from "../../assets/pdf_logo.svg";

const { Option } = Select;

function Calender({ data, userId, calendarTime, setCalenderTime, punchId, refresh, webAccess, loader }) {
  const [next, setNext] = useState(0);
  const [prev, setPrev] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [compoffInitModal, setCompoffInitModal] = useState(false);
  const [compoffRequestModal, setCompoffRequestModal] = useState(false);
  const [cancelLeaveModal, setCancelLeaveModal] = useState(false);
  const [feedbackModal, setFeedbackModal] = useState(false);
  const [nextButton, setNextButton] = useState(false);
  const [feedbackQuestion, setFeedbackQuestion] = useState([]);
  const [description, setDescription] = useState({})
  const { width } = useWindowDimensions();
  const [logModal, setLogModal] = useState();
  const [logModalPunchin, setLogModalPunchin] = useState();
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const [reasonVal, setReasonVal] = useState("")
  const [datas, setData] = useState(null);
  const [count, setCount] = useState(0);
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [questionsText, setQuestionsText] = useState([]);
  const [characterValues, setCharacterValue] = useState(0);
  const [preview, setPreview] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState('');
  const [imgFile, setImgFile] = useState("");
  const [isAnswerProvided, setIsAnswerProvided] = useState(false);
  const [confirmModal, setCongfirm] = useState(null)
  const ModalRef = useRef(null)
  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });
  const clickHandler = (punchIn, punchOut, duration, val) => {
    var startTime = moment(punchIn, 'HH:mm:ss a');
    var endTime = moment(punchOut, 'HH:mm:ss a');
    var workHours = duration;
    setLogModal({
      punchIn: punchIn,
      punchOut: punchOut,
      workHours: workHours,
      date: val?.date,
      effort: val?.effort,
      leaveId: val?.leaveId,
      punchdetails: val?.punchInfo
    })
  }

  // console.log("sssGeoLocationValue",coords?.latitude,coords?.longitude)

  const onFinishHandler = (callback=null) => {
    Api.post("/attendance-attendance/compOffinit")
      .params({
        date: logModal?.date,
        reason: reasonVal
      })
      .send((res) => {
        if (res?.show?.type === "success") {
          
          setLogModal()
          setCompoffInitModal(false)
          setReasonVal("");
          refresh();
          ModalRef.current= null
          setCongfirm(false)
          if(callback){
            callback()
          }
        }
      })
  }

  const cancelLeaveHandler = () => {
    Api.delete(`/attendance-attendance/deleteLeave?id=${logModalPunchin?.leaveId}`)
      .send((res) => {
        if (res?.show?.type === "success") {
          setCancelLeaveModal(false);
          refresh();
        }
      })
  }

  const compOffRequestHandler = () => {
    Api.post("/attendance-attendance/compOffApply")
      .params({
        compOffdate: logModal?.date,
      })
      .send((res) => {
        if (res?.show?.type === "success") {
          setLogModal()
          setCompoffRequestModal(false);
          refresh();
        }
      })
  }

  const getLocationHandler = () => {
    if (navigator.geolocation) {
      const options = {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 10000,
        // altitude: true, 
        // altitudeAccuracy: 10
      };

      const success = (pos) => {
        const crd = pos.coords;
        setData(crd);
      };

      const error = (err) => {
        const message = {
          type: 'error',
          title: 'Location Error',
          text: 'Something went wrong. Try again later.'
        };
        Notification(message);
      };

      navigator.geolocation.getCurrentPosition(success, error, options);
    } else {
      const message = {
        type: 'error',
        title: 'Location Error',
        text: 'Browser unable to fetch current location'
      };
      Notification(message);
    }
  }

  useEffect(() => {
    getLocationHandler();
  }, [])

  useEffect(() => {
    let todayDate = null;
    if (data !== undefined) {
      todayDate = data?.calendar.find(item => item.date === moment().format("YYYY-MM-DD"));
    }
    setLogModalPunchin({
      date: todayDate?.date,
      effort: todayDate?.effort,
      leaveId: todayDate?.leaveId,
      cancelled: data?.cancelled
    });
  }, [data]);

  console.log("ssssDataaaa", data);

  const PunchHandler = (punchId,confirmModal) => {
    // if(logModalPunchin!== undefined && logModalPunchin?.cancelled === false){
    //   setCancelLeaveModal(true);
    // }else{
      let today = moment().format("YYYY-MM-DD")
      setLogModal({
        punchIn: "",
        punchOut: "",
        workHours: "",
        date: today,
        effort: "",
        leaveId: "",
        punchdetails: ""
      })
       data?.calendar?.forEach(item => {
        if (item.date === today && item?.compOff) {
        
            console.log("is it here");
            ModalRef.current= true
            setCongfirm(true)
            return 
          
        } 
      })

      if( ModalRef.current===null){
        ModalRef.current = false
        setCongfirm(false)
      }
      if(ModalRef.current ===false&& ModalRef.current!=null){
        getLocation((res) => {
          // console.log("sssCresponse",res)
          let param = { ...res, punchInId: punchId, web: true }
          // let param={latitude:coords?.latitude,longitude:coords?.longitude,punchInId:punchId}
          punchInApi(param, (res) => {
            if (res) {
              // console.log("ssssResponse",res)
              if (res.show) {
                if (moment(calendarTime).month() !== moment().month()) {
                  setCalenderTime(moment());
                  setNext(0);
                  setPrev(0);
                }
                else {
                  refresh();
                }
              }
              else if (res.data) {
                let userId = res.data[0].id
                setDescription(res.data[0])
                Notification.error("error", 'Please fill feedback')
    
                CeoFeedbackApi(userId, (res) => {
                  // console.log("sssResssss",res)
                  if (res) {
                    setFeedbackQuestion(res?.feedBackQuestions.sort((a, b) => a.id - b.id))
                    setFeedbackModal(true);
                  }
                });
              }
            }
          })
        })
      }

    // }
  };

  const submitHandler = () => {
    let param = {
      userFeedbackId: null,
      feedbackDataId: description?.id,
      questions: questions,
      uid: userId,
    };
    FeedbackSubmitApi(param, (res) => {
      if (res) {
        // console.log("sssResponse",res);
        setFeedbackModal(false)
        setNextButton(false);
        setCount(0);
        setFeedbackQuestion([]);
        setQuestions([]);
        setPreview(false);
        setTextAreaValue('');
      }
    });
  }

  const currentDate = moment();
  const isCurrentDate = (date) => {
    return moment(date).isSame(currentDate, "day");
  };
  const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const weekDaysMobile = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]

  const currentTimes = new Date();
  const hours = currentTimes.getHours();

  const isBefore5AM = () => {
    let currentTime = new Date();
    let hour = currentTime.getHours();
    return hour < 5;
  };
  const isBefore5am = hours < 5;

  // console.log("ssssSome",isBefore5am,hours)

  const renderCalenderDays = () => {
    // let totalDays=moment(calendarTime).daysInMonth();
    // let renderedDays=0;
    let startday = moment(calendarTime).startOf('month').format('d');
    let calendarDays = []
    for (let i = 0; i < startday; i++) {
      calendarDays.push(
        <div key={"empty" + i}></div>
      )
    }
    data?.calendar?.forEach((val, id) => {
      // renderedDays+=1;
      let isToday = isCurrentDate(new Date(val?.date));

      let isWeekend = (moment(val?.date).day() === 6 || moment(val?.date).day() === 0)
      calendarDays.push(
        <div onClick={() => {
          setOpenModal(true);
          clickHandler(val?.checkIn, val?.checkOut, val?.duration, val)
        }} key={id}
          style={{
            border: "",
            background: isToday ? "#0086FF"
            // : (val?.effort === "Comp_Init" || val?.effort === "Comp_Req") &&  val?.status === "Pending"  ? "#641DBD"
            //   : (val?.effort === "Comp_Init" || val?.effort === "Comp_Req") &&  val?.status === "Approved"  ? "#f4f95d"
              : val?.secondEffort === "manual" && val?.type === "Forgot Punch" && val?.effort === "leave" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" && val?.leaveType !== "Advanced" ? "linear-gradient( to right, #FECC1B  50%, #FD8600 50%)"
                : val?.secondEffort === "manual" && val?.type === "Forgot Punch" && val?.effort === "leave" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" && val?.leaveType === "Advanced" ? "linear-gradient( to right, #FECC1B  50%, #581845 50%)"
                  : val?.secondEffort === "manual" && val?.type === "Forgot Punch" && val?.effort === "absent" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" ? "linear-gradient( to right, #FECC1B  50%, #F64A3F 50%)"
                    : val?.secondEffort === "manual" && val?.type === "Forgot Punch" && val?.effort === "present" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" ? "linear-gradient( to right, #FECC1B  50%, #0AAD43 50%)"
                      : val?.secondEffort === "absent" && val?.type === "Forgot Punch" && val?.effort === "manual" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" ? "linear-gradient( to right, #FECC1B 50%, #F64A3F 50%)"
                        : val?.secondEffort === "present" && val?.type === "Forgot Punch" && val?.effort === "manual" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" ? "linear-gradient( to right, #FECC1B 50%, #0AAD43 50%)"
                          : val?.secondEffort === "leave" && val?.type === "Forgot Punch" && val?.effort === "manual" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" && val?.leaveType !== "Advanced" ? "linear-gradient( to right, #FECC1B 50%, #FD8600 50%)"
                            : val?.secondEffort === "leave" && val?.type === "Forgot Punch" && val?.effort === "manual" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" && val?.leaveType === "Advanced" ? "linear-gradient( to right, #FECC1B 50%, #581845 50%)"
                              // :val?.secondEffort === "manual" && val?.type==="Forgot Punch" && val?.half === true && val?.status!=="Pending" && val?.secondStatus!=="Pending"? "linear-gradient( to right, #FECC1B 50%, #FD8600 50%)"
                              : val?.secondEffort === "manual" && val?.type === "Outdoor Punch" && val?.effort === "leave" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" && val?.leaveType !== "Advanced" ? "linear-gradient( to right, #FB29FF 50%, #FD8600 50%)"
                                : val?.secondEffort === "manual" && val?.type === "Outdoor Punch" && val?.effort === "leave" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" && val?.leaveType === "Advanced" ? "linear-gradient( to right, #FB29FF 50%, #581845 50%)"
                                  : val?.secondEffort === "manual" && val?.type === "Outdoor Punch" && val?.effort === "absent" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" ? "linear-gradient( to right, #FB29FF 50%, #F64A3F 50%)"
                                    : val?.secondEffort === "manual" && val?.type === "Outdoor Punch" && val?.effort === "present" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" ? "linear-gradient( to right, #FB29FF 50%, #0AAD43 50%)"
                                      : val?.secondEffort === "absent" && val?.type === "Outdoor Punch" && val?.effort === "manual" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" ? "linear-gradient( to right, #FB29FF 50%, #F64A3F 50%)"
                                        : val?.secondEffort === "present" && val?.type === "Outdoor Punch" && val?.effort === "manual" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" ? "linear-gradient( to right, #FB29FF 50%, #0AAD43 50%)"
                                          : val?.secondEffort === "leave" && val?.type === "Outdoor Punch" && val?.effort === "manual" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" && val?.leaveType !== "Advanced" ? "linear-gradient( to right, #FB29FF 50%, #FD8600 50%)"
                                            : val?.secondEffort === "leave" && val?.type === "Outdoor Punch" && val?.effort === "manual" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" && val?.leaveType === "Advanced" ? "linear-gradient( to right, #FB29FF 50%, #581845 50%)"
                                              // :val?.secondEffort === "manual" && val?.type==="Outdoor Punch" && val?.half === true && val?.status!=="Pending"&& val?.secondStatus!=="Pending"? "linear-gradient( to right, #FB29FF 50%, #FD8600 50%)"
                                              : val?.secondEffort === "absent" && val?.effort === "leave" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" && val?.leaveType !== "Advanced" ? "linear-gradient( to right, #FD8600 50%, #F64A3F 50%)"
                                                : val?.secondEffort === "present" && val?.effort === "leave" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" && val?.leaveType !== "Advanced" ? "linear-gradient( to right, #FD8600 50%, #0AAD43 50%)"
                                                  : val?.secondEffort === "leave" && val?.effort === "absent" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" && val?.leaveType !== "Advanced" ? "linear-gradient( to right, #FD8600 50%, #F64A3F 50%)"
                                                    : val?.secondEffort === "leave" && val?.effort === "present" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" && val?.leaveType !== "Advanced" ? "linear-gradient( to right, #FD8600 50%, #0AAD43 50%)"
                                                      : val?.secondEffort === "absent" && val?.effort === "leave" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" && val?.leaveType === "Advanced" ? "linear-gradient( to right, #581845 50%, #F64A3F 50%)"
                                                        : val?.secondEffort === "present" && val?.effort === "leave" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" && val?.leaveType === "Advanced" ? "linear-gradient( to right, #581845 50%, #0AAD43 50%)"
                                                          : val?.secondEffort === "leave" && val?.effort === "absent" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" && val?.leaveType === "Advanced" ? "linear-gradient( to right, #581845 50%, #F64A3F 50%)"
                                                            : val?.secondEffort === "leave" && val?.effort === "present" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" && val?.leaveType === "Advanced" ? "linear-gradient( to right, #581845 50%, #0AAD43 50%)"

                                                              : val?.secondEffort === "manual" && val?.effort === "leave" && val?.half === true && val?.status !== "Pending" && val?.secondStatus === "Pending" && val?.leaveType !== "Advanced" ? "linear-gradient( to right, #FD8600 50%, #9747FF 50%)"
                                                                : val?.secondEffort === "leave" && val?.effort === "manual" && val?.half === true && val?.status !== "Pending" && val?.secondStatus === "Pending" && val?.leaveType !== "Advanced" ? "linear-gradient( to right, #FD8600 50%, #9747FF 50%)"
                                                                  : val?.secondEffort === "manual" && val?.effort === "leave" && val?.half === true && val?.status !== "Pending" && val?.secondStatus === "Pending" && val?.leaveType === "Advanced" ? "linear-gradient( to right, #581845 50%, #9747FF 50%)"
                                                                    : val?.secondEffort === "leave" && val?.effort === "manual" && val?.half === true && val?.status !== "Pending" && val?.secondStatus === "Pending" && val?.leaveType === "Advanced" ? "linear-gradient( to right, #581845 50%, #9747FF 50%)"
                                                                      : val?.secondEffort === "present" && val?.effort === "leave" && val?.half === true && val?.status !== "Pending" && val?.secondStatus === "Pending" ? "linear-gradient( to right, #0AAD43 50%, #9747FF 50%)"
                                                                        : val?.secondEffort === "leave" && val?.effort === "present" && val?.half === true && val?.status !== "Pending" && val?.secondStatus === "Pending" ? "linear-gradient( to right, #0AAD43 50%, #9747FF 50%)"
                                                                          : val?.secondEffort === "absent" && val?.effort === "leave" && val?.half === true && val?.status !== "Pending" && val?.secondStatus === "Pending" ? "linear-gradient( to right, #F64A3F 50%, #9747FF 50%)"
                                                                            : val?.secondEffort === "leave" && val?.effort === "absent" && val?.half === true && val?.status !== "Pending" && val?.secondStatus === "Pending" ? "linear-gradient( to right, #F64A3F 50%, #9747FF 50%)"

                                                                              // val?.secondEffort === "manual" && val?.half === true ? "linear-gradient( to right, #FECC1B 50%, #FD8600 50%)"
                                                                              // :val?.secondEffort === "absent" && val?.effort === "manual" && val?.half === true ? "linear-gradient( to right, #FECC1B 50%, #F64A3F 50%)"
                                                                              // :val?.secondEffort === "absent" && val?.type==="Forgot Punch" && val?.effort === "manual" && val?.half === true && val?.status!=="Pending"? "linear-gradient( to right, #FECC1B 50%, #F64A3F 50%)"
                                                                              // :val?.secondEffort === "absent" && val?.type==="Outdoor Punch" && val?.effort === "manual" && val?.half === true && val?.status!=="Pending" ? "linear-gradient( to right, #FB29FF 50%, #F64A3F 50%)"
                                                                              // :val?.secondEffort === "absent" && val?.effort === "leave" && val?.half === true && val?.status!=="Pending" ? "linear-gradient( to right, #FD8600 50%, #F64A3F 50%)"
                                                                              : "",
            color: isToday ? "#FFFFFF"
              : (val?.effort === "Comp_Init" || val?.effort === "Comp_Req") &&  val?.status === "Pending"  ? "#641DBD"
              : (val?.effort === "Comp_Init" || val?.effort === "Comp_Req") &&  val?.status === "Approved"  ? "#f4f95d"

                : val?.effort === "leave" && val?.leaveType === "Sick" && val?.half !== true && val?.status !== "Pending" && val?.secondStatus !== "Pending" ? "#F46A9B"
                  // :val?.secondEffort === "manual" && val?.type!=="Outdoor Punch" && val?.half === true ? "#FFFFFF"
                  // :val?.secondEffort === "manual" && val?.type==="Outdoor Punch" && val?.half === true ? "#FFFFFF"
                  // :val?.secondEffort === "absent" && val?.type!=="Outdoor Punch" && val?.effort === "manual" && val?.half === true ? "#FFFFFF"
                  // :val?.secondEffort === "absent" && val?.type==="Outdoor Punch" && val?.effort === "manual" && val?.half === true ? "#FFFFFF"
                  : val?.secondEffort === "manual" && val?.type === "Forgot Punch" && val?.effort === "leave" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" ? "#FFFFFF"
                    : val?.secondEffort === "manual" && val?.type === "Forgot Punch" && val?.effort === "absent" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" ? "#FFFFFF"
                      : val?.secondEffort === "manual" && val?.type === "Forgot Punch" && val?.effort === "present" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" ? "#FFFFFF"
                        : val?.secondEffort === "absent" && val?.type === "Forgot Punch" && val?.effort === "manual" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" ? "#FFFFFF"
                          : val?.secondEffort === "present" && val?.type === "Forgot Punch" && val?.effort === "manual" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" ? "#FFFFFF"
                            : val?.secondEffort === "leave" && val?.type === "Forgot Punch" && val?.effort === "manual" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" ? "#FFFFFF"
                              : val?.secondEffort === "manual" && val?.type === "Forgot Punch" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" ? "#FFFFFF"
                                : val?.secondEffort === "manual" && val?.type === "Outdoor Punch" && val?.effort === "leave" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" ? "#FFFFFF"
                                  : val?.secondEffort === "manual" && val?.type === "Outdoor Punch" && val?.effort === "absent" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" ? "#FFFFFF"
                                    : val?.secondEffort === "manual" && val?.type === "Outdoor Punch" && val?.effort === "present" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" ? "#FFFFFF"
                                      : val?.secondEffort === "absent" && val?.type === "Outdoor Punch" && val?.effort === "manual" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" ? "#FFFFFF"
                                        : val?.secondEffort === "present" && val?.type === "Outdoor Punch" && val?.effort === "manual" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" ? "#FFFFFF"
                                          : val?.secondEffort === "leave" && val?.type === "Outdoor Punch" && val?.effort === "manual" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" ? "#FFFFFF"
                                            : val?.secondEffort === "manual" && val?.type === "Outdoor Punch" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" ? "#FFFFFF"
                                              : val?.secondEffort === "absent" && val?.effort === "leave" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" ? "#FFFFFF"
                                                : val?.secondEffort === "present" && val?.effort === "leave" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" ? "#FFFFFF"
                                                  : val?.secondEffort === "manual" && val?.effort === "leave" && val?.half === true && val?.status !== "Pending" && val?.secondStatus === "Pending" ? "#FFFFFF"

                                                    : val?.effort === "leave" && val?.leaveType === "Casual" && val?.half !== true && val?.status !== "Pending" && val?.secondStatus !== "Pending" ? "#163C86"
                                                      : val?.effort === "leave" && val?.leaveType === "Privilege" && val?.half !== true && val?.status !== "Pending" && val?.secondStatus !== "Pending" ? "#8E1F55"
                                                        : val?.effort === "leave" && val?.leaveType === "Wedding" && val?.half !== true && val?.status !== "Pending" && val?.secondStatus !== "Pending" ? "#C2F339"
                                                          : val?.effort === "manual" && val?.type !== "Outdoor Punch" && val?.status !== "Pending" && val?.half !== true && val?.secondStatus !== "Pending" ? "#FECC1B"
                                                            : val?.effort === "manual" && val?.type === "Outdoor Punch" && val?.status !== "Pending" && val?.half !== true && val?.secondStatus !== "Pending" ? "#FB29FF"
                                                              : val?.secondEffort === "absent" && val?.effort === "leave" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" ? "#FFFFFF"
                                                                // :val?.effort === "leave" &&  val?.leaveType === "Comp_off"&& val?.half !== true && val?.status !== "Pending"? "linear-gradient(45deg, #0DAC45 30%, #0086FF)"  
                                                                : val?.effort === "leave" && (val?.leaveType === "Maternity" || val?.leaveType === "Paternity" || val?.leaveType === "Parental") && val?.status !== "Pending" && val?.secondStatus !== "Pending" ? "#9A8745"
                                                                  : val?.effort === "leave" && val?.leaveType === "Optional" ? "#34FFDA"
                                                                    : val?.secondEffort === "manual" && val?.half === true && val?.status !== "Pending" && val?.secondStatus !== "Pending" ? "#FFFFFF"
                                                                      : val?.status === "Pending" && !isToday ? "#9747FF"
                                                                        : val?.secondStatus === "Pending" && !isToday ? "#9747FF"
                                                                          : ""
          }}
          className={`${isToday ? 'today' : val?.effort === "Week Off" ? 'weekend'
            : val?.effort === "leave" && val?.leaveType === "Comp_off" && val?.half !== true && val?.status !== "Pending" && val?.secondStatus !== "Pending" ? 'compOff'
              : val?.effort === 'half day' ? "half-day"
                : val?.effort === "leave" && val?.leaveType === "Advanced" ? "advanced"
                  : val?.effort}`}
        >

          { (((val?.effort === 'Comp_Init' || val?.effort=== "Comp_Req") && val?.status === "Pending")&& (val?.thirdEffort=== "holiday" || val?.thirdEffort=== "optional"))? 'H'
          :val?.effort === 'manual' && val?.type !== "Outdoor Punch" && val?.status !== "Pending" && val?.secondStatus !== "Pending" && !isToday ? 'M'
            : val?.effort === "manual" && val?.type === "Outdoor Punch" && val?.status !== "Pending" && val?.half !== true && val?.secondStatus !== "Pending" && !isToday ? "O"
              : val?.effort === "leave" && val?.leaveType === "Sick" && val?.status !== "Pending" && val?.secondStatus !== "Pending" && !isToday ? "SL"
                : val?.effort === "leave" && val?.leaveType === "Advanced" && val?.status !== "Pending" && val?.secondStatus !== "Pending" && !isToday ? "AL"
                  : val?.effort === "leave" && val?.leaveType === "Casual" && val?.status !== "Pending" && val?.secondStatus !== "Pending" && !isToday ? "CL"
                    : val?.effort === "leave" && val?.leaveType === "Privilege" && val?.status !== "Pending" && val?.secondStatus !== "Pending" && !isToday ? "PL"
                      : val?.effort === "leave" && val?.leaveType === "Wedding" && val?.status !== "Pending" && val?.secondStatus !== "Pending" && !isToday ? "W"
                        : val?.effort === "leave" && (val?.leaveType === "Maternity" || val?.leaveType === "Paternity" || val?.leaveType === "Parental") && val?.status !== "Pending" && val?.secondStatus !== "Pending" && !isToday ? "M/P"
                          : val?.effort === "leave" && val?.leaveType === "Comp_off" && val?.status !== "Pending" && val?.secondStatus !== "Pending" && !isToday ? "CO"
                            : val?.effort === "leave" && val?.leaveType === "Optional" ? "H"
                              : (val?.effort === "holiday"||val?.effort==="optional" )? "H"
                                : val?.effort === "leave" && val?.leaveType === "Sick" ? "SL"
                                  : val?.day}
        </div>
      )
    })
    // for(renderedDays;renderedDays<totalDays;renderedDays++){
    //     let isToday=(renderedDays+1===moment().date() && moment().isSame(moment(calendarTime),'month')  );
    //     let isWeekend=(moment(calendarTime).startOf('month').add(renderedDays,'days').day()===0||moment(calendarTime).startOf('month').add(renderedDays,'days').day()===6)
    //     calendarDays.push(
    //         <div className={`${isWeekend?'weekend':isToday?'today':''}`}>{moment(calendarTime).startOf('month').add(renderedDays,'days').date()}</div>
    //     )
    // }
    if (data !== undefined && data?.calendar[0]?.day === moment(data?.calendar[0]?.date).date()) {
      return calendarDays;
    }
    else {
      return []
    }
  }
  const customIcons = {
    1: <img src={SmileyIcon1} alt="" style={{ height: "2rem", width: "2rem" }} />,
    2: <img src={SmileyIcon2} alt="" style={{ height: "2rem", width: "2rem" }} />,
    3: <img src={SmileyIcon3} alt="" style={{ height: "2rem", width: "2rem", }} />,
    4: <img src={SmileyIcon4} alt="" style={{ height: "2rem", width: "2rem", }} />,
    5: <img src={SmileyIcon5} alt="" style={{ height: "2rem", width: "2rem" }} />,
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const imgbeforeUpload = (file) => {
    const isLt5M = file.size / 1024 / 1024 < 10;
    if (!isLt5M) {
      Notification.error("Image must smaller than 10MB!");
      setImgFile("");
    }
    else {
      getBase64(file, (url) => {

        setImgFile(file);
        const updatedQuestion = {
          answer: file,
          questionId: feedbackQuestion[count].id,
          question: feedbackQuestion[count].question,
          inputType: feedbackQuestion[count].inputType,
        };

        const questionIndex = questions.findIndex((q) => q.questionId === updatedQuestion.questionId);

        if (questionIndex !== -1) {
          const updatedQuestionsArray = [...questions];
          updatedQuestionsArray[questionIndex] = updatedQuestion;
          setQuestions(updatedQuestionsArray);
        } else {
          setQuestions([...questions, updatedQuestion]);
        }

      });
    }
    return false
  };

  const handleFileRemove = () => {
    setImgFile("");
    const updatedQuestion = {
      answer: "",
      questionId: feedbackQuestion[count].id,
      question: feedbackQuestion[count].question,
      inputType: feedbackQuestion[count].inputType,
    };

    const questionIndex = questions.findIndex((q) => q.questionId === updatedQuestion.questionId);

    if (questionIndex !== -1) {
      const updatedQuestionsArray = [...questions];
      updatedQuestionsArray[questionIndex] = updatedQuestion;
      setQuestions(updatedQuestionsArray);
    } else {
      setQuestions([...questions, updatedQuestion]);
    }
  };

  return (
    <>
      {loader ? <Spin style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }} /> : <div className={classes.attendenceCalender}>
        <div style={{ display: "flex", justifyContent: "center", }}>
          <span className={prev > 1 ? classes.disabled : ''} onClick={(() => { setCalenderTime(moment(calendarTime).subtract(1, 'month')); setNext(next - 1); setPrev(prev + 1); })} >{"<"}</span>
          <span>{moment(calendarTime).format("MMM")}</span>
          <span className={next > 0 ? classes.disabled : ''} onClick={(() => { setCalenderTime(moment(calendarTime).add(1, 'month')); setPrev(prev - 1); setNext(next + 1) })}>{">"}</span>
        </div>
        {width > 700 ?
          <div className={classes.weekDays}>
            {weekDays?.map((val, id) => {
              return (
                <div key={id} className={val} >{val}</div>
              )
            })}
          </div>
          :
          <div className={classes.weekDays}>
            {weekDaysMobile?.map((val, id) => {
              return (
                <div key={id} className={val} >{val}</div>
              )
            })}
          </div>
        }
        <div className={classes.calenderDays}>

          {loader !== true ? renderCalenderDays() : ''}
        </div>
        {(data === undefined || data?.calendar[0]?.day !== moment(data?.calendar[0]?.date).date()) ? <div>No data found.....network error..</div> : ''}
        <div>
          {(webAccess === true && loader !== true && data !== undefined && data?.calendar[0]?.day === moment(data?.calendar[0]?.date).date())
            ? <ButtonCompo title={isBefore5am && (hours >= 12 ? 'pm' : 'am' === 'am') ? "Punch Out" : punchId ? "Punch Out" : "Punch In"}
              style={{ backgroundColor: '#0086FF', color: 'white', height: "2rem", borderRadius: "8px" }}
              onClick={()=>PunchHandler(punchId,confirmModal)} /> : ''}
        </div>
        <div>
        </div>

        <Modal open={openModal}
          title={<h3 style={{ color: "#162432", fontSize: "19px", display: "flex", justifyContent: "flex-start", width: "100%", }}>
            Daily Attendance</h3>}
          footer={false} centered width={800}
          onCancel={() => { setLogModal(); setOpenModal(false) }} style={{ padding: "15px", borderRadius: "10px" }} >
          <Divider />
          <div>
            {console.log(logModal, "logmodalllll")}
            <div className={classes.logCards} style={{ margin: width > 700 ? "3rem" : "1rem 0", }} >
              <div>
                {width > 700 && <img src={PunchIcon} alt="" style={{ marginTop: "-4rem", height: "3rem", marginBottom: "1rem" }} />}
                <div style={{ color: "#0086FF", fontSize: "16px" }}>{logModal?.punchIn ? logModal?.punchIn : '--:--'}</div>
                <p style={{ marginTop: "0.8rem", fontSize: "16px" }}>Punch In</p>
              </div>
              <div>
                {width > 700 && <img src={PunchIcon} alt="" style={{ marginTop: "-4rem", height: "3rem", marginBottom: "1rem" }} />}
                <div style={{ color: "#0086FF", fontSize: "16px" }}>{logModal?.workHours && logModal?.punchIn ? logModal?.workHours : '--:--'}</div>
                <p style={{ marginTop: "0.8rem", fontSize: "16px" }}>Working Hours</p>
              </div>
              <div>
                {width > 700 && <img src={PunchIcon} alt="" style={{ marginTop: "-4rem", height: "3rem", marginBottom: "1rem" }} />}
                <div style={{ color: "#0086FF", fontSize: "16px" }}>{logModal?.punchOut ? logModal?.punchOut : '--:--'}</div>
                <p style={{ marginTop: "0.8rem", fontSize: "16px" }}> Punch Out</p>
              </div>
              <div>
                {width > 700 && <img src={PunchIcon} alt="" style={{ marginTop: "-4rem", height: "3rem", marginBottom: "1rem" }} />}
                <div style={{ color: "#0086FF", fontSize: "16px" }}>{logModal?.punchdetails ? logModal?.punchdetails?.FinalType : '--:--'}</div>
                <p style={{ marginTop: "0.8rem", fontSize: "16px" }}> Work type</p>
              </div>
            </div>
            <Divider />
            <div style={{ display: 'flex', justifyContent: width > 700 ? 'flex-end' : "flex-end", marginTop: "2rem", width: "100%", gap: "0.8rem", margin: width > 700 ? "1rem 0" : "0.2rem 0" }}>
              <ButtonCompo title={"Close"} onClick={() => { setLogModal(); setOpenModal(false) }}
                style={{ backgroundColor: '#0086Ff', color: '#FFFFFF', borderRadius: 5, height: "2.3rem" }}
              />
              {logModal?.effort === "Week Off" || logModal?.effort === "holiday" || logModal?.effort === "optional" || data?.compOff.includes(logModal?.date) ?
                <ButtonCompo title={<Typography.Text style={{ width: width < 700 && "31vw", fontSize: 16, color: "#0086FF" }} ellipsis={{ tooltip: true }}>
                  {logModal?.date >= moment().format("YYYY-MM-DD") ? "Comp Off init" : "Comp Off Request"}</Typography.Text>}
                  onClick={() => {
                    if (logModal?.date >= moment().format("YYYY-MM-DD")) {
                      setCompoffInitModal(true);
                      setOpenModal(false)
                    }
                    else {
                      setCompoffRequestModal(true);
                      setOpenModal(false)
                    }
                  }}
                  style={{ backgroundColor: '#FFFFFF', color: '#0086FF', borderRadius: 5, height: "2.3rem", border: "1px solid #0086FF" }}
                />
                : ""
              }
              {/* {logModal?.effort === "leave" ?
                <ButtonCompo title={<Typography.Text style={{width:width<700&&"31vw",fontSize:16,color:"#0086FF"}} ellipsis={{tooltip:true}}>
                 Cancel leave</Typography.Text>} 
                onClick={()=>{setCancelLeaveModal(true);setOpenModal(false)}} 
                style={{backgroundColor:'#FFFFFF',color:'#0086FF',borderRadius:5,height:"2.3rem",border:"1px solid #0086FF"}} 
                />
                : ""
               } */}
            </div>
          </div>
        </Modal>

        {cancelLeaveModal &&
          <Modal open={cancelLeaveModal}
            title={<h3 style={{ color: "#162432", fontSize: "19px", display: "flex", justifyContent: "center", width: "100%", }}>
              Cancel Leave</h3>}
            footer={false} centered width={700}
            onCancel={() => { setCancelLeaveModal(false) }} style={{ padding: "15px", borderRadius: "10px" }} >
            <Divider />
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%" }}>
              <div style={{ fontSize: 16 }}>
                Are you sure you want to cancel the leave?
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: "3rem", width: "100%", gap: "0.8rem", margin: "2rem 0" }}>
                <ButtonCompo title={"No"} onClick={() => { setCancelLeaveModal(false); }}
                  style={{ borderRadius: 5, height: "2.2rem", border: "2px solid #e3e3e3", width: "5rem" }} />
                <ButtonCompo title={"Yes"} onClick={cancelLeaveHandler}
                  style={{ backgroundColor: '#0086Ff', color: '#FFFFFF', borderRadius: 5, height: "2.2rem", width: "5rem" }} />
              </div>
            </div>
          </Modal>
        }

        <Modal open={compoffInitModal}
          title={<h3 style={{ color: "#162432", fontSize: "19px", display: "flex", justifyContent: "flex-start", width: "100%", }}>
            Comp Off Initiation Request</h3>}
          footer={false} centered width={700}
          onCancel={() => { setCompoffInitModal(false) }} style={{ padding: "15px", borderRadius: "10px" }} >
          <Divider />
          <div>
            <div>
              <p style={{ fontSize: 16 }}>Date:</p>
              <DatePicker style={{ width: "100%", marginTop: "0.3rem" }} format={'YYYY-MM-DD'}
                value={logModal?.date ? moment(logModal?.date) : null} disabled={true} />

              {/* <p style={{ fontSize: 16, marginTop: "1rem" }}>Reason:</p>
              <TextArea style={{ width: "100%", height: "6rem", marginTop: "0.3rem" }} placeholder="Write your reason here..."
                onChange={(e) => {
                  // console.log("SsssEEE",e.target.value);
                  setReasonVal(e.target.value)
                }}
              />
 */}
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: "2.5rem", width: "100%", gap: "0.8rem", margin: "1rem 0" }}>
              <ButtonCompo title={"Send Request"} onClick={onFinishHandler}
                style={{ backgroundColor: '#0086Ff', color: '#FFFFFF', borderRadius: 5, height: "2.2rem" }} />
            </div>
          </div>
        </Modal>

        {compoffRequestModal &&
          <Modal open={compoffRequestModal}
            title={<h3 style={{ color: "#162432", fontSize: "19px", display: "flex", justifyContent: "center", width: "100%", }}>
              Comp Off Request</h3>}
            footer={false} centered width={700}
            onCancel={() => { setCompoffRequestModal(false) }} style={{ padding: "15px", borderRadius: "10px" }} >
            <Divider />
            <div>
              <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                <span>Are you sure you want a comp off for {moment(logModal?.date).format("DD MMMM YYYY")} ?</span>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: "3rem", width: "100%", gap: "0.8rem", margin: "1rem 0" }}>
                <ButtonCompo title={"Yes"} onClick={compOffRequestHandler}
                  style={{ backgroundColor: '#0086Ff', color: '#FFFFFF', borderRadius: 5, height: "2.1rem", width: "5rem" }} />

                <ButtonCompo title={"No"} onClick={() => {
                  setCompoffRequestModal(false)
                  setLogModal()
                }}
                  style={{ backgroundColor: '#FFFFFF', border: "1px solid #0086FF", color: '#0086FF', borderRadius: 5, height: "2.1rem", width: "5rem" }} />
              </div>
            </div>
          </Modal>}
                {
                  confirmModal&&(
                    <Modal
                    title="Punch In"
                    open={confirmModal}
                    onCancel={()=>{setCongfirm(false)}}
                    footer={[
                      <Button key="compoff" onClick={()=>{
                        // setCompoffInitModal(true)
                        onFinishHandler(()=>{
                          getLocation((res) => {
                            // console.log("sssCresponse",res)
                            let param = { ...res, punchInId: punchId, web: true }
                            // let param={latitude:coords?.latitude,longitude:coords?.longitude,punchInId:punchId}
                            punchInApi(param, (res) => {
                              if (res) {
                                // console.log("ssssResponse",res)
                                if (res.show) {
                                  if (moment(calendarTime).month() !== moment().month()) {
                                    setCalenderTime(moment());
                                    setNext(0);
                                    setPrev(0);
                                  }
                                  else {
                                    refresh();
                                  }
                                }
                                else if (res.data) {
                                  let userId = res.data[0].id
                                  setDescription(res.data[0])
                                  Notification.error("error", 'Please fill feedback')
                      
                                  CeoFeedbackApi(userId, (res) => {
                                    // console.log("sssResssss",res)
                                    if (res) {
                                      setFeedbackQuestion(res?.feedBackQuestions.sort((a, b) => a.id - b.id))
                                      setFeedbackModal(true);
                                    }
                                  });
                                }
                              }
                            })
                          })
                        })
                        ModalRef.current= null
                        setCongfirm(false)  
                      }}>
                        Yes
                      </Button>,
                      <Button key="punchin" type="primary" onClick={()=>{
                        getLocation((res) => {
                          // console.log("sssCresponse",res)
                          let param = { ...res, punchInId: punchId, web: true }
                          // let param={latitude:coords?.latitude,longitude:coords?.longitude,punchInId:punchId}
                          punchInApi(param, (res) => {
                            if (res) {
                              // console.log("ssssResponse",res)
                              if (res.show) {
                                if (moment(calendarTime).month() !== moment().month()) {
                                  setCalenderTime(moment());
                                  setNext(0);
                                  setPrev(0);
                                  refresh();
                                }
                                else {
                                  refresh();
                                }
                              }
                              else if (res.data) {
                                let userId = res.data[0].id
                                setDescription(res.data[0])
                                Notification.error("error", 'Please fill feedback')
                    
                                CeoFeedbackApi(userId, (res) => {
                                  // console.log("sssResssss",res)
                                  if (res) {
                                    setFeedbackQuestion(res?.feedBackQuestions.sort((a, b) => a.id - b.id))
                                    setFeedbackModal(true);
                                  }
                                });
                                refresh();
                              }
                            }
                          })
                        })
                        setCongfirm(false)
                      }}>
                        No
                      </Button>,
                    ]}
                  >
                    <p>Do you want to apply for compoff?</p>
                  </Modal>
                  )
                }
        {feedbackModal &&
          <Modal open={feedbackModal}
            footer={false} centered width={700}
            onCancel={() => {
              setFeedbackModal(false);
              setNextButton(false);
              setCount(0);
              setFeedbackQuestion([]);
              setQuestions([]);
              setCharacterValue(0);
              setPreview(false);
              setTextAreaValue('');
            }} style={{ padding: "15px", borderRadius: "10px" }}
          >
            <>
              {preview ?
                <div>
                  <h3 style={{ display: "flex", alignItems: "center", flexDirection: "row", gap: "0.5rem", cursor: "pointer" }}
                    onClick={() => { setPreview(false) }}>
                    <img src={BackIcon} alt="" style={{ height: "1rem" }} /> Previous
                  </h3>
                  {questions.length === 0 ?
                    <div style={{ margin: "1rem 0", height: "70vh", overflow: "auto" }}>
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}>
                        There are no filled questions...</div>
                    </div>
                    : <div style={{ margin: "1rem 0", height: "70vh", overflow: "auto" }}>
                      {questions.map((item, index) => {
                        const selectedIcon = customIcons[item.answer];
                        if (item.inputType === 'Emoji-Rating') {
                          return (
                            <div key={index} style={{ marginBottom: '0.5rem' }}>
                              <p style={{ fontWeight: 550, fontSize: 16 }}>Q{index + 1}. {item.question}</p>
                              {/* <Rate size="large" character={selectedIcon} /> */}
                              {item.answer === 1 ? <img src={SmileyIcon1} alt="" style={{ height: "2rem", width: "2rem" }} />
                                : item.answer === 2 ? <img src={SmileyIcon2} alt="" style={{ height: "2rem", width: "2rem" }} />
                                  : item.answer === 3 ? <img src={SmileyIcon3} alt="" style={{ height: "2rem", width: "2rem", }} />
                                    : item.answer === 4 ? <img src={SmileyIcon4} alt="" style={{ height: "2rem", width: "2rem", }} />
                                      : item.answer === 5 ? <img src={SmileyIcon5} alt="" style={{ height: "2rem", width: "2rem" }} />
                                        : ""
                              }
                            </div>
                          );
                        } else if (item.inputType === 'FileUpload') {
                          return (
                            <div key={index} style={{ marginBottom: '0.5rem' }}>
                              <p style={{ fontWeight: 550, fontSize: 16 }}>Q{index + 1}. {item.question}</p>
                              {item.answer.type === 'application/pdf' ? (
                                <div style={{ display: "flex", margin: "0.5rem 0", flexDirection: "column" }}>
                                  <img src={PdfIcon} alt="PDF Icon" style={{ width: '3rem', height: '3rem' }} />
                                  {/* <a href={item.answer} target="_blank" rel="noreferrer">
                            Open PDF
                          </a> */}
                                </div>
                              ) : (
                                <div style={{ display: "flex", margin: "0.5rem 0", }}>
                                  <img src={URL.createObjectURL(item.answer)} alt={item.name} style={{ width: '3rem', height: '3rem' }} />
                                </div>
                              )}
                            </div>
                          )
                        }
                        else {
                          return (
                            <div key={index} style={{ marginBottom: '0.5rem' }}>
                              <p style={{ fontWeight: 550, fontSize: 16 }}>Q{index + 1}. {item.question}</p>
                              <p>{item.answer}</p>
                            </div>
                          )
                        }
                      })}
                    </div>
                  }
                  {questions.length > 0 && <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <Button type="primary" style={{ height: "2.2rem", width: "100%" }} onClick={submitHandler}
                    >Submit</Button>
                  </div>}
                </div>
                :
                <div style={{ display: "flex", flexDirection: "column", width: "100%", gap: "1rem" }}>

                  <div style={{ flex: "30%", width: "100%", }}>
                    <div style={{ height: "100%" }}>
                      {nextButton ?
                        <img src={FeedbackCeo2} alt="" style={{ height: "35vh", width: "100%" }} />
                        :
                        <img src={FeedbackCeo} alt="" style={{ height: "35vh", width: "100%" }} />
                      }
                    </div>
                  </div>

                  <div style={{ flex: "50%", width: "100%", border: "1px solid #e3e3e3", }}>
                    {nextButton ?
                      <div style={{ height: "100%", display: "flex", justifyContent: "center", flexDirection: "column", padding: "0.5rem 0.8rem" }}>
                        <div style={{ width: "100%", padding: "0.7rem 0 0.5rem 0" }}>
                          <Progress format={(percent) => `${count + 1}/${feedbackQuestion.length > 0 && feedbackQuestion?.length}`}
                            color="#5AB956" style={{ width: "100%" }} success={{
                              percent: ((count + 1) / feedbackQuestion.length) * 100,
                            }} />
                        </div>

                        <div style={{ fontWeight: 550, height: "3rem" }}>
                          <div style={{ height: "100%" }}>{feedbackQuestion[count].inputType !== "Text" &&
                            <span style={{ color: "red", fontSize: 17 }}>*</span>}
                            {' '} Q{count + 1}. {feedbackQuestion[count].question}</div>
                        </div>

                        <div style={{ width: "100%", padding: "0 0 0.5rem 0" }}>
                          {feedbackQuestion[count].inputType === "Text" ?
                            <TextArea placeholder="Please enter your response." style={{ height: "7rem", width: "100%", backgroundColor: "#F2F2F2" }}
                              value={questionsText.find(item => item?.questionId === feedbackQuestion[count].id) && questionsText.find(item => item?.questionId === feedbackQuestion[count].id).answer}
                              onChange={(e) => {
                                const updatedValue = e.target.value;
                                setTextAreaValue(updatedValue);
                                const updatedQuestion = {
                                  answer: updatedValue,
                                  questionId: feedbackQuestion[count].id,
                                  question: feedbackQuestion[count].question,
                                  inputType: feedbackQuestion[count].inputType
                                };
                                // const updatedQuestionsArray = [...questions];                        
                                const questionIndex = questionsText.findIndex((q) => q.questionId === updatedQuestion.questionId);

                                if (questionIndex !== -1) {
                                  const updatedQuestionsArray = [...questions];
                                  updatedQuestionsArray[questionIndex] = updatedQuestion;
                                  setQuestionsText(updatedQuestionsArray);
                                } else {
                                  // updatedQuestionsArray.push(updatedQuestion);
                                  setQuestionsText([...questions, updatedQuestion]);
                                }
                              }}
                              onBlur={(e) => {
                                let answers = e.target.value;
                                const updatedQuestion = {
                                  answer: answers,
                                  questionId: feedbackQuestion[count].id,
                                  question: feedbackQuestion[count].question,
                                  inputType: feedbackQuestion[count].inputType
                                };
                                // const updatedQuestionsArray = [...questions];                        
                                const questionIndex = questions.findIndex((q) => q.questionId === updatedQuestion.questionId);

                                if (questionIndex !== -1) {
                                  const updatedQuestionsArray = [...questions];
                                  updatedQuestionsArray[questionIndex] = updatedQuestion;
                                  setQuestions(updatedQuestionsArray);
                                } else {
                                  // updatedQuestionsArray.push(updatedQuestion);
                                  setQuestions([...questions, updatedQuestion]);
                                }
                                // setQuestions(updatedQuestionsArray);                      
                              }} />
                            : feedbackQuestion[count].inputType === "Multiple Choice" ? (
                              <div style={{}}>
                                {Object.entries(feedbackQuestion[count].options).map(([key, value]) => (
                                  <label key={key} style={{ display: "flex", gap: "0.6rem", marginTop: "0.3rem" }}>
                                    <input
                                      type="checkbox"
                                      value={value}
                                      checked={selectedCheckbox === value && questions.find(item => item?.questionId === feedbackQuestion[count].id)
                                        && questions.find(item => item?.questionId === feedbackQuestion[count].id).answer}
                                      onChange={() => {

                                        setSelectedCheckbox(value)
                                        const updatedQuestion = {
                                          answer: value,
                                          questionId: feedbackQuestion[count].id,
                                          question: feedbackQuestion[count].question,
                                          inputType: feedbackQuestion[count].inputType
                                        };
                                        // const updatedQuestionsArray = [...questions];                        
                                        const questionIndex = questions.findIndex((q) => q.questionId === updatedQuestion.questionId);

                                        if (questionIndex !== -1) {
                                          const updatedQuestionsArray = [...questions];
                                          updatedQuestionsArray[questionIndex] = updatedQuestion;
                                          setQuestions(updatedQuestionsArray);
                                        } else {
                                          // updatedQuestionsArray.push(updatedQuestion);
                                          setQuestions([...questions, updatedQuestion]);
                                        }
                                      }}
                                    />
                                    {value}
                                  </label>
                                ))}
                              </div>
                            )
                              : feedbackQuestion[count].inputType === "FileUpload" ?
                                <Upload.Dragger beforeUpload={imgbeforeUpload} style={{ border: "1px dashed #DFDFDF" }}
                                  multiple={false} maxCount={1} onRemove={handleFileRemove}>
                                  <div className={classes.uploads}>
                                    {questions.find(item => item?.questionId === feedbackQuestion[count].id)
                                      && questions.find(item => item?.questionId === feedbackQuestion[count].id).answer &&
                                      questions.find(item => item?.questionId === feedbackQuestion[count].id).answer.type === 'application/pdf' ?
                                      (<>
                                        <img src={PdfIcon} alt="PDF Icon" style={{ width: '3rem', height: '3rem' }} />
                                        <p className="ant-upload-hint" style={{ marginTop: "0.5rem" }}>Click or drag file to this area to Change</p>
                                      </>)
                                      : questions.find(item => item?.questionId === feedbackQuestion[count].id)
                                        && questions.find(item => item?.questionId === feedbackQuestion[count].id).answer &&
                                        questions.find(item => item?.questionId === feedbackQuestion[count].id).answer.type !== 'application/pdf' ?
                                        (<>
                                          <img src={URL.createObjectURL(questions.find((item) => item.questionId === feedbackQuestion[count].id)?.answer)} alt="Uploaded"
                                            style={{ height: "3rem", width: "3rem" }} />
                                          <p className="ant-upload-hint" style={{ marginTop: "0.5rem" }}>Click or drag file to this area to Change</p>
                                        </>
                                        ) : (<>
                                          <p className="ant-upload-drag-icon">
                                            <img src={UploadIcon} style={{ height: "2.5rem", width: "2.5rem", }} />
                                          </p>
                                          <p className="ant-upload-hint">Click or drag file to this area to upload</p>
                                        </>
                                        )}
                                  </div>
                                </Upload.Dragger>
                                :
                                <Rate size="large" style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "1rem 2.5rem" }}
                                  //  character={({ index }) => customIcons[index + 1]} 
                                  character={({ index }) => (
                                    <span
                                      style={{
                                        fontSize: "1.5rem",
                                        cursor: "pointer",
                                        opacity: index + 1 === characterValues ? '0.5'
                                          : questions?.length > 0 && questions.find(item => item?.questionId === feedbackQuestion[count].id)
                                            ? questions.find(item => item?.questionId === feedbackQuestion[count].id).answer === index + 1
                                              ? '0.5'
                                              : '1.0'
                                            : '1.0',
                                      }}
                                    >
                                      {customIcons[index + 1]}
                                    </span>
                                  )}
                                  onChange={(value) => {
                                    setIsAnswerProvided(true);
                                    setCharacterValue(value)
                                    const updatedQuestion = {
                                      answer: value,
                                      questionId: feedbackQuestion[count].id,
                                      question: feedbackQuestion[count].question,
                                      inputType: feedbackQuestion[count].inputType
                                    };
                                    const updatedQuestionsArray = Array.isArray(questions) ? [...questions, updatedQuestion] : [updatedQuestion];
                                    const questionIndex = questions.findIndex((q) => q.questionId === updatedQuestion.questionId);

                                    if (questionIndex !== -1) {
                                      const updatedQuestionsArray = [...questions];
                                      updatedQuestionsArray[questionIndex] = updatedQuestion;
                                      setQuestions(updatedQuestionsArray);
                                    } else {
                                      setQuestions([...questions, updatedQuestion]);
                                    }
                                    // setQuestions(updatedQuestionsArray); 
                                  }}
                                />
                          }
                        </div>
                      </div>
                      :
                      <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: "0.5rem" }}>
                        <h3>{description?.title}</h3>

                        {description?.title === "CEO_FEEDBACK" ?
                          <div style={{ display: "flex", flexDirection: "column", gap: "0.4rem", width: "100%", padding: "0.5rem 1.5rem" }}>
                            <p>Dear Associate,</p>
                            <p>Greetings!</p>
                            <p>I invite you all to share the Feedback related to our Work Culture.</p>
                            <p>𝐓𝐡𝐞 𝐫𝐞𝐬𝐩𝐨𝐧𝐬𝐞𝐬 𝐰𝐢𝐥𝐥 𝐨𝐧𝐥𝐲 𝐛𝐞 𝐬𝐡𝐚𝐫𝐞𝐝 𝐰𝐢𝐭𝐡 𝐦𝐞.</p>
                            <p>This will help us in strengthing the relationship and Work Culture at brigosha.</p>
                            <p>I welcome you to share your voice and suggestions as well.</p>
                            <p style={{ marginTop: "0.3rem", display: "flex", flexDirection: "column" }}>
                              <span>Cheers!</span>
                              <span>Brijesh, CEO</span>
                            </p>
                          </div>
                          : <p style={{ height: "100%", }}>{description?.description}</p>
                        }
                      </div>
                    }
                  </div>

                  <div style={{ flex: "10%", width: "100%" }}>
                    {nextButton && count + 1 !== feedbackQuestion?.length ? (
                      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                        {count === 0 ? (
                          <p></p> // Hide "Previous" button for the first question
                        ) : (
                          <Button style={{ color: "#0086FF", fontSize: 14, border: "1px solid #0086FF", display: "flex", alignItems: "center" }} onClick={() => {
                            // if (isAnswerProvided) {
                            //   setIsAnswerProvided(false);
                            // }
                            setCount((prevCount) => Math.max(prevCount - 1, 0));
                            setCharacterValue(0)
                            // setCharacterValue(questions[count - 1]?.answer || 0);
                          }}>
                            <img src={PreviousIcon} alt="" style={{ height: "0.7rem", marginRight: "0.4rem" }} /> Previous
                          </Button>
                        )}
                        <Button style={{ color: "#0086FF", fontSize: 14, border: "1px solid #0086FF", display: "flex", alignItems: "center" }} onClick={() => {
                          // if (isAnswerProvided) {
                          //   setIsAnswerProvided(false);
                          // }
                          setCount((prevCount) => prevCount + 1);
                          setCharacterValue(0)
                          // setCharacterValue(questions[count + 1]?.answer || 0);
                        }}
                          disabled={feedbackQuestion[count].inputType !== "Text" && questions.find((item) => item?.questionId === feedbackQuestion[count].id)?.answer === undefined}
                        >
                          Next <img src={NextIconBlue} alt="" style={{ height: "0.7rem", marginLeft: "0.4rem" }} />
                        </Button>
                      </div>
                    )
                      : count + 1 === feedbackQuestion?.length ?
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                          <Button style={{ color: "#0086FF", fontSize: 14, border: "1px solid #0086FF", display: "flex", alignItems: "center" }} onClick={() => { setCount(prevCount => Math.max(prevCount - 1, 0)); }}>
                            <img src={PreviousIcon} alt="" style={{ height: "0.7rem", marginRight: "0.4rem" }} /> Previous
                          </Button>
                          <Button type="primary" style={{ height: "2.2rem", width: "6rem" }} onClick={() => {
                            setPreview(true);
                          }
                            // submitHandler
                          }
                            disabled={feedbackQuestion[count].inputType !== "Text" && questions.find((item) => item?.questionId === feedbackQuestion[count].id)?.answer === undefined}
                          >Preview</Button>
                        </div>
                        :
                        <Button type="primary" style={{ height: "2.2rem", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}
                          onClick={() => { setNextButton(true); }}>
                          Next <img src={NextIcon} alt="" style={{ height: "0.8rem", marginLeft: "0.4rem" }} />
                        </Button>
                    }
                  </div>
                </div>
              }
            </>
          </Modal>
        }

      </div>}
    </>
  )
};
export default Calender;
