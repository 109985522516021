import SiderLayout from "../../components/siderLayout"
import React, { useEffect, useRef, useState } from 'react';
// import Button from "antd";
import { AiOutlineUpload } from "react-icons/ai";
import DeleteEmployeeModal from "../../components/deleteModal";
import { deleteDocuments, getDocuments, publishApi } from "./action";
import UploadModal from "../HealthInsurance/UploadModal/UploadModal";
import { uploadDocumentApi } from "../HealthInsurance/Actions";
import { useHealthInsuranceStore } from "../HealthInsurance/Store";
import NoDataImg from '../../assets/nodata.svg';
import pdf_doc_logo from './../../assets/pdf_logo.svg';
import dashboardIcon from "../../assets/Dashboard.svg";
import dashboardInactive from "../../assets/dashboard_inactive.svg"
import { ModalPrimaryButton, ModalSecondaryButton } from "../../components/Button/Button";
import classes from './index.module.css';
import { Popover, Spin, Typography,Button, Checkbox, Select } from "antd";
import viewClass from "../../services/ViewRole";
import useApiStore from "../../services/store";
import SearchCompo from "../../design/SearchCompo";
import { log } from "../../store";
import { useWindowDimensions } from "../../components/windowDimention/windowDimension";
import { useNavigate } from "react-router-dom";
import { usePDFReaderStore } from "../../components/PDFReader/store";

export const NoData = (props) =>{
  const {width} =useWindowDimensions();
  return(
    <>
    <div style={{height:width<700?"20vh":"70vh",width:"100%", display:"flex", alignItems:"center", justifyContent:"center", flexDirection:'column', marginTop:20,}}>
      <img src={NoDataImg}></img>
      <div>{props?.text?props?.text:"No Data"}</div>
    </div>
    </>
  )
}

const DotPopover=({id,refresh,param,width,status})=>{
  const [deleteModal, setdeletemodal] = useState(false);
  const [deleteId,setdeleteId] =useState();
  const [showPopover,setShowPopover]=useState(false);
  console.log("sssssstttts",status)
  return(
    <div>
      <Popover 
      placement="bottom"
      open={showPopover}
      onOpenChange={(e)=>{
        setShowPopover(e);
      }}
      content={
        <div style={{display:"flex",flexDirection:"column",fontSize:"0.8rem",gap:"0.5rem"}}>
          {!status&&<span style={{cursor:"pointer"}} onClick={()=>{
            publishApi({documentName:param==="Essential Documents"?"Essential":param==="Training Documents"?"Training":undefined,documentId:id});
            setShowPopover(false);
            refresh();
          }}>Publish</span>}
          <span onClick={()=>{
            setdeleteId(id);
            setdeletemodal(true);
            setShowPopover(false);
          }} style={{cursor:"pointer"}}>Delete</span>
        </div>
      } 
       trigger="click">
        <span style={{cursor:"pointer",fontSize:width>800?"auto":"1.2rem"}}>...</span>
    </Popover>
    <DeleteEmployeeModal modal={deleteModal} setModal={setdeletemodal} DeleteHandler={()=>{
        deleteDocuments(()=>{
          if(refresh)
          {
            refresh();

          }
          setdeletemodal(false);
        },{id:[deleteId]})
      }}/>
    </div>
  )
}


export const PageBody = ({param,loader, setSearch, uploadModalHandler, documentsList,refresh,filter,setFilter }) => {
  const [isScrollAdded,setIsScrollAdded]=useState(false);
  const [selectedCard,setSelectedCard]=useState([]);
  const{width}=useWindowDimensions();
  // const [filter,setFilter]=useState("All");
  const ref = useRef(null);
  const [deleteModal, setdeletemodal] = useState(false);
  console.log("selectedCard",selectedCard)

  const MobileCard=({ data,index,onRefresh,param,selectedCard,setSelectedCard})=>{
    return(
      <div className={classes.mobCard}>
        <div>
          <div>
            <span>File Name</span>
            <span>{data?.enteredFileName?data?.enteredFileName:"NA"}</span>
          </div>
          <div>
                <span>Document Name</span>
                <span style={{wordBreak:"break-word"}}>{data?.fileName}</span>
          </div>
          <div>
            <span>Preview</span>
            <img src={pdf_doc_logo} style={{width:"2rem",height:"3rem",cursor:"pointer"}} onClick={()=>{
              window.open(data?.filePath?.[0],'_blank')?.focus();
            }}/>
          </div>
         {(viewClass.hrAdmin ||viewClass.superAdmin)&& <div>
            <span>Status</span>
            <span className={data?.published?classes.published:classes.unpublished}>{data?.published?"Published":"Unpublished"}</span>
          </div>}

        </div>
        {(viewClass.hrAdmin ||viewClass.superAdmin)&&
        <div>
           <Checkbox
                    checked={selectedCard?.includes(data?.id)}
                     onChange={(e)=>{
                      if(e.target.checked)
                      {
                        setSelectedCard(prev=>{
                          if(prev.includes(data?.id))
                          {
                            return prev;
                          }
                          return [...prev,data?.id]
                        })
                      }
                      else
                      {
                        setSelectedCard(selectedCard?.filter(item=>item!==data?.id))
                      }
                      
                    }}/>
        <DotPopover id={data?.id} status={data?.published} refresh={onRefresh} param={param} width={width}/>
        </div>}

      </div>
    )
  }
 
 
  const DocumentsCard = ({ data,index,onRefresh,param,selectedCard,setSelectedCard}) =>{
const navigate=useNavigate();
const {pdfUrlHandler}=usePDFReaderStore();

   
    return (
      <>
        <div className={(viewClass.hrAdmin ||viewClass.superAdmin)?classes.card:classes.card2}>
            <div className={classes.fileName}>
            {(viewClass.hrAdmin ||viewClass.superAdmin)&&<Checkbox
                    checked={selectedCard?.includes(data?.id)}
                     onChange={(e)=>{
                      if(e.target.checked)
                      {
                        setSelectedCard(prev=>{
                          if(prev.includes(data?.id))
                          {
                            return prev;
                          }
                          return [...prev,data?.id]
                        })
                      }
                      else
                      {
                        setSelectedCard(selectedCard?.filter(item=>item!==data?.id))
                      }
                      
                    }}/>}
                <span>{data?.enteredFileName?data?.enteredFileName:"NA"}</span>
            </div>
            
                <Typography.Text style={{maxWidth:"15rem",}}  ellipsis={{tooltip:true}}>
                  <span>{data?.fileName}</span>
              </Typography.Text>
          
          <div>
            <img src={pdf_doc_logo} style={{width:"2rem",height:"3rem",cursor:"pointer"}} onClick={()=>{
              navigate('/pdfReader');
              pdfUrlHandler(data?.filePath?.[0]);
               //window.open(data?.filePath?.[0],'_blank')?.focus();
            }}/>
          </div>

          {(viewClass.hrAdmin ||viewClass.superAdmin)&&<span className={data?.published?classes.published:classes.unpublished}>{data?.published?"Published":"Unpublished"}</span>}
        
           
            {(viewClass.hrAdmin ||viewClass.superAdmin)&&<DotPopover status={data?.published} id={data?.id} refresh={onRefresh} param={param}/> }
        

        </div>
      </>
    )
  }

  const innerElement=documentsList?.map((item,index)=>(
          
    <>
    
    {width>800?<DocumentsCard data={item} selectedCard={selectedCard} setSelectedCard={setSelectedCard} index={index} onRefresh={refresh} param={param}/>:
    <MobileCard data={item} selectedCard={selectedCard} setSelectedCard={setSelectedCard} index={index} onRefresh={refresh} param={param}/>}
    </>
    
  ))

   useEffect(() => {
    const divElement = ref.current;
    if(divElement)
    {
    
      const hasScrollbar = divElement.scrollHeight > divElement.clientHeight;
    setIsScrollAdded(hasScrollbar);
    } 
  }, [innerElement]);

//   useEffect(() => {
//     function updateState() {
//        const el = ref.current;
//        el && setIsScrollAdded(el.scrollHeight > el.getBoundingClientRect().height);
//     }

//     updateState();

//     window.addEventListener('resize', updateState);
//     return () => window.removeEventListener('resize', updateState);
//  }, [innerElement]);

  console.log("isScrollAdded",isScrollAdded)

 


  return (

    <div className={classes.container}>
      <div className={classes.document}>
        <div style={{ fontWeight: "700", fontSize: "1.3rem",margin:"0", padding:"0"}}>
        
         {param}
        </div>
            <div className={classes.searchDiv}>
             
            <SearchCompo style={{width:"100%",height:"2.45rem",border: "1px solid #BEBEBE"}} setSearch={ setSearch }/>
           
              
                    {(viewClass.hrAdmin || viewClass.superAdmin)?
                    <div className={classes.operation}>
                      <Select value={filter}
                      size="large"
                      className={classes.select}
                      onChange={(e)=>{
                        setFilter(e);
                      }}
                      >
                        {["All","Published","Unpublished",]?.map((el,ind)=>(<Select.Option key={ind} value={el}>{el}</Select.Option>))}

                      </Select>
                    {selectedCard?.length===0?<div className={classes.docButton}>
                        <ModalPrimaryButton onSave={() => { uploadModalHandler(true) }} style={{background:"#fff",color:"#0086f8",width:"8rem",height:"2.45rem",fontSize:"1rem"}}>Upload</ModalPrimaryButton>
                      <ModalPrimaryButton onSave={()=>{
                        publishApi({documentName:param==="Essential Documents"?"Essential":param==="Training Documents"?"Training":undefined});
                        refresh();
                      }} style={{height:"2.45rem",margin:"0",fontSize:"1rem",alignItems:"center",justifyContent:"centet",width:"8rem"}}  disabled={documentsList?.length===0}>Publish</ModalPrimaryButton>
                      </div>:
                      <div className={classes.deleteDiv}>
                        <ModalSecondaryButton
                           style={{background:"#fff",width:"8rem",height:"2.45rem",fontSize:"1rem"}}
                        onCancel={()=>{
                          setSelectedCard([]);
                        }}
                        >Cancel</ModalSecondaryButton>
                        <ModalPrimaryButton 
                        onSave={()=>{
                          setdeletemodal(true);                     
                        }}
                        style={{background:"#FF6363",width:"8rem",height:"2.45rem",fontSize:"1rem",border:"1px solid #FF6363"}}
                        >Delete</ModalPrimaryButton>
                      </div>}
                      </div>
                      :null}
            </div>
      </div>

      {loader?<Spin style={{width:"100%",height:"100vh",display:"flex",alignItems:"center",justifyContent:"center"}}></Spin>:Array.isArray(documentsList) && documentsList.length?
      <div style={{overflowY:"auto",boxSizing:"border-box",}}>
      {width>800&&<div className={(viewClass.hrAdmin ||viewClass.superAdmin)?classes.tableHeader:classes.tableHeader2}>
        <span>File Name</span>
        <span>Document Name</span>
        <span>Preview</span>
        {(viewClass.hrAdmin ||viewClass.superAdmin)&&<span>Status</span>}
        {(viewClass.hrAdmin ||viewClass.superAdmin)&&<span>Actions</span>}

      </div>}
      <div ref={ref} style={{display:"grid", width:"100%",padding:width>800?`0 ${isScrollAdded?1.2:1.5}rem 0 1.5rem`:"1rem",gridAutoRows: width>800?"5rem":"auto", gridTemplateColumns:"repeat(1,1fr)",gap:"1rem",height:width>800?"68vh":"auto",overflowY:"auto",background:" #F8F8F8",boxSizing:"border-box",marginTop:width<800?"1rem":"auto"}}>
        {
           innerElement
         
        }
      </div>
      </div>
       :
      <NoData/>}
    
      <DeleteEmployeeModal modal={deleteModal} setModal={setdeletemodal} DeleteHandler={()=>{
         deleteDocuments(()=>{
          if(refresh)
          {
            refresh();     
            setSelectedCard([]);  
            setdeletemodal(false);            
          }                          
        },{id:selectedCard})
        
      }}/>

    </div>
    )
}

const TrainingEssentialDocuments = () => {
  const [documentsList,setDocumentsList] = useState([]);
  const {uploadModalHandler,uploadModal,fileList,setFileList} =useHealthInsuranceStore((state)=>state);
  const [loader]=useApiStore(state=>[state.loader]);
  const[search,setSearch]=useState('');
  const [filter,setFilter]=useState("All");
  let urlParams = new URLSearchParams(window.location.search);
  let param = urlParams.get('param');
 console.log("urlParams",param);

const refresh=()=>{
  getDocuments((res)=>{
    console.log(res,"hellloooores");
    setDocumentsList(res);
  },{documentName:param==="Essential Documents"?"Essential":"Training",search:search,filter:filter})
}

  useEffect(()=>{
    viewClass.updateView();
    getDocuments((res)=>{
      console.log(res,"hellloooores");
      setDocumentsList(res);
    },{documentName:param==="Essential Documents"?"Essential":"Training",search:search,filter:filter})
  },[search,filter])

  const navUrl=[
    {
    key:1,
    title:"Dashboard",
    url:`?param=${param==="Essential Documents"?"Essential":"Training"}%20Documents`,
    inactive:dashboardInactive,
    active:dashboardIcon,
  },
]

  
  return (
    <div style={{ display: "flex",flexDirection:"column"}}>
      <SiderLayout customComponenet={{ customComp: true, component: (
    <PageBody param={param} search={search} setSearch={setSearch} loader={loader} uploadModalHandler={uploadModalHandler} filter={filter} setFilter={setFilter} documentsList={documentsList} refresh={refresh}/>) }} navList={navUrl} >
      </SiderLayout>
      {uploadModal&&<UploadModal 
      loader={loader}
      screenType="document"
      onfinish={(e)=>{
        
                uploadDocumentApi({document:fileList?.map((item,index)=>(item.originFileObj)),
                  documentType:param==="Essential Documents"?"Essential":"Training",
                  fileName:e['fileName']
                },"/resource-home/uploadDocuments",()=>{
                  refresh();
                  uploadModalHandler(false);
                  setFileList([]);
                });
            }}/>}
    </div>
  )
}


export default TrainingEssentialDocuments