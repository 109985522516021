import ModalCompo from "../../design/ModalCompo";
import {  Form, Input, Select, TimePicker,Modal, Button } from "antd";
import classes from './AttendanceModal.module.css';
import { ModalPrimaryButton, ModalSecondaryButton } from "../../components/Button/Button";
import DatePickerCompo from "../../components/DatePicker/DatePickerCompo.tsx";
import { ManualPunchApi, getPunchInfo } from "./Action";
import { getLocation } from "../../services/fileCode";
// import Modal from "../../components/Modal/Modal";
import calendar_icon from './../../assets/calendarIcon.svg';
import { useWindowDimensions } from '../../components/windowDimention/windowDimension';
import moment from "moment";
import { useState } from "react";


function AttendenceModal({modal,setModal,id,refresh}){
    const [form]=Form.useForm();
    const Option=Select.Option;
    const selectDummy=["Forgot Punch","Outdoor Punch"];
    const [defaultVal,setDefaultVal] = useState({dis:true,val:null})
    const {width}=useWindowDimensions();

   
const manualPunchHandler=((val)=>{
    getLocation((res)=>{
        let param={...res,checkIn:val?.checkIn.format('HH:mm'),checkOut:val?.checkOut.format('HH:mm'),
        date:val?.date?.format("YYYY-MM-DD"),userId:id,punchType:val?.punchType,reason:val?.reason,attendance:true,workType:defaultVal?.val!==null?defaultVal?.val:val?.worktype}
        ManualPunchApi(param,(res)=>{
            if(res){
                setModal(false);
                refresh();
            }
        })
    })
  
})

    return(
        <Modal 
        open={modal} centered footer={false} width={width>700?"70vw":"95vw"}
        onCancel={()=>{setModal(false)}} 
        // className={classes.AttendenceModal}
        // closableRight={true}
        // style={{width:}}
        title={<div style={{display:"flex",flexDirection:"row",gap:"",color:"#0FB3FF",alignItems:"center"}}>
            <img src={calendar_icon}/>
            <h3>Attendance update</h3>
        </div>}
        >
            <Form form={form} layout="vertical"
            className={classes.attendance}
            onFieldsChange={(e,e2)=>{
                if(e[0]?.name?.includes("date")){
                    let dateSel = e[0]?.value
                    getPunchInfo(moment(dateSel).format("YYYY-MM-DD"),id,(res)=>{
                        if(res?.FinalType === "WFH"){
                            setDefaultVal({dis:true,val:"WFH"})
                        }else{
                            setDefaultVal({dis:false,val:null})
                        }
                    })
                }
            }} 
             onFinish={manualPunchHandler}>
                <div style={{height:width>700?"60vh":"50vh",overflowY:"auto"}}>
                <Form.Item name="punchType" label={<span className={classes.itemTitle}>Select Type </span>} rules={[{required:true,message:"please select type"}]} >
                    <Select placeholder='Select type' style={{width:"100%"}} size="large">
                            {selectDummy?.map((item,index)=>(
                                <Option key={index} value={item}>{item}</Option>
                            ))}
                    </Select>
                </Form.Item>
                <Form.Item name='date'  label={<span className={classes.itemTitle}>Select Date </span>} rules={[{required:true,message:"please select date"}]} >
                   <DatePickerCompo format="YYYY-MM-DD" size="large"  style={{width:"100%"}}/>
                </Form.Item>
                <Form.Item name="worktype" label={<span className={classes.itemTitle}>Select Work Type </span>} rules={[{required:defaultVal?.val!==null?false:true,message:"please select work type"}]} >
                    <Select disabled={defaultVal.dis} value={defaultVal?.val} placeholder={defaultVal?.val!==null?defaultVal?.val:'Select work type'} style={{width:"100%"}} size="large">
                            {["WFO", "WFH"]?.map((item,index)=>(
                                <Option key={index} value={item}>{item}</Option>
                            ))}
                    </Select>
                </Form.Item>


                <Form.Item name="checkIn" label={<span className={classes.itemTitle}>Punch In Time </span>} rules={[{required:true,message:"please select in time"}]}>
                   <TimePicker  format="HH:mm" size="large"  style={{width:"100%"}}></TimePicker>
                </Form.Item>

                <Form.Item name="checkOut" label={<span className={classes.itemTitle}>Punch Out Time </span>} rules={[{required:true,message:"please select out time"}]}>
                <TimePicker   format="HH:mm" size="large"  style={{width:"100%"}}></TimePicker>
                </Form.Item>

                <Form.Item name='reason' label={<span className={classes.itemTitle}>Reason </span>} rules={[{required:true,message:"please enter reason",whitespace:true}]}>
                    <Input.TextArea rows={4} placeholder="Reason">
                    </Input.TextArea>
                </Form.Item>
                </div>

                <div className={classes.buttonDiv}>
                    <Button style={{height:"2.1rem",width:"6rem"}} type="primary" htmlType="submit">Save</Button>
                    <Button style={{backgroundColor:"#FFFFFF",color:"#0086FF",border:"1px solid #0086FF",height:"2.1rem",width:"6rem",display:"flex",justifyContent:"center"}} 
                     onClick={()=>{
                        setModal(false);
                    }}>Cancel</Button>
                </div>
            </Form>
        </Modal>
    )
}
export default AttendenceModal; 