import React, { useState } from "react";
import { Button, Modal, Upload, message } from "antd"
import profileIcon from "../../../assets/profileIconForProfile.svg";
import { UploadFile } from "./Action";
import ModalCompo from "../../../design/ModalCompo";
import classes from './jobSearch.module.css';
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";

const UploadPhotoModal = ({modal,setModal,setPhoto,photo,setIsFormChanged}) =>{
    const [imgFile, setImgFile] = useState("");
    const [imgFileName, setImgFileName] = useState("");
    const {width}=useWindowDimensions();

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const imgbeforeUpload = (file) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isJpgOrPng) {
          message.error("You can only upload JPG/PNG file!");
          setImgFile("");
          return isJpgOrPng || Upload.LIST_IGNORE;
        } 
        else if (!isLt5M) {
          message.error("Image must smaller than 5MB!");
          setImgFile("");
          return isLt5M || Upload.LIST_IGNORE;
        } 
        else {
        setImgFileName(file);
          getBase64(file, (url) => {      
            setImgFile(file);

          });
        }
        return false
    };

    const handleFileRemove = () => {
        setImgFile("");
    };

    const submitHandler = () => {
        // Api.patch(`/auth-auth/editProfileImage?authUserId=${decryptedUser?.authUserLoginId}`)
        // .uploadAndPatch({
        //     profileImage: imgFile,
        // }, (per,response) => { 
        //     if (response!==undefined && per===100) {       
        //         if(response?.data?.show?.type === "success"){ 
        //             console.log("sssREsponse",response);
        //             setModal(false);
        //             refresh();
        //         }
        //     }
        // });
        let file={file:imgFile}
        UploadFile(file,(res,per)=>{
            console.log('rsesssss',res,per)
            setPhoto({url:imgFile,key:res});
            setIsFormChanged(true);
            setModal(false);
        })
    }   

    return(
        <Modal open={modal} closableRight={true} onCancel={()=>{setModal(false)}} className={classes.UploadModal} centered
         footer={false}>
            <div style={{display:"flex",flexDirection:"column",gap:"2.5vh",width:"100%",justifyContent:"center",alignItems:"center",padding:"1vh 0"}}>
                <h3>Upload your picture</h3>
                <img src={imgFile ? URL.createObjectURL(imgFile) : photo ? (  photo?.link?photo?.link:URL.createObjectURL(photo?.url)) : profileIcon} 
                    alt="" style={{borderRadius:"50%",border:"1px dotted #0086FF",height:'200px',width:'200px'}}/>
                <div style={{display:"flex",flexDirection:"row",gap:"1vw",marginTop:"1vw"}}>
                    {imgFile || photo ? 
                    <div  className={classes.saveChooseBtn} style={{display:"flex",gap:"0.5rem",flexDirection:"row"}}>
                   <Upload accept=".png, .jpg, .jpeg," beforeUpload={imgbeforeUpload}
                     multiple={false} maxCount={1} onRemove={handleFileRemove} showUploadList={false}>
                        <Button style={{color:"#0086FF",border:"1px solid #0086FF",}}>
                            Choose photo
                        </Button>
                    </Upload>
                    <Button type="primary" style={{minWidth:width>700?"8rem":"7rem"}} onClick={submitHandler}>Save</Button>
                    </div>
                    :
                    <Upload accept=".png, .jpg, .jpeg," beforeUpload={imgbeforeUpload}
                     multiple={false} maxCount={1} onRemove={handleFileRemove} showUploadList={false}>
                        <Button style={{color:"#0086FF",border:"1px solid #0086FF"}}>
                            Choose photo
                        </Button>
                    </Upload>
                    }
                </div>
            </div>
        </Modal>
    )
}
export default UploadPhotoModal;