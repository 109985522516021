import React, { useEffect, useState } from 'react';
// import classes from "./style.module.css";
import classes from './login.module.css';
import Logo from '../../assets/loginLogo.svg';
// import Image from "../../assets/loginImg.png";
import Image from "../../assets/loginImg.svg";
import login_brigo_text from '../../assets/login_brigo_text.svg';
import   our_journey_img from '../../assets/our-journey-img.svg';
import brigoshaLogo from "../../assets/BrigoshalogoIcon.svg";
import job_search_icon from "../../assets/job_search_icon.svg";
import search_blue from "../../assets/search_blue.svg";
import noti_blue from "../../assets/notification_blue.svg";
import noti_grey from "../../assets/notification_grey.svg";
import prof_icon from "../../assets/prof_icon.svg";
import profile_grey from "../../assets/profile_grey.svg";
import poweredBy_icon from '../../assets/poweredBy_icon.svg';

import circular_check_grey from "../../assets/circular_check_grey.svg";
import circular_check_green from "../../assets/circular_check_green.svg";
import feed_icon from "../../assets/feed_icon.svg";
import   feed_blue from '../../assets/feed_blue.svg';
import   hiring_status_blue from '../../assets/hiring_status_blue.svg';
import   hiring_status_grey from '../../assets/hiring_status_grey.svg';
import Youtube from "../../assets/youtube.svg";
import linkedIn from "../../assets/linkedin.svg";
import Twitter from "../../assets/twitter.svg";
import Vector from "../../assets/Vector.png";
import PasswordIcon from "../../assets/Password.png";
import Union from "../../assets/Union.svg";
import Circle from "../../assets/Circle.svg";
import Union1 from "../../assets/Union 1.svg";
import Union2 from "../../assets/Union 2.svg";
import error_red_icon from "../../assets/error_red_icon.svg";
import { Card, Form, Input,Button,Row, Select, Spin, Checkbox, Modal } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined, EyeInvisibleOutlined,EyeOutlined, LockOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import Api from '../../services/Api';
import useStore from '../../pages/store';
import { decryptData, encryptData,SECRET_KEY } from '../../services/fileCode';
import { useWindowDimensions } from "../../components/windowDimention/windowDimension";
import uselocalStorageStore from '../localStorageStore';
import { useLoginStore } from './store';
import Feed from './Feed/Feed';
import JobSearch from './JobSearch/jobSearch';
import Notification from '../../services/Notification';
import HiringStatus from './HiringStatus/HiringStatus';
import CanNotification from './canNotification/Notification';
import Profile from './Profile/Profile';
import useLoginStorageStore from './loginStorageStore';
import { ProfileStore } from "../Login/Profile/store";
import back_icon from '../../assets/backIcon.svg';
import PhoneInput from '../../components/PhoneInput/PhoneInput';
import ForgotPassword from './forgotPassword';
import CheckMail from './checkMail';
import { hiringRoutePath } from '../Homepage/components/menuData';
import FooterDetails from './Profile/footerDetails';
import { useHeaderStore } from '../Header/store';
import MobileNavModal from '../../components/MobileNavModal/MobileNavModal';
import ReactivationAccount from './ReactivationAccount';
import SetNewPassword from './setNewPassword';
import credentials2 from '../../credentials2';


const Option=Select.Option;

export let userData = {}

export const validatePassword=(password)=>{
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasUpperCase = /[A-Z]/.test(password);
  const hasNumber = /\d/.test(password);
  const passLength=password?.trim()?.length>=8;
  return hasSpecialChar&&hasLowerCase&&hasUpperCase&&passLength;
}

export const Header=({activeTab,setActiveTab,isCandidate,setCurPage,loadOnTabClick})=>{
  const {notificationModalHandler}=useLoginStore();
  const { currentPage,setCurrentPage} = ProfileStore();
  
  return(
    <header className={classes.header}>
      <img src={brigoshaLogo}/>
      <div>
        <div onClick={()=>{
          setActiveTab(1);
          loadOnTabClick();
        }}  style={{borderBottom:activeTab===1?"2px solid #1089FF":0,}}>
          <img src={activeTab===1?feed_blue:feed_icon}/>
          <span className={activeTab===1?classes.activeTab:""}>Feed</span>
        </div>
        {isCandidate&&<div onClick={
          ()=>{setActiveTab(2);
            loadOnTabClick();
          }}  style={{borderBottom:activeTab===2?"2px solid #1089FF":0,}}>
          <img src={activeTab===2?hiring_status_blue:hiring_status_grey}/>
          <span className={activeTab===2?classes.activeTab:""}>Hiring Status</span>
        </div>}
        
        <div  onClick={()=>{
          setActiveTab(3);
          loadOnTabClick();
          }}  style={{borderBottom:activeTab===3?"2px solid #1089FF":0,}}> 
          <img src={activeTab===3?search_blue:job_search_icon}/>
          <span className={activeTab===3?classes.activeTab:""}>Job Search</span>
        </div>
        {isCandidate&&<div onClick={()=>{notificationModalHandler(true)}}  style={{borderBottom:activeTab===4?"2px solid #1089FF":0,}}>
          <img src={activeTab===4?noti_blue:noti_grey}/>
          <span className={activeTab===4?classes.activeTab:""}>Notifications</span>
        </div>}
        <div  onClick={()=>{
          setActiveTab(5);setCurrentPage("profile");
          setCurPage(1);
          loadOnTabClick();
          }} style={{borderBottom:activeTab===5?"2px solid #1089FF":0,}}>
          <img src={activeTab===5?prof_icon:profile_grey}/>
          <span className={activeTab===5?classes.activeTab:""}>Profile</span>
        </div>
      </div>

    </header>
  )
}

const Page1=({setCurPage,setIsSignIn,setIsSignInModal})=>{
  const navigate=useNavigate();
  return(
    <div className={classes.page1}>
       <span>Join our brigosha family today! </span>

      <div className={classes.page1ButtonDiv}>
        <button onClick={()=>{
          setCurPage(2);
          setIsSignIn(false);
        }}>Sign up </button>
        <button onClick={()=>{
          //this piece of code is for when you sign in by clicking a button and through a sign in modal
            // setCurPage(5);
            //  setIsSignIn(true);
            // setIsSignInModal(true);
            //  navigate('/login/signin')

            setCurPage(5);
            setIsSignIn(true);
        }}>Sign in</button>
      </div>
      {/* <span>or</span>
      <div className={classes.continueAsGeust}>
        <span>Continue as guest</span>
        <ArrowRightOutlined/>
      </div>
      <span></span>
      <span>Check us on</span>
      <div className={classes.socialIcon}>
        <img src={Twitter}/>
        <img src={linkedIn}/>
        <img src={Youtube}/>
       
      </div> */}
     

    </div>
  )
}

const Page2=({setCurPage,onFinish,setCountryCode,countryCode,
  signup,setSignup,
  setfooterRef,setIsTerm})=>{
  const [form]=Form.useForm();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [valid,setValid]=useState({name:false,phone:false,email:false});
  const [isPolicy,setIsPolicy]=useState(false);
  const {width}=useWindowDimensions();

  const handleFormChange = () => {
    const formValues = form.getFieldsValue();
    const isDisabled = Object.values(formValues).some(value => !value);
    setIsSubmitDisabled(isDisabled);
  };

  const validateName = (_, value) => {
    if (!value||value.trim() === ''||!(/^[a-zA-Z\s]+$/.test(value.trim()))) {
      setValid(prev=>({name:false,phone:prev.phone,email:prev.email}));
      return Promise.reject('Enter a valid name!');
    }
    setValid(prev=>({name:true,phone:prev.phone,email:prev.email}));
    return Promise.resolve();
  };

  const validatePhone = (_, value) => {
    if (!value||value.trim() === ''||value.trim().length!==10) {
      setValid(prev=>({name:prev.name,phone:false,email:prev.email}));
      return Promise.reject('Phone number should be of 10 digits!');
    }
    setValid(prev=>({name:prev.name,phone:true,email:prev.email}));
    return Promise.resolve();
  };

  const validateEmail = (_, value) => {
    if (!value||value.trim() === ''||!value.trim().includes('@')||!(value.trim().includes('.com')||value.trim().includes('.in'))) {
      setValid(prev=>({name:prev.name,phone:prev.phone,email:false}));
      return Promise.reject('Enter a valid email!');
    }
    setValid(prev=>({name:prev.name,phone:prev.phone,email:true}));
    return Promise.resolve();
  };

  const validateAll=()=>{
    const nameVal=!signup.name||signup.name.trim() === ''||!(/^[a-zA-Z\s]+$/.test(signup.name.trim()));
    const emailVal=!signup.email||signup.email.trim() === ''||!signup.email.trim().includes('@')||!(signup.email.trim().includes('.com')||signup.email.trim().includes('.in'));
    const phoneVal=!signup.phone||signup.phone.trim() === ''||signup.phone.trim().length!==10;
    return nameVal||emailVal||phoneVal;
  }

  return(
    <div className={classes.page2}>
      <span>Welcome</span>
      <span>Begin your journey with us today and stay updated.</span>

      <Form layout='vertical' form={form}
      className={classes.page2Form}
      onFieldsChange={
        handleFormChange
      }
      initialValues={signup}
      onFinish={onFinish}
      >
        <Form.Item name="name" label={<span>Name</span>}  rules={[{validator:validateName,}]}>
           <Input type='text' placeholder='Enter name' style={{height:"2.3rem"}} 
           onChange={(e)=>{
            setSignup(prev=>({...prev,name:e.target.value,}))
           }}
           />
        </Form.Item>

        <Form.Item name="phone" label={<span>Mobile number</span>} rules={[{validator:validatePhone,}]} >
          <PhoneInput countryCode={countryCode}setCountryCode={setCountryCode}
          value={signup?.phone}
           onChange={(e)=>{
            form.setFieldValue({phone:e.target.value})
            
              setSignup(prev=>({...prev,phone:e.target.value,}))

           
          }}/>
        </Form.Item>

        <Form.Item name="email" label={<span>Email Id</span>} rules={[{validator:validateEmail,}]} >
           <Input type='text' placeholder='Enter email ID' style={{height:"2.3rem"}}
             onChange={(e)=>{
              setSignup(prev=>({...prev,email:e.target.value,}))
             }}
           />
        </Form.Item>

        
        <div style={{display:"flex",alignItems:"start",gap:"0.4rem"}}>
           <Checkbox className={classes.checkbox} onChange={(e)=>{
            setIsPolicy(e.target.checked);
           }}/><span> You accept the <span className={classes.terms} onClick={()=>{
            setfooterRef("terms");
            setIsTerm(true);
           }}>Terms and service</span> and <span className={classes.terms} onClick={()=>{
            setfooterRef("privacy");
            setIsTerm(true);
           }}>Privacy policy.</span> </span>
           </div>
        

       
          <div className={classes.page2button} >
        <button htmlType="submit" disabled={validateAll()||!isPolicy}>Next</button>
        </div>
      

      </Form>

      

    </div>
  )
}

const Page3=({setCurPage,onFinish,onResend,})=>{
  const [form]=Form.useForm();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [OTP,setOTP]=useState({email:"",phone:""});
   const [timer, setTimer] = useState(60); // Initial timer value in seconds

  useEffect(() => {
    let interval;

    // Function to decrement the timer and clear the interval when it reaches 0
    const decrementTimer = () => {
      setTimer((prevTimer) => {
        if (prevTimer === 0) {
          clearInterval(interval);
          return 0;
        }
        return prevTimer - 1;
      });
    };

    // Start the timer when the component mounts
    interval = setInterval(decrementTimer, 1000);

    // Clean up the interval when the component is unmounted
    return () => clearInterval(interval);
  }, [timer]);

  const handleFormChange = () => {
    const formValues = form.getFieldsValue();
    const isDisabled = Object.values(formValues).some(value => !value);
    setIsSubmitDisabled(isDisabled);
  };

  console.log("isSubmitDisabled",isSubmitDisabled)
  return(
    <div className={classes.page2}>
      <span>Authentication check</span>
      <span>Please enter the 6 digit codes sent to your mobile number and email ID</span>

      <Form layout='vertical' form={form}
      className={classes.page3Form}
      onFieldsChange={
        handleFormChange
      }
      onFinish={onFinish}
      >
        <Form.Item name="mobileOTP" label={<span>Mobile number OTP</span>} >
           <Input type='number' placeholder='Enter mobile number OTP' style={{height:"2.4rem"}}
           onChange={(e)=>{
            setOTP(prev=>({phone:e.target.value,email:prev.email}))
           }}
           />
        </Form.Item>

        <Form.Item name="emailOTP" label={<span>Email ID OTP</span>} >
           <Input type='number' placeholder='Enter email id OTP' style={{height:"2.4rem"}}
            onChange={(e)=>{
              setOTP(prev=>({email:e.target.value,phone:prev.phone}))
             }}
           />
        </Form.Item>

        

        
          <div className={classes.page3button} >
          {timer>0? <span style={{color:"#000",fontSize:"0.85rem"}}>Resend OTP in<span style={{color:"#0086ff",fontWeight:"600"}}>{timer} seconds</span></span>:<div >
              <span style={{fontSize:"0.85rem"}}>Didn’t receive the codes?</span>
              <span style={{fontSize:"0.85rem"}} onClick={()=>{
                onResend(()=>{
                  setTimer(60);
                })
              }}>Resend</span>
            </div>}
        <button htmlType="submit" disabled={isSubmitDisabled||OTP.email.length!==6||OTP.phone.length!==6} >Next</button>
        </div>
      

      </Form>

      

    </div>
  )
}

const Page4=({setCurPage,onFinish})=>{
  const [form]=Form.useForm();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [password,setPassword]=useState({current:"",confirm:""});
  const [showPassword, setShowPassword] = useState(false);

  const handleFormChange = () => {
    const formValues = form.getFieldsValue();
    const isDisabled = Object.values(formValues).some(value => !value);
    setIsSubmitDisabled(isDisabled);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  console.log("isSubmitDisabled",isSubmitDisabled)
  const validateAll=()=>{
    return(!password?.current?.length>=8||!/[a-z]/.test(password?.current)||!/[A-Z]/.test(password?.current)||!/\d/.test(password?.current)||!/[!@#$%^&*(),.?":{}|<>]/.test(password?.current))
  }
  return(
    <div className={classes.page2}>
      <span>Start with a strong password</span>
      <span>Craft a robust, unique password for your email ID sign in.</span>

      <Form layout='vertical' form={form}
      className={classes.page2Form}
      onFieldsChange={
        handleFormChange
      }
      onFinish={onFinish}
      >
        <Form.Item name="password" label={<span>Password</span>} style={{marginBottom:"0",paddingBottom:"0"}}>
           <Input.Password type='password' showPassword placeholder='Enter password' style={{height:"2.4rem"}}
           onChange={(e)=>{
            setPassword(prev=>({current:e.target.value,confirm:prev.confirm}))
           }}
            iconRender={(visible) =>
                                      visible ? (
                                        <EyeOutlined onClick={togglePasswordVisibility} style={{marginTop:"0.2rem",}}/>
                                      ) : (
                                        <EyeInvisibleOutlined onClick={togglePasswordVisibility} style={{marginTop:"0.2rem",}}/>
                                       )
                                    }
           />
           
        </Form.Item>
        {/* {password?.current&&!validatePassword(password?.current)&&<span style={{margin:"0.2rem 0 0 0",fontSize:"0.8rem",display:"flex",color:"#FC5C65",gap:"0.4rem"}}><img src={error_red_icon} style={{width:"0.8rem",height:"0.8rem"}}/>Password should be at least 8 characters long, include at least one capital letter, one smaller letter, one special character and one decimal digit</span>} */}
        <div className={classes.passReq}>
          <span>Password Requirements:</span>
          <div >
            <img src={password?.current?.length>=8?circular_check_green:circular_check_grey}/>
            <span><span>Must</span> contain 8 or more characters</span>
          </div>
          <div >
            <img src={(/[a-z]/.test(password?.current))?circular_check_green:circular_check_grey}/>
            <span><span>Must </span> contain at least one lowercase letter</span>
          </div>
          <div >
            <img src={(/[A-Z]/.test(password?.current))?circular_check_green:circular_check_grey}/>
            <span><span>Must </span> contain at least one uppercase letter</span>
          </div>
          <div >
            <img src={(/\d/.test(password?.current))?circular_check_green:circular_check_grey}/>
            <span><span>Must </span> contain at least one number</span>
          </div>
          <div >
            <img src={(/[!@#$%^&*(),.?":{}|<>]/.test(password?.current))?circular_check_green:circular_check_grey}/>
            <span><span>Must </span> contain special characters (@ ! # $ % ^ & *)</span>
          </div>

        </div>
        {/* <span style={{color:"#CACACA",margin:"0.2rem 0 1rem 0",fontSize:"0.7rem"}}>Password should be minimum of 8 characters</span> */}

        <Form.Item name="confirmedPassword" label={<span>Re-enter password</span>} style={{marginTop:"1rem"}} >
           <Input.Password type='password' placeholder='Enter password' style={{height:"2.4rem"}}
            onChange={(e)=>{
              setPassword(prev=>({confirm:e.target.value,current:prev.current}))
             }}
           />
        </Form.Item>    
          <div className={classes.page2button} >
        <button htmlType="submit" disabled={isSubmitDisabled||validateAll()||password.confirm.length<8}>Next</button>
        </div>
      </Form>  
    </div>
  )
}

export const Page5=({setCurPage,setLoginBy,loginBy,setMobOTPResponse,setCountryCode,countryCode,isSignInModal})=>{
  const [form]=Form.useForm();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [ismobSignIn,setIsMobSignIn]=useState(true);

  const handleFormChange = () => {
    const formValues = form.getFieldsValue();
    const isDisabled = Object.values(formValues).some(value => !value);
    setIsSubmitDisabled(isDisabled);
  };

  

  function containsOnlyDigits(str) {
    // Regular expression to match only digits
    var regex = /^[0-9]+$/;
    // Test the string against the regex
    return regex.test(str);
  }

  console.log("isSubmitDisabled",isSignInModal)

  

  return(
    <div  className={isSignInModal?classes.page5:classes.page2} >
      <span style={{width:"100%",textAlign:"center"}}>Welcome Back</span>
      <span  style={{width:"100%",textAlign:"center"}}>Let's get you signed in</span>

      <Form layout='vertical' form={form}
      className={classes.page2Form}
      onFieldsChange={
        handleFormChange
      }
      onFinish={(event)=>{
        console.log("Page 2 Form event",event);

        if(event?.email)
        {
          Api.post('/auth-auth/checkEmail-Phone').params({
          email:event.email,
         
        }).send(res=>{
          if(res?.type==="error")
          {
            if(res?.message==="Unique User")
            {
              Notification.error("Oops! It looks like you don't have an account yet. To get started, sign up and enjoy our range of services.")
            }
            else
            {
              Notification.error(res?.message);

            }
          }
          else if(res?.type==="success")
          {
            setCurPage(6);
          }
        })
          

        }
        else
        {
          Api.post('/auth-auth/checkEmail-Phone').params({
            phone:event.phone,
            countryCode:countryCode,
           
          }).send(res=>{
            if(res?.type==="error")
            {
              if(res?.message==="Unique User")
              {
                Notification.error("Oops! It looks like you don't have an account yet. To get started, sign up and enjoy our range of services.")
              }
              else
              {
                Notification.error(res?.message);
  
              }
            }
            else if(res?.type==="success")
            {
              Api.post('/auth-auth/sendMobileOTP').params({
                phone:event.phone
              }).send(res=>{
                setMobOTPResponse(res);
                setCurPage(8);
              });
            }
          })
         
          
        }
        
      }}
      >
        {!ismobSignIn?<Form.Item name="email" label={<span>Email ID</span>} >
           <Input type='text' 
          //  value={loginBy}
           onChange={(e)=>{
            setLoginBy(e.target.value);
           }}
           placeholder='Enter email ID' style={{height:"2.3rem"}}/>
        </Form.Item>:
        <Form.Item name="phone" label={<span>Mobile Number</span>}>
        <PhoneInput countryCode={countryCode} setCountryCode={setCountryCode} onChange={(e)=>{
          form.setFieldValue({emailOrPhone:e.target.value});
          setLoginBy(e.target.value);
        }}/>
      </Form.Item>

        }

        {!ismobSignIn?<span className={classes.signBy} onClick={()=>{setIsMobSignIn(prev=>!prev);
         form.setFieldValue({email:undefined});
         setLoginBy('');
        }}>Sign in using<span>mobile number</span> </span>:
        <span className={classes.signBy} onClick={()=>{setIsMobSignIn(prev=>!prev);
          form.setFieldValue({email:undefined});
          setLoginBy('');
          form.resetFields();
        }}>Sign in using<span>email ID</span></span>}
        
        <div className={classes.page2button}>
        <button htmlType="submit" disabled={isSubmitDisabled||(containsOnlyDigits(loginBy)?loginBy.length!==10:(!loginBy.includes('@')||!(loginBy.includes('.com')||loginBy.trim().includes('.in'))))}>{!ismobSignIn?"Next":"Send OTP"} </button>
        </div>
      

      </Form>

      

    </div>
  )
}


export const Page6=({setCurPage,onFinish,isSignInModal})=>{
  const [form]=Form.useForm();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [password,setPassword]=useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate=useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleFormChange = () => {
    const formValues = form.getFieldsValue();
    const isDisabled = Object.values(formValues).some(value => !value);
    setIsSubmitDisabled(isDisabled);
  };

  return(
    <div className={isSignInModal?classes.page5:classes.page2}>
      <span style={{width:"100%",textAlign:"center"}}>Authentication check</span>
      <span style={{width:"100%",textAlign:"center"}}>Please enter your password</span>

      <Form layout='vertical' form={form}
      className={classes.page3Form}
      onFieldsChange={
        handleFormChange
      }
      onFinish={onFinish}
      >
        <Form.Item name="password" label={<span>Password</span>} >
        <Input.Password type='password' showPassword placeholder='Enter password' style={{height:"2.4rem"}}
        onChange={(e)=>{
          setPassword(e.target.value);
        }}
            iconRender={(visible) =>
                                      visible ? (
                                        <EyeOutlined onClick={togglePasswordVisibility} style={{marginTop:"0.2rem",}}/>
                                      ) : (
                                        <EyeInvisibleOutlined onClick={togglePasswordVisibility} style={{marginTop:"0.2rem",}}/>
                                       )
                                    }
           />
        </Form.Item>
          
          <div className={classes.page3button}  style={{marginTop:"4rem"}}>
          <div >
            <span></span>
              <span style={{fontSize:"0.83rem"}} onClick={()=>{
                setCurPage(7);
                // navigate('/forgotPassword');
                }}>Forgot password?</span>
            </div>
        <button htmlType="submit" disabled={isSubmitDisabled||password.length<8}>Sign in</button>
        </div>     
      </Form>
    </div>
  )
}



export const Page8=({setCurPage,onFinish,onResend,isSignInModal})=>{
  const [form]=Form.useForm();
  const [OTP,setOTP]=useState();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [timer,setTimer]=useState(60)// Initial timer value in seconds


  useEffect(() => {
    let interval;

    // Function to decrement the timer and clear the interval when it reaches 0
    const decrementTimer = () => {
      setTimer((prevTimer) => {
        if (prevTimer === 0) {
          clearInterval(interval);
          return 0;
        }
        return prevTimer - 1;
      });
    };

    // Start the timer when the component mounts
    interval = setInterval(decrementTimer, 1000);

    // Clean up the interval when the component is unmounted
    return () => clearInterval(interval);
  }, [timer]);

  const handleFormChange = () => {
    const formValues = form.getFieldsValue();
    const isDisabled = Object.values(formValues).some(value => !value);
    setIsSubmitDisabled(isDisabled);
  };

  console.log("isSubmitDisabled",isSubmitDisabled)
  return(
    <div className={isSignInModal?classes.page5:classes.page2}>
      <span>Authentication check</span>
      <span>Please enter the OTP sent to your mobile number</span>

      <Form layout='vertical' form={form}
      className={classes.page3Form}
      onFieldsChange={
        handleFormChange
      }
      onFinish={onFinish}
      >
        <Form.Item name="OTP" label={<span>OTP</span>} >
           <Input type='number' placeholder='Enter OTP' style={{height:"2.4rem"}}
           onChange={(e)=>{
            setOTP(e.target.value);
           }}
           />
        </Form.Item>
          <div className={classes.page3button} style={{marginTop:"3rem"}}>
          {timer>0? <span style={{color:"#000",fontSize:"0.85rem"}}>Resend OTP in<span style={{color:"#0086ff",fontWeight:"600"}}>{timer} seconds</span></span>
          :
          <div style={{fontSize:"0.85rem"}}>
              <span >Didn’t receive the codes?</span>
              <span
              onClick={()=>{
                onResend(()=>{
                  setTimer(60);
                })
              }}
              >Resend</span>
            </div>}
        <button htmlType="submit" disabled={isSubmitDisabled||OTP.length!==6}>Sign in</button>
        </div>
      </Form>
    </div>
  )
}



function Login() {
  const navigate = useNavigate();
  const {updateToken,updateUser,updateNewUser } = useStore(state=>state);
  const [showPassword, setShowPassword] = useState(false);
  const {width}=useWindowDimensions();
  const {setProfileImage}=uselocalStorageStore();
  // const [curPage, setCurPage]=useState(1);
  const {curPage,resendPhone,setResendPhone,setCurPage,setIsSignIn,setIsSignInModal,isSignIn,isSignInModal,notificationModal,activeTab,setActiveTab,referrerURL,setUrl,setClickedJob,updateRender,
    // isComingfromLidar,setIsComingfromLidar
  }=useLoginStore();
  const {setUserData}= ProfileStore()
  const [loginBy,setLoginBy]=useState("");
  const [loginPass,setLoginPass]=useState('');
  // const [isSignIn, setIsSignIn]=useState(false);
  // const []=useState(1);
  const [mobOTPResponse,setMobOTPResponse]=useState("");
  const [emailOTPResponse,setEmailOTPResponse]=useState("");
  const [userCredential,setUserCredential]=useState({name:"",email:"",phone:""});
  const [signInCredential, setSignInCreadential]=useState({email:"",phone:""});
  const [verifySucces,setverifySuccess]=useState({phone:false,email:false});;
  const {isLogin, setIsLogin,isCandidate,setIsCandidate}=useLoginStorageStore();
  const [signup,setSignup]=useState({name:"",email:"",phone:""});
  const [countryCode,setCountryCode]=useState("+91");
  const [loading,setLoading]=useState(false);
  const [isTerm,setIsTerm]=useState(false);
  const [ispolicy,setIsPolicy]=useState(false);
  const {setfooterRef}=ProfileStore();
  const {sidemenu,setSidemenu}=useHeaderStore();
  const {notificationModalHandler}=useLoginStore();
  const { currentPage,setCurrentPage} = ProfileStore();
  const candidate=isCandidate&&isLogin;
const[isComingfromLidar,setIsComingfromLidar]=useState(false)

  const loadOnTabClick=()=>{
    setClickedJob();
    updateRender(1);
    setLoading(true);
    setTimeout(()=>{
      setLoading(false);
    },50)
  }

  // useEffect(() => {
  //       const handlePopState = () => {
  //         // Reload the page when the back button is clicked
  //         window.location.reload();
  //       };
    
  //       // Add a listener for the popstate event
  //       window.addEventListener('popstate', handlePopState);
    
  //       // Clean up the listener when the component unmounts
  //       return () => {
  //         window.removeEventListener('popstate', handlePopState);
  //       };
  //     }, []);



 

//  const onFinish=(values)=>{
//   Api.post("/resource-auth/signin") 
//      .params({
//       email:loginBy,
//       password:values.password,
//      })
//      .send((res)=>{
//       console.log("Response",res);
//       if (typeof res !== undefined && res?.show?.type === "success") {
//         console.log("Response",res);  
//         Api.header(res?.show?.data?.accessToken)
//         const encryptedToken = encryptData(res?.show?.data?.accessToken, SECRET_KEY);
//         const encryptedUser = encryptData(res?.show?.data?.userPayload, SECRET_KEY);
//         // const encryptedRole=encryptData(res?.show?.data?.userRole,SECRET_KEY);
//         localStorage.setItem('token', encryptedToken);
//         localStorage.setItem('user', encryptedUser);    
//         updateToken(res?.show?.data?.accessToken);
//         updateUser(res?.show?.data?.userPayload);
//         updateNewUser(res?.show?.data?.newUser);
//         userData=res?.show?.data?.userPayload;
//         setProfileImage(res?.show?.data?.userPayload?.profileImage);
        
//         Api.get(`/appraisal-associate/userData?userViewId=${res?.show?.data?.userPayload?.id}`)
//         .params({
//           tokenId: res?.show?.data?.accesstoken,
//         })
//         .send((response, error) => {
//           console.log("appraisalDaaata",response)
//           if (typeof response != "undefined") {
//             let user = {
//               ...response.appraisalUsers,
//               email: response.email,
//               name: response.name,
//             };
//             const encryptedAppraisalData = encryptData(JSON.stringify(user),SECRET_KEY)
//             localStorage.setItem('AppraisalUserData',encryptedAppraisalData)
//           }
//         });
     

//         if(res?.show?.data?.newUser === true){
//           navigate("/checkMail")
//         }else{
//           navigate("/homePage"); 
//         }
//       }
//     });
//  }


  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    let decryptedUser;
    let params= new  URLSearchParams(window.location.search)
    let page=params.get('page')
    let id=params.get('jobId')
    let origin=params.get('origin')
    let tab=params.get('page')
    if (storedUser) {
       decryptedUser = decryptData(storedUser, SECRET_KEY);
       if(decryptedUser?.lidarUser?.id && origin==='lidar'){
        // this is to check if user is already login in pmt portal so directly we will pass to lidar by giving token
          let token=localStorage.getItem('token')
          if(token){
            token=decryptData(token,SECRET_KEY);
            let  lidarUrl=credentials2?.lidarUrl;
            let navUrl=lidarUrl+'/verify?token='+token     
            window.location.replace(navUrl)
          }
          
        
       }
    if(decryptedUser?.authRole==="Associate" && origin!=='lidar'){
      navigate("/homePage");
     } 
    }

    
    // console.log("useeffect page:",page,"jobId",id)
    if(page==="jobs"){
      // console.log("useeffect kaushal if page:",page,"jobId",id)
      navigate("/login");
      setActiveTab(3);
      setClickedJob({id:id})
      
    }
    else if(origin==='lidar'){
      setIsComingfromLidar(true);
      navigate("/login");
      setActiveTab(5);
        if(tab==='signIn'){

        }
        else if (tab==='signUp'){

        }
    }
  }, []);


 useEffect(() => {
  const storedToken = localStorage.getItem('token');
  const storedUser = localStorage.getItem('user');
  if (storedToken && storedUser) {
  const decryptedToken = decryptData(storedToken, SECRET_KEY);
  const decryptedUser = decryptData(storedUser, SECRET_KEY);
    updateToken(decryptedToken);
    updateUser(decryptedUser);
  }
}, []);

useEffect(()=>{
  console.log("verifySucces",verifySucces)
  if(verifySucces.email&&verifySucces.phone)
  {
      setCurPage(4);
  }
},
[verifySucces])
// let referrerURL = document.referrer;

useEffect(()=>{
  let user =  localStorage.getItem('user')
  if(user){
    user = decryptData(user, SECRET_KEY);
    if(user?.hiringUser?.role?.includes("College-Admin")||user?.hiringUser?.role?.includes("Agency")){
      setIsLogin(false)
      setCurPage(1)
      setUserData({})
      window.localStorage.clear()
    }
  }
 

  /* if(document.referrer&& referrerURL?.pre==="NotLink"){
    setUrl({curr:"", pre:document.referrer?"link":"NotLink"})
  }
  if(referrerURL.cur===""&&referrerURL?.pre==="link"){
    setIsLogin(false)
    setCurPage(1)
    setUserData({})
    window.localStorage.clear()
    setUrl({curr:"", pre:""})
  } */

},[document.referrer])

const registerUser=(events)=>{
  if(events.password!==events.confirmedPassword)
  {
    Notification.error('The entered passwords should match!');
    return;
  }
  Api.post("/auth-auth/registerUser").params({registrationData:{
    email:userCredential.email,
    name:userCredential.name,
    phone:userCredential.phone,
    password:events.password,
    sessionId:mobOTPResponse?.show?.sessionId,
    countryCode:countryCode,
  }}).send(res=>{
    if(res?.show?.type==="success")
    {
       setCurPage(1);
      // console.log("ENTER HERRRRREEE")
      // setIsSignIn(true);
      // if(isComingfromLidar){
      //  navigate('/login/signin?origin=lidar')

      // }
      // else{
      //  navigate('/login/signin')
      // }
    }

  }) 
 

}

const getOTP=(events)=>{
  Api.post('/auth-auth/checkEmail-Phone').params({
    email:events.email,
    phone:events.phone,
    countryCode:countryCode,
  }).send(res=>{
    if(res?.type==="error")
    {
      setSignup({name:events.name, email:events.email, phone:events.phone});
      setUserCredential({name:events.name, email:events.email, phone:events.phone});
      Api.post('/auth-auth/sendMobileOTP').params({
        phone:events.phone,
        register:true,
      }).send(res=>{
        setMobOTPResponse(res);
      });
    
      Api.post('/auth-auth/sendEmailOTP').params({
        email:events.email,
        userName:events.name
      }).send(res=>{
        setEmailOTPResponse(res);
    
      })
      setSignup({name:"",email:"",phone:""})
      setCurPage(3);

    }
    else if(res?.type==="success")
    {
      if(res?.message==="Email & Phone already registered")
      {
        Notification.error("The entered email ID and phone number are already registered!");
        return;
      }
      else if (res?.message==="Phone already registered")
      {
        Notification.error("The entered phone number is already registered!");
        return;
      }
      else if (res?.message==="Email already registered")
      {
        Notification.error("The entered email ID  is already registered!");
        return;
      }
    
      Notification.error(res?.message);
      return;
    }
  })
  console.log("userEventt",events)
  // setCountryCode(events.countryCode);
 
}

const resendSignupOtp=(callback)=>{
  Api.post('/auth-auth/sendMobileOTP').params({
    phone:signup.phone
  }).send(res=>{
    setMobOTPResponse(res);
  });

  Api.post('/auth-auth/sendEmailOTP').params({
    email:signup.email,
    userName:signup.name
  }).send(res=>{
    setEmailOTPResponse(res);
  callback();
  })
}

 const verifyMobOTP=(events)=>{
  Api.post('/auth-auth/verifyMobileOTP').params({
    phone:userCredential.phone,
    otp:events.OTP,
    sessionId:mobOTPResponse?.show?.sessionId

  }).send(res=>{
    if(res?.show?.type==="success")
    {
      Api.post(`/auth-auth/signIn`).params({signInData:{
        phoneNo:loginBy,
        web:true,
        countryCode:countryCode
      }}).send(res=>{
        if(res?.show?.type === "success" )
        {
          setIsSignIn(false);
        }

        if(res?.show?.verifyEmail===false)
  {
    // navigate("/checkMail")
   // setIsLogin(false);
   setIsSignIn(true);
   setResendPhone(res?.show?.phone)
    setCurPage(9);
  }
  else if(res?.newResourceUserValidation=== true){
    console.log("xxxxxxx12344",res)
  
    navigate(`/auth-auth/checktoken?userId=${res?.authUserLoginId}&token=new`);
    // setCurPage(11);
    return;
    // to show the reset password page;
  }
     else if ( res?.show?.type === "success" && res?.show?.data) 
      {

        

        console.log("Response",res);  
        Api.header(res?.show?.data?.accessToken)
        const encryptedToken = encryptData(res?.show?.data?.accessToken, SECRET_KEY);
        const encryptedUser = encryptData(res?.show?.data?.payload, SECRET_KEY);
        const encryptedLoginToken = encryptData(res?.show?.data?.encryptedLoginId, SECRET_KEY);
        // const encryptedRole=encryptData(res?.show?.data?.userRole,SECRET_KEY);
       
        localStorage.setItem('loginToken', encryptedLoginToken);
        localStorage.setItem('token', encryptedToken);
        localStorage.setItem('user', encryptedUser);    
        updateToken(res?.show?.data?.accessToken);
        updateUser(res?.show?.data?.payload);
        updateNewUser(res?.show?.data?.newUser);
        userData=res?.show?.data?.payload;
        setProfileImage(res?.show?.data?.payload?.profileImage);
        if(referrerURL?.curr === "" && referrerURL.pre ===""){
            setUrl({
              curr:"login",
              pre:"NotLink"
            })
        }
        if (res?.show?.data?.payload?.hiringUser?.role?.includes("Agency")||res?.show?.data?.payload?.hiringUser?.role?.includes("College-Admin")) {
          hiringRoutePath().then((result) => {
            window.location.replace(result)
          }).catch((error) => {
            console.error('Error:', error);
          });
        }
        setIsLogin(true);
        setCurPage(1);
          if(res?.show?.data?.payload?.authRole==="Associate")
          {
              Api.get(`/appraisal-associate/userData?userViewId=${res?.show?.data?.payload?.resourceUser?.id}`)
              .params({
                tokenId: res?.show?.data?.accessToken,
              })
              .send((response, error) => {
                console.log("appraisalDaaata",response)
                if (typeof response != "undefined") {
                  let user = {
                    ...response.appraisalUsers,
                    email: response.email,
                    name: response.name,
                  };
                  const encryptedAppraisalData = encryptData(JSON.stringify(user),SECRET_KEY)
                  localStorage.setItem('AppraisalUserData',encryptedAppraisalData)
                }
        
          }
           )
             setActiveTab(undefined);
             if(isComingfromLidar===false){
              navigate("/homePage"); 
             }
           
          }
          else if (res?.show?.data?.payload?.authRole==="Guest")
            {
              setActiveTab(1);
              // if(res?.show?.data?.payload?.hiringCandidate)
              // {
              //   setIsCandidate(true);
              // }
            }
          else if(res?.show?.data?.payload?.authRole==="Candidate")
            {
              setIsCandidate(true);
              setActiveTab(1);
            }
                  // else if(res?.show?.data?.payload?.authRole==="Associate")
              // {
              //   setActiveTab(undefined);
              //   navigate("/homePage"); 

              // }
    if(isComingfromLidar ){
      let  lidarUrl=credentials2?.lidarUrl;

      let token=''
      if(res?.show?.data?.payload?.lidarUser?.id){
        token=res?.show?.data?.accessToken;
      }
      let navUrl=lidarUrl+'/verify?token='+token
      
      setIsComingfromLidar(false);
      window.location.replace(navUrl)
    }
       
  }
      })
    }
  })
}

const verifyOTP= (events)=>{
 
   Api.post('/auth-auth/verifyMobileOTP').params({
    phone:userCredential.phone,
    otp:events.mobileOTP,
    sessionId:mobOTPResponse?.show?.sessionId

  }).send(res=>{
   
    setverifySuccess(prev=>({phone:res?.show?.type==="success",email:prev.email}))
    

  })
   Api.post('/auth-auth/verifyEmailOTP').params({
    email:userCredential.email,
    otp:events.emailOTP,
    modOTP:emailOTPResponse?.show?.modOTP,
    expiryTime:emailOTPResponse?.show?.expiryTime,
  }).send( res=>{
    setverifySuccess(prev=>({email:res?.show?.type==="success",phone:prev.phone}))
  })

}

const onResend=(callback)=>{
  Api.post('/auth-auth/sendMobileOTP').params({
    phone:loginBy
  }).send(res=>{
    setMobOTPResponse(res);
    setCurPage(8);
    callback();
  });
}

function containsOnlyDigits(str) {
  // Regular expression to match only digits
  var regex = /^[0-9]+$/;
  // Test the string against the regex
  return regex.test(str);
}





const signIn=(events)=>{
  if(!containsOnlyDigits(loginBy))
  {
    setLoginPass(events.password);
    Api.post(`/auth-auth/signIn`).params({signInData:{
      email:loginBy,
      password:events.password,
      web:true,
    }}).send(res=>{
      if(res?.show?.type === "success" )
      {
        setIsSignIn(false);
      }

      if(res?.show?.verifyEmail===false)
  {
    // navigate("/checkMail")
   // setIsLogin(false);
   setIsSignIn(true);
   setResendPhone(res?.show?.phone)
    setCurPage(9);
  }
  else if(res?.newResourceUserValidation=== true){
  
    navigate(`/auth-auth/checktoken?userId=${res?.authUserLoginId}&token=new`);
    // setCurPage(11);
    return;
    // to show the reset password page;
  }
     else if ( res?.show?.type === "success" && res?.show?.data) 
      {

        

        console.log("Response",res);  
        Api.header(res?.show?.data?.accessToken)
        const encryptedToken = encryptData(res?.show?.data?.accessToken, SECRET_KEY);
        const encryptedUser = encryptData(res?.show?.data?.payload, SECRET_KEY);
        const encryptedLoginToken = encryptData(res?.show?.data?.encryptedLoginId, SECRET_KEY);
        // const encryptedRole=encryptData(res?.show?.data?.userRole,SECRET_KEY);
        // let userLoginToken = decryptData(encryptedLoginToken,SECRET_KEY);
        // console.log("userLoginTokenuserLoginToken",userLoginToken)
        localStorage.setItem('loginToken', encryptedLoginToken);
        localStorage.setItem('token', encryptedToken);
        localStorage.setItem('user', encryptedUser);    
        updateToken(res?.show?.data?.accessToken);
        updateUser(res?.show?.data?.payload);
        updateNewUser(res?.show?.data?.newUser);
        userData=res?.show?.data?.payload;
        setProfileImage(res?.show?.data?.payload?.profileImage);
        if(referrerURL?.curr === "" && referrerURL.pre ===""){
            setUrl({
              curr:"login",
              pre:"NotLink"
            })
        }
        if (res?.show?.data?.payload?.hiringUser?.role?.includes("Agency")||res?.show?.data?.payload?.hiringUser?.role?.includes("College-Admin")) {
          hiringRoutePath().then((result) => {
            window.location.replace(result)
          }).catch((error) => {
            console.error('Error:', error);
          });
        }
        setIsLogin(true);
        setCurPage(1);
          if(res?.show?.data?.payload?.authRole==="Associate")
          {
              Api.get(`/appraisal-associate/userData?userViewId=${res?.show?.data?.payload?.resourceUser?.id}`)
              .params({
                tokenId: res?.show?.data?.accessToken,
              })
              .send((response, error) => {
                console.log("appraisalDaaata",response)
                if (typeof response != "undefined") {
                  let user = {
                    ...response.appraisalUsers,
                    email: response.email,
                    name: response.name,
                  };
                  const encryptedAppraisalData = encryptData(JSON.stringify(user),SECRET_KEY)
                  localStorage.setItem('AppraisalUserData',encryptedAppraisalData)
                }
        
          }
           )
             setActiveTab(undefined);
             if(isComingfromLidar===false){
             navigate("/homePage"); }
           
          }
          else if (res?.show?.data?.payload?.authRole==="Guest")
            {
              setActiveTab(1);
              // if(res?.show?.data?.payload?.hiringCandidate)
              // {
              //   setIsCandidate(true);
              // }
            }
          else if(res?.show?.data?.payload?.authRole==="Candidate")
            {
              setIsCandidate(true);
              setActiveTab(1);
            }
                  // else if(res?.show?.data?.payload?.authRole==="Associate")
              // {
              //   setActiveTab(undefined);
              //   navigate("/homePage"); 

              // }
    console.log('iscomingfromLidar==>',isComingfromLidar)
              if(isComingfromLidar){
                let  lidarUrl=credentials2?.lidarUrl;
                let token=''
      if(res?.show?.data?.payload?.lidarUser?.id){
        token=res?.show?.data?.accessToken;
      }
                let navUrl=lidarUrl+'/verify?token='+token
    console.log('isgoing to Lidar==>',navUrl)
                
                setIsComingfromLidar(false);
                window.location.replace(navUrl)
              }
  }
  
 
})
  }
}

 
  


// useEffect(()=>{
//   if(activeTab!==5)
//   {
//     setCurPage(1);
//   }
  
// },[activeTab])

// console.log("currrrr",curPage)

let mobileNav=<svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20" fill="none">
<path d="M20.8389 8.77979H1.16111C0.519847 8.77979 0 9.46039 0 10.3V10.46C0 11.2996 0.519847 11.9802 1.16111 11.9802H20.8389C21.4802 11.9802 22 11.2996 22 10.46V10.3C22 9.46039 21.4802 8.77979 20.8389 8.77979Z" fill="#162432"/>
<path d="M20.8389 16.7808H1.16111C0.519847 16.7808 0 17.4614 0 18.3009V18.461C0 19.3005 0.519847 19.9811 1.16111 19.9811H20.8389C21.4802 19.9811 22 19.3005 22 18.461V18.3009C22 17.4614 21.4802 16.7808 20.8389 16.7808Z" fill="#162432"/>
<path d="M20.8389 0.778809H1.16111C0.519847 0.778809 0 1.45941 0 2.29899V2.459C0 3.29858 0.519847 3.97918 1.16111 3.97918H20.8389C21.4802 3.97918 22 3.29858 22 2.459V2.29899C22 1.45941 21.4802 0.778809 20.8389 0.778809Z" fill="#162432"/>
</svg>


  return (
    <>
      <MobileNavModal sidemenu={sidemenu} setSidemenu={setSidemenu} candidate={true}>
    <div className={classes.sider} onClick={()=>{
      setSidemenu(false);
      // window.location.reload();
    }}>               
                        <div>
        <div onClick={()=>{
          setActiveTab(1);
          loadOnTabClick();
        }}  style={{borderBottom:activeTab===1?"2px solid #1089FF":0,}}>
          <img src={activeTab===1?feed_blue:feed_icon}/>
          <span className={activeTab===1?classes.activeTab:""}>Feed</span>
        </div>
        {isCandidate&&<div onClick={
          ()=>{setActiveTab(2);
            loadOnTabClick();
          }}  style={{borderBottom:activeTab===2?"2px solid #1089FF":0,}}>
          <img src={activeTab===2?hiring_status_blue:hiring_status_grey}/>
          <span className={activeTab===2?classes.activeTab:""}>Hiring Status</span>
        </div>}
        
        <div  onClick={()=>{
          setActiveTab(3);
          loadOnTabClick();
          }}  style={{borderBottom:activeTab===3?"2px solid #1089FF":0,}}> 
          <img src={activeTab===3?search_blue:job_search_icon}/>
          <span className={activeTab===3?classes.activeTab:""}>Job Search</span>
        </div>
        {isCandidate&&<div onClick={()=>{notificationModalHandler(true)}}  style={{borderBottom:activeTab===4?"2px solid #1089FF":0,}}>
          <img src={activeTab===4?noti_blue:noti_grey}/>
          <span className={activeTab===4?classes.activeTab:""}>Notifications</span>
        </div>}
        <div  onClick={()=>{
          setActiveTab(5);setCurrentPage("profile");
          setCurPage(1);
          loadOnTabClick();
          }} style={{borderBottom:activeTab===5?"2px solid #1089FF":0,}}>
          <img src={activeTab===5?prof_icon:profile_grey}/>
          <span className={activeTab===5?classes.activeTab:""}>Profile</span>
        </div>
      </div>

                      <div>
                              <img src={poweredBy_icon}></img>
                      </div>      
             </div>
    </MobileNavModal>
    {width<700?
    <div className={classes.mobHeader}>
       <img src={brigoshaLogo}/>
     <span  onClick={()=>{
      setSidemenu(true)
    }}>{mobileNav}</span> 
      </div>:
     <Header loadOnTabClick={loadOnTabClick} setActiveTab={setActiveTab} activeTab={activeTab} isCandidate={candidate} setCurPage={setCurPage}/>}
    {loading?
    null:
        <>

        {activeTab===1&&<Feed setActiveTab={setActiveTab} candidate={candidate}/>}
        {activeTab===2&&<HiringStatus/>}
        {activeTab===3&&<JobSearch/>}
        {notificationModal&&<CanNotification setActiveTab={setActiveTab} />}
       

        {activeTab===5&&(
          isLogin?
          <Profile/>
          :isTerm?
          <FooterDetails onBackClick={()=>{setIsTerm(false)}}/>
          :


        <div className={classes.login}>
          {width < 700 && (curPage === 2 || curPage === 3 || curPage === 4 || curPage === 5 || curPage === 6 || 
          curPage === 7 || curPage === 8 || curPage === 9) ?
           "":
          <section className={classes.imageSection}>
            
                {/* <img src={login_brigo_text}/> */}
                <img src={our_journey_img}/>
                <div className={classes.integrityText}>
                  <span>Self determination and integrity</span>
                </div>
          </section>}

          <section className={classes.loginWrapper} style={{marginLeft:width<700 && "0 0.5rem",marginRight:width<700 && "0 0.5rem"}}>
            {curPage!==1&&<div className={classes.backDiv} style={{marginTop: (width < 700 && curPage!==1) && "-3.5rem" }} 
             onClick={()=>{
              if(isSignIn&&curPage===5)
              {
                setCurPage(1);
              }
              else if((loginBy?.includes('.com')||loginBy?.includes('.in'))&&curPage===9)
              {
                  setCurPage(6);
              }
              else if (curPage===8)
              {
                setCurPage(5);
              }
              else
              {
                setCurPage(curPage-1);
              }
              }}>
              <div>
                <ArrowLeftOutlined/>
              </div>
              <span>Back</span>
            </div>}

            <div className={classes.loginContainer} style={{height: width<700 && curPage===1 ? "18rem" : width<700 && "70vh",borderRadius:(width<700 && curPage===1)&&"none",boxShadow:(width<700 && curPage===1)&&"none"}}>
              {(curPage===1)&&<Page1 setCurPage={setCurPage} setIsSignIn={setIsSignIn} setIsSignInModal={setIsSignInModal}/>}
              
              {curPage===2&&<Page2
              signup={signup}
              setSignup={setSignup}
               setCurPage={setCurPage} onFinish={getOTP}
                setCountryCode={setCountryCode} countryCode={countryCode} setfooterRef={setfooterRef} setIsTerm={setIsTerm}/>}
              {curPage===3&&<Page3 setCurPage={setCurPage} onFinish={verifyOTP} onResend={resendSignupOtp}/>}
              {curPage===4&&<Page4 setCurPage={setCurPage} onFinish={registerUser}/>}
              {curPage===5&&<Page5 
              isSignInModal={isSignInModal}
              setCountryCode={setCountryCode} countryCode={countryCode}
              setCurPage={setCurPage} setLoginBy={setLoginBy} loginBy={loginBy} setMobOTPResponse={setMobOTPResponse}/>}
              {curPage===6&&<Page6 setCurPage={setCurPage} onFinish={signIn} isSignInModal={isSignInModal}/>}
              {curPage===7&&<ForgotPassword/>}
              {curPage===8&&<Page8 setCurPage={setCurPage} onFinish={verifyMobOTP} onResend={onResend} isSignInModal={isSignInModal} />}
              {curPage===9 
                &&<CheckMail onResend={()=>{
                //backned team asked to hit signin api with phone on clicking the resend button. which they are providing in sign in api response.
                
                    
      Api.post(`/auth-auth/signIn`).params({signInData:{
        phoneNo:resendPhone,
        web:true,
      }}).send(res=>{
                  })
                  
              }}/>}
            
              {curPage===10 &&<ReactivationAccount />}
              {/* {curPage===11 && <SetNewPassword />} */}
            </div>

          </section>

        </div>)}

        {/* {isSignIn&&<Modal open={isSignIn}
        width={"30vw"}
        centered
        onCancel={()=>{setIsSignIn(false);
          setCurPage(1);
        }}
        footer={false}
        > 
        <div style={{display:"flex",flexDirection:"column"}} >
         {curPage!==1&&curPage!==5&&<div className={classes.backDiv} 
        
             onClick={()=>{
              if(isSignIn&&curPage===5)
              {
                setCurPage(1);
                setIsSignIn(false);
              }
              else if((loginBy?.includes('.com')||loginBy?.includes('.in'))&&curPage===9)
              {
                  setCurPage(6);
              }
              else if (curPage===8)
              {
                setCurPage(5);
              }
              else
              {
                setCurPage(curPage-1);
              }
              }}>
              <div>
                <ArrowLeftOutlined style={{color:"#000"}}/>
              </div>
              <span style={{color:"#000"}}>Back</span>
            </div>}
         {curPage===5&&<Page5 
              setCountryCode={setCountryCode} countryCode={countryCode}
              setCurPage={setCurPage} setLoginBy={setLoginBy} loginBy={loginBy} setMobOTPResponse={setMobOTPResponse}/>}
              {curPage===6&&<Page6 setCurPage={setCurPage} onFinish={signIn} />}
              {curPage===7&&<ForgotPassword/>}
              {curPage===8&&<Page8 setCurPage={setCurPage} onFinish={verifyMobOTP} onResend={onResend} />}
              {curPage===9 
                &&<CheckMail onResend={()=>{
                //backned team asked to hit signin api with phone on clicking the resend button. which they are providing in sign in api response.
                
                    
      Api.post(`/auth-auth/signIn`).params({signInData:{
        phoneNo:resendPhone,
        web:true,
      }}).send(res=>{
                  })
                  
              }}/>}
              </div>

        </Modal>} */}
        </>
    }
    </>
  )
}

export default Login;