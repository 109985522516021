import React, { useEffect, useState } from 'react'
import '../../design/framework.css'
import { GridListView } from './components/gridListView';
import { appraisalRoutePath, ceoFeedbackRoutePath, controlPanel, controlPanelPath, data, hiringRoutePath, ibelongRoutePath } from './components/menuData';
import { AiOutlineRight, AiFillLike } from "react-icons/ai";
import { TbShare3 } from "react-icons/tb";
import Button from '../../components/buttonCustom';
import classes from './homePage.module.css';
import HealthInsuranceCardModal from '../HealthInsurance/HealthInsuranceCardModal/HealthInsuranceCardModal';
import viewClass from '../../services/ViewRole';


import { userData } from '../Login/login';
import { useNavigate } from 'react-router';
import { useWindowDimensions } from '../../components/windowDimention/windowDimension';
import { Typography } from 'antd';
import { SECRET_KEY, decryptData } from '../../services/fileCode';
import credentials2 from '../../credentials2';

const report = require("../../assets/facebookForHome.svg").default;






const HomePage = () => {
  const [card,setCard]=useState([]);
  const Navigate=useNavigate();
  const cardClickHandler = async (link,newLink=null) => {

    if (link !== undefined) {
      if(link==="https://www.brigosha.com/portal/ceo-desk" || link==="https://mypayroll.paysquare.com/" || link==="https://mail.zoho.in/zm/#mail/folder/inbox/"){
        var newTab = window.open(link, "_blank");
        newTab.focus();
      }else{
        if (link === "appraisal") {
          let linkR = await appraisalRoutePath();
          console.log("kkkkkkkkkkkkkk muthu", linkR);
          // window.location.href = linkR;
          Navigate(linkR)
        }
       else if (link === "ibelong") {
          let linkR = await ibelongRoutePath();
          window.location.href = linkR;
        } 
        else if (link === "controlPanel") {
          let linkR = await controlPanelPath();
          window.location.href = linkR;
          // Navigate(linkR);
        }
        else if (link==="hiring-management")
        {
          let linkR=await hiringRoutePath();
          window.location.href = linkR;
        }

        else {
          let linkR=newLink != null ? newLink : link;
          // window.location.href = linkR;
          Navigate(linkR);
        }

      }
    }
  
 /*  if (link !== undefined) {
    var newTab = window.open(newLink!=null?newLink:link, "_blank");
    newTab.focus();
  } */
  
}

const MenuCards = ({ item, selectedView, setSelectedView,setInsuranceCardModal }) => {
  const OnCardClick =() => {
    let newLink = `${item?.link}?param=${item.title}`
    console.log(newLink,"kkkkkkkkkkkkkkkkkkkkk")

   /*  if(item?.key===20){
      let lidarUrl=credentials2?.lidarUrl
      let tt=localStorage.getItem('token');
      if(tt){
      let token=decryptData(tt,SECRET_KEY)
      if(token){
        let url = lidarUrl+"/verify?token="+encodeURIComponent(token)
        window.open(url,'__blank')
      }}
      return;
    } */


    if(item.key===9) //for health insurance
    {
      setInsuranceCardModal(true);
    }
    else{
      if(item.key === 10 || item.key === 11){ // to separate essential and training documents
        cardClickHandler(item?.link,newLink);
     }
     else{
      console.log('kkkkkkkkkkkkkkkk inside suraj',item)
      cardClickHandler(item?.link);
       setSelectedView(selectedView);
     }
    }
    
    }
  return (
    <>
    {
      selectedView === "grid"?
      <>
          <div className={classes.hoverDiv} style={{ display: "flex",flexDirection:"column", borderRadius: "5px", backgroundColor:"#FFFFFF",  cursor: "pointer" }}
                  onClick={OnCardClick}
                >
                <div style={{display:"flex", height:"60%", width:"100%", justifyContent:"space-between", }}>
                    <div style={{height:"100%",display:"flex", alignItems:"center", justifyContent:"center",width:"25%"}}>
                      <img src={item.img} style={{ height: "30px", width: "30px" }} />
                    </div>
                    <div>
                          <img src={item.img} style={{ height: "50px", width: "50px", marginRight:10, opacity: 0.2, }} />
                    </div>
                </div>
                <div style={{marginLeft:15,}}>
                      <div  style={{ width:  "100%"  , fontSize:"14px", fontWeight:600,marginBottom:10 }}>{item.title}</div>
                      <div style={{ width:  "100%" , fontSize:"11px", fontWeight:200 }}>{item.subtitle}</div>
                </div>
                </div>

      </>
      :
      <>
           <div className={classes.hoverDiv} style={{ display: "flex", borderRadius: "5px", justifyContent:  "space-between", backgroundColor:"#FFFFFF",  cursor: "pointer",alignItems:"center",paddingTop:"1.3vh",height:"12vh" }}
                  onClick={OnCardClick}>
                <div style={{display:"flex", alignItems:"center", marginLeft:20,}}>
                   <img src={item.img} style={{ height: "40px", width: "40px" }} />
                   <div style={{marginLeft:15,}}>
                      <div  style={{   fontSize:"14px", fontWeight:600,marginBottom:10 }}>{item.title}</div>
                      <div style={{  fontSize:"11px", fontWeight:200 }}>{item.subtitle}</div>
                   </div>
                </div>
                <div style={{ marginRight: 10, }}>
                        <AiOutlineRight style={{ fontSize: 25 }} />
                </div>
            </div>
      </>
    }
     
    </>
  )
}
useEffect(()=>{
  appraisalRoutePath()
},[])

  useEffect(()=>{
    viewClass.updateView("homepage se");
    let temp=data;
    console.log('check viewclass',viewClass)
    if(!viewClass.hiringUser)
    { 
      let restrict=[18];
     
      temp=temp.filter((val)=>(!restrict.includes(val?.key)))
      
    }
if(viewClass.superAdmin || viewClass.hrAdmin || viewClass.hrAssociate){
  setCard(temp);
}  

else if (viewClass.associate || viewClass.hod || viewClass.manager){
 let restrict=[7,12,15];
  setCard(
    temp.filter((val)=>(!restrict.includes(val?.key)))
  )
}
console.log("viewClass.associate",viewClass)
  },[data,window.location.href])
  const [selectedView, setSelectedView] = useState("grid");
  const [insuranceCardModal,setInsuranceCardModal]=useState(false);
  const {width}= useWindowDimensions()
  return (
    <>
    <div className={classes.container}>
        <div className={classes.cardContainer}>
          <div style={{padding:15,}}>
                <div style={{display:"flex", width:"100%", justifyContent:"space-between", alignItems:"center", padding:10,gap:"1rem"}}>
                    {width>800?<div style={{fontSize:23, fontWeight:600}}>
                          Welcome <span style={{color:"#0086FF",wordBreak:"break-word"}}>{userData?.name}</span>
                    </div>:
                    <div style={{display:"flex",flexDirection:"column",gap:"0.3rem",fontSize:"1.1rem", fontWeight:600}}>
                        <span>Welcome</span>
                        <span style={{color:"#0086FF",wordBreak:"break-word"}}>{userData?.name}</span>
                    </div>
                    }
                    <div>
                      <GridListView defaultview={selectedView} onChange={setSelectedView} />
                    </div>
                </div>
                <div className={classes.navContainer} style={{gridTemplateColumns: selectedView === "grid" ? width>700?"repeat(3,1fr)":"repeat(1,1fr)" : "1fr",width:"100%",marginTop:"1rem",}}>
                  {/* style={{height:"78vh",overflowY:"auto", ,gap:"1rem", display:selectedView==="grid"?"flex":"grid", flexWrap:"wrap",gridTemplateColumns:selectedView==="grid"?"repeat(3,1fr)":"1fr" }} */}
                  {
                    card.map((item) => {
                      return(<MenuCards item={item} selectedView={selectedView} setSelectedView={setSelectedView} setInsuranceCardModal={setInsuranceCardModal}/>
                    )})
                  }
          </div>
          </div>
        </div>
        <div className={classes.shareDiv}>
        <div style={{ padding: "1.5rem",  }}>
          <div style={{ display: "flex", justifyContent: "center", }}>
            <div className='b-g-card' style={{ height: "18vh", padding: "1rem", display: "flex", flexDirection: "column", justifyContent: "center", boxShadow:" rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}>
              <div className='r-c-c' style={{marginTop:"1rem",}}>
                <img src={report} style={{ height: "1.7rem", width: "1.7rem",}} />
                <div className='m-l-5' style={{ fontWeight: 600,fontSize:"14px" }}>brigosha technologies pvt ltd</div>
              </div>
              <div className='r-c-c m-t-10' style={{ justifyContent: "space-between", padding: "15px 15px 10px 15px",}}>
                <Button onclick={() => {
                  window.location.href = 'https://www.facebook.com/brigosha/'
                }} name='Like Page' background={"#0086FF"} fontSize={12} borderColor={"#FFFFF"} borderRadius={7} fontColor={"white"} height={"30px"} width={"90px"} Prefix={() => <AiFillLike style={{ color: "#FFFFFF", height: "15px", width: "15px", marginRight: 5 }} />} />
                <Button
                  onclick={() => {
                 const profileUrl='https://www.linkedin.com/company/brigosha/';
                 let temp="https://www.facebook.com/brigosha/";
                const shareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(profileUrl)}`;
                window.open(temp, '_blank');
                    }}
                name='Share' background={"white"} fontSize={12} borderColor={"#0086FF"} borderRadius={7} fontColor={"#0086FF"} height={"30px"} width={"90px"} Prefix={() => <TbShare3 style={{ color: "#0086FF", height: "15px", width: "15px", marginRight: 5 }} />}/>
              </div>
            </div>
          </div>
        </div>
        </div>
    </div>
    {/*   <div style={{height: "90vh", display: "flex",  width: "100%", flexDirection: "row", justifyContent: "space-between", padding:"25px 30px 30px 30px",}}>
        <div>
            <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
              <div>
                Welcome<span></span>
              </div>
            </div>
              <div>
                <GridListView defaultview={selectedView} onChange={setSelectedView} />
              </div>
        <div style={{width:"100%",marginRight:"3rem", backgroundColor:"#F3F3F3" }}>
          
            <div>
              <GridListView defaultview={selectedView} onChange={setSelectedView} />
            </div>

          <div style={{height:1,border:"1px solid #ECECEC",width:"100%", margin:"20px 0px 20px 0px",}}></div>
          <div className={classes.navContainer} style={{gridTemplateColumns: selectedView === "grid" ? "repeat(3,1fr)" : "1fr",width:"100%",marginTop:"1.5rem",}}>
            style={{height:"78vh",overflowY:"auto", ,gap:"1rem", display:selectedView==="grid"?"flex":"grid", flexWrap:"wrap",gridTemplateColumns:selectedView==="grid"?"repeat(3,1fr)":"1fr" }}
            {
              card.map((item) => {
                {console.log("sssMenuMap",item)}
                return(<MenuCards item={item} selectedView={selectedView} setSelectedView={setSelectedView} setInsuranceCardModal={setInsuranceCardModal}/>
              )})
            }
          </div>
        </div>
        </div>
        
        <div style={{ backgroundColor: "#F3F3F3", padding: "1.5rem",  }}>
          <div style={{ display: "flex", justifyContent: "center", }}>
            <div className='b-g-card' style={{ height: "18vh", padding: "1rem", display: "flex", flexDirection: "column", justifyContent: "center"}}>
              <div className='r-c-c' style={{marginTop:"1rem",}}>
                <img src={report} style={{ height: "1.7rem", width: "1.7rem",}} />
                <div className='m-l-5' style={{ fontWeight: 600,fontSize:"14px" }}>brigosha technologies pvt ltd</div>
              </div>
              <div className='r-c-c m-t-10' style={{ justifyContent: "space-between", padding: "15px 15px 10px 15px",}}>
                <Button onclick={() => {
                  window.location.href = ' https://www.linkedin.com/company/brigosha/'
                }} name='Like Page' background={"#0086FF"} fontSize={12} borderColor={"#FFFFF"} borderRadius={7} fontColor={"white"} height={"30px"} width={"90px"} Prefix={() => <AiFillLike style={{ color: "#FFFFFF", height: "15px", width: "15px", marginRight: 5 }} />} />
                <Button
                  onclick={() => {
                 const profileUrl='https://www.linkedin.com/company/brigosha/';
                const shareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(profileUrl)}`;
                window.open(shareUrl, '_blank');
                    }}
                name='Share' background={"white"} fontSize={12} borderColor={"#0086FF"} borderRadius={7} fontColor={"#0086FF"} height={"30px"} width={"90px"} Prefix={() => <TbShare3 style={{ color: "#0086FF", height: "15px", width: "15px", marginRight: 5 }} />}/>
              </div>
            </div>
          </div>
        </div>


      </div> */}
      <HealthInsuranceCardModal 
      cardClickHandler={cardClickHandler}
      insuranceCardModal={insuranceCardModal} 
      setInsuranceCardModal={setInsuranceCardModal}
      />
    </>
  )
}


export default HomePage