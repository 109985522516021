import axios from "axios";
import axiosRetry from "axios-retry";
import Notification from "./Notification";
// import { withRouter } from "react-router-dom";

import useApiStore from "./store";
import backendUrl from "../credentials";
let _TIMER = null;
const _REQUESTS = {};
// const [loader,setLoader]=useApiStore((state)=>[state.loader,state.setLoader])
// console.log("AAAAAAAA",loader);
//     setLoader(true);
//     console.log("AAAAAAAA",)
class Api {
  constructor() {
    axiosRetry(axios, {
      retries: 3,
      retryDelay: (retryCount) => {
        // console.log(`retry attempt: ${retryCount}`);
        return retryCount * 1000;
      },
      retryCondition: (error) => error.response.status === 504,
    });

    axios.interceptors.response.use((res)=>{
      if(Object.keys(res).length>0){
        return res;
      }
      else{
        Notification.error('Error!','Server error found')
        return;
      }

    });
    
  }
  
  _url = "/";
  _base_url = backendUrl;

  _method = "get";
  _params = {};
  _headers = {
    'ngrok-skip-browser-warning': 'anything',
    Authorization: "",
  };
  changeUrl(url){
    console.log("thisss",url)
  this._base_url=url
  }
  get(url, params = {}) {
    this._url = url;
    this._method = "get";
    this._middleUrl = "";
    this._params = params;
    return this;
  }

 post(url, params = {}) {
    this._url = url;
    this._method = "post";
    this._middleUrl = "";
    this._params = params;
    return this;
  }

  delete(url, params = {}) {
    this._url = url;
    this._method = "delete";
    this._middleUrl = "";
    this._params = params;
    return this;
  }
  patch(url, params = {}) {
    this._url = url;
    this._method = "patch";
    this._middleUrl = "";
    this._params = params;
    return this;
  }

  params(params) {
    this._params = params;
    return this;
  }

  header(value) {
    this._headers["Authorization"] = `Bearer ${value}`;
  }

  send(callback) {
    _REQUESTS[this._url] = {
      _url: this._url,
      _base_url: this._base_url,
      _method: this._method,
      _params: this._params,
      _middleUrl: this._middleUrl,
      _headers: this._headers,
      callback,
    };

    const _self = this;
    clearTimeout(_TIMER);
    _TIMER = setTimeout(() => {
      _self.processApiRequest();
    }, 1000);
  }

  processApiRequest() {
    const _keys = Object.keys(_REQUESTS);
    if (!_keys.length) {
      return;
    }

    const _self = _REQUESTS[_keys[0]];
    delete _REQUESTS[_keys[0]];
    _self.processApiRequest = this.processApiRequest;

    const request = {
      method: _self._method,
      headers: _self._headers,
      timeout: _self._timeout,
      url: `${_self._base_url}${_self._middleUrl}${_self._url}`,
    };

    if (_self._method === "post" || _self._method === "patch") {
      request.data = _self._params;
    } else if (_self._method === "get" || _self._method === "delete") {
      request.params = _self._params;
    }
    console.log("APi req", request);
    useApiStore.getState().setLoader(true)
    // console.log("jhula",useApiStore.getState().loader)
    axios(request)
      .then((response, error) => {
        // console.log("APi res", response);
    useApiStore.getState().setLoader(false)
        if (typeof response !== "undefined" && response.data.show) {
          Notification[response.data.show.type](
            response.data.show.type,
            response.data.show.message
          );
        }
        _self.processApiRequest();
        _self.callback(response.data, error);
      })
      .catch((error, response) => {
    useApiStore.getState().setLoader(false)
        // console.log("Error", error);
        if (
          typeof error.response !== "undefined" &&
          error.response.status === 401
        ) {
          // TODO: Send to login page
          localStorage.removeItem('user');
          localStorage.removeItem('token');
          window.location.href = "/login";
          window.localStorage.clear()
          return;
        }
        if (typeof error.response !== "undefined" && error.response.data.show) {
          Notification.error("Error!", error.response.data.show.message);
        }
        _self.processApiRequest();
        _self.callback(response, error.message);
      });
  }

  upload(params, callback) {
    const headers = this._headers;
    headers["Content-Type"] = "multipart/form-data";
    // eslint-disable-next-line no-undef
    const form = new FormData();
    if (params.files) {
      params.files.forEach((file) => {
        form.append("files", file);
      });
    }
    if (params.file) {
      params.file.forEach((file) => {
        form.append("file", file);
      });
    }
    if(params.document){
      params.document.forEach((file) => {
        form.append("document", file);
      }); 
    }
    for (const param in params) {
      if (param !== "files" && param !=='document') {
        form.append(param, params[param]);
      }
    }

    const config = {
      headers,
      onUploadProgress(progressEvent) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        callback(percentCompleted);
      },
    };
    useApiStore.getState().setLoader(true)
    axios
      .post(this._base_url + "" + this._url, form, config)
      .then((response) => {
        if (typeof response !== "undefined" && response.data.show) {
          Notification[response.data.show.type](
            response.data.show.type,
            response.data.show.message
          );
        }
        if (
          typeof response !== "undefined" &&
          response.data.show &&
          response.data.show.type === "error"
        ) {
          callback(-1);
        }
    useApiStore.getState().setLoader(false)
        callback(100, response);
      })
      .catch((error) => {
    useApiStore.getState().setLoader(false)
        if (typeof error.response !== "undefined" && error.response.data.show) {
          Notification.error("Error!", error.response.data.show.message);
        }
        callback(-1);
      });

    this._headers["Content-Type"] = "application/json";
  }

  uploadAndPatch(params, callback) {
    const headers = this._headers;

    const form = new FormData();
    // for (var param in params) {
    //   form.append(param, params[param]);
    // }
    for (var param in params) {
      if (Array.isArray(params[param])) {
        form.append(param, JSON.stringify(params[param]));
      } else {
        form.append(param, params[param]);
      }
    }
    headers["Content-Type"] = `multipart/form-data;boundary=${form._boundary}`;

    const config = {
      headers,
      onUploadProgress(progressEvent) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        callback(percentCompleted);
      },
    };
    useApiStore.getState().setLoader(true)
    axios
      .patch(this._base_url + "" + this._url, form, config)
      .then((response) => {
        if (typeof response !== "undefined" && response.data.show) {
          Notification[response.data.show.type](
            response.data.show.type,
            response.data.show.message
          );
          if (
            typeof response !== "undefined" &&
            response.data.show &&
            response.data.show.type === "error"
          ) {
            callback(-1);
          }
        }
    useApiStore.getState().setLoader(false)
        callback(100, response);
      })
      .catch((error) => {
    useApiStore.getState().setLoader(false)
        if (typeof error.response !== "undefined" && error.response.data.show) {
          Notification.error("Error!", error.response.data.show.message);
        }
        callback(-1);
      });

    this._headers["Content-Type"] = "application/json";
  }

  uploadAndPost(_url = null, params, callback) {
    const headers =this._headers;
    const form = new FormData();
    // for (var param in params) {
    //   form.append(param, params[param])
    // }
    for (var param in params) {
      if (Array.isArray(params[param])) {
        form.append(param, JSON.stringify(params[param]));
      } else {
        form.append(param, params[param]);
      }
    }
    headers["Content-Type"] = `multipart/form-data;boundary=${form._boundary}`;
    const config = {
      headers,
      onUploadProgress(progressEvent) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        callback(percentCompleted);
      },
    };
    useApiStore.getState().setLoader(true)
    axios
      .post(this._base_url + "" + _url, form, config)
      .then((response) => {
        if (typeof response !== "undefined" && response.data.show) {
          Notification[response.data.show.type](
            response.data.show.type,
            response.data.show.message
          );
          if (
            typeof response !== "undefined" &&
            response.data.show &&
            response.data.show.type === "error"
          ) {
            callback(-1);
          }
        }
        useApiStore.getState().setLoader(false)
        callback(100, response);
      })
      .catch((error) => {
    useApiStore.getState().setLoader(false)
        if (typeof error.response !== "undefined" && error.response.data.show) {
          Notification.error("Error!", error.response.data.show.message);
        }
        callback(-1);
      });

    this._headers["Content-Type"] = "application/json";
  }
}

const ApiClass = new Api();
export default ApiClass;