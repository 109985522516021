import React, { useEffect, useRef, useState } from "react";
import ButtonCompo from "../../../design/ButonCompo";
import SearchCompo from "../../../design/SearchCompo";
import { Select, Table, Form, Typography, Spin, Checkbox, Pagination, Modal, Button, Tooltip } from "antd";
import EditIcon from "../../../assets/userEditIcons.svg";
import LocIcon from "../../../assets/locUser.svg";
import userIcon from "../../../assets/UserIcon.svg";
import deleteIcon from "../../../assets/DeleteIcon.svg";
import calenderIcon from "../../../assets/calendarSetting.svg";
import webAccessIcon from "../../../assets/webAccess.svg";
import UserIcon from "../../../assets/UserIcon.svg";
import { getFilterValues } from "../../ProjectManagement/action/action";
import { useProjecteStore } from "../../ProjectManagement/store/store";
import { RiDownloadLine } from "react-icons/ri";
// import rejectHeaderIcon from "../../../assets/reject_header_icon.svg";
import classes from "./user.module.css";
import EditUser from "./EditUser";
import ModalCompo from "../../../design/ModalCompo";
import Views from "../../../services/ViewRole";
import { useNavigate } from "react-router";
import {
  ModalPrimaryButton,
  ModalSecondaryButton,
} from "../../../components/Button/Button";
import DatePickerCompo from "../../../components/DatePicker/DatePickerCompo.tsx";
import {
  getUserApi,
  getUserListApi,
  giveWebAccessApi,
  locationAccessApi,
  removeWebAccessApi,
  removeTempLocationApi,
  tempLocationAccessApi,
  locationHistory,
} from "./userAction";
import { applyLeaveModal, applyLeaveModalApi } from "../action/action";
import useApiStore from "../../../services/store";
import useAUserStore from "./store";
import moment from "moment";
import { getLogsDetails } from "../action/action";
import { useAttendnaceStore } from "../store/store";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";
import AscendingSortIcon from "../../../assets/AscendingSortIcon.svg";
import DescendingSortIcon from "../../../assets/DescendingSortIcon.svg";
import TableCustom from "../../../components/tableCustom/customTable.js";
import DownloadModal from "./downloadModal.js";

const { Option } = Select;

const Users = () => {
  const { setAttendanceLogsData, setRender, setSelectedUser, setApplyLeaveData, setSelectAssociate } = useAttendnaceStore(
    (state) => state
  );
  const tableHeight = window.innerHeight - 310;
  const [search, setSearch] = useState("");
  const [userSearch, setUserSearch] = useState("");
  const [loader] = useApiStore((state) => [state.loader]);
  const [locationList, userList, setUserList, setLocationList] = useAUserStore(
    (state) => [
      state.locationList,
      state.userList,
      state.setUserList,
      state.setLocationList,
    ]
  );
  const navigate = useNavigate();
  const [editModal, setEditModal] = useState();
  const { setFilterValues, filteredData } = useProjecteStore((state) => state);
  const Tab = ["All Users", "Web Access Users", "Temp Location"];
  const [activeTab, setActiveTab] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [deleteModal, setDeleteModal] = useState();
  const [addUserModal, setAddUserModal] = useState();
  const [tempLocationModal, setTempLocationModal] = useState();
  const [multipleEditModal, setMultipleEditModal] = useState();
  const [OfficeLocation, setOfficeLocation] = useState([]);
  const [checked, setChecked] = useState();
  const [form] = Form.useForm();
  const [calendarParams, setCalendarParams] = useState();
  const [list, setList] = useState();
  const [userData, setUserData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filteredDeptValue, setFilteredDeptValue] = useState(null);
  const [filteredManagerValue, setFilteredManagerValue] = useState(null);
  const { width } = useWindowDimensions();
  const [sortingEmployee, setSortingEmployee] = useState('ASC');
  const [locModal, setLocModal] = useState(false)
  const [userListA, setUserListA] = useState()
  const [refresh, setrefresh] = useState(false)
  const [visible, setVisible] = useState(false)

  const webAccessHeader = (
    <div
      style={{
        display: "flex",
        gap: "0.5rem",
        justifyContent: "flex-start",
        width: "100%",
        flexDirection: "row",
        color: "#0086FF",
        marginLeft: width > 700 && "-5rem",

      }}
    >
      <img src={webAccessIcon}></img>
      <div style={{ fontSize: width < 700 && "18px", }}>Web Access</div>
    </div>
  );
  // const onSelectChange = (newSelectedRowKeys,newSelectedRows) => {
  //   console.log("sssselectedRowKeys changed: ", newSelectedRowKeys,newSelectedRows);
  //   setSelectedRowKeys(newSelectedRowKeys);
  //   setSelectedRows(newSelectedRows); 
  //   setOfficeLocation(selectedRows.map(row => row.user.city !== null ? row.user.city.cityName : undefined))
  // };
  // const onSelectChange = (newSelectedRowKeys, newSelectedRows) => {
  //   console.log("selectedRowKeys changed: ", newSelectedRowKeys, newSelectedRows);
  //   setSelectedRowKeys(newSelectedRowKeys);

  //   // Ensure that newSelectedRows is always an array
  //   const selectedRowsArray = Array.isArray(newSelectedRows) ? newSelectedRows : [];

  //   setSelectedRows(newSelectedRows);

  //   // Now you can use .map() safely
  //   setOfficeLocation(selectedRowsArray.map(row => row.user.city !== null ? row.user.city.cityName : undefined));
  // };
  const onSelectChange = (newSelectedRowKeys, newSelectedRows) => {

    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(newSelectedRows);

    // Now you can use .map() safely
    const officeLocations = newSelectedRows.map(row =>
      row.user.city !== null ? row.user.city.cityName : undefined
    );
    // setOfficeLocation(officeLocations);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  useEffect(() => {
    setList(
      userList?.filter((val) => val?.name?.toLowerCase()?.includes(userSearch))
    );
  }, [userList, userSearch]);

  const [tableParams, setTableParams] = useState({
    pagination: {
      showSizeChanger: true,
      current: 1,
      pageSize: 10,
      size: "small",
      // total:userData?.length,
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
    },
  });
  const getRandomuserParams = (params) => ({
    limit: params.pagination?.pageSize,
    page: params.pagination?.current,
  });
  const handleTableChange = (page, pageSize) => {
    setTableParams((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        current: page,
        pageSize: pageSize,
      },
    }))
    setSelectedRowKeys([]);
    setSelectedRows([]);
  };
  const SearchHandler = (val) => {
    let filter =
      activeTab === 0
        ? "All"
        : activeTab === 1
          ? "WebAccess"
          : activeTab === 2
            ? "TemporaryLocation"
            : "";
    let param = {
      search: val,
      filter: filter,
      departmentId: filteredDeptValue,
      managerId: filteredManagerValue,
      employeeIdSort: sortingEmployee,
      ...getRandomuserParams(tableParams),
    };
    getUserApi(param, (res) => {
      console.log("data", res);
      setUserData(res?.items);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          current: 1,
          total: res?.meta?.totalItems,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
        },
      });
    });
  }
  const scrollRef = useRef(null);

  useEffect(() => {
    const savedScrollPosition = localStorage.getItem('TSP1900');
    if (savedScrollPosition && scrollRef.current) {
      scrollRef.current.scrollTo(0, parseInt(savedScrollPosition));
    }
  }, []);

  const handleScroll = () => {
    if (scrollRef.current) {
      const scrollTop = scrollRef.current.scrollTop;
      localStorage.setItem('TSP1900', scrollTop.toString());
    }
  };

  const getSelectApi = () => {
    let param = {};
    let activeLocations = [];
    getUserListApi(param, (res) => {
      console.log("user list", res);
      setUserList(res?.userView);
      activeLocations = res?.city.filter(location => location.status === 'Active');
      setLocationList(activeLocations);
    });
  }
  useEffect(() => {
    getSelectApi();
  }, []);

  useEffect(() => {

    getFilterValues(setFilterValues, "departmentAttendance");

    let filter =
      activeTab === 0
        ? "All"
        : activeTab === 1
          ? "WebAccess"
          : activeTab === 2
            ? "TemporaryLocation"
            : "";
    let param = {
      search: search,
      filter: filter,
      departmentId: filteredDeptValue,
      managerId: filteredManagerValue,
      employeeIdSort: sortingEmployee,
      ...getRandomuserParams(tableParams),
    };
    getUserApi(param, (res) => {
      console.log("data", res);
      setUserData(res?.items);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: res?.meta?.totalItems,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
        },
      });
    });
  }, [tableParams.pagination.current, tableParams.pagination.pageSize, search, activeTab, filteredDeptValue, filteredManagerValue, sortingEmployee]);

  const refreshUserApi = () => {
    let filter =
      activeTab === 0
        ? "All"
        : activeTab === 1
          ? "WebAccess"
          : activeTab === 2
            ? "TemporaryLocation"
            : "";
    let param = {
      search: search,
      filter: filter,
      departmentId: filteredDeptValue,
      managerId: filteredManagerValue,
      employeeIdSort: sortingEmployee,
      ...getRandomuserParams(tableParams),
    };
    getUserApi(param, (res) => {
      console.log("data", res);
      setUserData(res?.items);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: res?.meta?.totalItems,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
        },
      });
    });
  };
  const removeWebAccessHandler = (uid, id) => {
    if (activeTab === 2) {
      removeTempLocationApi(id, (res) => {
        if (res) {
          setDeleteModal();
          refreshUserApi();
          getSelectApi();
        }
      });
    }
    else {
      let param = { ids: [uid], access: false };
      removeWebAccessApi(param, (res) => {
        if (res) {
          setDeleteModal();
          refreshUserApi();
          getSelectApi();
        }
      });
    }
  };
  const WebAccessHandler = (val) => {
    console.log(val);
    let param = { ids: val?.access, access: true };
    giveWebAccessApi(param, (res) => {
      if (res) {
        setAddUserModal();
        refreshUserApi();
        getSelectApi();
      }
    });
  };

  const tableColumn = {
    0: {
      cols: "Office",
      width: "30%",
      dataKey: "office",
      onclick: () => { }
    },
    1: {
      cols: "City",
      width: "15%",
      innerWidth: "6rem",
      dataKey: "cityName",
      onclick: () => { }
    },
    2: {
      cols: "Location type",
      width: "15%",
      dataKey: "locType",
      onclick: () => { }
    },
    3: {
      cols: "Date(s)",
      width: "15%",
      dataKey: "dates",
      onclick: () => { }
    },
    4: {
      cols: "Modify timestamp",
      width: "15%",
      dataKey: "modifiedDates",
      onclick: () => { }
    },
    5: {
      cols: "Modified By",
      width: "15%",
      dataKey: "modifierName",
      onclick: () => { }
    },
  }


  useEffect(() => {
    Views.updateView();
  }, [])


  const logMoveHandler = (id, name) => {
    setSelectedUser({ userId: id, name: name })
    setSelectAssociate("")
    getLogsDetails(
      (res) => {
        setAttendanceLogsData(res);
      },
      { userId: id, month: moment().format("M"), year: moment().format("YYYY") }
    );
    applyLeaveModalApi(
      {
        userId: id,
      },
      (res) => {
        setApplyLeaveData(res);
      }
    );
    setRender(2);
    navigate("/attendancePortal/attendance-logs");

  };

  const refreshCalendar = () => {
    logMoveHandler(calendarParams?.id, calendarParams?.name)
  }


  const userColumn = () => {
    let column = [
      {
        title: "Associate Name",
        dataIndex: "Associate Name",
        key: "Associate Name",
        width: "180px",
        render: (text, record) => {
          return (
            <div style={{ marginLeft: "-0.3rem" }}>
              <div style={{ display: "flex", flexDirection: "row", gap: "0.4rem" }}>
                <img src={record?.image ? record?.image : userIcon} />
                <div>
                  <span>{record?.name ? record?.name : "NA"}</span>
                  <div>{record?.uid ? record?.uid : "NA"}</div>
                </div>
              </div>
            </div>
          );
        },
        sorter: {
          // console.log("'aaaaaa",a,b);
          compare: (a, b) => a.name.localeCompare(b.name),
        },
      },
      {
        title: "Contact Details",
        dataIndex: "Contact Details",
        key: "Contact Details",
        render: (text, record) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                // gap: "8px",
                flexDirection: "column",
              }}
            >
              <div>
                <Typography.Text style={{ width: "10rem", }} ellipsis={{ tooltip: true }}>
                  <span>{record?.email ? record?.email : "NA"}</span></Typography.Text>
              </div>
              <span>{record?.phone ? record?.phone : "NA"}</span>
            </div>
          );
        },
      },
      {
        title: "Manager",
        key: "Manager",
        dataIndex: "Manager",
        render: (text, record) => {
          return <div>
            <Typography.Text style={{ width: "10rem", }} ellipsis={{ tooltip: true }}>
              <span>{record?.managerName ? record?.managerName : "NA"}</span></Typography.Text>
          </div>;
        },
      },
      {
        title: "WorkPlace",
        key: "WorkPlace",
        dataIndex: "WorkPlace",
        render: (text, record) => {
          // console.log("sssRecord",record,Tab)
          return <div>
            {activeTab === 2 ?
              <div>
                <Typography.Text style={{ width: "10rem", }} ellipsis={{ tooltip: true }}>
                  <span>{record?.user?.tempCity?.cityName ? record?.user?.tempCity?.cityName : "NA"}</span>
                </Typography.Text>
              </div>
              :
              <div>
                <Typography.Text style={{ width: "10rem", }} ellipsis={{ tooltip: true }}>
                  <span>{record?.user?.city?.cityName ? record?.user?.city?.cityName : "NA"}</span>
                </Typography.Text>
              </div>
            }
          </div>
        },
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        render: (text, record) => {
          return <span>{record?.user?.active ? record?.user?.active : "NA"}</span>;
        },
      },
    ];
    // return column;
    if (activeTab === 0 || activeTab === 1) {
      return [
        ...column,
        {
          title: "Actions",
          dataIndex: "Actions",
          key: "Actions",
          width: "180px",
          render: (text, record) => {
            return (
              <div>
                {activeTab == 0 ? (
                  <div
                    className={`${selectedRowKeys?.length !== 0 ? classes.disabled : ""
                      }`}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setEditModal(record);
                    }}
                  >
                    <img src={EditIcon} style={{ height: "2.1rem" }}></img>
                  </div>
                ) : activeTab === 1 ? (
                  <div
                    style={{
                      display: "flex",
                      gap: "12px",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      onClick={() => {
                        setDeleteModal({
                          id: record?.id,
                          uid: record?.uid,
                          name: record?.name,
                        });
                      }}
                      src={deleteIcon}
                    ></img>
                    <img
                      onClick={() => {
                        setCalendarParams({ id: record?.id, name: record?.name })
                        logMoveHandler(record?.id, record?.name);
                      }}
                      src={calenderIcon}
                    ></img>
                  </div>
                ) : (
                  ""
                )}
              </div>
            );
          },
        },
      ];
    } else if (activeTab === 2) {
      return column;
    }
  };

  const multipleHandler = (val) => {
    console.log("sssValue", val)
    let offIds = [];
    let param = {
      userViewIds: val?.user,
      cityId: val?.city,
      date: moment(val?.date).format("YYYY-MM-DD"),
    };
    if (checked) {
      OfficeLocation?.forEach((val) => {
        console.log(val);
        offIds.push(val?.id);
      });
    }
    if (val?.office !== undefined && offIds?.length === 0) {

      param = { ...param, placeIds: [val?.office] };
    } else if (offIds?.length !== 0) {
      param = { ...param, placeIds: offIds };
    }

    locationAccessApi({ permPayload: param }, (res) => {
      if (res) {
        setOfficeLocation();
        setChecked();
        setMultipleEditModal();
        setSelectedRowKeys([]);
        form.resetFields();
        // getUserApi(param, (res) => {
        //   console.log("data", res);
        //   setUserData(res?.items);
        //   setTableParams({
        //     ...tableParams,
        //     pagination: {
        //       ...tableParams.pagination,
        //       total: res?.meta?.totalItems,
        //       showTotal:(total, range) => `${range[0]}-${range[1]} of ${total} items`,
        //     },
        //   });
        // });
        refreshUserApi();
      }
    });
  };

  const tempLocationHandler = (val) => {

    let offIds = [];
    let param = {
      duration: val?.duration,
      userViewIds: val?.tempUser,
      cityId: val?.tempCity,
      startDate: moment(val?.startDate).format("YYYY-MM-DD"),
    };
    if (checked) {
      OfficeLocation?.forEach((val) => {
        offIds.push(val?.id);
      });
    }
    if (val?.tempOffice !== undefined && offIds?.length === 0) {
      param = { ...param, placeIds: val?.tempOffice };
    } else if (offIds?.length !== 0) {
      param = { ...param, placeIds: offIds };
    }

    locationAccessApi({ tempPayload: param }, (res) => {
      if (res) {
        setTempLocationModal();
        setOfficeLocation();
        setChecked();
        setSelectedRowKeys([]);
        setOfficeLocation([])
        // getUserApi(param, (res) => {
        //   console.log("data", res);
        //   setUserData(res?.items);
        //   setTableParams({
        //     ...tableParams,
        //     pagination: {
        //       ...tableParams.pagination,
        //       total: res?.meta?.totalItems,
        //       showTotal:(total, range) => `${range[0]}-${range[1]} of ${total} items`,
        //     },
        //   });
        // });
        refreshUserApi();
      }
    });
  };
  const [historyDetails, setdetails] = useState()
  const onLocClick = (ids, download = false, url = undefined,locHistdownload=false,currentLocdownload=false) => {
    setrefresh(true)
    let filter =
      activeTab === 0
        ? "All"
        : activeTab === 1
          ? "WebAccess"
          : activeTab === 2
            ? "TemporaryLocation"
            : "";



    if (url) {
      locationHistory({
        locHistdownload:locHistdownload,
        currentLocdownload:currentLocdownload,
        download: download, search: search,
        filter: filter,
        departmentId: filteredDeptValue,
        managerId: filteredManagerValue,
        employeeIdSort: sortingEmployee,
        ...getRandomuserParams(tableParams)
      }, (res) => {
        setVisible(false)
        if(res?.file){
          window.open(res?.file, "_blank")?.focus();
        }else{
          if (!download) {
            let dataArr = []
            if (res !== undefined) {
              let temp = res;
              temp?.forEach(element => {
                let obj = {
                  ...element,
                  "office": (
                    <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>
                      {/* <Tooltip title={element?.reason}>
                              <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width:"13vw" }}>{element.reason}</div>
                          </Tooltip> */}
                      <div style={{ fontSize: 14, color: "#161616", fontWeight: 600 }}>
                        {element?.officeName}
                      </div>
                      <div style={{ fontSize: 12, color: "#161616", fontWeight: 400 }}>
                        Latitude : {element?.latitude},  Longitude: {element?.longitude}
                      </div>
                      <div style={{ fontSize: 12, color: "#161616", fontWeight: 400 }}>
                        Radius: {element?.radius} meters
                      </div>
                    </div>
                  ),
                  "dates": (
                    <>
                      <div>{moment(element?.startDate).format('DD-MM-YYYY')} {element?.endDate !== null ? "-" : ""} {(element?.endDate && element?.endDate !== null) ? moment(element?.endDate).format('DD-MM-YYYY') : ""} </div>
                    </>
                  ),
                  "modifiedDates": (
                    <>
                      <div>{element?.updatedAt ? element?.updatedAt : "NA"}</div>
                    </>
                  )
                }
                dataArr.push(obj)
              });
              setrefresh(false)
              setdetails([...dataArr]);
            }
          } else if (download) {
  
          }
        }
       
      }, url)
    } else {
      locationHistory({ userIds: ids, download: download, locHistdownload:locHistdownload,
        currentLocdownload:currentLocdownload, }, (res) => {
        if (!download) {
          let dataArr = []
          if (res !== undefined) {
            let temp = res;
            temp?.forEach(element => {
              let obj = {
                ...element,
                "office": (
                  <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>
                    {/* <Tooltip title={element?.reason}>
                            <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width:"13vw" }}>{element.reason}</div>
                        </Tooltip> */}
                    <div style={{ fontSize: 14, color: "#161616", fontWeight: 600 }}>
                      {element?.officeName}
                    </div>
                    <div style={{ fontSize: 12, color: "#161616", fontWeight: 400 }}>
                      Latitude : {element?.latitude},  Longitude: {element?.longitude}
                    </div>
                    <div style={{ fontSize: 12, color: "#161616", fontWeight: 400 }}>
                      Radius: {element?.radius} meters
                    </div>
                  </div>
                ),
                "dates": (
                  <>
                    <div>{moment(element?.startDate).format('DD-MM-YYYY')} - {element?.endDate !== "NA" ? moment(element?.endDate).format('DD-MM-YYYY') : "NA"} </div>
                  </>
                ),
                "modifiedDates": (
                  <>
                    <div>{element?.updatedAt ? element?.updatedAt : "NA"}</div>
                  </>
                )
              }
              dataArr.push(obj)
            });
            console.log(dataArr, "dataArrdataArr");
            setrefresh(false)
            setdetails([...dataArr]);
          }
        } else if (download) {
          window.open(res, "_blank")?.focus();
        }
      }, url)
    }
  }

  return (
    <div className="ContainerD" style={{ paddingRight: width > 700 && "0.5rem", margin: 0, width: width < 700 && "100vw", backgroundColor: "#FFFFFF" }}>
      <div className="mainDiv" style={{ padding: 0, marginTop: width > 700 && 10, marginLeft: 0, marginRight: 0 }}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "-0.2rem", padding: width > 700 ? 0 : "0.2rem 0.8rem 0 0.8rem", marginLeft: width > 700 && 10, marginRight: width > 700 && 10 }}>
          <h3 style={{ fontSize: width > 700 ? "1.5vw" : "27px", color: "#162432" }}>
            Users
          </h3>
          {width > 700 && <div
            style={{ display: "flex", justifyContent: "center", gap: "12px" }}
          >
            {/* <ButtonCompo title={"Web Access"} style={{backgroundColor:'#FDA428'}} /> */}
            {activeTab === 0 ? (
              <>
                <div onClick={() => {
                  setVisible(true)
                  
                }} style={{ display: "flex", color: "#FFB783", alignItems: "center", justifyContent: "center", gap: 5 }}>
                  <RiDownloadLine />
                  <div style={{ cursor: "pointer", }}>Download history</div>
                </div>
                <ButtonCompo
                  title={"Temporary Location"}
                  onClick={() => {
                    setTempLocationModal(true);
                  }}
                  disabled={selectedRowKeys?.length === 0}
                  style={{ color: "#0086FF", borderRadius: 5, border: "1px solid #0086FF", height: "2.1rem", width: "" }}
                />
                <ButtonCompo
                  title={"Premanent Location Access"}
                  onClick={() => {
                    setMultipleEditModal(true);
                  }}
                  disabled={selectedRowKeys?.length === 0}
                  style={{ backgroundColor: "#0086FF", borderRadius: 5, color: "#fff", height: "2rem", width: "" }}
                />
              </>
            ) : activeTab === 1 ? (
              <ButtonCompo
                disabled={Views?.hrAssociate && !Views?.hrAdmin ? true : false}
                title={"+Add User"}
                onClick={() => {
                  setAddUserModal(true);
                }}
                style={{ backgroundColor: "#0086FF", borderRadius: 5, color: "#fff", height: "2rem", width: "" }}
              />
            ) : (
              ""
            )}
          </div>}
          {width < 700 && <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem", margin: "0.2rem 0 -0.2rem 0" }}>
            <Select placeholder="Manager" allowClear size="middle"
              getPopupContainer={(trigger) => trigger.parentElement}
              // className="m-t-5"
              showSearch
              value={filteredManagerValue}
              style={{
                // width: "9vw",
                height: "2.5rem", display: "flex",
                fontSize: "16px", alignItems: "center"
              }}
              onChange={(e) => {
                setFilteredManagerValue(e);
                setTableParams({
                  ...tableParams,
                  pagination: {
                    ...tableParams.pagination,
                    current: 1,
                    // pageSize: 10,
                  },
                });
              }}
              disabled={false}
              optionFilterProp="children"
            >
              {filteredData?.department?.allManager?.map((option, index) => {
                return (<>{option.name !== undefined &&
                  <Option key={index} value={option.id}>
                    {option.name}
                  </Option>}</>)
              })}
            </Select>
            <Select placeholder="Department" allowClear size="middle"
              getPopupContainer={(trigger) => trigger.parentElement}
              // className="m-t-5"
              showSearch
              value={filteredDeptValue}
              style={{
                // width: "9vw",
                height: "2.5rem", display: "flex",
                fontSize: "16px", alignItems: "center"
              }}
              onChange={(e) => {
                setFilteredDeptValue(e);
                setTableParams({
                  ...tableParams,
                  pagination: {
                    ...tableParams.pagination,
                    current: 1,
                    // pageSize: 10,
                  },
                });
              }}
              disabled={false}
              optionFilterProp="children"
            >
              {filteredData?.department?.result?.map((option, index) => {
                return (<>{option.department !== undefined &&
                  <Option key={index} value={option.departmentId}>
                    {option.department}
                  </Option>}</>)
              })}
            </Select>
          </div>}
        </div>
        {width < 700 &&
          <div style={{ margin: 0, padding: width > 700 ? 0 : "0 0.8rem 0.2rem 0.8rem", }}>
            <SearchCompo onSearch={(res) => { SearchHandler(res) }} setSearch={setSearch} style={{ height: width > 700 ? "1.9rem" : "2.5rem", width: "99%", fontSize: "14px" }} />
          </div>}
        {width < 700 && <div
          style={{ display: "flex", justifyContent: "flex-end", gap: "12px", padding: width > 700 ? 0 : "0rem 0.9rem", }}
        >
          {/* <ButtonCompo title={"Web Access"} style={{backgroundColor:'#FDA428'}} /> */}
          {activeTab === 0 ? (
            <>
              <ButtonCompo
                title={<Typography.Text style={{ width: "8.5rem", color: "#0086FF" }} ellipsis={{ tooltip: true }}>
                  <span>Temporary Location</span></Typography.Text>}
                onClick={() => {
                  setTempLocationModal(true);
                }}
                disabled={selectedRowKeys?.length === 0}
                style={{ color: "#0086FF", borderRadius: 5, border: "1px solid #0086FF", height: "2.1rem", width: "" }}
              />
              <ButtonCompo
                title={<Typography.Text style={{ width: "5.5rem", color: "#FFFFFF" }} ellipsis={{ tooltip: true }}>
                  <span>Multiple Edit</span></Typography.Text>}
                onClick={() => {
                  setMultipleEditModal(true);
                }}
                disabled={selectedRowKeys?.length === 0}
                style={{ backgroundColor: "#0086FF", borderRadius: 5, color: "#fff", height: "2rem", width: "" }}
              />
            </>
          ) : activeTab === 1 ? (
            <ButtonCompo
              title={"+Add User"}
              onClick={() => {
                setAddUserModal(true);
              }}
              style={{ backgroundColor: "#0086FF", borderRadius: 5, color: "#fff", height: "2.1rem", }}
            />
          ) : (
            ""
          )}
        </div>}
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: width > 700 ? 0 : "0rem 0.5rem", marginLeft: width > 700 && "0.6rem", marginRight: width > 700 && "0.6rem", }}>
          <div className={classes.tabContainer}>
            {Tab?.map((val, id) => {
              return (
                <div
                  key={id} style={{ fontSize: "1.2vw" }}
                  className={activeTab === id ? classes.activeTab : ""}
                  onClick={() => {
                    setActiveTab(id);
                    setSelectedRowKeys([]);
                    setTableParams({
                      ...tableParams,
                      pagination: {
                        ...tableParams.pagination,
                        current: 1,
                        // pageSize: 10,
                      },
                    });
                  }}
                >
                  {val}
                </div>
              );
            })}
          </div>

          {width > 700 &&
            <div style={{ display: "flex", flexDirection: "row", gap: "0.6rem", alignItems: "center" }}>
              <Select placeholder="Manager" allowClear
                getPopupContainer={(trigger) => trigger.parentElement}
                // className="m-t-5"
                showSearch
                value={filteredManagerValue}
                style={{
                  width: "9vw",
                  height: "2rem", fontSize: "1vw"
                }}
                onChange={(e) => {
                  setFilteredManagerValue(e);
                  setTableParams({
                    ...tableParams,
                    pagination: {
                      ...tableParams.pagination,
                      current: 1,
                      // pageSize: 10,
                    },
                  });
                }}
                disabled={false}
                optionFilterProp="children"
              >
                {filteredData?.department?.allManager?.map((option, index) => {
                  return (<>{option.name !== undefined &&
                    <Option key={index} value={option.id}>
                      {option.name}
                    </Option>}</>)
                })}
              </Select>
              <Select placeholder="Department" allowClear
                getPopupContainer={(trigger) => trigger.parentElement}
                // className="m-t-5"
                showSearch
                value={filteredDeptValue}
                style={{
                  width: "9vw",
                  height: "2rem", fontSize: "1vw"
                }}
                onChange={(e) => {
                  setFilteredDeptValue(e);
                  setTableParams({
                    ...tableParams,
                    pagination: {
                      ...tableParams.pagination,
                      current: 1,
                      // pageSize: 10,
                    },
                  });

                }}
                disabled={false}
                optionFilterProp="children"
              >
                {filteredData?.department?.result?.map((option, index) => {
                  return (<>{option.department !== undefined &&
                    <Option key={index} value={option.departmentId}>
                      {option.department}
                    </Option>}</>)
                })}
              </Select>
              <SearchCompo onSearch={(res) => {
                SearchHandler(res); setTableParams({
                  ...tableParams,
                  pagination: {
                    ...tableParams.pagination,
                    current: 1,
                    // pageSize: 10,
                  },
                });
              }}
                setSearch={setSearch} style={{ height: "2rem", width: "20rem", fontSize: "14px", border: "1px solid #d9d9d9" }} />
            </div>}
        </div>
        <div style={{ width: width > 700 ? "100%" : "99vw", }}>
          {/* <Table
          rowClassName={(record, index) => index % 2 === 0 ? classes.tableRow :  classes.tableRowWhite}
            columns={userColumn()}
            dataSource={userData}
            loading={loader}
            rowSelection={activeTab === 0 ? rowSelection : null}
            onChange={handleTableChange}
            pagination={tableParams.pagination}
            // rowClassName={classes.tableRow}
            scroll={{
              y: "54vh",
            }}
          /> */}
          {width > 700 ?
            <div style={{ backgroundColor: "#F9F9F9", width: "100%", marginTop: "0.5rem" }}>
            <div className={classes.ListContainer}>
              <div className={classes.ListHeader} style={{fontSize: "1.1vw", color: "#16243280", fontWeight: 700 }}>
              <div className={classes.FixedColumn}>
                {activeTab === 1 || activeTab === 2 ? "" :
                  <div style={{ minWidth: "2.5rem", paddingLeft: '0.5rem' }}>
                    {/* <div style={{width:'4%',border:"2px solid red"}} > */}
                    {/* <Checkbox 
                          indeterminate={selectedRowKeys.length>0 && selectedRowKeys.length<userData.length} 
                          checked={userData?.length!==0?selectedRowKeys.length===userData.length:false}   
                          onClick={(e)=>{onSelectChange('all',e.target.checked)}} />  */}
                    <Checkbox
                      indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < userData.length}
                      checked={selectedRowKeys.length === userData.length && userData.length > 0 ? true : false}
                      onClick={(e) => {
                        const selectAll = e.target.checked;
                        const newSelectedRowKeys = selectAll ? userData.map(row => row.id) : [];
                        onSelectChange(newSelectedRowKeys, userData);
                      }}
                    />
                  </div>
                }
                <span style={{ minWidth: "9rem"}}>
                  Employee Id {sortingEmployee === 'ASC' ? <img src={AscendingSortIcon} alt='' onClick={() => {
                    setSortingEmployee('DESC');
                    setTableParams({
                      ...tableParams,
                      pagination: {
                        ...tableParams.pagination,
                        current: 1,

                      },
                    });
                  }}
                    style={{ cursor: "pointer", marginLeft: "0.2rem" }} />
                    : <img src={DescendingSortIcon} alt='' onClick={() => {
                      setSortingEmployee('ASC');
                      setTableParams({
                        ...tableParams,
                        pagination: {
                          ...tableParams.pagination,
                          current: 1,

                        },
                      });
                    }} style={{ cursor: "pointer", marginLeft: "0.2rem" }} />}
                </span>
                <span style={{ minWidth: "16rem",paddingLeft:"0.3rem"}}>Associate Details</span>
              </div>
                {/* <span style={{minWidth:"13vw"}}>Contact Details</span> */}
                <span style={{ minWidth: "9rem" }}>Department</span>
                <span style={{ minWidth: "14rem" }}>Manager</span>
                <span style={{ minWidth: "10rem" }}>WorkPlace</span>
                <span style={{ minWidth: "12rem" }}>Current Location</span>
                <span style={{ minWidth: "12rem" }}>Permanent Location</span>
                <span style={{ minWidth: "9rem" }}>Status</span>
                <span style={{ minWidth: "6rem" }}>Action</span>
              </div>
              {/* <div ref={scrollRef} onScroll={handleScroll} className={classes.ListBodyContainer} style={{ height: "62vh", overflowY: 'scroll', overflowX: "hidden", width: "100%" }}> */}
                {loader ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh', width: "100%" }}><Spin /></div> :
                  // <div style={{ width: "100%" }}>
                    userData?.length === 0 ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}><div>No data found</div></div> :
                    userData?.map((record, id) => {
                        return (
                          <div className={classes.rowContainer} style={{ color: "#162432",fontSize: "1vw", fontWeight: 400, }}>
                            <div className={classes.FixedColumns}>
                            {activeTab === 1 || activeTab === 2 ? "" :
                              <div style={{ minWidth: "2.5rem", paddingLeft: '0.5rem'}}>
                                <Checkbox
                                  checked={selectedRowKeys.includes(record?.id)}
                                  onClick={(e) => {
                                    const newSelectedRowKeys = e.target.checked
                                      ? [...selectedRowKeys, record?.id]
                                      : selectedRowKeys.filter(key => key !== record?.id);

                                    const newSelectedRows = userData.filter(row => newSelectedRowKeys.includes(row.id));

                                    onSelectChange(newSelectedRowKeys, newSelectedRows);
                                  }}
                                />
                              </div>
                            }
                            <div style={{ minWidth: "9rem"}}>
                              <Typography.Text style={{ width: "6rem", fontSize: "1vw", color: "#162432", fontWeight: 400, }} ellipsis={{ tooltip: true }}>
                                <span>{record?.uid ? record?.uid : "NA"}</span></Typography.Text>
                            </div>
                            <div style={{ minWidth: "16rem"}}>
                                <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
                                <img style={{ height: "2.3rem", width: "2.3rem",borderRadius: "50%", border: "1px dotted #e3e3e3" }} src={UserIcon} />
                                <div style={{ width: "12rem",marginLeft:"0.4rem" }}>
                                  <Typography.Text style={{ width: "12rem", fontSize: "1.06vw", color: "#162432", fontWeight: 400, }} ellipsis={{ tooltip: true }}>
                                    {record?.name}</Typography.Text>
                                  {/* <div style={{display:'flex',justifyContent:'center',gap:'1px',flexDirection:'column',}}> */}
                                  <Typography.Text style={{ width: "12rem", fontSize: "1.07vw", color: "#162432", fontWeight: 400, }} ellipsis={{ tooltip: true }}>
                                    {record?.email}</Typography.Text>
                                  <Typography.Text style={{ width: "12rem", fontSize: "1vw", color: "#162432", fontWeight: 400, }} ellipsis={{ tooltip: true }}>
                                    {record?.phone}</Typography.Text>
                                  {/* <span style={{fontSize:"1vw"}}></span> */}
                                  {/* </div> */}
                                  {/* <span style={{fontSize:"1vw"}}>{record?.uid}</span> */}
                                  {/* </div> */}
                                </div>
                                </div>
                            </div>
                            {/*  <div style={{minWidth:"13vw"}}>
                          <div style={{display:'flex',justifyContent:'center',gap:'1px',flexDirection:'column',}}>
                              <Typography.Text style={{width:"12vw",fontSize:"1.07vw",color: "#162432",fontWeight: 400,}} ellipsis={{tooltip:true}}>
                                {record?.email}</Typography.Text>
                                <span style={{fontSize:"1vw"}}>{record?.phone}</span>
                            </div>
                          </div> */}
                          </div>
                          <div style={{ minWidth: "9rem"}}>
                              <Typography.Text style={{ width: "8rem", fontSize: "1vw", color: "#162432", fontWeight: 400, }} ellipsis={{ tooltip: true }}>
                                <span>{record?.department ? record?.department?.name : "NA"}</span></Typography.Text>
                            </div>
                            <div style={{ minWidth: "14rem",width: "12rem" }}>
                              <Typography.Text stylonLocClicke={{ fontSize: "1vw", color: "#162432", fontWeight: 400, }} ellipsis={{ tooltip: true }}>
                                <span>{record?.managerName ? record?.managerName : "NA"}</span></Typography.Text>
                            </div>
                            <div style={{ minWidth: "10rem" }}>
                              {/* <div> */}
                              {activeTab === 2 ?
                                <div>
                                  <Typography.Text style={{ width: "5rem", fontSize: "1vw", color: "#162432", fontWeight: 400, }} ellipsis={{ tooltip: true }}>
                                    <span>{record?.user?.tempCity?.cityName ? record?.user?.tempCity?.cityName : "NA"}</span>
                                  </Typography.Text>
                                </div>
                                :
                                <div>
                                  <Typography.Text style={{ width: "8rem", fontSize: "1vw", color: "#162432", fontWeight: 400, }} ellipsis={{ tooltip: true }}>
                                    <span>{record?.user?.city?.cityName ? record?.user?.city?.cityName : "NA"}</span>
                                  </Typography.Text>
                                </div>
                              }
                              {/* </div> */}
                            </div>
                            <div style={{ minWidth: "12rem" }}>
                              <Typography.Text  style={{ width: "8rem", fontSize: "1vw", color: "#162432", fontWeight: 400, }} ellipsis={{ tooltip: true }}>
                                <span>{record?.user?.tempOffices[0]?.name?record?.user?.tempOffices?.map(obj => obj.name).join(", "):"NA"}</span></Typography.Text>
                            </div>
                            <div style={{ minWidth: "12rem" }}>
                              <Typography.Text style={{ width: "8rem", fontSize: "1vw", color: "#162432", fontWeight: 400, }} ellipsis={{ tooltip: true }}>
                                <span>{record?.user?.currentOffices[0]?.name?record?.user?.currentOffices?.map(obj => obj.name).join(", "):"NA"}</span></Typography.Text>
                            </div>
                            <div style={{ minWidth: "9rem" }}>
                              <span style={{ fontSize: "1vw", color: "#162432", fontWeight: 400, }}>{record?.user?.active ? record?.user?.active : "NA"}</span>
                            </div>

                            <div style={{ minWidth: "6rem" }}>
                              <div>
                                {activeTab == 0 ? (
                                  <div style={{ justifyContent: "center", display: "flex", gap: 10 }}>
                                    <div
                                      className={`${selectedRowKeys?.length !== 0 ? classes.disabled : ""
                                        }`}
                                      style={{ cursor: "pointer", }}
                                      onClick={() => {
                                        setEditModal(record);
                                      }}
                                    >
                                      <img src={EditIcon} style={{ height: "4vh" }}></img>
                                    </div>
                                    <div style={{ cursor: "pointer" }} onClick={() => {
                                      let tempid = []
                                      tempid.push(record?.id)
                                      setUserListA(tempid)
                                      onLocClick(tempid, false)
                                      setLocModal(true)
                                    }}>
                                      <img src={LocIcon} style={{ height: "3.5vh" }}></img>
                                    </div>
                                  </div>
                                ) : activeTab === 1 ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      // gap: "1px",
                                      alignItems: "center",
                                    }}
                                  >
                                    <img
                                      onClick={() => {
                                        if (Views?.hrAssociate && !Views?.hrAdmin) {

                                        } else {
                                          setDeleteModal({
                                            id: record?.id,
                                            uid: record?.uid,
                                            name: record?.name,
                                          });
                                        }
                                      }}
                                      style={{
                                        opacity: Views?.hrAssociate && !Views?.hrAdmin ? "0.5" : "",
                                        cursor: Views?.hrAssociate && !Views?.hrAdmin ? "not-allowed" : "pointer",
                                      }}
                                      src={deleteIcon}
                                    ></img>
                                    <img
                                      onClick={() => {
                                        setCalendarParams({ id: record?.id, name: record?.name })
                                        logMoveHandler(record?.id, record?.name);
                                      }}
                                      src={calenderIcon}
                                      style={{ cursor: "pointer", }}
                                    ></img>
                                  </div>
                                ) : activeTab === 2 ? (
                                  <div style={{}}>
                                    <img
                                      onClick={() => {
                                        if (Views?.hrAssociate && !Views?.hrAdmin) {

                                        } else {
                                          setDeleteModal({
                                            id: record?.id,
                                            uid: record?.uid,
                                            name: record?.name,
                                          });
                                        }
                                      }}
                                      style={{
                                        opacity: Views?.hrAssociate && !Views?.hrAdmin ? "0.5" : "",
                                        cursor: Views?.hrAssociate && !Views?.hrAdmin ? "not-allowed" : "pointer",
                                      }}
                                      src={deleteIcon}
                                    ></img>
                                  </div>
                                ) : ""}
                              </div>
                            </div>
                          </div>
                        )
                    })
                }
              {/* </div> */}
            </div>
            </div>
            :
            <div className={classes.ListBodyContainer} style={{ height: "51vh", overflowY: 'scroll', overflowX: "scroll", width: "100%", backgroundColor: "#F9F9F9", padding: "0.2 1.2rem" }}>
              {loader ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '55vh', }}><Spin /></div> :
                userData?.length === 0 ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '55vh' }}><div>No data found</div></div> :
                  userData?.map((record, id, index) => {
                    return (
                      <div style={{ marginTop: "0.3rem", marginBottom: "1rem", borderRadius: 10, backgroundColor: "#FFFFFF", padding: "1rem 1rem", margin: "1rem" }}>

                        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", }}>
                          <div style={{ display: "flex", flexDirection: "row", gap: "0.2rem" }}>
                            <Checkbox size="large"
                              checked={selectedRowKeys.includes(record?.id)}
                              onClick={(e) => {
                                const newSelectedRowKeys = e.target.checked
                                  ? [...selectedRowKeys, record?.id]
                                  : selectedRowKeys.filter(key => key !== record?.id);

                                const newSelectedRows = userData.filter(row => newSelectedRowKeys.includes(row.id));

                                onSelectChange(newSelectedRowKeys, newSelectedRows);
                              }}
                            />
                            <img style={{ height: "3.5rem", width: "3.5rem", borderRadius: "50%" }} src={UserIcon} />
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", cursor: "pointer" }}
                            >
                              <p style={{ color: "" }}>
                                <Typography.Text style={{ width: "45vw", fontSize: 18, fontWeight: 550 }} ellipsis={{ tooltip: true }}>
                                  {record?.name}
                                </Typography.Text>
                              </p>
                              <p style={{ color: "#16243280", fontSize: 17 }}>{record?.uid}</p>
                            </div>
                          </div>
                          {activeTab === 2 ? "" :
                            <div style={{ width: '', marginRight: "0.1rem" }}>
                              <div>
                                {activeTab == 0 ? (
                                  <div
                                    className={`${selectedRowKeys?.length !== 0 ? classes.disabled : ""
                                      }`}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setEditModal(record);
                                    }}
                                  >
                                    <img src={EditIcon} style={{ height: "2.3rem" }}></img>
                                  </div>
                                ) : activeTab === 1 ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      // gap: "1px",
                                      alignItems: "center",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <img
                                      onClick={() => {
                                        setDeleteModal({
                                          id: record?.id,
                                          uid: record?.uid,
                                          name: record?.name,
                                        });
                                      }}
                                      src={deleteIcon}
                                    ></img>
                                    <img
                                      onClick={() => {
                                        setCalendarParams({ id: record?.id, name: record?.name })
                                        logMoveHandler(record?.id, record?.name);
                                      }}
                                      src={calenderIcon}
                                    ></img>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>}
                        </div>

                        <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem", marginTop: "0.5rem", }}>
                          <p style={{ fontSize: "19px", color: "#16243280", marginTop: "0.3rem", }}>Email: </p>
                          <Typography.Text style={{ width: "65vw", fontSize: 19, fontWeight: 550 }} ellipsis={{ tooltip: true }}>
                            {record?.email ? record?.email : "NA"}
                          </Typography.Text>
                          {/* <p style={{fontSize:"19px"}}>{record?.email ? record?.email :"NA"}</p> */}
                        </div>

                        <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem", marginTop: "0.5rem", }}>
                          <p style={{ fontSize: "19px", color: "#16243280" }}>Phone: </p>
                          <p style={{ fontSize: "19px" }}>{record?.phone ? record?.phone : "NA"}</p>
                        </div>

                        <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem", marginTop: "0.5rem", }}>
                          <p style={{ fontSize: "19px", color: "#16243280" }}>Department: </p>
                          <p style={{ fontSize: "19px" }}>{record?.department ? record?.department?.name : "NA"}</p>
                        </div>

                        <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem", marginTop: "0.5rem", }}>
                          <p style={{ fontSize: "19px", color: "#16243280" }}>Manager: </p>
                          <p style={{ fontSize: "19px" }}>{record?.managerName ? record?.managerName : "NA"}</p>
                        </div>

                        <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem", marginTop: "0.6rem", }}>
                          <p style={{ fontSize: "19px", color: "#16243280" }}>WorkPlace: </p>
                          <div>
                            {activeTab === 2 ?
                              <div>
                                <Typography.Text style={{ width: "50vw", fontSize: 18, fontWeight: 550 }} ellipsis={{ tooltip: true }}>
                                  <span>{record?.user?.tempCity?.cityName ? record?.user?.tempCity?.cityName : "NA"}</span>
                                </Typography.Text>
                              </div>
                              :
                              <div>
                                <Typography.Text style={{ width: "50vw", fontSize: 18, fontWeight: 550 }} ellipsis={{ tooltip: true }}>
                                  <span>{record?.user?.city?.cityName ? record?.user?.city?.cityName : "NA"}</span>
                                </Typography.Text>
                              </div>
                            }
                          </div>
                        </div>

                        <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem", marginTop: "0.3rem", }}>
                          <p style={{ fontSize: "19px", color: "#16243280" }}>Status: </p>
                          <p style={{ fontSize: "19px" }}>{record?.user?.active ? record?.user?.active : "NA"}</p>
                        </div>

                      </div>
                    )
                  })}
            </div>
          }
          <div>
            <Pagination position="bottomRight" size="small" style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.5rem", fontSize: width < 700 && 15 }}
              current={tableParams.pagination.current}
              pageSize={tableParams.pagination.pageSize}
              total={tableParams.pagination.total}
              showSizeChanger={tableParams.pagination.showSizeChanger}
              // showTotal={tablePagniation.pagination.showTotal}
              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
              // Other props you might want to pass
              onChange={handleTableChange}
              onShowSizeChange={handleTableChange}
            />
          </div>
        </div>
      </div>

      {editModal !== undefined ? (
        <EditUser
          modal={editModal}
          setModal={setEditModal}
          refresh={refreshUserApi.bind(this)}
        />
      ) : (
        ""
      )}
      <ModalCompo closableRight={true}
        header={<div
          style={{
            color: "#FF3434",
            font: "normal 600 20px/30px Poppins",
            display: "flex",
            alignItems: "center",
            gap: "8px",
            width: "100%",
            justifyContent: "center",
            // marginBottom: "10px",
          }}
        >
          <img src={deleteIcon} />
          <div> Delete</div>
        </div>}
        open={deleteModal !== undefined}
        onCancel={() => {
          setDeleteModal();
        }}
        closable={false}
        contentStyle={{ width: width > 700 && "850px", top: "25vh", padding: "13px", borderRadius: "8px", margin: width < 700 && 15 }}
      >
        <div className={classes.confirmationModalMainDiv} style={{
          width: "100%", alignItems: "center",
          display: "flex", flexDirection: "column", justifyContent: "center"
        }}>

          <div
            style={{
              border: "1px solid #F1F1F1",
              width: "100%",
              marginBottom: "20px",
            }}
          ></div>
          <div
            style={{ font: "normal 590 17px/27px Poppins", color: "#162432" }}
          >
            Are you sure you want to delete{" "}
            <span style={{ color: "#0086FF" }}>{`"${deleteModal?.name}"`}</span>{" "}
            {activeTab === 2 ? "Temp Location" : "web access"} ?
          </div>
          {/* <div style={{display:'flex',justifyContent:'center',alignItem:'center',gap:'1rem',font:"normal 500 24px/36px Poppins",color:"#162432"}}><div> web access</div></div> */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItem: "center",
              gap: "1.5rem",
              marginTop: "3rem",
              marginBottom: "1.5rem", height: "2.1rem"
            }}
          >
            <ButtonCompo
              title={"Yes"}
              disabled={Views?.hrAssociate && !Views?.hrAdmin ? true : false}
              style={{ backgroundColor: "#0086FF", color: "#fff", width: "5rem" }}
              onClick={() => {
                removeWebAccessHandler(deleteModal?.uid, deleteModal?.id);
              }}
            ></ButtonCompo>
            <ButtonCompo
              title={"No"}
              disabled={Views?.hrAssociate && !Views?.hrAdmin ? true : false}
              style={{ border: "1px solid #0086FF", color: "#0086FF", width: "5rem" }}
              onClick={() => {
                setDeleteModal();
              }}
            ></ButtonCompo>
          </div>
        </div>
      </ModalCompo>

      <ModalCompo closableRight={true}
        open={addUserModal}
        header={webAccessHeader}
        onCancel={() => {
          setAddUserModal();
        }}
        contentStyle={{ width: width > 700 && "800px", top: "27vh", borderRadius: "8px", margin: width < 700 && 15 }}
      >
        <Form
          layout="vertical"
          onFinish={WebAccessHandler}
          style={{ marginTop: "20px", width: "100%" }}
        >
          <Form.Item
            name="access"
            label={<div><b>Select Associates</b></div>}
            rules={[{ required: true, message: "please select associate" }]}
          >
            <Select
              onSearch={(val) => {
                setUserSearch(val);
              }}
              showArrow={false}
              showSearch
              filterOption={false}
              placeholder="please type name"
              mode="multiple"
            >
              {list?.map((val, id) => {
                if (val?.user?.webAccess !== true) {
                  return (
                    <Select.Option key={val?.id} value={val?.uid}>
                      {val?.name}
                    </Select.Option>
                  );
                }
              })}
            </Select>
          </Form.Item>
          <div className={classes.buttonDiv} style={{ marginTop: "40px", height: "2.1rem" }}>
            <ModalPrimaryButton htmlType="submit" style={{ width: "6rem" }}
              disabled={Views?.hrAssociate && !Views?.hrAdmin ? true : false}
            >Save</ModalPrimaryButton>
            <ModalSecondaryButton style={{ backgroundColor: "white", width: "6rem" }}
              onCancel={() => {
                setAddUserModal();
              }}
              disabled={Views?.hrAssociate && !Views?.hrAdmin ? true : false}
            >
              Cancel
            </ModalSecondaryButton>
          </div>
        </Form>
      </ModalCompo>

      <Modal
        open={multipleEditModal} centered footer={false} width={width > 700 ? "900px" : "87vw"}
        title={width > 500 ? <span
          style={{
            color: "#0086FF", font: "normal 600 19px/30px Poppins", display: "flex", flexDirection: "row",
            justifyContent: "flex-start", width: "100%",
          }}
        >
          Multiple User Location Access
        </span> : width > 340 ? <div>
          <span
            style={{ color: "#0086FF", font: "normal 600 20px/30px Poppins", display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%", }}
          >
            Multiple User Location
          </span>
          <span style={{ color: "#0086FF", font: "normal 600 20px/30px Poppins", display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%", }}>
            Access</span>
        </div>
          : <div>
            <span
              style={{ color: "#0086FF", font: "normal 600 20px/30px Poppins", display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%", }}
            >
              Multiple User
            </span>
            <span style={{ color: "#0086FF", font: "normal 600 20px/30px Poppins", display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%", }}>
              Location Access</span>
          </div>}
        onCancel={() => {
          setOfficeLocation([]);
          setChecked();
          setMultipleEditModal();
          setSelectedRowKeys([]);
          form.resetFields();
        }}
      // bodyStyle={{height:width>700&&"73vh"}}
      // contentStyle={{top:width>700?"13vh":"18vh",borderRadius: "8px",height:width>700&&"73vh",margin:width<700&&15 }}
      // closableRight={true}
      >
        <Form
          key={Math.random()}
          form={form}
          onFinish={multipleHandler}
          fields={[{ name: "user", value: selectedRowKeys },
            // { name: "city", value: selectedRows.map(row => row.user.city !== null ? row.user.city.cityName : null) },
            // { name: "office", value: OfficeLocation }
          ]}
          layout="vertical"
          style={{ width: "100%", marginTop: "20px" }}
          onValuesChange={(changedValues, allValues) => {

            if ('city' in changedValues) {
              form.setFieldsValue({
                office: undefined,
              });
            }
          }}
        >
          <Form.Item
            name="city"
            label={<div>Work City Location</div>}
            rules={[
              { required: true, message: "please select work city location" },
            ]}
          >
            <Select
              placeholder="select work city location"
              onChange={(val) => {
                locationList?.forEach((item) => {
                  if (item?.id === val) {
                    setOfficeLocation(item?.geoFenceSetting);
                  }
                });
              }}
            >
              {locationList?.map((val, id) => {
                return (
                  <Select.Option value={val?.id} key={id}>
                    {val?.cityName}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="office"
            label={<div>
              <div>Work Office Place</div>{" "}
              {/* <div>
                <input
                  onChange={(val) => {
                    setChecked(val?.target?.checked);
                  }}
                  type="checkbox"
                />{" "}
                <label>All</label>
              </div>{" "} */}
            </div>}
            rules={[
              { required: !checked, message: "please select office location" },
            ]}
          >
            <Select
              disabled={OfficeLocation?.length === 0 ? true : false}
              placeholder="select work location"
            >
              {OfficeLocation?.map((val, id) => {
                return (
                  <Select.Option value={val?.id} key={id}>
                    {val?.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item name="user" label={<span>Selected User</span>}>
            <Select placeholder="select user" mode="multiple" showSearch optionFilterProp="children">
              {userList?.map((val, id) => {
                return (
                  <Select.Option value={val?.id} key={val?.id}>
                    {val?.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          {width > 700 ?
            <div className={classes?.buttonDiv}>
              {/* <ButtonCompo
              title={"Reset"}
              type={"reset"}
              disabled={ Views?.hrAssociate && !Views?.hrAdmin  ? true : false}
              onClick={() => {
                // setSelectedRowKeys([]);
                // setMultipleEditModal();
                form.resetFields();
              }}
              style={{ color: "#fff", backgroundColor: "#FF3232" }}
            /> */}
              <ButtonCompo
                title={width > 700 ? "Clear Users" : "Clear"}
                type={"reset"}
                disabled={Views?.hrAssociate && !Views?.hrAdmin ? true : false}
                onClick={() => {
                  setSelectedRowKeys([]);
                  // setMultipleEditModal();
                  form.resetFields();
                }}
                style={{ color: "#0086FF", border: "1px solid #0086FF" }}
              />
              <Button
                // title={"Update"}
                htmlType="submit"
                disabled={Views?.hrAssociate && !Views?.hrAdmin ? true : false}
                style={{ color: "#fff", backgroundColor: "#0086FF", width: "6.5rem", height: "2.2rem" }}
              >Update</Button>
            </div>
            :
            <div>
              {/* <ButtonCompo 
            title={"Reset"}
            type={"reset"}
            disabled={ Views?.hrAssociate && !Views?.hrAdmin  ? true : false}
            onClick={() => {
              // setSelectedRowKeys([]);
              // setMultipleEditModal();
              form.resetFields();
            }}
            style={{ color: "#fff", backgroundColor: "#FF3232",width:"6rem", }}
          /> */}
              <div style={{ display: "flex", flexDirection: "Row", justifyContent: "flex-end", width: "100%", gap: "0.5rem", marginTop: "0.8rem" }}>
                <ButtonCompo
                  title={width > 700 ? "Clear Users" : "Clear"}
                  type={"reset"}
                  disabled={Views?.hrAssociate && !Views?.hrAdmin ? true : false}
                  onClick={() => {
                    setSelectedRowKeys([]);
                    // setMultipleEditModal();
                    form.resetFields();
                  }}
                  style={{ color: "#0086FF", border: "1px solid #0086FF", width: "6rem", }}
                />
                <Button
                  // title={"Update"}
                  htmlType="submit"
                  disabled={Views?.hrAssociate && !Views?.hrAdmin ? true : false}
                  style={{ color: "#fff", backgroundColor: "#0086FF", width: "6.5rem", height: "2.2rem" }}
                >Update</Button>
              </div>
            </div>
          }
        </Form>
      </Modal>

      <Modal
        open={tempLocationModal} centered footer={false} width={width > 700 ? "900px" : "87vw"}
        title={width > 500 ? <span
          style={{ color: "#0086FF", font: "normal 600 20px/30px Poppins", display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%", }}
        >
          Temporary Location Access
        </span> : <div>
          <span
            style={{ color: "#0086FF", font: "normal 600 20px/30px Poppins", display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%", }}
          >
            Temporary Location
          </span>
          <span style={{ color: "#0086FF", font: "normal 600 20px/30px Poppins", display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%", }}>
            Access</span>
        </div>
        }
        onCancel={() => {
          setTempLocationModal();
          setChecked();
          setOfficeLocation([]);
          setSelectedRowKeys([]);
          form.resetFields();
        }}
      >
        <div style={{ width: "99.5%", height: width > 700 && "76vh", overflowY: "scroll", }}>
          {/* <div style={{width:"100%",display:"flex",justifyContent:"flex-end"}}>
        </div> */}
          <Form
            form={form}
            fields={[
              { name: "tempUser", value: selectedRowKeys },
              // { name: "tempCity", value: selectedRows.map(row => row.user.city !== null ? row.user.city.cityName : null) },
              // { name: "tempOffice", value: selectedRows.map(row => row.user.tempCity !== null ? row.user.tempCity.cityName : null) },
              { name: "startDate", value: moment() },
              // { name: "duration", value: selectedRows.map(row => row.duration !== null ? row.duration : null) },
            ]}
            onFinish={tempLocationHandler}
            layout="vertical"
            className={classes.FormDiv}
            style={{ width: "100%", height: width > 700 && "76vh", overflowY: "scroll", }}
            onValuesChange={(changedValues, allValues) => {
              if ('tempCity' in changedValues) {
                form.setFieldsValue({
                  tempOffice: undefined,
                });
              }
            }}
          >
            <Form.Item
              name="tempCity"
              label={<div>Work City Location</div>}
              rules={[
                { required: true, message: "please select work city location" },
              ]}
            >
              <Select allowClear
                placeholder="select work city location"
                onChange={(val) => {
                  locationList?.forEach((item) => {
                    if (item?.id === val) {
                      setOfficeLocation(item?.geoFenceSetting);
                    }
                  });
                }}
              >
                {locationList?.map((val, id) => {
                  return (
                    <Select.Option value={val?.id} key={id}>
                      {val?.cityName}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="tempOffice"
              label={<div>
                <div>Work Office Place</div>{" "}
                {/* <div>
                <input
                  onChange={(val) => {
                    setChecked(val?.target?.checked);
                  }}
                  type="checkbox"
                />{" "}
                <label>All</label>
              </div>{" "} */}
              </div>}
              rules={[
                {
                  required: !checked,
                  message: "please select office city location",
                },
              ]}
            >
              <Select
                showSearch={false}
                disabled={OfficeLocation?.length === 0 ? true : false}
                placeholder="select work location" mode="multiple">
                {OfficeLocation?.map((val, id) => {
                  return (
                    <Select.Option value={val?.id} key={id}>
                      {val?.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="startDate"
              label={<span>Date</span>}
              rules={[{ required: true, message: "please select start date" }]}
            >
              <DatePickerCompo style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name="duration"
              label={<div>Duration</div>}
              rules={[{ required: true, message: "please select duration" }]}
            >
              <Select placeholder="Please select duration">
                <Select.Option value={7} key={3}>
                  7 Days
                </Select.Option>
                <Select.Option value={14} key={4}>
                  14 Days
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="tempUser" label={<span>Selected User</span>}>
              <Select placeholder="select user" mode="multiple" showSearch optionFilterProp="children">
                {userList?.map((val, id) => {
                  return (
                    <Select.Option value={val?.id} key={val?.id}>
                      {val?.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            {width > 700 ?
              <div className={classes?.buttonDiv} style={{ marginTop: "2.5rem", margin: width < 700 && "0 0.1rem", gap: width < 700 && "0.5rem" }}>
                {/* <ButtonCompo
              title={"Reset"}
              disabled={ Views?.hrAssociate && !Views?.hrAdmin  ? true : false}
              type={"reset"}
              onClick={() => {
                // setTempLocationModal();
                // setOfficeLocation();
                // setSelectedRowKeys([]);
                form.resetFields();
              }}
              style={{ color: "#fff", backgroundColor: "#FF3232" }}
            /> */}
                <ButtonCompo
                  title={width > 700 ? "Clear Users" : "Clear"}
                  type={"reset"}
                  disabled={Views?.hrAssociate && !Views?.hrAdmin ? true : false}
                  onClick={() => {
                    setSelectedRowKeys([]);
                    // setTempLocationModal();
                    form.resetFields();
                  }}
                  style={{ color: "#0086FF", border: "1px solid #0086FF" }}
                />
                <Button
                  // title={"Update"}
                  htmlType="submit"
                  disabled={Views?.hrAssociate && !Views?.hrAdmin ? true : false}
                  style={{ color: "#fff", backgroundColor: "#0086FF", height: "2.2rem", width: "6.5rem" }}
                >Update</Button>
              </div>
              :
              <div>
                {/* <ButtonCompo
              title={"Reset"}
              type={"reset"}
              disabled={ Views?.hrAssociate && !Views?.hrAdmin  ? true : false}
              onClick={() => {
                // setTempLocationModal();
                // setOfficeLocation();
                // setSelectedRowKeys([]);
                form.resetFields();
              }}
              style={{ color: "#fff", backgroundColor: "#FF3232",width:"6rem", }}
            /> */}
                <div style={{ display: "flex", flexDirection: "Row", justifyContent: "flex-end", width: "100%", gap: "0.5rem", marginTop: "0.8rem" }}>
                  <ButtonCompo
                    title={width > 700 ? "Clear Users" : "Clear"}
                    type={"reset"}
                    disabled={Views?.hrAssociate && !Views?.hrAdmin ? true : false}
                    onClick={() => {
                      setSelectedRowKeys([]);
                      // setTempLocationModal();
                      form.resetFields();
                    }}
                    style={{ color: "#0086FF", border: "1px solid #0086FF", width: "6rem" }}
                  />
                  <Button
                    // title={"Update"}
                    htmlType="submit"
                    disabled={Views?.hrAssociate && !Views?.hrAdmin ? true : false}
                    style={{ color: "#fff", backgroundColor: "#0086FF", width: "6.5rem", height: "2.2rem" }}
                  >Update</Button>
                </div>
              </div>
            }
          </Form>
        </div>
      </Modal>
      {
        locModal &&
        <Modal
          open={locModal}
          onCancel={() => { setLocModal(false) }}
          centered footer={false} width={width > 700 ? "90%" : "87vw"}
          title={<div style={{ textAlign: 'center', fontSize: 14, }}>Location history</div>}
        >
          <TableCustom columns={tableColumn} dataSource={historyDetails} loading={refresh}

          />
          <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", gap: 10 }}>
            <Button onClick={() => {
              onLocClick(userListA, true)
              setLocModal(false)

            }} type="primary" icon={<RiDownloadLine />} style={{ border: "0.5px solid #5E5E5E", color: "white", fontSize: 14 }} >
              Download
            </Button>
            <Button type="dashed" style={{ border: "0.5px solid #5E5E5E", color: "#5E5E5E", fontSize: 14 }} onClick={() => { setLocModal(false) }}>
              Close
            </Button>
          </div>
        </Modal>
      }
      {
        visible &&
        <DownloadModal visible={visible}  onCancel={()=>{setVisible(false)}} onDownload={(sel)=>{
          onLocClick(selectedRowKeys, true, "/attendance-users/getusers",sel==="locationHistory"?true:false,sel==="currentLocation"?true:false)
        }
        }/>
      }
    </div>
  );
};

export default Users;
