import Api from "../../services/Api";


export const getCalenderDataApi=(params,callback)=>{
    Api.get("/attendance-attendance/calendar").params(params).send((res)=>{
            callback(res);
    })
}
export const ManualPunchApi=(params,callback)=>{

    Api.post("/attendance-attendance/applyManualPunch").params(params).send((res)=>{
        if(res!==undefined && res?.show?.type!=="error"){
            callback(true)
        }
        else{
            callback(false)
        }  
    })
}

export const punchInApi=(params,callback)=>{
    Api.post("/attendance-attendance/dailyLoginRecord").params(params).send((res)=>{
        callback(res);
    })
}
export const CeoFeedbackApi=(id,callback)=>{
    Api.get(`/resource-feedback/feedbackResponseForPunch?id=${id}`).send((res)=>{
        callback(res);
    })
}
export const FeedbackSubmitApi=(params,callback)=>{
    Api.post("/resource-feedback/fillUserFeedback").params(params).send((res)=>{
        callback(res);
    })
}
export const getOHDApi=(params,callback)=>{
    Api.get("/attendance-attendance/getOptionalHoliday").params(params).send((res)=>{
        if(res!==undefined){
            callback(res);
        }
    });
}
export const getPunchInfo=(date,userid,callback)=>{
    Api.post(`/attendance-attendance/getPunchInfo?date=${date}&userId=${userid}`).send((res)=>{
        if(res!==undefined){
            callback(res);
        }
    });
}
export const applyHolidayApi=(params,callback)=>{

    Api.post("/attendance-attendance/selectOptionalHoliday").params(params).send((res)=>{
        if(res!==undefined && res?.show?.type!=="error"){
            callback(true)
        }
        else{
            callback(false)
        }  
    })
}
        