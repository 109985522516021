import { SECRET_KEY, decryptData } from "./fileCode";

class ViewRole{
    constructor(){
        this.hr=false;
        this.hrAdmin=false;
        this.hrAssociate=false;
        this.associate=false;
        this.superAdmin=false;
        this.manager=false;
        this.hod=false;
        this.admin=false;
        this.ceo=false;
        this.hiringUser=false;
    }

    updateView(val){
        let u=localStorage.getItem('user');
        let user;
        if(u){

            user=decryptData(u,SECRET_KEY);
        }

        
        if(user?.userRoles?.includes('Associate')){
            this.associate=true;
        }
        if(user?.userRoles?.includes('Manager')){
            this.manager=true;
        }
        if(user?.userRoles?.includes('Department Head')){
            this.hod=true;
        }
        if(user?.userRoles?.includes('Super Admin')){
            this.superAdmin=true;
        }
        if(user?.userRoles?.includes('HR')){
            this.hr=true;
            this.hrAdmin=true;
        }
        if(user?.userRoles?.includes('HR Associate')){
            this.hrAssociate=true;
        }
        if(user?.resourceUser?.designation==='CEO'){
            this.ceo=true;
        }
        if(user?.resourceUser?.privilege?.includes('Admin')){
            this.admin=true;
        }
        if(user?.hiringUser){
            this.hiringUser=true;
        }
    }


}
const viewClass=new ViewRole();
export default viewClass;
