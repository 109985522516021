import React, { useEffect, useState } from 'react';
import locationIcon from "../../../assets/locationIcanS.svg";
import cal from "../../../assets/calIcon.svg";
import tickGreen from "../../../assets/tickgreen.svg";
import tickBlue from "../../../assets/tickBlue.svg";
import inProgress from "../../../assets/inprogress2.svg"
import inProgressGrey from "../../../assets/greyprogress.svg"

import cancel from "../../../assets/crossRed.svg";
import back_icon from '../../../assets/backIcon.svg';
import { useLoginStore } from "../store";
import Api from '../../../services/Api';
import { SECRET_KEY, decryptData } from '../../../services/fileCode';
import { NoData } from "../../Training_essentialDocuments";
import timeIcon from "../../../assets/time.svg";
import clockSmall from "../../../assets/clocksmall.svg";
import queueImg from "../../../assets/inque.svg";
import arrowRight from "../../../assets/arrowright.svg";
import fileIcon from "../../../assets/fileicon.svg";
import { Modal, Spin, Input, Button, Typography, DatePicker, Form } from 'antd'
import { hiringRoutePath } from '../../Homepage/components/menuData';
import moment from "moment";
import { ModalPrimaryButton, ResetButton } from '../../../components/Button/Button';
import ApplyJobForm from '../JobSearch/ApplyJobForm';
import ButtonCompo from '../../../design/ButonCompo';
import { useWindowDimensions } from '../../../components/windowDimention/windowDimension';
import { MdOutlineRadioButtonUnchecked, MdOutlineRadioButtonChecked } from "react-icons/md";
import warningIcon from "../../../assets/warningModalImage.svg"
import SelectDateIcon from "../../../assets/selectDateIcon.svg";
import { Document, Page } from 'react-pdf';
import "../JobSearch/Pdf.css";
import { CloseOutlined} from '@ant-design/icons';
import classes from "./hiringStatus.module.css";
import dayjs from 'dayjs';
import ModalCompo from '../../../design/ModalCompo';

const resheduleByCandidate = (data,selectedSlotsList,dateVal, reason, callback) => {
    Api.post(`/hiring-mobile/resheduleByCandidate`)
        .params({
            candidateId: data?.sourceId,
            job: data?.job,
            date: data?.interviewDate,
            // time: data?.time,
            reason: reason,
            panelistEmail: data?.panelistEmail,
            slot: selectedSlotsList.map((slot,index) => ({
                number: index+1,
                date: slot?.date,
                time: `${slot.selectedSlot[0].startTime}-${slot.selectedSlot[0].endTime}`
            })),
        })
        .send((res, error) => {
            if (res) {
                callback()
            }
        });
}

const Icons = {
    "Submitted": tickGreen,
    "submitted": tickGreen,
    "inprogress": inProgress,
    "Shortlisted": tickGreen,
    "Not Shortlisted": cancel,
    "New": tickGreen,
    'Scheduled': inProgress,
    'Selected': tickGreen,
    'Not Selected': cancel,
    'Hold': inProgress,
    'In Progress': inProgress,
    'Not Interested': cancel,
    'Old': cancel,
    'ProposedJd': cancel,
    'Re-Discussion': inProgress,
    'Re-Schedule': inProgress,
    'Feedback-Pending': inProgress,
}

const colors = {
    "Submitted": "#26DE81",
    "submitted": "#26DE81",
    "inprogress": "#FD9644",
    "Shortlisted": "#26DE81",
    "Not Shortlisted": "#FC5C65",
    "New": "#26DE81",
    'Scheduled': "#FD9644",
    'Selected': "#26DE81",
    'Not Selected': "#FC5C65",
    'Hold': "#FD9644",
    'In Progress': "#FD9644",
    'Not Interested': "#FC5C65",
    'Old': "#FC5C65",
    'ProposedJd': "#FC5C65",
    'Re-Discussion': "#FD9644",
    'Re-Schedule': "#FD9644",
    'Feedback-Pending': "#FD9644",

}

const findStatus = {
    "New": "Submitted",
    'Shortlisted': "Shortlisted",
    'Not Shortlisted': "Not Shortlisted",
    'Scheduled': "In-progress",
    'Selected': "Selected",
    'Not Selected': "Not Selected",
    'Hold': "Hold",
    'In Progress': "In-progress",
    'Not Interested': "Not Selected",
    'Old': "Withdrawn",
    'ProposedJd': "Not Shortlisted",
    'Re-Discussion': "Re-Discussion",
    'Re-Schedule': "In-progress",
    'Feedback-Pending': "In-progress",
    "Submitted": "Submitted"
}
const findStatus1 = {
    "New": "Submitted",
    'Shortlisted': "Shortlisted",
    'Not Shortlisted': "Not Shortlisted",
    'Scheduled': "In-progress",
    'Selected': "Selected",
    'Not Selected': "Not Selected",
    'Hold': "Hold",
    'In Progress': "In-progress",
    'Not Interested': "Withdrawn",
    'Old': "Withdrawn",
    'ProposedJd': "Not Shortlisted",
    'Re-Discussion': "In-progress",
    'Re-Schedule': "In-progress",
    'Feedback-Pending': "In-progress",
    "Submitted": "Submitted"
}

const StatusCards = ({ onClinkHandler, item }) => {

    return (
        <>
            <div style={{ display: "flex", backgroundColor: "#FFFFFF", width: "100%", height: "fit-content", marginTop: 10, cursor: (findStatus1[item?.status]!=="Withdrawn" &&findStatus1[item?.status]!=="Not Selected")?"pointer":'default', borderRadius: 8 }}
                onClick={() => onClinkHandler(item)}
            >
                <div style={{ padding: 10, width: "100%" }}>
                    <div className={classes.header2} style={{ color:'#161616' }}>
                        {item?.job}
                    </div>
                    <div style={{ display: "flex", marginTop: 10, alignItems: "center" }}>
                        <img src={locationIcon} style={{ height: 15, width: 15 }} />
                        <div className={classes.title2} style={{ color: "#3D3D3D", marginLeft: 10 }}>{item?.location}</div>
                    </div>
                    <div style={{ display: "flex", marginTop: 5, alignItems: "center" }}>
                        <img src={cal} style={{ height: 15, width: 15 }} />
                        <div style={{ color: "#3D3D3D", fontWeight: 570, fontSize: 12, marginLeft: 10 }}>Applied on {moment(item?.appliedOn).format("DD/MM/YYYY")}</div>
                    </div>
                    <div style={{ border: ".3px solid #CACACA", marginTop: 15 }}></div>
                    <div>
                        <div style={{ display: "flex", marginTop: 15, alignItems: "center", marginBottom: 5, justifyContent: "space-between" }}>
                            <div style={{ display: "flex" }}>
                                <img src={Icons[item?.status]} style={{ height: 15, width: 15 }} />
                               { console.log( findStatus1[item?.status]!=="Withdrawn","kjhugyfttgyui",(item?.interviewDetais&&(item?.status!=="Selected" && findStatus1[item?.status]!=="Withdrawn")))}
                                <div style={{ color: colors[item?.status], fontWeight: 600, fontSize: 12, marginLeft: 10 }}>{item?.round ? `Round ${item?.round}` : findStatus1[item?.status]} {(item?.interviewDetais&&(item?.status!=="Selected" && findStatus1[item?.status]!=="Withdrawn" &&findStatus1[item?.status]!=="Not Selected")) && `(Round ${item?.interviewDetais?.round}: ${item?.interviewDetais?.interviewStatus==="Skipped"?"Skipped": item?.interviewDetais?.interviewStatus==="Feedback-Pending"?"Pending":item?.statusmap})`}</div>
                            </div>
                            <div>
                                {
                                    (item?.status!=="Old"&&item?.status!=="Not Interested")&&
                                <img src={arrowRight} style={{ height: 10, width: 10 }} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

// ["Submitted", "Shortlisted", /* `${data?.round?`Round ${data?.round}`:"In-progress"}` */"Round 1", "Round 2", "Round 3", "Selected"]


const Hiringdetails = ({ onBackClick, data, basicData }) => {

    const { width } = useWindowDimensions();
    const [widtdraw, setWidthdraw] = useState(false);
    const [contactUs,setContactUs]=useState(false);
    const [jd, setJd] = useState({ ref: false, file: null })
    const [onGoToprofile, setOnGoToProfile] = useState(false)
    const [reschedule, setreschedule] = useState(false);
    const [dateVal, setDateVal] = useState(null);
    const [slotState, setSlotState] = useState([{
        date: [],
        selectedSlot: [],
      }]);
    const [slotValues, setSlotValues] = useState([]);
    const [rescheduleConfirm, setRescheduleConfirm] = useState(false);
    const [reason, setReason] = useState('');
    const [interviewRound, setRound] = useState(0)
    const [numPages, setNumPages] = useState(null);
    const [arrMaker, setArrMaker] = useState([]);
   
    // interviewRoundData?.length
    useEffect(()=>{
        let arr1 = ["Submitted", "Shortlisted"];
        let arr2 = [`${findStatus[data?.candidateHiringStatus] ==="Not Selected"?"Not Selected":"Selected"}`]
        let roundNames = []
        for (let i = 1; i <= data?.interviewRoundData?.length; i++) {
            if(findStatus[data?.candidateHiringStatus]==="Not Selected"){
                if(!data?.interviewRoundData[i-1]?.queue){
                    roundNames.push(`Round ${i}`);
                }
            }else{
                roundNames.push(`Round ${i}`);
            }
        }

        if(data?.candidateHiringStatus==="Hold" && data?.interviewRoundData?.length<=1){
            setArrMaker([...arr1,"Hold",...arr2])
        }else{
            console.log(roundNames,"roundNamesroundNames");
            setArrMaker([...arr1,...roundNames,...arr2])
        }
    },[data?.interviewRoundData?.length])

    const [selectedSlot, setSelectedSlot] = useState([]);
    const [selectedSlotsList, setSelectedSlotsList] = useState([]);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const JobDetails = ({ icon, text }) => {

        return (
            <>
                <div style={{ display: "flex", marginTop: 10, alignItems: "center" }}>
                    <img src={icon} style={{ height: 15, width: 15 }} />
                    <div className={classes.title2} style={{ color: "#3D3D3D",  marginLeft: 10 }}>{text}</div>
                </div>
            </>
        )
    }

    console.log("sssValue", data?.jobRounds && data?.round
        ? data.jobRounds.filter(item => item.round === data.round)
        : []);


    return (
        <>
            {
                onGoToprofile ?
                    <>
                        <ApplyJobForm jobForm={
                            {
                                expType: data?.candidateType,
                                jobId: data?.jobId,
                                location: data?.canJobPrifferdLocation,
                                saved: true,
                                title: data?.job,
                                type: 'hiringStatus',
                                status: basicData?.status,
                                sourceId: data?.sourceId,

                            }
                        } setJobForm={() => { setOnGoToProfile(false) }} />
                    </>
                    :
                    <>
                        <div style={{ display: "flex", padding: 5,gap:"0.2rem", alignItems: "center", cursor: "pointer" }}>
                            <div onClick={() => {
                                onBackClick()//add ref 
                            }}>
                                <img src={back_icon} style={{height:"1.1rem",width:"1.1rem"}} />

                            </div>
                            <div style={{ marginLeft: 5 }}>Back</div>
                        </div>
                        <div style={{ backgroundColor: "#FFFFFF", width: "100%", height: "fit-content", padding: 20 }}>
                            <div style={{ color: "#808080", fontSize: 16, fontWeight: 550 }}>Status</div>
                            <div style={{ display: "flex", width: "100%", height: "fit-content", marginTop: 10, padding:width<700?0: 20, marginBottom:width<700?15:0 }}>
                                
                                    <HiringProgress width={width} roundDetails={data?.interviewStatus ? data?.interviewStatus : ""} 
                                    list={findStatus[data?.candidateHiringStatus] === "Withdrawn" ? ["Submitted", "Withdrawn"] /* :findStatus[data?.candidateHiringStatus] === "Not Selected" ?["Submitted", "Not Selected"] */ 
                                    :findStatus[data?.candidateHiringStatus] === "Not Shortlisted" ? ["Submitted", "Not Shortlisted"] : arrMaker}
                                    rejRound={findStatus[data?.candidateHiringStatus]}
                                     status={findStatus[data?.candidateHiringStatus]==="Selected"?findStatus[data?.candidateHiringStatus]:data?.round ? `Round ${data?.round}` : findStatus[data?.candidateHiringStatus]} />
                                
                            </div>
                            <div style={{ border: ".3px solid #CACACA", marginTop: 15 }}></div>
                            <div style={{ color: "#808080", fontSize: 14, fontWeight: 550, marginTop: 10 }}>
                                {
                                    data?.candidateHiringStatus === "Submitted" || data?.candidateHiringStatus === "submitted" ?
                                        <>
                                            {`Congratulations! You have successfully submitted your job application for the position `}<span>{data?.job} ({data?.joblocations[0]}). </span>
                                            <br></br>
                                            {`We appreciate your interest in pursuing a career with
                                    brigosha. We will carefully consider your application during the initial screening and will contact you if you are selected`}
                                        </>
                                        :
                                        data?.candidateHiringStatus === "Shortlisted" || data?.candidateHiringStatus === "Scheduled" ?
                                            <>
                                                {
                                                    `Congratulations on being shortlisted! Your skills and experience have captured our attention, and we’d like to invite you to the next stage of our evaluation process for the `}<span>{data?.job} role.</span>
                                                <br>
                                                </br>
                                                {`You’ll engage in a series of four rounds: Technical, Managerial, Interpersonal, and HR discussions.`}
                                                <br></br>
                                                {`Our recruitment team will be in touch with you shortly to arrange an interview.`}


                                            </>
                                            :

                                            data?.candidateHiringStatus === "Not Shortlisted" || data?.candidateHiringStatus === "Not Selected" ?
                                                <>
                                                    {
                                                        `Thank you for your interest in the `
                                                    }
                                                    <span>{data?.job} ({data?.joblocations[0]}) position.</span>
                                                    {`We’ve carefully reviewed all applicants and, although your qualifications are impressive, we’ve chosen a candidate whose background more closely fits the role. While this particular opportunity might not have been the perfect fit, we encourage you to stay connected with us and consider applying for future roles that match your qualifications and experience.`}
                                                </>
                                                :
                                                (data?.candidateHiringStatus === "Hold" || data?.candidateHiringStatus === "ProposedJd" || data?.candidateHiringStatus === "Re-Discussion"
                                                    || data?.candidateHiringStatus === "Re-Schedule" || data?.candidateHiringStatus === "Feedback-Pending") ?
                                                    <>
                                                        {` Best wishes as you advance through the hiring process. Your talents make you a valuable candidate!`}
                                                    </>
                                                    :
                                                    findStatus[data?.candidateHiringStatus] === "Selected" ?
                                                        <>
                                                            {`We are thrilled to inform you that you have been selected for the ${data?.job}
                                                    position at brigosha. Your impressive qualifications, experience, and enthusiasm stood out among an exceptional pool of candidates.`
                                                            }
                                                            <br></br>
                                                            {`
                                                             Your skills and expertise align perfectly with what we are seeking for this role, and we are confident that you will be a valuable addition
                                                             to our team.
                                                    `}
                                                            <br>
                                                            </br>
                                                            {
                                                                ` We invite you to celebrate this accomplishment and look forward to welcoming you aboard. Our team will be in touch shortly to discuss the next steps, 
                                                        including the details of your onboarding process.`
                                                            }
                                                            <br></br>
                                                            {`
                                                    Once again, congratulations on your well-deserved success! We are excited about the contributions you will bring to our organization.
                                                    `}
                                                        </>
                                                        : null
                                }
                            </div>
                        </div>
                        {
                            data?.testName && <div style={{ backgroundColor: "#FFFFFF", width: "100%", height: "fit-content", padding: 20, marginTop: 10 }}>
                                <div style={{ color: "#808080", fontSize: 12, fontWeight: 550 }}>Hackathon details</div>
                                <div style={{ display: "flex", width: "100%", marginTop: 10 }}>
                                    <div style={{ fontSize: 12, color: "#808080", fontWeight: 600, width: "10vw" }}>
                                        Name
                                        <br></br>
                                        <div style={{ fontSize: 12, color: "black", fontWeight: 400 }}>
                                            {data?.testName}
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "flex-start", paddingLeft: 130 }}>
                                        <div style={{ fontSize: 12, color: "#808080", fontWeight: 600, }}>
                                            Date
                                            <br></br>
                                            <div style={{ fontSize: 12, color: "black", fontWeight: 400 }}>
                                                {data?.testDate}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", width: "100%", marginTop: 10 }}>
                                    <div style={{ fontSize: 12, color: "#808080", fontWeight: 600, width: "10vw" }}>
                                        Start Time
                                        <br></br>
                                        <div style={{ fontSize: 12, color: "black", fontWeight: 400 }}>
                                            {
                                                (parseInt(data?.testStartTime.split(":")[0]) > 12)
                                                    ? `${parseInt(data?.testStartTime.split(":")[0]) - 12}:${data?.testStartTime.split(":")[1]} pm`
                                                    : (parseInt(data?.testStartTime.split(":")[0]) === 12)
                                                        ? `${parseInt(data?.testStartTime.split(":")[0])}:${data?.testStartTime.split(":")[1]} pm`
                                                        : `${data?.testStartTime} am`
                                            }
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "flex-start", paddingLeft: 130 }}>
                                        <div style={{ fontSize: 12, color: "#808080", fontWeight: 600, }}>
                                            End Time
                                            <br></br>
                                            <div style={{ fontSize: 12, color: "black", fontWeight: 400 }}>
                                                {
                                                     (parseInt(data?.testendTime.split(":")[0]) > 12)
                                                        ? `${parseInt(data?.testendTime.split(":")[0]) - 12}:${data?.testendTime.split(":")[1]} pm`
                                                        : (parseInt(data?.testendTime.split(":")[0]) === 12)
                                                            ? `${parseInt(data?.testendTime.split(":")[0])}:${data?.testendTime.split(":")[1]} pm`
                                                            : `${data?.testendTime} am`
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Button disabled={data?.submit} type="primary" style={{ marginTop: "2vh", borderRadius: 6, width: "100%" }}
                                    //  disabled={(data?.testStartTime <= moment().format('h:mm:ss') && data?.testendTime >= moment().format('h:mm:ss') && data?.testDate === moment().format('YYYY-MM-DD')) ? false : true}
                                    onClick={() => {
                                        let tt = localStorage.getItem("user");
                                        let loginToken = localStorage.getItem('loginToken')
                                        if (tt) {
                                            let userLoginToken = decryptData(loginToken, SECRET_KEY);
                                            // window.location.href = `https://hackathonnew.brigosha.com/AccessLoginLoading/:token=${userLoginToken}&sourceId=${data?.sourceId}`
                                            window.open(`https://hiringhackathon.brigosha.com/AccessLoginLoading/:token=${userLoginToken}&sourceId=${data?.sourceId}`, "_blank")
                                        }
                                    }}>
                                   {data?.submit ? "Test Submitted" : "Start test"} 
                                </Button>
                            </div>
                        }
                        {
                            data?.interview  ?
                                <>


                                    <div style={{ backgroundColor: "#FFFFFF", width: "100%", height: "fit-content", padding: 20, marginTop: 10 }}>
                                        <div style={{ color: "#808080", fontSize: 12, fontWeight: 550 }}>Round {data?.interviewRoundData?.[interviewRound]?.round} {data?.interviewRoundData?.[interviewRound]?.queue && `(${data?.interviewRoundData?.[interviewRound]?.roundType})`}</div>
                                        {
                                            data?.interviewRoundData?.[interviewRound]?.queue ?
                                                <>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                                        <img src={queueImg} />

                                                        <div style={{ fontSize: 16, fontWeight: 600, marginTop: 10 }}>
                                                            In the queue
                                                        </div>
                                                        <div style={{ fontSize: 14, marginTop: 10 }}>
                                                            Your interview is pending; stay tuned for updates.
                                                        </div>

                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div style={{ display: "flex", width: "100%", marginTop: 10 }}>
                                                        <div style={{ fontSize: 12, color: "#808080", fontWeight: 600, width: "10vw" }}>
                                                            Round
                                                            <br></br>
                                                            <div style={{ fontSize: 12, color: "black", fontWeight: 400 }}>
                                                                {data?.interviewRoundData?.[interviewRound]?.roundType}
                                                            </div>
                                                        </div>
                                                        <div style={{ display: "flex", justifyContent: "flex-start", paddingLeft: 130 }}>
                                                            <div style={{ fontSize: 12, color: "#808080", fontWeight: 600, }}>
                                                                Mode
                                                                <br></br>
                                                                <div style={{ fontSize: 12, color: "black", fontWeight: 400, minWidth: '120px' }}>
                                                                    {data?.interviewRoundData?.[interviewRound]?.interviewMode?data?.interviewRoundData?.[interviewRound]?.interviewMode:"NA"}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ display: "flex", width: "100%", marginTop: 10 }}>
                                                        <div style={{ fontSize: 12, color: "#808080", fontWeight: 600, width: "10vw" }}>
                                                            Date
                                                            <br></br>
                                                            <div style={{ fontSize: 12, color: "black", fontWeight: 400, minWidth: '120px' }}>
                                                                {data?.interviewRoundData?.[interviewRound]?.interviewDate?data?.interviewRoundData?.[interviewRound]?.interviewDate:"NA"}
                                                            </div>
                                                        </div>
                                                        <div style={{ display: "flex", justifyContent: "flex-start", paddingLeft: 130 }}>
                                                            <div style={{ fontSize: 12, color: "#808080", fontWeight: 600, }}>
                                                                Time
                                                                <br></br>
                                                                <div style={{ fontSize: 12, color: "black", fontWeight: 400, minWidth: '120px' }}>
                                                                    {data?.interviewRoundData?.[interviewRound]?.time?data?.interviewRoundData?.[interviewRound]?.time:"NA"}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ display: "flex", width: "100%", marginTop: 10 }}>
                                                        <div style={{ fontSize: 12, color: "#808080", fontWeight: 600, width: "10vw" }}>
                                                            Interviewer
                                                            <br></br>
                                                            <div style={{ fontSize: 12, color: "black", fontWeight: 400, minWidth: '120px' }}>
                                                                {data?.interviewRoundData?.[interviewRound]?.panelist?data?.interviewRoundData?.[interviewRound]?.panelist:"NA"}
                                                            </div>
                                                        </div>
                                                        <div style={{ display: "flex", justifyContent: "flex-start", paddingLeft: 130 }}>
                                                            <div style={{ fontSize: 12, color: "#808080", fontWeight: 600, }}>
                                                                Status
                                                                <br></br>
                                                                <div style={{ fontSize: 12, color: "black", fontWeight: 400, minWidth: '120px' }}>
                                                                    {
                                                                        (data?.round===interviewRound+1 &&(data?.candidateHiringStatus==="Re-Schedule"||data?.candidateHiringStatus==="Re-Discussion"))?`${data?.candidateHiringStatus}`:
                                                                        data?.interviewRoundData?.[interviewRound]?.interviewStatus==="Feedback-Pending"?"Pending":data?.interviewRoundData?.[interviewRound]?.interviewStatus
                                                                    }
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ marginTop: 10 }}>
                                                        <div style={{ fontSize: 12, color: "#808080", fontWeight: 600 }}>
                                                            Applied on
                                                            <br></br>
                                                            <div style={{ fontSize: 12, color: "black", fontWeight: 400 }}>
                                                                {data?.interviewRoundData?.[interviewRound]?.interviewDate?data?.interviewRoundData?.[interviewRound]?.interviewDate:"NA"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ marginTop: 10 }}>
                                                        <div style={{ fontSize: 12, color: "#808080", fontWeight: 600 }}>
                                                            Meet Link
                                                            <br></br>
                                                            <div style={{ fontSize: 12, color: "black", fontWeight: 400 }}>
                                                                {
                                                                    data?.interviewRoundData?.[interviewRound]?.meetlink?
                                                                    <>
                                                                    {
                                                                    
                                                                        data?.interviewRoundData?.[interviewRound]?.interviewStatus === "Scheduled" ?
                                                                            <a href={data?.interviewRoundData?.[interviewRound]?.meetlink}>{data?.interviewRoundData?.[interviewRound]?.meetlink}</a>
                                                                            :
                                                                            data?.interviewRoundData?.[interviewRound]?.meetlink
                                                                    }
                                                                    </>
                                                                    :
                                                                    "NA"
                                                                }
                                                                
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <>
                                                        <div style={{ border: ".3px solid #CACACA", marginTop: 15 }}></div>
                                                        <div style={{ backgroundColor: "#FFFFFF", width: "100%", height: "fit-content", marginTop: 10, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                            <div>
                                                                <div style={{ fontSize: 15, fontWeight: 600 }}>Re-schedule Interview</div>
                                                                {/* <div style={{ color: "#808080", fontSize: 12, fontWeight: 550 }}>Are you sure you want to re-schedule your interview?</div> */}
                                                            </div>
                                                            <div onClick={() => {
                                                                if (data?.interviewRoundData?.[interviewRound]?.interviewStatus === "Scheduled") {
                                                                    // setreschedule(true)
                                                                    setRescheduleConfirm(true);
                                                                }
                                                            }} 
                                                            style={{ color: data?.interviewRoundData?.[interviewRound]?.interviewStatus === "Scheduled" ? "#FC5C65" : "grey", fontWeight: 600, cursor: data?.interviewRoundData?.[interviewRound]?.interviewStatus === "Scheduled" ? "pointer" : "not-allowed", }}
                                                            >Re-schedule</div>
                                                        </div>

                                                    </>
                                                </>
                                        }

                                    </div>
                                    <div style={{ display: "flex", height: "5vh", width: "100%", justifyContent: "center", alignItems: "center" }}>
                                        {
                                        Array.isArray(data?.interviewRoundData) &&findStatus[data?.candidateHiringStatus] ==="Not Selected"? data.interviewRoundData.filter(item => item.queue === false).map((data, index) => (
                                                <>
                                                    {
                                                        index === interviewRound ?
                                                            <div style={{ padding: 5, cursor: "pointer" }} onClick={() => { setRound(index) }}>
                                                                <MdOutlineRadioButtonChecked style={{ height: 15, width: 15, color: "#1089FF" }} />
                                                            </div>
                                                            :
                                                            <div style={{ padding: 5, cursor: "pointer" }} onClick={() => { setRound(index) }}>
                                                                <MdOutlineRadioButtonUnchecked style={{ height: 15, width: 15, color: "#CACACA" }} />
                                                            </div>

                                                    }
                                                </>
                                            )):

                                            Array(data?.interviewRoundData?.length).fill().map((data, index) => (
                                                <>
                                                    {
                                                        index === interviewRound ?
                                                            <div style={{ padding: 5, cursor: "pointer" }} onClick={() => { setRound(index) }}>
                                                                <MdOutlineRadioButtonChecked style={{ height: 15, width: 15, color: "#1089FF" }} />
                                                            </div>
                                                            :
                                                            <div style={{ padding: 5, cursor: "pointer" }} onClick={() => { setRound(index) }}>
                                                                <MdOutlineRadioButtonUnchecked style={{ height: 15, width: 15, color: "#CACACA" }} />
                                                            </div>

                                                    }
                                                </>
                                            ))
                                        }
                                       {/*  {
                                            Array.isArray(data?.interviewRoundData) &&findStatus[data?.candidateHiringStatus] ==="Not Selected"? data.interviewRoundData.filter(item => item.queue === false).map((data, index) => (
                                                <>
                                                    {
                                                        index === interviewRound ?
                                                            <div style={{ padding: 5, cursor: "pointer" }} onClick={() => { setRound(index) }}>
                                                                <MdOutlineRadioButtonChecked style={{ height: 15, width: 15, color: "#1089FF" }} />
                                                            </div>
                                                            :
                                                            <div style={{ padding: 5, cursor: "pointer" }} onClick={() => { setRound(index) }}>
                                                                <MdOutlineRadioButtonUnchecked style={{ height: 15, width: 15, color: "#CACACA" }} />
                                                            </div>

                                                    }
                                                </>
                                            ))
                                        } */}
                                    </div>
                                </>
                                :
                                <></>
                        }


                        <div style={{ backgroundColor: "#FFFFFF", width: "100%", height: "fit-content", padding: 20, marginTop: 10 }}>
                            <div style={{ color: "#808080", fontSize: 12, fontWeight: 550 }}>About</div>
                            <div style={{ fontSize: 17, fontWeight: 600, marginTop: 10 }}>{basicData?.job}</div>
                            <JobDetails icon={timeIcon} text={data?.jobExperience} />
                            <JobDetails icon={clockSmall} text={"Full time"} />
                            <JobDetails icon={locationIcon} text={data?.joblocations?.join(", ")} />

                            <div style={{ backgroundColor: '#F9F9FC', width: "100%", height: "fit-content", padding: 20, marginTop: 10, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <img src={fileIcon} style={{ height: 25, width: 25 }} />
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <div style={{ fontSize: 12, fontWeight: 550, marginLeft: 10 }}>jd.pdf</div>
                                        <div style={{ fontSize: 12, fontWeight: 550, marginLeft: 10 }}>54KB</div>
                                    </div>
                                </div>
                                <div onClick={() => { setJd({ ref: true, file: data?.jobpdf }) }} style={{ color: "#1089FF", fontWeight: 600, cursor: "pointer" }}>View</div>
                            </div>
                        </div>
                        <div style={{ backgroundColor: "#FFFFFF", width: "100%", height: "fit-content", padding: 20, marginTop: 10 }}>
                            <div style={{ color: "#808080", fontSize: 12, fontWeight: 550 }}>Your application</div>
                            <div style={{ display: "flex", width: "100%", marginTop: 10, justifyContent: 'space-between' }}>
                                <div style={{ fontSize: 12, color: "#808080", fontWeight: 600, width: "10vw" }}>
                                    Name
                                    <br></br>
                                    <Typography.Text ellipsis={{ tooltip: true }} style={{ fontSize: 12, color: "black", fontWeight: 400, maxWidth: '120px' }}>
                                        {data?.candidateName}
                                    </Typography.Text>
                                </div>
                                <div style={{ display: "flex", justifyContent: "flex-start", }}>
                                    <div style={{ fontSize: 12, color: "#808080", fontWeight: 600, }}>
                                        Email ID
                                        <br></br>
                                        <Typography.Text ellipsis={{ tooltip: true }} style={{ fontSize: 12, color: "black", fontWeight: 400, maxWidth: '120px' }}>
                                            {data?.candidateEmail}
                                        </Typography.Text>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex", width: "100%", marginTop: 10, justifyContent: 'space-between' }}>
                                <div style={{ fontSize: 12, color: "#808080", fontWeight: 600, width: "100px" }}>
                                    Mobile number
                                    <br></br>
                                    <div style={{ fontSize: 12, color: "black", fontWeight: 400 }}>
                                        {data?.candidatePhone}
                                    </div>
                                </div>
                                <div style={{ display: "flex", justifyContent: "flex-start", }}>
                                    <div style={{ fontSize: 12, color: "#808080", fontWeight: 600, }}>
                                        Applied on
                                        <br></br>
                                        <div style={{ fontSize: 12, color: "black", fontWeight: 400, width: '120px' }}>
                                            {moment(data?.appliedon).format("YYYY-MM-DD")}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style={{ color: "#808080", fontSize: 12, fontWeight: 550, marginTop: 10 }}>Resume</div>
                            <div style={{ backgroundColor: '#F9F9FC', width: "100%", height: "fit-content", padding: 20, marginTop: 10, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <img src={fileIcon} style={{ height: 25, width: 25 }} />
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <div style={{ fontSize: 12, fontWeight: 550, marginLeft: 10 }}>Resume.pdf</div>
                                        <div style={{ fontSize: 12, fontWeight: 550, marginLeft: 10 }}>54KB</div>
                                    </div>
                                </div>
                                <div onClick={() => { setJd({ ref: true, file: data?.candiidateResume }) }} style={{ color: "#1089FF", fontWeight: 600, cursor: "pointer" }}>View</div>
                            </div>
                            <div onClick={() => {
                                setOnGoToProfile(true)
                            }} style={{ display: "flex", width: "100%", justifyContent: "center", color: "#1089FF", fontSize: 13, fontWeight: 600, marginTop: 20, cursor: "pointer" }}>Go to application</div>
                        </div>
                        {/*  <div style={{ backgroundColor: "#FFFFFF", width: "100%", height: "fit-content", padding: 20, marginTop: 10, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div>
                    <div style={{ fontSize: 15, fontWeight: 600 }}>Update your profile</div>
                    <div style={{ color: "#808080", fontSize: 12, fontWeight: 550 }}>Take a moment to update your profile with more information.</div>
                </div>
                <div onClick={() => { }} style={{ color: "#1089FF", fontWeight: 600, cursor: "pointer" }}>Go to Profile</div>
            </div> */}

           {
           findStatus[data?.candidateHiringStatus] === "Selected" || findStatus[data?.candidateHiringStatus] === "Submitted" || findStatus[data?.candidateHiringStatus] === "Shortlisted" || findStatus[data?.candidateHiringStatus] === "In-progress" || findStatus[data?.candidateHiringStatus] === "Re-Discussion"?
             <div style={{ backgroundColor: "#FFFFFF", width: "100%", height: "fit-content", padding: 20, marginTop: 10, display: "flex", justifyContent: "space-between",  }}>
                                        <div>
                                            <div style={{color:'#161616',fontWeight:600}} className={classes.header3}>Connect with our team</div>
                                            <div className={classes.title3} style={{ color: "#808080", margin:'4px 0px'}}>Need assistance or have something on your mind?</div>
                                            <div>
                                                <div className={classes.header3} style={{color:'#161616'}}>Mail us at:<span style={{color:'#1089FF',marginLeft:'6px'}}>{data?.jobspocmail}</span></div>
                                                <div className={classes.header3} style={{color:'#161616',marginTop:'3px'}}>Call us at:<span style={{color:'#1089FF',marginLeft:'6px'}}>{data?.jobspocphone}</span></div>
                                            </div>
                                        </div>
                                        <div onClick={() => { setContactUs(true) }} style={{ color: "#1089FF", fontWeight: 600, cursor: "pointer" }}>Contact us</div>
            </div>:''}
                        {
                            findStatus[data?.candidateHiringStatus] === "Selected" || findStatus[data?.candidateHiringStatus] === "Not Shortlisted"||findStatus[data?.candidateHiringStatus] === "Not Selected" || findStatus[data?.candidateHiringStatus] === "Withdrawn" ?
                                <></>
                                :
                                <>
                                    <div style={{ backgroundColor: "#FFFFFF", width: "100%", height: "fit-content", padding: 20, marginTop: 10, display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                                        <div>
                                            <div style={{ fontSize: 15, fontWeight: 600 }}>Withdraw application</div>
                                            {/* <div style={{ color: "#808080", fontSize: 12, fontWeight: 550 }}>Are you sure you want to withdraw your job application?</div> */}
                                        </div>
                                        <div onClick={() => { setWidthdraw(true) }} style={{ color: "#FC5C65", fontWeight: 600, cursor: "pointer" }}>Withdraw</div>
                                    </div>
                                </>
                        }

                    </>
            }

            {
                jd.file &&
                <Modal open={jd.file} footer={null} onCancel={() => { setJd({ ref: false, file: null }) }} centered>

                    <div className='jdPdf' style={{ margin: '1.5rem auto 1rem auto', minHeight: '300px', maxHeight: '80vh' }}>
                        <Document file={jd?.file} onLoadSuccess={onDocumentLoadSuccess} width="100%" height="100%" >
                            {Array.from(new Array(numPages), (el, index) => (
                                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                            ))}
                        </Document>
                    </div>
                    {/* <iframe
                        title="File Viewer"
                        style={{ width: '100%', height: '500px', marginTop: 20 }}
                        src={jd?.file}
                    ></iframe> */}
                </Modal>
            }
            {
                rescheduleConfirm &&
                <Modal open={rescheduleConfirm}

                    width={width > 700 ? '50vw' : '100vw'}
                    footer={<div style={{ display: "flex", width: "100%", gap: "0.5rem", justifyContent: "center", alignItems: "center" }}>
                        <Button onClick={() => { setRescheduleConfirm(false) }} style={{ width: "6rem", height: "2.1rem" }}
                        >No</Button>
                        <Button type='primary' style={{ width: "6rem", height: "2.1rem" }}
                            onClick={() => { setreschedule(true); setRescheduleConfirm(false);
                                Api.get(`/hiring-mobile/rescheduledSlotDetails?candidateId=${data?.sourceId}`)
                                .send((res)=>{
                                 if(res !== undefined)
                                     // console.log("ssssResponse",res);
                                     if(res?.rescheduledSlot !== null && res?.rescheduledSlot !== undefined){
                                         // setSelectedSlot((prevList) => [...prevList, res?.rescheduledSlot]);
                                         setSelectedSlotsList((prevList) => {
                                             const updatedList = [...prevList];
                                         
                                             res?.rescheduledSlot.forEach((slot) => {
                                                 const existingIndex = updatedList.findIndex((item) => item.date === slot?.date);
                                         
                                                 const newSelectedSlot = [{
                                                     startTime: slot?.time.split('-')[0],
                                                     endTime: slot?.time.split('-')[1],
                                                 }];
                                         
                                                //  if (existingIndex !== -1) {
                                                //      updatedList[existingIndex].selectedSlot = newSelectedSlot;
                                                //  } else {
                                                     updatedList.push({ date: slot?.date, selectedSlot: newSelectedSlot });
                                                //  }
                                             });
                                             return updatedList
                                         });
                                         
                                     }
                                }) }}>Yes</Button>
                    </div>}

                    onCancel={() => { setRescheduleConfirm(false) }}
                    centered

                >
                    <div style={{ fontSize: 16, fontWeight: 550, display: "flex", flexDirection: "column", alignItems: "center", gap: "0.5rem", padding: "2rem", width: "100%", justifyContent: "center" }}>
                        <img src={warningIcon} alt="" style={{ height: "2.5rem", width: "2.5rem" }}

                        />
                        <div>Are you sure you want to re-schedule your interview?</div>
                    </div>
                </Modal>
            }
            {
                reschedule &&
                <Modal open={reschedule} footer={null} onCancel={() => { setreschedule(false); setDateVal(null);
                    setSelectedSlot([]);setSelectedSlotsList([]) }} centered style={{ width: "90vw" }}>
                    <div style={{ marginTop: 15, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: "100%" }}>
                        <div style={{ fontSize: 18, fontWeight: 600 }}>Re-schedule</div>
                        <div style={{ marginTop: 5, fontSize: 12, textAlign: "center" }}>If this time no longer works, feel free to reschedule your interview. You can choose up to three time slots.</div>
                        <div style={{ display: "flex", marginTop: 15, alignItems: "center", justifyContent: "flex-start", flexDirection: "column", width: "100%", overflowY: "auto", height: "60vh" }}>
                            <div style={{ font: 12, color: "#808080", textAlign: "start", width: "100%" }}>
                                Date
                            </div>
                            <DatePicker allowClear={false} size={width < 700 && "large"} style={{ width: "100%", fontSize: width < 700 && 17, marginBottom: "0.5rem" }}
                                value={dateVal ? dayjs(dateVal, "YYYY-MM-DD") : null }
                                disabledDate={(current) => {
                                    return current && current < dayjs().startOf('day');
                                }}
                               onChange={(date, dateString) => {
                                // if (dateVal.includes(dateString)) {
                                //     setDateVal((prevList) => prevList.filter((selected) => selected !== dateString));
                                //     setSlotState((prevSchedule) => prevSchedule.date.filter((selected) => selected !== dateString));
                                // } else {
                                        // setDateVal((prevList) => [...prevList, dateString]);
                                        console.log("sssDateString",dateString)
                                if(dateString){
                                        setDateVal(dateString)
                                        setSlotState((prevSchedule) => ({ ...prevSchedule, date: dateVal }));
                                // }
                                let jobInterviewRoundId = data?.jobRounds && data?.round ? data.jobRounds.filter(item => item.round === data.round) : [];

                                if (jobInterviewRoundId.length > 0) {
                                Api.get(`/hiring-poc/panelistSlot?jobInterviewRoundId=${jobInterviewRoundId[0]?.jobInterviewRoundId}&date=${dateString}`)
                                   .send((res) => {
                                        if (res !== undefined) {
                                            // console.log("Response", res);
                                            setSlotValues(res)
                                           
                                        }
                                    });
                                }}
                               }} 
                              />
                            {dateVal ? 
                            <div className={classes.Slots}>
                                {slotValues?.map((slot,index)=>{
                                    const isSelected =
                                    selectedSlotsList?.some(
                                        (selected) =>
                                            selected.date === dateVal &&
                                            selected.selectedSlot.some(
                                                (selectedSlot) =>
                                                    selectedSlot.startTime === slot.startTime &&
                                                    selectedSlot.endTime === slot.endTime
                                            )
                                    );                 
                                    console.log("ssssfSelectSlots",selectedSlotsList,isSelected)       
                                    return(
                                        <div key={index} style={{cursor:isSelected ? "not-allowed" :"pointer",backgroundColor: isSelected || selectedSlot.includes(slot)? "#EAF4FF" : "#F4F4F4",
                                        border: (isSelected || selectedSlot.includes(slot)) && "1px solid #1089FF",}} 
                                        onClick={()=>{
                                            if (selectedSlot.includes(slot)) {
                                                setSelectedSlot((prevList) => prevList.filter((selected) => selected !== slot));
                                              } else {
                                                if (selectedSlot.length >= 3) {
                                                    const newSelectedSlots = selectedSlot.length > 0 ? selectedSlot.slice(1) : selectedSlotsList[0].selectedSlot.slice(1); 
                                                    setSelectedSlot([...newSelectedSlots, slot]);
                                                } else {
                                                    setSelectedSlot((prevList) => [...prevList, slot]);
                                                }
                                            }
                                            
                                        }} >{slot?.startTime} - {slot?.endTime}</div>
                                    )
                                })}
                               
                            </div>
                            :
                            <div style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",width:"100%",borderBottom:"1px solid #e3e3e3"}}>
                                <img src={SelectDateIcon} alt="" />
                                <h4 style={{font:"normal normal 600 19px/24px Figtree"}}>Select a date</h4>
                                <p style={{margin:"0.2rem 0 0.4rem 0"}}>Select a date to show available time slots.</p>
                            </div>
                            }
                            <Button disabled={selectedSlot.length === 0 || selectedSlot.length >3 ? true:false} type='primary' style={{width:"100%",height:"2.2rem"}} onClick={()=>{
                                 if (selectedSlot.length > 0) {
                                    //   setSelectedSlotsList((prevList) => [...prevList, selectedSlot]);
                                    
                                      setSelectedSlotsList((prevList) => {
                                        const existingIndex = prevList.findIndex((item) => item.date === dateVal);
                                    
                                        if (existingIndex !== -1 && selectedSlotsList.length === 0) {
                                            const updatedList = [...prevList];
                                            updatedList[existingIndex].selectedSlot = selectedSlot;
                                            return updatedList;
                                        } else if(selectedSlot.length > 1){
                                            const updatedList = [...prevList];
                                            selectedSlot.forEach((slot) => {  
                                                const newSelectedSlot = [{
                                                    startTime: slot?.startTime,
                                                    endTime: slot?.endTime,
                                                }];                                      
                                                updatedList.push({ date: dateVal, selectedSlot: newSelectedSlot });
                                            });
                                            if (updatedList.length >= 3) {
                                                return updatedList.slice(-3);
                                            }  else {
                                                return updatedList;
                                            }
                                        } else if (prevList.length >= 3) {
                                            return [...prevList.slice(1), { date: dateVal, selectedSlot }];
                                        } else {
                                                return [...prevList, { date: dateVal, selectedSlot }];
                                        }
                                    });
                                    
                                    setDateVal(null);
                                    setSelectedSlot([]);
                                  }
                            }}>Add slot</Button>
                            {/* {console.log("ssssFinalValue",selectedSlot,dateVal,selectedSlotsList,selectedSlotsList.length)} */}
                            <div className={classes.Cards}>
                                <div style={{display:"flex",flexDirection:"column",fontSize:13}}>
                                   <CloseOutlined style={{display:"flex",justifyContent:"flex-end",width:"100%",marginTop:"-0.4rem",marginRight:"0.7rem"}}
                                   onClick={()=>{
                                    setSelectedSlotsList((prevList) => [...prevList.slice(1)]);
                                    }}/>
                                    {selectedSlotsList[0] && (<p style={{color:"#161616"}}>{selectedSlotsList[0]?.date}</p>)}                                    
                                    <p >{selectedSlotsList[0] ?`${selectedSlotsList[0]?.selectedSlot[0]?.startTime} - ${selectedSlotsList[0]?.selectedSlot[0]?.endTime}`: "Slot 1"}</p>
                                </div>
                                <div style={{display:"flex",flexDirection:"column",fontSize:13}}>
                                    <CloseOutlined style={{display:"flex",justifyContent:"flex-end",width:"100%",marginTop:"-0.4rem",marginRight:"0.7rem"}}
                                    onClick={()=>{
                                        setSelectedSlotsList((prevList) => prevList.filter((item, index) => index !== 1));
                                        // setSelectedSlotsList((prevList) => {
                                        //     const newList = [...prevList];
                                        //     newList.splice(2, 1); 
                                        //     return newList;
                                        //   });
                                    }}/>                                    
                                    {selectedSlotsList[1] && (<p style={{color:"#161616"}}>{selectedSlotsList[1]?.date}</p>)}                                    
                                    <p >{selectedSlotsList[1] ?`${selectedSlotsList[1]?.selectedSlot[0]?.startTime} - ${selectedSlotsList[1]?.selectedSlot[0]?.endTime}`: "Slot 2"}</p>
                                </div>
                                <div style={{display:"flex",flexDirection:"column",fontSize:13}}>
                                    <CloseOutlined style={{display:"flex",justifyContent:"flex-end",width:"100%",marginTop:"-0.4rem",marginRight:"0.7rem"}}
                                    onClick={()=>{
                                        setSelectedSlotsList((prevList) => prevList.filter((_, index) => index !== 2));
                                    }}/>                                    
                                    {selectedSlotsList[2] && (<p style={{color:"#161616"}}>{selectedSlotsList[2]?.date}</p>)}                                    
                                    <p >{selectedSlotsList[2] ?`${selectedSlotsList[2]?.selectedSlot[0]?.startTime} - ${selectedSlotsList[2]?.selectedSlot[0]?.endTime}`: "Slot 3"}</p>
                                </div>
                            </div>
                            <div style={{ font: 12, color: "#808080", textAlign: "start", width: "100%", marginTop: "0.6rem" }}>
                               <span style={{color:"red"}}>*</span> Reason
                            </div>
                            <Input onChange={(e) => { setReason(e.target.value) }} size='large' style={{ height: "10rem",marginBottom:"1rem",marginTop:"0.2rem" }} placeholder='Let us know the reason for the re-schedule'>
                            </Input>
                            {/* <div style={{ display: "flex", color: "#808080", fontSize: 12 }}>
                                Our recruitment team will be in touch with you shortly to arrange a new interview.
                            </div> */}
                        </div>
                        <ButtonCompo style={{ marginTop: 15, height: "5vh", width: "10rem", backgroundColor: "#1089FF", }} disabled={reason === ''} title={"Submit"} height 
                        onClick={() => { resheduleByCandidate(data,selectedSlotsList,dateVal, reason, () => { setreschedule(false); onBackClick() }) }}></ButtonCompo>
                        {/* <ModalPrimaryButton onClick={()=>{resheduleByCandidate(data, reason)}} style={{marginTop:15, height:"5vh", width:"10vw"}}>Submit</ModalPrimaryButton> */}
                    </div>
                </Modal>
            }
            {

                widtdraw &&
                <Modal
                    open={widtdraw}
                    footer={null}
                    onCancel={() => { setWidthdraw(false) }}
                    centered
                >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: 19, fontWeight: 600, marginTop: 10 }}>
                        Confirm
                    </div>
                    <div style={{ color: "#808080", fontSize: 14, fontWeight: 550, textAlign: "center", width: "100%", marginTop: 10 }}>Are you sure you want to withdraw your job application?</div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 20, flexDirection: width > 700 ? 'row' : 'column', gap: '8px' }}>
                        <ResetButton
                            style={{ height: 32, borderRadius: 3, backgroundColor: "#FFFFFF", color: "#FC5C65", border: "0.5px solid #CACACA", width: "180px", minwidth: width < 700 ? "40vw" : "15vw" }}
                            onClick={() => {
                                Api.patch(`/hiring-mobile/withdrawApplication?sourceId=${basicData?.sourceId}`)
                                    .send((res, error) => {
                                        res && setWidthdraw(false); onBackClick()
                                    });
                            }}
                            title="Yes, withdraw"
                        />
                        <ResetButton
                            style={{ height: 32, borderRadius: 3, backgroundColor: "#1089FF", color: "#FFFFFF", marginLeft: 10 }}
                            onClick={() => {
                                setWidthdraw(false)
                            }}
                            title="Cancel"
                        />
                    </div>
                </Modal>
            }
            {contactUs && <ModalCompo 
                        
                        open={contactUs}
                        closableRight={true}
                        onCancel={()=>{setContactUs(false)}}
                        className={classes.contactUsModal}
                        >
                        <div style={{width:'100%'}}>
                           
                           <div style={{display:'flex',justifyContent:'center'}}>
                           <div style={{marginTop:'-1rem'}} className={classes.header1}>Contact us</div>
                           </div>

                            <Form 
                             layout='vertical'
                            className={classes.modalConatiner}
                            onFinish={(val)=>{

                              console.log('Submitted by kaushal',val)  
                              Api.post('/hiring-mobile/contactUs/').params({title:val?.title,message:val?.message,candidateId:data?.sourceId,jobId:data?.jobId}).send((res)=>{
                                console.log("jai mahakal",res?.show?.type);
                                if(res?.show?.type==="success"){
                                    setContactUs(false);

                                }
                              })
                            }}
                            >
                                <Form.Item label="Title" name='title' rules={[{required:true,message:'please enter title',whitespace:true}]}>
                                    <Input placeholder='Enter title here'></Input>
                                </Form.Item>
                                <Form.Item label="Message" name='message' rules={[{required:true,message:'please enter message',whitespace:true}]}>
                                    <Input.TextArea placeholder='Write a message for us...' rows={4}>

                                    </Input.TextArea>
                                </Form.Item>
                                <Form.Item style={{display:'flex',justifyContent:'center'}}>
                                    <ButtonCompo title={"Submit"} style={{color:'#fff',backgroundColor:'#1089ff',maxWidth:'240px'}} />
                                </Form.Item>
                            </Form>
                        </div>

                        </ModalCompo>}
        </>
    )
}
const statusMap={
    "Scheduled":true,
    "In Progress":true,
    "In-progress":true,
    "Hold":true,
    "ProposedJd":true,
    "Re-Discussion":true,
    "Re-Schedule":true,
    "Feedback-Pending":true,
    "Cancelled":true,
}
const statusMap1={
  
    "In Progress":true,
    "In-progress":true,
  
}

const HiringProgress = ({ list, status, roundDetails, width ,rejRound}) => {
    const selector = list.indexOf(status);
    const selector2= list.indexOf(rejRound);
    console.log(list,status,roundDetails,rejRound,"helaflnjdfsbdudb");
    return (
        <>
            <div style={{ display: "flex", width: "100%", alignItems: "center"}}>
                {
                    list?.map((item, index) => (
                        <>
                            {
                                index !== 0 &&
                                <div style={{ display: "flex", height: 1, border: `0.5px solid ${list?.length <= 2 ? "red" : index <= selector ? "#26DE81" :rejRound==="Not Selected"&&index > selector?"red": "#CACACA"}`, width:width<700?`${100/1.2}%`: list?.length <= 2 ? `${100 / 1.1}%` : `${100 / 2}%` }}></div>
                            }
                            <div style={{ display: "flex", flexDirection: "column", position: "relative" }}>
                                {
                                    index <= selector?
                                        <img src={(status===item&&roundDetails==="Completed"&&statusMap1[rejRound])?tickGreen:(status===item&&roundDetails==="Skipped")?inProgressGrey:item==="Hold"?inProgress:rejRound==="Not Selected"&&index >=selector?cancel:(index <= selector && (item?.charAt(0) === "R"&& item===status)&& statusMap[rejRound])?inProgress:index === 0 ? tickGreen : status === "Not Shortlisted" || status === "Withdrawn"||status === "Not Selected"  ? cancel : tickGreen} style={{ height: 15, width: 15 }}></img>
                                        :
                                        rejRound==="Not Selected"&&index > selector?
                                        <img src={cancel} style={{ height: 15, width: 15 }}></img>
                                        :
                                        <div style={{ height: 15, width: 15, borderRadius: 8, border: "0.3px solid #CACACA" }}>
                                        </div>
                                }
                                <div style={{ display: "flex", position: "absolute", marginTop: 15, fontSize:width<700?8: 11, color: "#808080", marginLeft: -10, width:width<700?"15vw": "5vw" }}>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        {item}
                                    </div>
                                </div>
                            </div>
                        </>
                    ))
                }
            </div>
        </>
    )
}


const HiringStatus = ({ reference = '', onBackClickProfile = () => { } }) => {
    const { hiringStatusUpdater, hiringstatus,updateRender,updateDeatails, updateBasicDeatails, setActiveTab } = useLoginStore(state => state);
    const [hiringData, setHiringData] = useState();
    const [loader, setloader] = useState(false);
    const { width } = useWindowDimensions();
    const user = decryptData(localStorage.getItem('user'), SECRET_KEY);

    const onClinkHandler = (val) => {

        updateBasicDeatails(val)
        hiringStatusUpdater(2, () => {
            getDetails(val?.sourceId)
        }, "hiring")
    }
    const getHiringData = () => {
        setloader(true)
        Api.get(`/hiring-mobile/appliedJobs?email=${user?.email}&phone=${user?.phone}`)
            .send((res, error) => {
                if (res) {
                    setHiringData(res)
                    setloader(false)
                }
            });
    }
    const getDetails = (sourceId) => {
        setloader(true)
        Api.get(`/hiring-mobile/appliedJobData?sourceId=${sourceId}`)
            .send((res, error) => {
                if (res) {
                    updateDeatails(res)
                    setloader(false)
                }
            });
    }

    const onBackClick = (ref = null) => {
        if (hiringstatus?.ref === "hiring") {
            hiringStatusUpdater(1, () => { })
        } else {
            setActiveTab(1)
            hiringStatusUpdater(1, () => { })

        }
    }


    useEffect(() => {
        console.log(hiringstatus, "hiringstatushiringstatus");
        hiringstatus?.render === 1 ?
            getHiringData()
            :
            hiringstatus?.updater()
    }, [hiringstatus?.render])

    useEffect(() => {
        return (() => {
            hiringstatus?.ref === "hiring" &&
                hiringStatusUpdater(1, () => { })
        })
    }, [])

    return (
        <>
            {
                loader ?
                    <div style={{ display: "flex", height: "100vh", justifyContent: "center", alignItems: "center" }}>
                        <Spin></Spin>
                    </div>
                    :
                    <div style={{ display: "flex", justifyContent: "center", backgroundColor: "#F4F4F4", height: "90vh", alignItems: "flex-start", flexWrap: "wrap", overflow: "scroll", padding: 10 }}>
                        <div style={{ width: width < 700 ? "100%" : "45%" }}>
                            {

                                hiringstatus?.render === 1 ?
                                    <>
                                        {
                                            reference === "profile" ?
                                                <>
                                                    <div style={{ display: "flex", alignItems: "center", cursor: "pointer", marginTop: 10 }}>
                                                        <div onClick={() => {
                                                            onBackClickProfile()//add ref 
                                                        }}>
                                                            <img src={back_icon} style={{ height: 15, width: 15, marginTop: 5 }} />

                                                        </div>
                                                        <div style={{ marginLeft: 5 }}>Back</div>
                                                    </div>
                                                    <div style={{ fontWeight: 700, fontSize: 19, paddingTop: 15 }}>Applied jobs</div>
                                                </>
                                                :
                                                <>
                                                    <div style={{ fontWeight: 700, fontSize: 19, paddingTop: 15 }}>Hiring status</div>
                                                </>
                                        }
                                        <div style={{ width: "100%", display: "flex", height: "80%", overflow: "scroll", flexWrap: "wrap", marginTop: 10 }}>
                                            {hiringData?.length ?
                                                hiringData?.map((item) => (
                                                    <>
                                                        <StatusCards item={item} onClinkHandler={(item?.status!=="Old"&&item?.status!=="Not Interested")?onClinkHandler:()=>{}} />
                                                    </>
                                                ))
                                                :
                                                <>
                                                    {/* <NoData /> */}
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: "100%", backgroundColor: "#FFFFFF", padding: 30, height: "80vh" }}>
                                                        <img src={queueImg} />

                                                        <div style={{ fontSize: 18, fontWeight: 600, marginTop: 10 }}>
                                                            Looks like you haven’t applied any jobs yet
                                                        </div>
                                                        <div style={{ fontSize: 12, marginTop: 10 }}>
                                                            Start exploring and apply jobs you’re interested in.
                                                        </div>
                                                        <div onClick={() => { setActiveTab(3) }} style={{ display: 'flex', alignItems: "center", justifyContent: "center", width: "10rem", height: "5vh", color: "#FFFFFF", backgroundColor: "#1089FF", borderRadius: 5, fontSize: 14, marginTop: 30, cursor: "pointer" }}>
                                                            Job search
                                                        </div>
                                                    </div>
                                                </>

                                            }
                                        </div>

                                    </>
                                    :
                                    <>
                                        <div style={{ width: "100%", display: "flex", height: "90vh", overflowY: "scroll", flexWrap: "wrap" }}>
                                            <Hiringdetails onBackClick={onBackClick} data={hiringstatus?.details} basicData={hiringstatus?.basicDetails} />
                                        </div>
                                    </>
                            }
                        </div>

                   
                    </div>
            }

        </>
    )
}

export default HiringStatus;