import { Spin, Progress, Tooltip } from 'antd';
import classes from './Project.module.css';
import moment from 'moment';
import useApiStore from '../../../services/store';
import viewClass from '../../../services/ViewRole';
import { useEffect, useState } from 'react';
import editIcon from '../../../assets/editicon.svg'
import deleteIcon from '../../../assets/deleteiconWB.svg'
import transferIcon1 from '../../../assets/transferIcon1.svg'
import logefforticon from "../../../assets/logefforticon.svg"
import { NoData } from '../../Training_essentialDocuments';
import { useWindowDimensions } from '../../../components/windowDimention/windowDimension';
import { TbTopologyStar3 } from "react-icons/tb";


function TaskCards({ data, onEdit, onDelete, OnTransfer, onLogChange }) {
    const { loader } = useApiStore(state => state);
    const { width } = useWindowDimensions();
    const [items, setItem] = useState([
        {
            key: "edit",
            label: <div style={{ cursor: 'pointer' }}
            > Edit</div>
        },
        {
            key: "delete",
            label: <div style={{ cursor: 'pointer' }}
            > Delete</div>
        },
        {
            key: "transfer",
            label: <div style={{ cursor: 'pointer' }}
            > Transfer</div>
        },
        data?.logEffort &&
        {
            key: "logEffort",
            label: <div style={{ cursor: 'pointer' }}
            > Log effort</div>
        },

    ])
    const DropdownHandler = (key, val) => {
        console.log(key, val, "valkey");
        if (key?.key === "edit") {
            onEdit(val);
        }
        else if (key?.key === "delete") {
            console.log(val)
            onDelete({ id: val?.id, name: val?.name });
        }
        else if (key?.key === "transfer") {
            OnTransfer({ id: val?.id, name: val?.name });
        }
        else if (key?.key === "logEffort") {
            onLogChange({ id: val?.id, name: val?.name, task: val })
        }
    }



    return (
        <>
            {loader ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}><Spin /></div> : (data?.length === 0 || data === undefined) ?
                <NoData />
                :
                <>
                    {
                        width < 700 ?
                            <>
                                <div style={{ width: "100%", padding: 10 }}>

                                    {data?.map((val) => {
                                        console.log(val?.isManager, "val?.isManager");
                                        return (
                                            <div style={{ height: 200, boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px", padding: 10, height: "fit-content" }}>
                                                <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", width: "100%" }}>
                                                    <div className={classes.projectHeader}>{val?.name}</div>
                                                    <div style={{ display: "flex", justifyContent: "space-between", width: "17%" }}>
                                                        {
                                                            (viewClass.manager) ?
                                                                val?.isManager
                                                                &&
                                                                <>
                                                                    <div style={{ cursor: "pointer" }} onClick={(e) => { onEdit(val); }}>
                                                                        <img src={editIcon} style={{ height: 25, width: 25 }} />
                                                                    </div>
                                                                    <div style={{ cursor: "pointer" }} onClick={(e) => { OnTransfer({ id: val?.id, name: val?.name }); }}>
                                                                        <img src={transferIcon1} style={{ height: 25, width: 25 }} />
                                                                    </div>
                                                                    <div style={{ cursor: "pointer" }} onClick={(e) => { onDelete({ id: val?.id, name: val?.name }) }}>
                                                                        <img src={deleteIcon} style={{ height: 25, width: 25 }} />
                                                                    </div>
                                                                </>
                                                                : null
                                                        }
                                                        <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
                                                            {
                                                                ((!viewClass.manager&&!viewClass.superAdmin&&!viewClass.hod&&val?.webAccess)) &&
                                                                <Tooltip title='Log Effort'>

                                                                    <div style={{ cursor: "pointer" }} onClick={(e) => { onLogChange({ id: val?.id, name: val?.name, task: val }) }}>
                                                                        <img src={logefforticon} style={{ height: 25, width: 25 }} />
                                                                    </div>
                                                                </Tooltip>
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ display: "flex", width: "50%" }}>
                                                    <Progress color={'green'} percent={val?.progress ? val?.progress : 0} showInfo={true} />
                                                </div>
                                                <div style={{ display: "flex" }}>
                                                    <div style={{ fontWeight: "600", fontSize: "0.9rem" }}><span className={val?.status} style={{ height: 30, width: 30, marginRight: 10 }}>•</span>{val?.status}</div>
                                                    <div style={{ width: 2, height: "100%", backgroundColor: "#ABABAB" }}></div>
                                                    <div className={classes.title2} style={{ marginLeft: 10, fontWeight: "500" }}>Effort : </div>
                                                    <div className={classes.taskTitle} style={{ marginLeft: 5 }}>{val?.totalTask ? val?.totalTask : `${val?.actualEffort ? val?.actualEffort : 0} / ${val?.plannedEffort ? val?.plannedEffort : 0}`}</div>
                                                </div>


                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", marginLeft: 10, padding: 5 }}>
                                                    <div className={classes.title1}>Associate:</div>
                                                    <div className={classes.title2} style={{ backgroundColor: "#0086FF26", height: 25, width: 150, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 15, color: "#0086FF", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                                        <span style={{ width: 90, overflow: "hidden" }} title={val?.associate}>{val?.associate}</span>
                                                    </div>
                                                </div>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", marginLeft: 10, padding: 5 }}>
                                                    <div className={classes.title1}>Allocation:</div>
                                                    <div className={classes.title2} style={{ backgroundColor: "#B9796426", height: 25, width: 150, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 15, color: "#B97964", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}> <span style={{ width: 90, overflow: "hidden" }} title={val?.allocation}>{val?.allocation}</span></div>
                                                </div>


                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", marginLeft: 10, padding: 5 }}>
                                                    <div className={classes.title1}>CreatedBy:</div>
                                                    <div className={classes.title2} style={{ backgroundColor: "#39CA6C26", height: 25, width: 150, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 15, color: "#39CA6C", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}><span style={{ width: 90, overflow: "hidden" }} title={val?.createdBy}>{val?.createdBy}</span> </div>
                                                </div>

                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", marginLeft: 10, padding: 5 }}>
                                                    <div className={classes.title1}>Started On:</div>
                                                    <div className={classes.title2} style={{ backgroundColor: "#FF7B7B26", height: 25, width: 150, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 15, color: "#FF7B7B", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}> <span style={{ width: 100, overflow: "hidden" }} title={moment(val?.startDate).format("DD-MMM-YYYY")}>{moment(val?.startDate).format("DD-MMM-YYYY")}</span></div>
                                                </div>



                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", marginLeft: 10 }}>
                                                    <div className={classes.title1}>Due On:</div>
                                                    <div className={classes.title2} style={{ backgroundColor: "#39CA6C26", height: 25, width: 150, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 15, color: "#39CA6C", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}><span style={{ width: 100, overflow: "hidden" }} title={moment(val?.dueDate).format("DD-MMM-YYYY")}>{moment(val?.dueDate).format("DD-MMM-YYYY")}</span> </div>
                                                </div>

                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", marginRight: 10 }}>
                                                    <div className={classes.title1}></div>
                                                    <div className={classes.title2} style={{ height: 25, width: 150, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 15, color: "#FF7B7B", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}> <span style={{ width: 90, overflow: "hidden" }} ></span></div>
                                                </div>


                                            </div>
                                            /*   <div  className={classes.newProjectCard}>
                                            <div>
                                            <div className={classes.projectHeader}>{val?.name}</div>
                                            <div className={classes.title1}>Associate: <span className={classes.title2}>{val?.associate}</span></div>
                                            <div className={classes.title1}>Allocation: <span className={classes.title2}>{val?.allocation} allocated</span></div>
                                            <div className={classes.title1}>CreatedBy: <span className={classes.title2}>{val?.createdBy}</span></div>
                                            <div className={classes.title1}>Started On: <span className={classes.title2}>{moment(val?.startDate).format("DD-MMM-YYYY")}</span></div>
                                            <div className={classes.title1}>Due On: <span className={classes.title2}>{moment(val?.dueDate).format("DD-MMM-YYYY")}</span></div>
                                            </div>
                                            <div>
                                            <div>
                                            <div style={{display:'flex',alignItems:'center'}}>
                                              <div className={val?.status}>{val?.status}</div>
                                            </div>
                                            <CircleProgressBar strokeWidth={5} sqSize={60} percentage={data?.progress?data?.progress:0}  />
                                            <div className={classes.title2} style={{marginBottom:'-10px'}}>Effort</div>
                                            <div className={classes.taskTitle}>{val?.totalTask?val?.totalTask:`${val?.actualEffort?val?.actualEffort:0} / ${val?.plannedEffort?val?.plannedEffort:0}`}</div>
                                            </div>
                                            <div>
                                              {
                                                  (viewClass.manager||data?.logEffort)?
                                                  <Dropdown trigger="hover" menu={{items,onClick:(key)=>{DropdownHandler(key,val)}}}><div ><MoreOutlined  style={{fontSize:'20px',fontWeight:'600'}}/></div></Dropdown>
                                                  :null
                                              }
                                            </div>
                                            </div>
                                            </div>  */
                                        )
                                    })}
                                </div>
                            </>
                            :
                            <>

                                <div className={classes.projectCardContainer}>

                                    {data?.map((val) => {
                                        console.log((viewClass.manager && (val?.isManager)), val?.isManager, "(val?.isManager")
                                        return (
                                            <div style={{ height: 200, boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px", padding: 10, height: "fit-content" }}>
                                                <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", width: "100%" }}>
                                                    <div className={classes.projectHeader}>{val?.name}</div>
                                                    <div style={{ display: "flex", justifyContent: "space-between", width: "20%" }}>
                                                        {
                                                            (viewClass.manager && (val?.isManager)) &&
                                                            <>
                                                                <div style={{ cursor: "pointer" }} onClick={(e) => { onEdit(val); }}>
                                                                    <img src={editIcon} style={{ height: 25, width: 25 }} />
                                                                </div>
                                                                <div style={{ cursor: "pointer" }} onClick={(e) => { OnTransfer({ id: val?.id, name: val?.name }); }}>
                                                                    <img src={transferIcon1} style={{ height: 25, width: 25 }} />
                                                                </div>
                                                                <div style={{ cursor: "pointer" }} onClick={(e) => { onDelete({ id: val?.id, name: val?.name }) }}>
                                                                    <img src={deleteIcon} style={{ height: 25, width: 25 }} />
                                                                </div>
                                                            </>
                                                        }
                                                        <div style={!(viewClass.manager && (val?.isManager))?{ display: "flex", width: "100%", justifyContent: "flex-end" }:{}}>
                                                            {
                                                              ((!viewClass.manager&&!viewClass.superAdmin&&!viewClass.hod&&val?.logEffort)) &&
                                                                <Tooltip title='Log Effort'>

                                                                    <div style={{ cursor: "pointer" }} onClick={(e) => { onLogChange({ id: val?.id, name: val?.name, task: val }) }}>
                                                                        <img src={logefforticon} style={{ height: 25, width: 25 }} />
                                                                    </div>
                                                                </Tooltip>
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ display: "flex", width: "50%" }}>
                                                    <Progress color={'green'} percent={val?.progress ? val?.progress : 0} showInfo={true} />
                                                </div>
                                                <div style={{ display: "flex" }}>
                                                    <div style={{ fontWeight: "600", fontSize: "0.9rem" }}><span className={val?.status} style={{ height: 30, width: 30, marginRight: 10 }}>•</span>{val?.status}</div>
                                                    <div style={{ width: 2, height: "100%", backgroundColor: "#ABABAB" }}></div>
                                                    <div className={classes.title2} style={{ marginLeft: 10, fontWeight: "500" }}>Effort : </div>

                                                    <div className={classes.taskTitle} style={{ marginLeft: 5 }}>{val?.totalTask ? val?.totalTask : `${val?.actualEffort ? val?.actualEffort : 0} / ${val?.plannedEffort ? val?.plannedEffort : 0}`}</div>

                                                </div>
                                                <div style={{ display: "flex", width: "100%", justifyContent: "space-between", marginTop: 20 }}>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "45%", marginLeft: 10 }}>
                                                        <div className={classes.title1} style={{ marginRight: 5 }} >Associate:</div>
                                                        <div className={classes.title2} style={{ backgroundColor: "#0086FF26", height: 25, width: 150, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 15, color: "#0086FF", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                                            <Tooltip title={val?.associate}>
                                                                <span style={{ width: 90, overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }} >{val?.associate}</span>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "45%", marginRight: 10 }}>
                                                        <div className={classes.title1} style={{ marginRight: 5 }}>Allocation:</div>
                                                        <div className={classes.title2} style={{ backgroundColor: "#B9796426", height: 25, width: 150, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 15, color: "#B97964", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                                            <Tooltip title={val?.allocation}>
                                                                <span style={{ width: 90, overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }} >{val?.allocation}</span>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ display: "flex", width: "100%", justifyContent: "space-between", marginTop: 20 }}>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "45%", marginLeft: 10 }}>
                                                        <div className={classes.title1} style={{ marginRight: 5 }}>CreatedBy:</div>
                                                        <div className={classes.title2} style={{ backgroundColor: "#39CA6C26", height: 25, width: 150, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 15, color: "#39CA6C", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                                            <Tooltip title={val?.createdBy}>
                                                                <span style={{ width: 90, overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }} >{val?.createdBy}</span>
                                                            </Tooltip>

                                                        </div>
                                                    </div>

                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "45%", marginRight: 10 }}>
                                                        <div className={classes.title1} style={{ marginRight: 5 }}>Started On:</div>
                                                        <div className={classes.title2} style={{ backgroundColor: "#FF7B7B26", height: 25, width: 150, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 15, color: "#FF7B7B", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                                            <Tooltip title={moment(val?.startDate).format("DD-MMM-YYYY")}>
                                                                <span style={{ width: 100, overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }} >{moment(val?.startDate).format("DD-MMM-YYYY")}</span>
                                                            </Tooltip>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div style={{ display: "flex", width: "100%", justifyContent: "space-between", marginTop: 20 }}>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "45%", marginLeft: 10 }}>
                                                        <div className={classes.title1} style={{ marginRight: 5 }}>Due On:</div>
                                                        <div className={classes.title2} style={{ backgroundColor: "#39CA6C26", height: 25, width: 150, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 15, color: "#39CA6C", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                                            <Tooltip title={moment(val?.dueDate).format("DD-MMM-YYYY")}>
                                                                <span style={{ width: 100, overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }} >{moment(val?.dueDate).format("DD-MMM-YYYY")}</span>
                                                            </Tooltip>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "37%", marginRight: 10 }}>
                                                        <div className={classes.title1} style={{ marginRight: 5 }}></div>
                                                        <div className={classes.title2} style={{ height: 25, width: 150, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 15, color: "#FF7B7B", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}> <span style={{ width: 90, overflow: "hidden" }} ></span></div>
                                                    </div>

                                                </div>
                                            </div>
                                            /*   <div  className={classes.newProjectCard}>
                                            <div>
                                            <div className={classes.projectHeader}>{val?.name}</div>
                                            <div className={classes.title1}>Associate: <span className={classes.title2}>{val?.associate}</span></div>
                                            <div className={classes.title1}>Allocation: <span className={classes.title2}>{val?.allocation} allocated</span></div>
                                            <div className={classes.title1}>CreatedBy: <span className={classes.title2}>{val?.createdBy}</span></div>
                                            <div className={classes.title1}>Started On: <span className={classes.title2}>{moment(val?.startDate).format("DD-MMM-YYYY")}</span></div>
                                            <div className={classes.title1}>Due On: <span className={classes.title2}>{moment(val?.dueDate).format("DD-MMM-YYYY")}</span></div>
                                            </div>
                                            <div>
                                            <div>
                                            <div style={{display:'flex',alignItems:'center'}}>
                                              <div className={val?.status}>{val?.status}</div>
                                            </div>
                                            <CircleProgressBar strokeWidth={5} sqSize={60} percentage={data?.progress?data?.progress:0}  />
                                            <div className={classes.title2} style={{marginBottom:'-10px'}}>Effort</div>
                                            <div className={classes.taskTitle}>{val?.totalTask?val?.totalTask:`${val?.actualEffort?val?.actualEffort:0} / ${val?.plannedEffort?val?.plannedEffort:0}`}</div>
                                            </div>
                                            <div>
                                              {
                                                  (viewClass.manager||data?.logEffort)?
                                                  <Dropdown trigger="hover" menu={{items,onClick:(key)=>{DropdownHandler(key,val)}}}><div ><MoreOutlined  style={{fontSize:'20px',fontWeight:'600'}}/></div></Dropdown>
                                                  :null
                                              }
                                            </div>
                                            </div>
                                            </div>  */
                                        )
                                    })}
                                </div>
                            </>
                    }
                </>
            }
        </>
    )
}
export default TaskCards;

// <div className={classes.projectCardMain}>
// <div>
//   <div style={{display:'flex',justifyContent:'flex-end',width:'94%',marginTop:'-5%'}}>
//   {viewClass.associate?'':<Dropdown trigger="hover" menu={{items,onClick:(key)=>{console.log("clicked",val);DropdownHandler(key,val)}}}><div className={classes.projectDropdown}>...</div></Dropdown>}
//    </div>
// <div className={classes.projectCard} style={{cursor:'default'}}>
//     <div>
//     <CircleProgressBar strokeWidth={5} sqSize={60} percentage={data?.progress?data?.progress:0}  />
//         <div>
//             {/* <div>...</div> */}
//             {/* <div></div> */}
//             {/* <div>{val?.status}</div> */}
//         </div>
//     </div>
//     <div>
//         <div>{val?.name}</div>
//         <div>Effort</div>
//     </div>
//     <div>
//         <div>{val?.associate}</div>
//         <div>{val?.plannedEffort}/{val?.actualEffort?val?.actualEffort:0}</div>

//         {/* <div></div> */}
//     </div>
//     <div>
//         <div>{parseInt(val?.allocation)} % Allocated</div>
//     </div>
//     <div>
//         <div>CreatedBy: {val?.createdBy}</div>
//         {/* <div>{val?.dept}</div> */}
//     </div>
//     <div>
//         <div style={{color:'#162432'}}>Started On</div>
//         <div style={{color:'#162432'}}>Due On</div>
//     </div>
//     <div>
//         <div>{moment(val?.startDate).format("DD-MMM-YYYY")}</div>
//         <div>{moment(val?.dueDate).format("DD-MMM-YYYY")}</div>
//     </div>

// </div>
// </div>
// </div>