import { useState, useEffect } from "react";
import housePic from "../../assets/notification_another_icon.svg";
import { Button, Spin, Modal } from "antd";
import { MoreOutlined } from "@ant-design/icons"
import { getNotification, request } from "./action";
import AllRequest from "./AllRequest";
import { useNotificationStore } from "./store";
import ApproveModal from "./ApproveModal";
import RejectModal from "./RejectModal";
import { decryptData, SECRET_KEY } from "../../services/fileCode";
import classes from './NotificationRequest.module.css';
import { useWindowDimensions } from "../../components/windowDimention/windowDimension";
import { useNavigate } from "react-router";
import AllNotificationModal from "./AllNotificationModal";
import moment from "moment";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";

function NotificationRequest({ setModal }) {
    const { approveModal, approveAllModal, rejectModal, rejectAllModal, approveModalHandler, rejectModalHandler, setId, user, setSelUser } = useNotificationStore();
    const [notification, setNotification] = useState(true)
    const [isHovered, setIsHovered] = useState(false);
    const [refresh, setrefresh] = useState(true);
    const [loader, setLoader] = useState(true);
    const [getRequests, setgetRequest] = useState(null);
    const [filteredRequests, setFilteredRequest] = useState([]);
    const [centerModel, setCenterModel] = useState(false);
    const [allNotModal, setAllNotModal] = useState(false);
    const [loadingButton, setloadingButton] = useState(false);
    const [itemId, setitemId] = useState('')
    const handleMouseEnter = () => {
        setIsHovered(true);
    };
    const [getNotifications, setgetNotification] = useState([])
    const [userData, setUserData] = useState({})
    const { width } = useWindowDimensions();
    const navigate = useNavigate();
    const [clickednotification, setclickedNotification] = useState()

    useEffect(() => {
        let token = localStorage.getItem('user');
        if (token) {
            let decryptedData = decryptData(token, SECRET_KEY);
            if (decryptedData) {
                setUserData(decryptedData);
            } else {
                console.error("Decryption failed. Invalid token or secret key.");
            }
        }
    }, [localStorage.getItem('user')]);

    useEffect(() => {
        setrefresh(true)
        let param = { userId: user?.resourceUser?.id, page: 1, limit: 20 }
        if (user?.resourceUser?.id) {
            getNotification(param, (res) => {
                setrefresh(false)
                setgetNotification(res?.allNotifications)
            })
        }
    }, [user?.resourceUser?.id])

    const getRequest = () => {
        setLoader(true)
        request(null, '', '', '', '', (res) => {
            setLoader(false)
            setgetRequest(res)
        }, user?.resourceUser?.id)
    }

    useEffect(() => {
        if (user?.resourceUser?.id) {
            getRequest();
        }
    }, [user?.resourceUser?.id])

    useEffect(() => {
        if (getRequests !== undefined && getRequests !== null) {
            let filteredData = getRequests.allRequests.filter(item => item.status !== "Rejected");
            setFilteredRequest(filteredData)
            // console.log("sssssREquesttttsfiet",getRequests.allRequests,filteredData)
        }
    }, [getRequests])


    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    return (
        <>
            {!allNotModal && !centerModel && <div className={classes.notification}>
                <div>
                    {width < 800 && <svg
                        onClick={() => {
                            navigate(-1);
                        }}
                        xmlns="http://www.w3.org/2000/svg" width="11" height="18" viewBox="0 0 11 18" fill="none">
                        <path d="M9 2L2.35355 8.64645C2.15829 8.84171 2.15829 9.15829 2.35355 9.35355L9 16" stroke="#162432" stroke-width="3" stroke-linecap="round" />
                    </svg>}
                    <h3>Notification</h3>
                </div>

                <div className={classes.tab}>
                    <span className={notification ? classes.activeTab : ""} onClick={() => { setNotification(true) }} > Updates</span>
                    <span className={!notification ? classes.activeTab : ""} onClick={() => { setNotification(false) }} >Request</span>
                </div>



                {
                    notification ?
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
                            {
                                refresh ?
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'white', padding: '20%' }}>

                                        <Spin />

                                    </div> :
                                    <div className={classes.notificationTabDetails}>
                                        {getNotifications.length > 0 ? getNotifications.map((item, index) => (
                                            // <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <>
                                            <div key={index} style={{ display: 'flex', flexDirection: 'column', }}>
                                                <div style={{display:"flex", justifyContent:"space-between"}}>
                                                <div key={index} style={{ display: 'flex', justifyContent: 'flex-start', }}>
                                                    <img style={{ width: '2rem', height: '2rem' }} src={housePic} />
                                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="27" height="31" viewBox="0 0 27 31" fill="none">
                                                    <path d="M17.5191 27.2316C17.062 28.9664 15.4318 30.2402 13.5002 30.2402C11.5686 30.2402 9.93837 28.9664 9.48121 27.2316H17.5191ZM24.4038 21.2144H23.8846V12.1887C23.8846 6.66293 19.222 2.16016 13.5 2.16016C7.77802 2.16016 3.11538 6.66293 3.11538 12.1887V21.2144H2.59615C1.1632 21.2144 0 22.3378 0 23.7216C0 24.2832 0.19726 24.8146 0.519231 25.2259H26.4808C26.8027 24.8147 27 24.2832 27 23.7216C27 22.3378 25.8368 21.2144 24.4038 21.2144Z" fill="#162432"/>
                                                    <circle cx="21.0603" cy="3.78" r="3.78" fill="#FF5555"/>
                                                    </svg> */}
                                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', marginLeft: 20 }}>
                                                        <div style={{ fontSize: '0.9rem', color: 'black', fontWeight: "600" }}>{item.title}</div>
                                                        <div style={{ fontSize: '13px', marginTop: 10 }}>{item.message}</div>
                                                        {
                                                            (item.info?.comments && clickednotification === index) &&
                                                            <>
                                                                {
                                                                    item.info?.comments?.commentApproved1 &&
                                                                    <div style={{ fontSize: '13px', marginTop: 10, fontWeight: 600, color: "green" }}>
                                                                        {
                                                                            `Approver 1 (${item?.info?.approvers?.approverOneName}) commented on ${item.info?.comments?.commentApproved1Date} : '${item.info?.comments?.commentApproved1}'`
                                                                        }
                                                                    </div>

                                                                }
                                                                {
                                                                    item.info?.comments?.commentRejected1 &&
                                                                    <div style={{ fontSize: '13px', marginTop: 10, fontWeight: 600, color: "red" }}>
                                                                        {
                                                                            `Approver 1 (${item?.info?.approvers?.approverOneName}) commented on ${item.info?.comments?.commentRejected1Date} : '${item.info?.comments?.commentRejected1}'`
                                                                        }
                                                                    </div>
                                                                }
                                                                {
                                                                    item.info?.comments?.commentApproved2 &&
                                                                    <div style={{ fontSize: '13px', marginTop: 10, fontWeight: 600, color: "green" }}>
                                                                        {
                                                                            `Approver 2 (${item?.info?.approvers?.approverTwoName}) commented on ${item.info?.comments?.commentApproved2Date} : '${item.info?.comments?.commentApproved2}'`
                                                                        }
                                                                    </div>

                                                                }
                                                                {
                                                                    item.info?.comments?.commentRejected2 &&
                                                                    <div style={{ fontSize: '13px', marginTop: 10, fontWeight: 600, color: "red" }}>
                                                                        {
                                                                            `Approver 2  (${item?.info?.approvers?.approverTwoName}) commented on ${item.info?.comments?.commentRejected2Date} : '${item.info?.comments?.commentRejected2}'`
                                                                        }
                                                                    </div>

                                                                }
                                                                {
                                                                    item.info?.comments?.commentCancelled1 &&
                                                                    <div style={{ fontSize: '13px', marginTop: 10, fontWeight: 600, color: item?.status === 'Accepted' ? "green" : "red" }}>
                                                                        {
                                                                            `Approver 1  (${item?.info?.approvers?.approverOneName}) commented for cancellation request on ${item.info?.comments?.commentCancelled1Date} : '${item.info?.comments?.commentCancelled1}'`
                                                                        }
                                                                    </div>

                                                                }



                                                                {/*  <div style={{ fontSize: '13px', marginTop: 10, fontWeight: 600 }}>
                                                                    {item.info?.comments?.commentRejected1 ? `Approver 1 comment : ${item.info?.comments?.commentRejected1}`
                                                                    : item.info?.comments?.commentApproved1 ? `Approver 1 comment : ${item.info?.comments?.commentApproved1}` : null}</div>
                                                                <div style={{ fontSize: '13px', marginTop: 10, fontWeight: 600 }}>{item.info?.comments?.commentRejected2 ? `Approver 2 comment : ${item.info?.comments?.commentRejected2}`
                                                                    : item.info?.comments?.commentApproved2 ? `Approver 2 comment : ${item.info?.comments?.commentApproved2}` : null}</div>
                                                                    <div style={{ fontSize: '13px', marginTop: 10,fontWeight:600 }}>{item.info?.comments?.commentCancelled1 ? `Approver 1 comment : ${item.info?.comments?.commentCancelled1}` 
                                                                : item.info?.comments?.commentCancelled1 ? `Approver 1 comment : ${item.info?.comments?.commentCancelled1}` : null}</div> */}
                                                            </>
                                                        }


                                                    </div>
                                                </div>
                                               {
                                                item.info?.comments &&
                                                 <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginRight: 10 }}>
                                                 {
                                                     clickednotification === index ?
                                                         <FaAngleUp style={{ height: 20, width: 20, cursor: "pointer" }} onClick={() => {
                                                             setclickedNotification(-1)
                                                         }} />

                                                         :
                                                         <FaAngleDown style={{ height: 20, width: 20, cursor: "pointer" }} onClick={() => {
                                                             setclickedNotification(index)
                                                         }} />

                                                 }

                                             </div>
                                               }
                                                </div>
                                                <div style={{ height: 1, backgroundColor: '#F1F1F1 ', marginTop: 10 }}></div>
                                            </div>
                                              
                                               </>


                                            // </div>
                                        )) :

                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 30 }}>
                                                <h4>No notification created</h4>
                                            </div>}
                                    </div>
                            }
                            {notification &&
                                <div className={classes.seeRequest} onClick={() => {
                                    console.log('kaushal clcikeddddd')
                                    setAllNotModal(true)
                                }}>
                                    See all updates
                                </div>}
                        </div> :
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
                            {
                                loader ?
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'white', padding: '20%' }}>
                                        <Spin />
                                    </div> :
                                    <div className={classes.requestInfoTab}>

                                        {
                                            filteredRequests?.allRequests?.length === 0 &&
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 30 }}>
                                                <h4>No pending Request is found!</h4>
                                            </div>
                                        }
                                        <div style={{ display: "flex", flexDirection: "column", gap: "1rem", paddingRight: "0.5rem", }}>
                                            {filteredRequests?.map((item, index) => {
                                                let targetDate = null;
                                                if (item?.status === "Accepted" && (item?.title === "Privilege request" || item?.title === "Sick request"
                                                    || item?.title === "Casual request" || item?.title === "Wedding request" || item?.title === "Parental request" || item?.title === "Lop request")
                                                ) {
                                                    if (item.info.startDate) {
                                                        targetDate = moment(item.info.startDate).format("YYYY-MM-DD");
                                                    } else if (item.info.date) {
                                                        targetDate = moment(item.info.date).format("YYYY-MM-DD");
                                                    } else if (item.info.manualPunchDate) {
                                                        targetDate = moment(item.info.manualPunchDate).format("YYYY-MM-DD");
                                                    }
                                                }
                                                const today = moment();
                                                const approver2 = item?.info.approvers !== undefined && Object.keys(item?.info.approvers).length > 1 && user?.resourceUser?.id === item?.info.approvers.approverTwo ? true : false
                                                const differenceInDays = targetDate !== null ? moment(targetDate).diff(today, 'days') : null;
                                                const isWithinLast3Days = targetDate !== null && differenceInDays !== null && differenceInDays >= 0 && moment(targetDate).isSameOrAfter(today, 'day');
                                                // console.log("sssssDate",item?.info.approvers,item?.info.approvers !== undefined && item?.info.approvers.approverTwo);
                                                return (
                                                    ((item?.status === "Accepted" && (item?.title === "Privilege request" || item?.title === "Sick request" || item?.title === "Casual request"
                                                        || item?.title === "Wedding request" || item?.title === "Parental request" || item?.title === "Lop request") && item?.old === false && item?.showReject === true) || item?.status === "Pending") &&
                                                    <div userId={index} className={classes.eachRequest}>
                                                        <div style={{ display: 'flex', flexDirection: "row", minWidth: "65%", }}>
                                                            <div style={{ minWidth: "1.5rem" }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                    <path d="M20 8.33333V20H0V8.33333H20ZM20 6.66667V4.16667C20 3.50363 19.7366 2.86774 19.2678 2.3989C18.7989 1.93006 18.163 1.66667 17.5 1.66667H15V0H13.3333V1.66667H6.66667V0H5V1.66667H2.5C1.83696 1.66667 1.20107 1.93006 0.732233 2.3989C0.263392 2.86774 0 3.50363 0 4.16667L0 6.66667H20ZM15 11.6667H13.3333V13.3333H15V11.6667ZM10.8333 11.6667H9.16667V13.3333H10.8333V11.6667ZM6.66667 11.6667H5V13.3333H6.66667V11.6667ZM15 15H13.3333V16.6667H15V15ZM10.8333 15H9.16667V16.6667H10.8333V15ZM6.66667 15H5V16.6667H6.66667V15Z" fill="black" />
                                                                </svg>
                                                            </div>
                                                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, gap: "0.5rem" }}>
                                                                <div style={{ fontSize: '0.9rem', color: 'black', fontWeight: "600" }}>{item?.title}</div>
                                                                <div
                                                                    style={{ fontWeight: "500", fontSize: "0.85rem" }}
                                                                >
                                                                    {item?.message}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {item?.status === "Pending" ?
                                                            <div style={{ display: 'flex', justifyContent: 'right', alignItems: "center", width: "100%" }}>
                                                                <Button
                                                                    onClick={() => {

                                                                        setSelUser(item)
                                                                        approveModalHandler(true);
                                                                        setloadingButton(true)
                                                                        setitemId(item?.id)
                                                                        setId(item?.notificationId)
                                                                        const manualPunch = {
                                                                            id: item?.id,
                                                                            status: 'Approved'
                                                                        }
                                                                    }}
                                                                    type="text"
                                                                    style={{
                                                                        background: "#0086ff",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        borderRadius: "5px",
                                                                        width: "8rem",
                                                                        height: "2.2rem"
                                                                    }}
                                                                >
                                                                    <div style={{ color: 'white' }}>Approve</div>
                                                                </Button>
                                                                <Button
                                                                    onClick={() => {
                                                                        setitemId(item?.id)
                                                                        setSelUser(item)
                                                                        setId(item?.notificationId)
                                                                        rejectModalHandler(true);
                                                                        setloadingButton(true)
                                                                        const manualPunch = {
                                                                            id: item?.id,
                                                                            status: 'Rejected'
                                                                        }
                                                                    }}
                                                                    type="text"
                                                                    style={{
                                                                        marginLeft: 10,
                                                                        background: "#fff",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        borderRadius: "5px",
                                                                        border: "1px solid #0086ff",
                                                                        width: "8rem",
                                                                        height: "2.2rem"

                                                                    }}
                                                                >
                                                                    <div style={{ color: "#0086ff", }}>Reject</div>
                                                                </Button>
                                                            </div>
                                                            : item?.status === "Accepted" && (item?.title === "Privilege request" || item?.title === "Sick request" || item?.title === "Casual request"
                                                                || item?.title === "Wedding request" || item?.title === "Parental request" || item?.title === "Lop request") && item?.old === false && item?.showReject === true ?
                                                                <div style={{ display: 'flex', justifyContent: 'right', alignItems: "center", width: "100%" }}>
                                                                    <Button
                                                                        onClick={() => {
                                                                        }}
                                                                        type="text"
                                                                        style={{
                                                                            background: "#26DE81",
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            borderRadius: "5px",
                                                                            width: "8rem",
                                                                            height: "2.2rem",
                                                                            cursor: "not-allowed"
                                                                        }}
                                                                    >
                                                                        <div style={{ color: 'white' }}>
                                                                            {
                                                                                item?.status === "Accepted" && item?.autoapprove ?
                                                                                    "Auto-Approved"
                                                                                    :
                                                                                    "Approved"
                                                                            }
                                                                        </div>
                                                                    </Button>
                                                                    <Button
                                                                        onClick={() => {
                                                                            setitemId(item?.id)
                                                                            setSelUser(item)
                                                                            setId(item?.notificationId)
                                                                            rejectModalHandler(true);
                                                                            setloadingButton(true)
                                                                            const manualPunch = {
                                                                                id: item?.id,
                                                                                status: 'Rejected'
                                                                            }
                                                                        }}
                                                                        type="text"
                                                                        style={{
                                                                            marginLeft: 10,
                                                                            background: "#fff",
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            borderRadius: "5px",
                                                                            border: "1px solid #0086ff",
                                                                            width: "8rem",
                                                                            height: "2.2rem"
                                                                        }}
                                                                    >
                                                                        <div style={{ color: "#0086ff", }}>Reject</div>
                                                                    </Button>
                                                                </div>
                                                                : ""
                                                        }
                                                        {/* <div style={{ height: 1, backgroundColor: '#F1F1F1 ', marginTop: 10 }}></div> */}
                                                    </div>
                                                )
                                            }
                                            )}
                                        </div>
                                    </div>
                            }
                            {!notification &&
                                <div className={classes.seeRequest} onClick={() => {
                                    setCenterModel(true)
                                }}>
                                    See all request
                                </div>}
                        </div>
                }

            </div>}
            {centerModel && <AllRequest setCenterModel={setCenterModel}></AllRequest>}
            {allNotModal && <AllNotificationModal setAllNotModal={setAllNotModal} />}
            {approveModal && <ApproveModal getRequest={getRequest.bind(this)} />}
            {rejectModal && <RejectModal getRequest={getRequest.bind(this)} />}
        </>


    );
}

export default NotificationRequest;