import { ModalPrimaryButton, ModalSecondaryButton } from "../../../../components/Button/Button";
import ModalCompo from "../../../../design/ModalCompo";
import DeleteIcon from "../../../../assets/DeleteIcon.svg";
import cancelIcon from '../../../../assets/RejectIcon.svg';
import classes from './employee.module.css'
import { deleteAssociateApi, lastDateChangeApi, rejecteDepartmentApi } from "./employeeAction";
import viewClass from "../../../../services/ViewRole";
import { useEffect, useState } from "react";
import Notification from "../../../../services/Notification";
import moment from "moment";
import DatePickerCompo from "../../../../components/DatePicker/DatePickerCompo.tsx";
function DeleteEmployeeModal({modal,setModal,refresh}){
const [lastDate,setLastDate]=useState();
const [releaseDate,setReleaseDate]=useState();
useEffect(()=>{
    viewClass.updateView();
    if(modal?.type==='delete'){
        setLastDate(modal?.releaseDate);
        setReleaseDate(modal?.releaseDate);
    }
},[])
   const DeleteHandler=()=>{

    if(modal?.type==='delete'){
    let param={userId:modal?.id,lastWorkingDate:lastDate}
    console.log('new release',releaseDate,'old release',modal?.releaseDate,releaseDate===modal?.releaseDate)
    if(lastDate){
        if(releaseDate && releaseDate!==modal?.releaseDate){
            let param={userId:modal?.id,lastDate:releaseDate}
            lastDateChangeApi(param,()=>{
     
            })
        }
    deleteAssociateApi(param,(res)=>{
        if(res){
          setModal();
          refresh();
        }
    })}
   
    else{
        Notification.error('Error','Please give last date')
    }
    
   
}
    else {
        let param={userId:modal?.id}
        rejecteDepartmentApi(param,(res)=>{
            if(res){
                setModal();
                refresh() ;
            }
        })}
   }
    return(
        <>
        <ModalCompo open={modal!==undefined} 
        header={<div style={{display:'flex',justifyContent:'center',flexDirection:"row",width:"100%",gap:'8px',alignItems:'center'}}>
        <img src={modal?.type==='delete'?DeleteIcon:cancelIcon} style={{height:'36px',width:'36px'}} />
        <div style={{color:'#F96363',font:'normal 600 20px/36px Poppins'}}>{modal?.type==='delete'?'Delete Profile':'Cancel Request'}</div>
        </div>}
        className={classes.DeleteModal}
        closableRight={true} onCancel={()=>{setModal();}}>

        <div style={{display:'flex',justifyContent:'center',alignItems:'center',gap:'1rem',flexDirection:'column',width:"100%",height:'100%'}}>
            
            <div style={{border:'1px solid #F1F1F1',width:'100%'}}></div>
           {modal?.type==='delete' &&
            <>
                <div style={{display:'flex',gap:'8px',alignItems:'center'}}>
                    <div style={{font:'normal 500 16px/20px Lato',color:'#162432'}} >Department release date:</div>
                    <DatePickerCompo  
                    
                    
                     value={releaseDate ? moment(releaseDate) : null}
                     disabledDate={(current) => {
                        return (current && current >= moment().endOf('day'));
                        }}
                     onChange={(date,dateString)=>{
                      if(dateString){
                            setReleaseDate(dateString);
                            if(moment(dateString).isAfter(lastDate,'day')){
                                setLastDate();
                            }
                      }
                        
                        }} 
                     allowClear={false}
                     />
                </div>
                <div style={{display:'flex',gap:'8px',alignItems:'center'}}>
                    <div style={{font:'normal 500 16px/20px Lato',color:'#162432'}} >Please Select last working day:</div>
                    <DatePickerCompo  
                     value={lastDate ? moment(lastDate) : null}
                     disabledDate={(current) => {
                        let dd=releaseDate?releaseDate:modal?.joined
                        return (current >= moment().endOf('day') || current<moment(dd));
                        }}
                     onChange={(date,dateString)=>{setLastDate(dateString)}} />
                </div>
            </>
            }
            <div style={{font:'normal 500 17px/36px Poppins',color:'#162432'}}>Are you sure to {modal?.type==='delete'?'delete':'cancel'} <span style={{color:'#0086FF'}}>"{modal?.name}"</span> {modal?.type==='delete'?'Profile ?':'department allocation request'}</div>
            <div style={{display:'flex',gap:'1rem',justifyContent:'center',flexDirection:"row",width:"100%",marginTop:'1rem',marginBottom:'1rem'}}>
            <ModalSecondaryButton style={{backgroundColor:"white",height:"2.3rem",width:"6rem"}} onCancel={()=>{setModal()}}>NO</ModalSecondaryButton>
            <ModalPrimaryButton disabled={viewClass.hrAssociate} style={{height:"2.3rem",width:"6rem",}} onSave={()=>{
               
                DeleteHandler();
                }}>YES</ModalPrimaryButton>
            </div>
        </div>
        
        </ModalCompo>
        </>
    )       
}
export default DeleteEmployeeModal;