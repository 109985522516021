import { useState } from "react";
import { useEffect } from "react";
import classes from './jobSearch.module.css';
import { ApplyJobApi, UpdateJobApi, UploadFile, getJobDetailsApi, getProfileDetailsApi } from "./Action";
import JobSuccessPopup from "./JobSuccessPopup";
import Notification from "../../../services/Notification";
import LocIcon from '../../../assets/LocIcon.svg';
import uploadIcon from '../../../assets/UploadResume.svg';
import {  Form, Input,  Row,  Select, Spin, Typography, Upload, message } from "antd";
import { ModalPrimaryButton } from "../../../components/Button/Button";
import backIcon from '../../../assets/backIcon.svg';
import ButtonCompo from "../../../design/ButonCompo";
import { Option } from "antd/es/mentions";
import useLoginStorageStore from "../loginStorageStore";
import { ProfileStore } from "../Profile/store";
import { SECRET_KEY, decryptData } from "../../../services/fileCode";
import { useLoginStore } from "../store";
import UploadPhotoModal from "./UploadPhotoModal";
import CameraIcon from "../../../assets/CameraIcon.svg";
import profileIcon from "../../../assets/profileIconForProfile.svg";
import ModalCompo from "../../../design/ModalCompo";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";
import PhoneInput from "../../../components/PhoneInput/PhoneInput";
import Api from "../../../services/Api";


export const validatePhone=(phone)=>{
    if(!phone || phone===''){
        return  false
       }
        if(phone?.length!==10 ){
            return true
        }
        const phoneCheck=/^\d+$/
        if(phoneCheck.test(phone)){
            return false;
        }
        return true
}
export const validateEmail = (emailId) => {
    if (!emailId || emailId === "") {
        return false
    }
    const email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailId?.match(email)) {
        return false
    } else {
        return true
    }
}

export const ValidateKey=(key,val)=>{
    console.log('Inside ValidateKey' ,'key=',key,'val=',val)

if(val && val!==''){
    return true;
}
else{
    console.log('empty key found' ,'key=',key,'val=',val)
    Notification.error('Error!',`Please Fill the field ${key}`)
    return false
}
}
function ApplyJobForm({jobForm,setJobForm}) {
    
    const [popupModal,setPopupModal]=useState(false);
    const {isCandidate,setIsLogin,isLogin}=useLoginStorageStore();

    const {setActiveTab,setClickedJob}=useLoginStore();
    const {userData,setUserData}=ProfileStore();
    const [init,setInit]=useState(null);
    const [uploadModal,setUploadModal]=useState(false);
    const [photo,setPhoto]=useState();
    const [location,setLocation]=useState([]);
    const [loader,setLoader]=useState(false);
    const [cnfmModal,setCnfmModal]=useState({open:false,});
    const [refresh,setRefresh]=useState(false);
    const [countryCode,setCountryCode]=useState("+91");
    const [isFormChanged, setIsFormChanged] = useState(false); 
    const [form] = Form.useForm();
    const {width}=useWindowDimensions();

    const imgbeforeUpload = (file) => {
        console.log(file)
        const ispdf = file.type === "application/pdf";
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!ispdf) {
          message.error("You can only upload pdf file!");
        //   setImgFile("");
          return ispdf || Upload.LIST_IGNORE;
        } 
        else if (!isLt5M) {
          message.error("pdf must smaller than 5MB!");
        //   setImgFile("");
          return isLt5M || Upload.LIST_IGNORE;
        } 
        else {
        // setImgFileName(file);
        //   getBase64(file, (url) => {      
        //     // setImgFile(file);

        //   });
        }
        return false
    };

    const handleValuesChange = (changedValues, allValues) => {
        if(jobForm?.type==='hiringStatus'){
        const isFormChanged = Object?.keys(changedValues)?.some(
            (key) => changedValues[key] !== init[key]
        );
        setIsFormChanged(isFormChanged);
        }
    };
      
useEffect(()=>{
    setLoader(true)
    setTimeout(()=>{
        setLoader(false)
    },1500)
},[])

    useEffect(()=>{
        
    if(isLogin &&  Object.keys(userData).length===0){
        const storedUser = localStorage.getItem('user');
        let decryptedUser = decryptData(storedUser, SECRET_KEY);
        
        Api.get(`/auth-auth/getProfile?authId=${decryptedUser?.authUserLoginId}`)
            .send((res)=>{
                
            if(res !== undefined){
                setUserData(res);
                setInit({'name':res?.name,'email':res?.email,'phone':res?.phone})
            }
            })
        }
        else if(isLogin && !isCandidate){
            setInit({'name':userData?.name,'email':userData?.email,'phone':userData?.phone})
        }
       
    },[jobForm])
    useEffect(()=>{
        if(isCandidate && Object.keys(userData).length!==0){
           if(jobForm?.type==='hiringStatus'){
            
            getJobDetailsApi(jobForm?.sourceId,(res)=>{

                setLocation(res?.job?.locations)
                if(res?.photo){
                    setPhoto({link:res?.photo,key:res?.photo?.split('.com/')[1]})
                }
                    if(res?.job?.jobExperience==='Fresher'){
                            setInit({'name':res?.name,'email':res?.email,'phone':res?.phone,
                            'collegeName':res?.collegeName,'department':res?.department,'backlogs':res?.backlogs,
                            'cgpa':res?.cgpa,'XIIth':res?.xii,Xth:res?.x,
                            'resume':res?.resume
                })
                    }
                    else{
                        setInit({'name':res?.name,'email':res?.email,'phone':res?.phone,
                            'experienceYears':res?.experienceYears,'experienceMonths':res?.experienceMonths,'currentLocation':res?.currentLocation,
                            'currentCompany':res?.currentCompany,'noticePeriod':res?.noticePeriod,'currentCtc':res?.currentCtc,'preferredLocation':res?.preferredLocation?.id,
                            'expectedCtc':res?.expectedCtc,'resume':res?.resume})
                    }
            })
            // setLoader(false);
           }
           else{
            getProfileDetailsApi(userData?.email,(res)=>{

                if(res?.photo){
                    setPhoto({link:res?.photo,key:res?.photo?.split('.com/')[1]})
                }
                console.log('jarurat k3',res,jobForm)
             if(jobForm?.expType==='Experienced'){
                console.log('jarurat kaushal experienced',res)

                setInit({'name':userData?.name,'email':userData?.email,'phone':userData?.phone,
             'experienceYears':res?.experienceYears,'experienceMonths':res?.experienceMonths,'currentLocation':res?.currentLocation,
             'currentCompany':res?.currentCompany,'noticePeriod':res?.noticePeriod,'currentCtc':res?.currentCtc,
             'expectedCtc':res?.expectedCtc,'resume':res?.resume
            })
                    
                }
            else if(jobForm?.expType==='Fresher'){
            console.log('jarurat kaushal fresher',res)

            setInit({'name':userData?.name,'email':userData?.email,'phone':userData?.phone,
                'collegeName':res?.collegeName,'department':res?.department,'backlogs':res?.backlogs,
                'cgpa':res?.cgpa,'XIIth':res?.XIIth,Xth:res?.Xth,
                'resume':res?.resume
               })
                }
            // backend stopped giving expType, so this will take experience one.
            else{
                console.log('jarurat kaushal 3',res)

                setInit({'name':userData?.name,'email':userData?.email,'phone':userData?.phone,
             'experienceYears':res?.experienceYears,'experienceMonths':res?.experienceMonths,'currentLocation':res?.currentLocation,
             'currentCompany':res?.currentCompany,'noticePeriod':res?.noticePeriod,'currentCtc':res?.currentCtc,
             'expectedCtc':res?.expectedCtc,'resume':res?.resume
            })
                    
                }
            // setLoader(false);
            }) 
        }
         }
        
    },[userData,jobForm,refresh])
   
  const submitHandler=(val)=>{
    if(jobForm?.type==='hiringStatus'){
      
        ///
        if(val?.resume?.fileList){
                if(val?.resume?.fileList?.length===0){
                    Notification.error('Error!','Please upload resume');
                    return;
                }
                let file={file:val?.resume?.fileList[0]?.originFileObj}

                if(photo?.key===undefined){
                    Notification.error('Error!','Please upload your photo')
                    return
                }

                UploadFile(file,(res,per)=>{
            if(res){
                let data={...val,jobId:jobForm?.jobId,resume:res,expType:jobForm?.expType,photo:photo?.key,sourceId:jobForm?.sourceId,countryCode:countryCode}
                UpdateJobApi(data,(res,per)=>{
                    if(res?.show?.type==='success'){
                        setRefresh(!refresh)
                    }
                    else{
                        console.log('Network error try again')
                    }
                })
            }
            
            }
                )
            }
        else{
                if(photo?.key===undefined){
                Notification.error('Error!','Please upload your photo')
                return
                    }
                let data={...val,jobId:jobForm?.jobId,resume:init?.resume?.split('.com/')[1],expType:jobForm?.expType,photo:photo?.key,sourceId:jobForm?.sourceId,countryCode:countryCode}
                UpdateJobApi(data,(res,per)=>{
                    if(res?.show?.type==='success'){
                    }
                    else{
                        console.log('Network error try again')
                        }
                    })
                }
///
    }
    else{

            if(val?.resume?.fileList){
            console.log('file ressume inside', val?.resume)
                if(val?.resume?.fileList?.length===0){
                    Notification.error('Error!','Please upload resume');
                    return;
                }
        let file={file:val?.resume?.fileList[0]?.originFileObj}

        if(photo?.key===undefined){
            Notification.error('Error!','Please upload your photo')
            return
        }

            UploadFile(file,(res,per)=>{
            console.log('rrrrr',res,per)
        if(res){
            let data={...val,jobId:jobForm?.jobId,resume:res,expType:jobForm?.expType,photo:photo?.key,countryCode:countryCode}
            console.log('dattatatatatatat',data,photo)

            ApplyJobApi(jobForm?.expType,data,(res,per)=>{
                if(res?.show?.type==='success'){
                    
            if(isLogin && !isCandidate){
                // if user is logged in as guest and applied a job then we will redirect him to again login page and he have to login so that he will be able to see the candidate view.
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                setActiveTab(1);
                setIsLogin(false);            
            }

                    setPopupModal(true);


                }
                else{
                    console.log('Network error try again')
                }
            })
        }
        
        }
            )
            }
            else{
            if(photo?.key===undefined){
                Notification.error('Error!','Please upload your photo')
                return
                    }
                let data={...val,jobId:jobForm?.jobId,resume:init?.resume?.split('.com/')[1],expType:jobForm?.expType,photo:photo?.key,countryCode:countryCode}
                ApplyJobApi(jobForm?.expType,data,(res,per)=>{
                    if(res?.show?.type==='success'){
                            if(isLogin && !isCandidate){
                                // if user is logged in as guest and applied a job then we will redirect him to again login page and he have to login so that he will be able to see the candidate view.
                                localStorage.removeItem('token');
                                localStorage.removeItem('user');
                                setActiveTab(1);
                                setIsLogin(false);            
                            }
                        setPopupModal(true);
                    }
                    else{
                        console.log('Network error try again')
                        }
                    })
                }
        }
}
        
       
    return (<div  className={classes.JobFormContainer} >
        <div style={{display:'flex',justifyContent:'flex-start',}}>
            <ButtonCompo title={'Back'} onClick={()=>{setJobForm()}}  prefixIcon={backIcon} style={{color:'#161616',font:'normal 600 18px/24px Lato',marginLeft:'-24px'}}></ButtonCompo>
        </div>
        <div  style={{backgroundColor:'white',borderRadius:'5px',padding:'16px'}}>
        <div style={{borderRadius:5,marginBottom:16,borderBottom:'1px solid #e6e6e6',paddingBottom:'12px'}}>
            <div style={{color:'#3d3d3d',}} className={classes.header2}>{jobForm?.title}</div>
    
         {jobForm?.type==='hiringStatus'?'':<div style={{display:'flex', flexDirection:'row',marginTop:6,alignItems:'center'}}>
                <img src={LocIcon}  style={{height:'20px',width:'20px'}}/>
                <Typography.Text ellipsis={{tooltip:true}} className={classes.title2+" "+classes.locationText}  style={{color:'#3d3d3d'}}>{jobForm?.location?.map((loc,id)=>(<span style={{marginRight:8}}>{loc?.name}{(jobForm?.location?.length>1 && id<jobForm?.location?.length-1)?',':''}</span>))}</Typography.Text>
            </div>}
        </div>
        
        {jobForm?.type==='hiringStatus'?'':<div style={{color:'#3d3d3d',}} className={classes.title2}>Let’s get started! Complete our job application form and take the first step towards a new career.</div>}
        {loader?<Row style={{height:'70vh',display:'flex',justifyContent:'center',alignItems:'center'}}><Spin  size="large"></Spin></Row>:
     <>
        <div style={{backgroundColor:"#FFFFFF",borderRadius:"5px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",marginTop:'24px'}}>
                 <img src={photo?(  photo?.link?photo?.link:URL.createObjectURL(photo?.url)): profileIcon} alt="" style={{borderRadius:"50%",border:"1px dotted #0086FF",height:'140px',width:'140px'}}/>
                 <img src={CameraIcon} className={classes.addImage} alt="" style={{marginTop:"-6vh",objectFit:"cover",marginLeft:"5rem",cursor:"pointer"}}
                 onClick={()=>{
                    setUploadModal(true);
                 }}/>
           </div>
        <Form form={form} onValuesChange={handleValuesChange}
         key={isLogin?`key${init?.name+"_"+init?.resume}`:'keyy'}
         initialValues={init} 
        //  disabled={true}
        layout="vertical" 
        onFinish={(val)=>{
            if(isCandidate){
                setCnfmModal({data:val,open:true});
            }
            else{
            submitHandler(val)
            }
            }} 
        style={{display:'flex',flexDirection:'column',justifyContent:'center',}}
        >
            <Form.Item  label="Name" name="name" rules={[{required:true,message:'please enter name',whitespace:true}]}>
            <Input onKeyDown={(e) =>["1","2","3","4","5","6","7","8","9","0", "+", "-"].includes(e.key) && e.preventDefault()} disabled={isLogin}  type="text" placeholder="Please enter name" />
            </Form.Item>
            <Form.Item label="Email ID" name="email" rules={[{required:true,message:'please enter email',whitespace:true}]}>
            <Input disabled={isLogin} type="email" placeholder="Please enter email" />
            </Form.Item> 
            <Form.Item  validateTrigger='onBlur' label="Mobile number" name="phone" rules={[{required:true,message:'please enter valid mobile number',min:10,max:10}]}>
            {/* <Input onWheel={(e) => e.target.blur()} disabled={isLogin} type='tel' placeholder="Please enter mobile number" min={0} /> */}
            <PhoneInput countryCode={countryCode} disabled={isLogin} setCountryCode={setCountryCode} />
            </Form.Item>
            
       
       {/* <Form.Item name="workExperience" label={<div>Work Experience</div>} rules={[{ required: true,
                         message: "Please select the Work Experience"
                     },]}>
                        <Radio.Group onChange={(event)=>{ 
                            setWorkExperience(event.target.value);}}>
                            <Radio value={"Fresher"}>Fresher</Radio>
                            <Radio value={"Experienced"}>Experienced</Radio>
                        </Radio.Group>
                    </Form.Item> */}

                    {jobForm?.expType === "Fresher" ?
                    <div>
                        <Form.Item name="collegeName" label="College Name" rules={[{ required: true,message: "Please enter your college name",whitespace:true},]}>
                            <Input type="text"  placeholder="please enter college name"/>
                        </Form.Item>
                        <Form.Item name="department" label="Department" rules={[{ required: true,message: "Please enter the department",whitespace:true},]}>
                            <Input type="text" placeholder="please enter department" />
                        </Form.Item>
                        <Form.Item name="backlogs" label="Number of backlog(s)" rules={[{ required: true,message: "Please enter backlog details",whitespace:true},]}>
                            <Input onWheel={(e) => e.target.blur()} type="number" placeholder="please enter backlogs" min={0} />
                        </Form.Item>
                        <Form.Item name="cgpa" label="CGPA" rules={[{ required: true,message: "Please enter cgpa",whitespace:true},]}>
                            <Input onWheel={(e) => e.target.blur()} type="number" min={0} placeholder="please enter cgpa" />
                        </Form.Item>
                        <Form.Item name="XIIth" label="12th marks" rules={[{ required: true,message: "Please enter 12th marks",whitespace:true},]}>
                            <Input  type="number" min={0}placeholder="please enter 12th marks"/>
                        </Form.Item>
                        <Form.Item name="Xth" label="10th marks" rules={[{ required: true,message: "Please enter 10th marks",whitespace:true},]}>
                            <Input  type="number" min={0} placeholder="please enter 10th marks" />
                        </Form.Item>
                    </div>
                    : jobForm?.expType === "Experienced" ?
                    <div>
                        <div><span style={{color:'red'}}>*</span> Total work experience </div>
                       <div style={{display:'flex',alignItems:'center',gap:'1rem'}}>
                            <Form.Item style={{width:'100%'}} name="experienceYears" label="" rules={[{ required: true,message: "Please enter total work experience year",},]}>
                                <Input suffix={<span style={{color:'#cacaca'}}>year(s)</span>} onWheel={(e) => e.target.blur()} type="number"  min={0} placeholder={width>700?"Enter year(s)":"Enter"}/>
                                
                            </Form.Item>
                            <Form.Item style={{width:'100%'}} name="experienceMonths" label="" rules={[{ required: true,message: "Please enter total work experience month",},]}>
                            <Input suffix={<span style={{color:'#cacaca'}}>month(s)</span>} onWheel={(e) => e.target.blur()} type="number" placeholder={width>700?"Enter month(s)":"Enter"} min={0} max={11}/>
                            </Form.Item>
                        </div>
                        <Form.Item name="currentLocation" label="Current location" rules={[{ required: true,message: "Please enter current location",whitespace:true},]}>
                            <Input type="text" placeholder="please enter current location" />
                        </Form.Item>
                        <Form.Item name="preferredLocation" label="Preferred location" rules={[{ required: true,message: "Please select preferred location",},]}>
                            <Select placeholder="please select preferred location">
                            { jobForm?.type==='hiringStatus'?
                            location?.map((val,id)=>{
                                return(
                                    <Option key={id} value={val?.id}>{val?.name}</Option>
                                )
                            })
                            :
                                jobForm?.location?.map((val,id)=>{
                                return(
                                    <Option key={id} value={val?.id}>{val?.name}</Option>
                                )
                            })
                            
                            }
                            </Select>
                        </Form.Item>
                        <Form.Item label="Current Organization" name="currentCompany" rules={[{required:true,message:'please enter current organization',whitespace:true}]}>
                            <Input type="text" placeholder="Please enter current organization" />
                        </Form.Item>
                        <Form.Item name="noticePeriod" label="Notice period" rules={[{ required: true,message: "Please enter notice period duration"},]}>
                            <Input onWheel={(e) => e.target.blur()} type="number" min={0} placeholder={width>700?"please enter notice period in days":"please enter notice period"} suffix={<span style={{color:'#cacaca'}}>days</span>} />
                        </Form.Item>
                        <Form.Item name="currentCtc" label="Annual current CTC" rules={[{ required: true,message: "Please enter current ctc",whitespace:true},]}>
                            <Input onWheel={(e) => e.target.blur()} type="number"  min={0} placeholder="please enter current CTC" prefix={<span style={{color:'#cacaca'}}>₹</span>} />
                        </Form.Item>
                        <Form.Item name="expectedCtc" label="Annual expected CTC" rules={[{ required: true,message: "Please enter expected CTC",whitespace:true},]}>
                            <Input onWheel={(e) => e.target.blur()} type="number"  min={0} placeholder="please enter expected ctc"  prefix={<span style={{color:'#cacaca'}}>₹</span>}/>
                        </Form.Item>
                        {/* <Form.Item label="Current Organization" name="currentOrg" rules={[{required:true,message:'please enter current organization',whitespace:true}]}>
                            <Input type="text" placeholder="Please enter current organization" />
                        </Form.Item> */}
                    </div>
                    :<div style={{marginTop:"8rem"}}></div>
                    }
           
           
           
            <Form.Item name="resume" label="Resume" rules={[{ required: true,message: "Please upload your resume",},]}>
                         <Upload.Dragger 
                          accept=".pdf"
                         beforeUpload={imgbeforeUpload}
                            multiple={false} maxCount={1}  style={{backgroundColor:"#EAF4FF",borderColor:"#1089FF",padding:'10px'}}
                            defaultFileList={(isCandidate && init?.resume)?[{url:init?.resume,name:init?.resume?.split('.com/file/')[1]}]:null}
                            // itemRender={(existingCompo,file)=><div className={classes.itemRender}>  
                            //             <div >
                            //                 <img src={render_doc_icon}/> 
                            //                 <div>
                            //                     <Typography.Text style={{width:"13rem"}} ellipsis={{tooltip:true}}>{file?.name}</Typography.Text>
                            //                     <span>{parseInt(file?.size/1000)+" KB"}</span>
                            //                 </div>                                            
                            //             </div>             
                            //             <DeleteOutlined style={{color:"grey",fontSize:"16px"}} onClick={()=>{
                            //                 setFileList(fileList?.filter(item=>item.uid!==file?.uid))
                            //             }}/>
                            //             </div>} 
                            
                            >
                            <div style={{display:"flex",justifyContent:"center",alignItems:"center",gap:"16px"}}>
                                 <img src={uploadIcon} style={{height:"2.5rem",width:"2.5rem",}}/>
                                <div style={{display:'flex',flexDirection:'column',gap:'8px',alignItems:'baseline'}}>
                                <div style={{color:'#161616',fontWeight:"bold",fontSize:17}}>Click to browse and select your document</div>
                                <p style={{color:"#3d3d3d",}}>Only PDF files with max size of 5 MB</p>
                                </div>
                            </div>
                          </Upload.Dragger>
                </Form.Item>
            <Form.Item style={{display:'flex',justifyContent:'center'}}>
                <ModalPrimaryButton 
                // disabled={!isFormChanged}
                disabled={jobForm?.type==='hiringStatus'?(!((jobForm?.status==="Submitted" || jobForm?.status==="New") && isFormChanged)):false} 
                > {jobForm?.type==='hiringStatus'?"Update Application": "Submit Application"}</ModalPrimaryButton>
            </Form.Item>
        </Form>  
        </>}


{uploadModal && <UploadPhotoModal modal={uploadModal} setModal={setUploadModal} setPhoto={setPhoto} photo={photo} setIsFormChanged={setIsFormChanged} />}
{popupModal && <JobSuccessPopup modal={popupModal} setModal={setPopupModal} />}
            <ModalCompo
            open={cnfmModal?.open}
            onCancel={()=>{setCnfmModal({open:false})}}
            className={classes.cnfmModal}
            closableRight={true}
             >
                <div style={{backgroundColor:'white',justifyContent:'center',alignItems:'center'}}>
                    
                <div style={{display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'center', }}>
                    <div style={{font:"normal normal 600 24px/29px Figtree",marginBottom:16}}>Confirm application</div>
                    <p style={{font:"normal normal normal 18px/22px Figtree",marginTop:'8px',textAlign:'center'}}>
                    You’ve already submitted applications for other position(s).
                    Are you sure you want to apply for the 
                     {" "+jobForm?.title} as well? </p>
                
                <ButtonCompo onClick={()=>{
                    submitHandler(cnfmModal?.data)
                    setTimeout(()=>{
                        setCnfmModal({open:false});
                    },200)

                            // Navigate('/login');
                            // setActiveTab(1);
                            // // setActiveTab(3);
                            // setTimeout(()=>{
                            //     setActiveTab(3);
                            // },10)
                    }} title={"Yes, submit application"} style={{color:'white',backgroundColor:'#1089FF',marginTop:'64px',width:'260px',marginBottom:'12px'}} />
                
                <ButtonCompo title={"Cancel"} onClick={()=>{
                    setCnfmModal(false);
                }}  style={{color:'#3D3D3D',border:'1px solid #CACACA',width:'260px'}}/>
                </div>
                </div>

             </ModalCompo>
      </div>
       
        </div> ); 
}

export default ApplyJobForm;

