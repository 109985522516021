import { Button, Form,Modal } from "antd";
import ModalCompo from "../../design/ModalCompo";
import classes from './OptionalHolidayModal.module.css';
import { ModalPrimaryButton, ModalSecondaryButton } from "../../components/Button/Button";
import { useEffect, useState } from "react";
import { applyHolidayApi} from "./Action";
import WarningImage from "../../assets/warningModalImage.svg";
import Notification from "../../services/Notification";
// import Modal from "../../components/Modal/Modal";
import calendar_icon from './../../assets/calendarIcon.svg';
import moment from "moment";
import { useWindowDimensions } from '../../components/windowDimention/windowDimension';

function OptionalHolidayModal({modal,setModal,holidays,total,location}){
    const {width}=useWindowDimensions();

    const [list,setList]=useState()
    const [confirmModal,setConfirmModal]=useState(false);

    useEffect(()=>{
        setList(holidays)
    },[])
    let holidayCount=list?.filter((item)=>item.checked===true)?.length;
    
    const SaveOHD=()=>{
        let ids=[];
       
        list?.forEach(element => {
             //backend wanted date in YYYY-MM-DD by shubham done by kaushal on 23-aug-2023
        const [d,m,y]=element?.date?.split('-')
        const date=y+'-'+m+'-'+d;
            if(element?.checked){
                ids.push({date:date,location:location,accountOf:element?.accountOf})
            }
        });

        // if(ids?.length<total){
        //     Notification.error("error",`please select all ${total} optional holidays`)
        //     return;
        // }
        applyHolidayApi(ids,(res)=>{
            if(res){
                setModal();
                window.location.reload();
            }
        }
        )
    }
    return(<>
        <Modal 
        open={modal} centered footer={false}
        onCancel={()=>{setModal(false)}} 
        style={{width:width>700?"55vw":"90vw"}}
        closableRight={true}
        title={<div className={classes.header} style={{width:"100%",display:"flex",justifyContent:"flex-start"}}>
            <img src={calendar_icon}/>
            <h3>Optional Holidays</h3>
        </div>}
        >

            <Form className={classes.holiday}>
                        <div>
                            <span>{holidayCount} Selected</span>
                            <span>Balance {total}</span>
                        </div>
                        <div style={{height:width>700?'50vh':"50vh",overflowY:'scroll',paddingLeft:"rem",}}>
                            {list?.map((item,index)=>{
                                let currentDate = moment();  
                                let holidayDate = moment(item.date, 'DD-MM-YYYY');

                                if (holidayDate.isSameOrAfter(currentDate, 'day')) {
                                return(
                                    <div className={classes.eachItem}>
                                        <div >
                                                <input size={"large"}
                                                type="checkbox" 
                                                checked={item.checked}
                                                disabled={holidayCount>=total && !item.checked}
                                                 onChange={(event)=>{
                                                    setList(list?.map((item2,index2)=>{
                                                        if(index===index2)
                                                        {
                                                            return {...item2,checked:event.target.checked}
                                                        }
                                                        return item2;
                                                    }))
                                                }}/>
                                        </div>
                                        <div>
                                        <span>{item.accountOf}</span>
                                        <span>{item.date}</span>
                                        </div>
                                    </div>
                                )}
                            })}
                        </div>

                        <div className={classes.buttonDiv}>
                            <Button type="primary" style={{height:"2.1rem",width:"6rem",borderRadius:"8px"}} 
                                onClick={()=>{setConfirmModal(true);}} htmlType="submit">Apply</Button>
                            <Button style={{backgroundColor:"#FFFFFF",border:"1px solid #0086FF",color:"#0086FF",borderRadius:"8px",height:"2.1rem",width:"6rem",display:"flex",justifyContent:"center"}}
                            onClick={()=>{
                                setModal(false);
                            }}>Cancel</Button>
                        </div>
            </Form>
            
        </Modal>
        {confirmModal&&
         <Modal 
         open={confirmModal} centered footer={false}
         onCancel={()=>{setConfirmModal(false)}} 
         style={{width:width>700?"55vw":"90vw"}}
         title={<div style={{width:"100%",display:"flex",justifyContent:"center",alignItems:"center",color:"#FF5745",height:"2rem",gap:"0.4rem"}}>
             <img src={WarningImage}/>
             <h3>Warning</h3>
         </div>}
         >
            <div style={{display:"flex",alignItems:"center",justifyContent:"center",width:"100%",flexDirection:"column",margin:"2rem 0 1rem 0"}}>
                <div style={{fontSize:17,marginBottom:"1rem"}}>Are you sure you want to submit?</div>
                <div className={classes.buttonDiv} style={{marginTop:"1rem"}}>
                    <Button style={{backgroundColor:"#FFFFFF",border:"1px solid #0086FF",color:"#0086FF",borderRadius:"8px",height:"2rem",width:"5rem",gap:"1rem"}}
                        onClick={()=>{setConfirmModal(false);setModal(false)}}>No</Button>
                    <Button type="primary" style={{height:"2rem",width:"5rem",borderRadius:"8px"}} onClick={SaveOHD} htmlType="submit"
                    >Yes</Button>
                </div>
            </div>
         </Modal>}
       </> 
    )
}
export default OptionalHolidayModal; 