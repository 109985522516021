
import classes from "./Project.module.css";
import { Spin, Progress,Tooltip } from "antd";
import moment from "moment";
import "./comp.css";
import editIcon from '../../../assets/editicon.svg'
import deleteIcon from '../../../assets/deleteiconWB.svg'
import useApiStore from "../../../services/store";
import viewClass from "../../../services/ViewRole";
import { NoData } from "../../Training_essentialDocuments";
import { useEffect } from "react";
import useProjectStore from "./store";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";

function ProjectCards(props) {
    const { loader } = useApiStore(state => state)
    const { filterOption, appliedFilter } = useProjectStore(state => state)
    const { width } = useWindowDimensions();
    const clickHandler = (data) => {
        // console.log(data)
        props.onClick(data);

    }
    /*     const DropdownHandler = (key, val) => {
            // console.log(key,val,"val")
            if (key?.key === "edit") {
                props?.onEdit(val);
            }
            else if (key?.key === "delete") {
                props?.onDelete(val);
            }
        } */

    useEffect(() => {
        console.log('Project data', filterOption, appliedFilter)
    }, [appliedFilter])
    return (
        <>
            {loader ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90%', }}><Spin /></div> : (props?.data?.length === 0 || props?.data === undefined) ?
                <NoData />
                :
                <>
                    {
                        width < 700 ?
                            <>
                                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                    {props?.data?.map((val) => {
                                        return (
                                            <div onClick={() => { clickHandler(val) }} style={{ height: 200, boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px", padding: 10, height: "fit-content" }}>
                                                <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", width: "100%" }}>
                                                    <div className={classes.projectHeader} style={{width:"6rem", overflow:"hidden", textOverflow:"ellipsis", whiteSpace:"nowrap"}}>{val?.projectName}</div>
                                                    <div style={{ display: "flex", justifyContent: "space-between", width: "17%" }}>
                                                        {
                                                            (viewClass.superAdmin || viewClass.hod || viewClass.manager) && !(val?.isCoManager) &&
                                                            <>
                                                                <div style={{ cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); props?.onEdit(val) }}>
                                                                    <img src={editIcon} style={{ height: 25, width: 25 }} />
                                                                </div>
                                                                <div style={{ cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); props?.onDelete(val) }}>
                                                                    <img src={deleteIcon} style={{ height: 25, width: 25 }} />
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                                <div style={{ display: "flex", width: "50%" }}>
                                                    <Progress percent={val?.progress ? val?.progress : 0} showInfo={true} />
                                                </div>
                                                <div style={{ display: "flex" }}>
                                                    <div style={{ fontWeight: "600", fontSize: "0.9rem" }}><span className={val?.status} style={{ height: 30, width: 30, marginRight: 10 }}>•</span>{val?.status}</div>
                                                    <div style={{ width: 2, height: "100%", backgroundColor: "#ABABAB" }}></div>
                                                    <div className={classes.title2} style={{ marginLeft: 10, fontWeight: "500" }}>Tasks : </div>
                                                    <div className={classes.taskTitle} style={{ marginLeft: 5 }}>{val?.totalTask ? val?.totalTask : `${val?.actualEffort ? val?.actualEffort : 0} / ${val?.plannedEffort ? val?.plannedEffort : 0}`}</div>
                                                </div>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", marginLeft: 10 ,padding:5}}>
                                                    <div className={classes.title1}>Department</div>
                                                    <div className={classes.title2} style={{ backgroundColor: "#0086FF26", height: 25, width: 150, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 15, color: "#0086FF", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}><span style={{ width: 90, overflow: "hidden" }} title={val?.departmentName}>{val?.departmentName}</span> </div>
                                                </div>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", marginLeft: 10 ,padding:5}}>
                                                    <div className={classes.title1}>Team</div>
                                                    <div className={classes.title2} style={{ backgroundColor: "#B9796426", height: 25, width: 150, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 15, color: "#B97964", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}> <span style={{ width: 90, overflow: "hidden" }} title={val?.teamName}>{val?.teamName}</span></div>
                                                </div>
                                                {/*  <div style={{ display: "flex", width: "100%", justifyContent: "space-between", marginTop: 20 }}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "45%", marginLeft: 10 }}>
                                        <div className={classes.title1}>Department</div>
                                        <div className={classes.title2} style={{ backgroundColor: "#0086FF26", height: 25, width: 150, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 15, color: "#0086FF", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}><span style={{ width: 90, overflow: "hidden" }} title={val?.departmentName}>{val?.departmentName}</span> </div>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "37%", marginRight: 10 }}>
                                        <div className={classes.title1}>Team</div>
                                        <div className={classes.title2} style={{ backgroundColor: "#B9796426", height: 25, width: 150, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 15, color: "#B97964", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}> <span style={{ width: 90, overflow: "hidden" }} title={val?.teamName}>{val?.teamName}</span></div>
                                    </div>

                                </div> */}
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", marginLeft: 10 ,padding:5}}>
                                                    <div className={classes.title1}>Manager</div>
                                                    <div className={classes.title2} style={{ backgroundColor: "#39CA6C26", height: 25, width: 150, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 15, color: "#39CA6C", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}><span style={{ width: 90, overflow: "hidden" }} title={val?.managerName}>{val?.managerName}</span> </div>
                                                </div>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", marginLeft: 10 ,padding:5}}>
                                                    <div className={classes.title1}>Due On</div>
                                                    <div className={classes.title2} style={{ backgroundColor: "#FF7B7B26", height: 25, width: 150, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 15, color: "#FF7B7B", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}> <span style={{ width: 100, overflow: "hidden" }} title={moment(val?.dueDate).format("DD-MMM-YYYY")}>{moment(val?.dueDate).format("DD-MMM-YYYY")}</span></div>
                                                </div>
                                                {/*  <div style={{ display: "flex", width: "100%", justifyContent: "space-between", marginTop: 20 }}>
                                   

                                </div> */}
                                            </div>
                                        )
                                    })}
                                </div>
                            </>
                            :
                            <>
                                <div className={classes.projectCardContainer} style={{cursor:"pointer"}}>
                                    {props?.data?.map((val) => {
                                        return (
                                            <div onClick={() => { clickHandler(val) }} style={{ height: 200, boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px", padding: 10, height: "fit-content" }}>
                                                <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", width: "100%" }}>
                                                    <Tooltip title={val?.projectName}>
                                                    <div className={classes.projectHeader} style={{width:"16rem", overflow:"hidden", textOverflow:"ellipsis", whiteSpace:"nowrap"}}>{val?.projectName}</div>
                                                    </Tooltip>
                                                    <div style={{ display: "flex", justifyContent: "space-between", width: "12%" }}>
                                                        {
                                                            (viewClass.superAdmin || viewClass.hod || viewClass.manager) && !(val?.isCoManager) &&
                                                            <>
                                                                <div style={{ cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); props?.onEdit(val) }}>
                                                                    <img src={editIcon} style={{ height: 25, width: 25 }} />
                                                                </div>
                                                                <div style={{ cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); props?.onDelete(val) }}>
                                                                    <img src={deleteIcon} style={{ height: 25, width: 25 }} />
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                                <div style={{ display: "flex", width: "50%" }}>
                                                    <Progress percent={val?.progress ? val?.progress : 0} showInfo={true} />
                                                </div>
                                                <div style={{ display: "flex" }}>
                                                    <div style={{ fontWeight: "600", fontSize: "0.9rem" }}><span className={val?.status} style={{ height: 30, width: 30, marginRight: 10 }}>•</span>{val?.status}</div>
                                                    <div style={{ width: 2, height: "100%", backgroundColor: "#ABABAB" }}></div>
                                                    <div className={classes.title2} style={{ marginLeft: 10, fontWeight: "500" }}>Tasks : </div>
                                                    <div className={classes.taskTitle} style={{ marginLeft: 5 }}>{val?.totalTask ? val?.totalTask : `${val?.actualEffort ? val?.actualEffort : 0} / ${val?.plannedEffort ? val?.plannedEffort : 0}`}</div>
                                                </div>
                                                <div style={{ display: "flex", width: "100%", justifyContent: "space-between", marginTop: 20 }}>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "45%", marginLeft: 10 }}>
                                                        <div className={classes.title1} style={{paddingRight:5}}>Department</div>
                                                        <div className={classes.title2} style={{ backgroundColor: "#0086FF26", height: 25, width: 150, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 15, color: "#0086FF"}}>
                                                            <Tooltip  title={val?.departmentName}   >
                                                            <span style={{ width: 90, overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"  }}>{val?.departmentName}</span>
                                                            </Tooltip>
                                                             </div>
                                                    </div>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "37%", marginRight: 10 }}>
                                                        <div className={classes.title1} style={{paddingRight:5}}>Team</div>
                                                        <div className={classes.title2} style={{ backgroundColor: "#B9796426", height: 25, width: 150, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 15, color: "#B97964",  }}>
                                                            <Tooltip title={val?.teamName}>

                                                             <span style={{overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis",  width: 90, overflow: "hidden" }} >{val?.teamName}</span>
                                                            </Tooltip>
                                                             </div>
                                                    </div>

                                                </div>
                                                <div style={{ display: "flex", width: "100%", justifyContent: "space-between", marginTop: 20 }}>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "45%", marginLeft: 10 }}>
                                                        <div className={classes.title1} style={{paddingRight:5}}>Manager</div>
                                                        <div className={classes.title2} style={{ backgroundColor: "#39CA6C26", height: 25, width: 150, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 15, color: "#39CA6C",  }}>
                                                            <Tooltip title={val?.managerName}>
                                                            <span style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", width: 90, overflow: "hidden" }} >{val?.managerName}</span>

                                                            </Tooltip>
                                                             </div>
                                                    </div>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "37%", marginRight: 10 }}>
                                                        <div className={classes.title1} style={{paddingRight:5}}>Due On</div>
                                                        <div className={classes.title2} style={{ backgroundColor: "#FF7B7B26", height: 25, width: 150, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 15, color: "#FF7B7B",  }}>
                                                            <Tooltip title={moment(val?.dueDate).format("DD-MMM-YYYY")}>
                                                             <span style={{overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis",  width: 100, overflow: "hidden" }} >{moment(val?.dueDate).format("DD-MMM-YYYY")}</span>

                                                            </Tooltip>
                                                             </div>
                                                    </div>

                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </>
                    }
                </>

            }
        </>
    )
}
export default ProjectCards;