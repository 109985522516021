import React, { useCallback, useEffect, useState } from "react";

import classes from "./Team.module.css";
import { Table, Form, Radio, Collapse, /* Progress, Tooltip, */ Progress, Divider, Dropdown, Spin, Tooltip, Select, Button} from "antd";
import AddMembersModal from "./AddMembersModal/AddMembersModal";
import AddTeamModal from "./AddTeamModal/AddTeamModal";
// import filter_blue_icon from "../../../assets/filter_blue_icon.svg";
import downloadIconBlack from "../../../assets/downloadBlue.svg";
import edit_black_icon from "../../../assets/editWNB.svg";
import ReleaseModal from "./ReleaseModal/ReleaseModal";
import ApplyFiltersModal, { onSaveFilter } from "../../../components/AppyFiltersModal/ApplyFiltersModal";
import {
  deleteTeam,
  downloadLeaveHistory,
  downloadLeaveSummery,
  downloadTeamData,
  getApplyFilterData,
  getCandidateList,
  getDepartmentData,
  getSecDeptAndTeam,
  getTeamApi,
  getTeamByDepartment,
  getTeamsList,
} from "./Action";
import Api from "../../../services/Api";
// import information_blue_icon from "../../../assets/information_blue_icon.svg";
// import calender_brown_icon from "../../../assets/calender_brown_icon.svg";
// import release_red_icon from "../../../assets/release_red_icon.svg";
import { useNavigate } from "react-router-dom";
import { useApplyFilterStore } from "../../../components/AppyFiltersModal/store";
import { useGlobalStore } from "../../../store";
import {
  ModalPrimaryButton,
  ModalSecondaryButton,
  ResetButton,
} from "../../../components/Button/Button";
import Modal from "../../../components/Modal/Modal";
import Calender from "./Calender/Calender";
import PluseIcon from "../../../assets/pluseIcon.svg";
import MinusIcon from "../../../assets/minusIcon.svg";
import UserIcon from "../../../assets/UserIcon.svg";
import Filter from "../../../assets/filter.svg";
import viewClass from "../../../services/ViewRole";
import { NoData } from "../../Training_essentialDocuments";
import EmployeeSummaryTeam from "../Logs/employSummaryteams";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { AiOutlineMore } from "react-icons/ai";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";
import { MobileCard } from "../Release/Release";
import ListViewTeamData from "./teamSecondTab";
import SearchCompo from "../../../design/SearchCompo";
import { IoMdContact } from "react-icons/io";
import { MdDeleteOutline } from "react-icons/md";
import ModalCompo from "../../../design/ModalCompo";
import ButtonCompo from "../../../design/ButonCompo";



const Teams = () => {
  const [deptId, setDeptId] = useState("");
  const [role, setRole] = useState("");
  const [managerId, setManagerId] = useState("");
  const [teamId, setTeamId] = useState("");
  const [loader, setLoader] = useState(false);
  const [pageLoader, setPLoader] = useState(false)
  const [memberId, setMemberId] = useState("");
  const [page, setPage] = useState("homePage");
  const [calenderData, setCalenderData] = useState();
  const [team, setTeam] = useState("");
  const [form] = Form.useForm();
  const [render, setRender] = useState({ uid: "", page: 1 });
  const { width } = useWindowDimensions();
  const [tabs, setTabs] = useState("Team");
  const [depts, setDepts] = useState([])
  const [teamsDD, setTeamsDD] = useState([]);
  const [secLoader, setSecLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [loaderD,setloaderD]= useState(false)
  const [ids, setIds] = useState({
    deptId: undefined,
    teamIds: [],
    list: undefined
  })
  const [deleteModal, setDeleteModal] = useState(false)



  const {
    isAddTeamModal,
    addTeamModalHandler,
    editTeamModalHandler,
    isEditTeamModal,
    isAddMembersModal,
    addMembersModalHandler,
    releaseModalHandler,
    getTeamDataHandler,
    departmentsHandler,
    applyFilterDataHandler,
    applyFilterData,
    departments,
    downloadModal,
    teamsByDep,
    teamsByDepartment,
    downloadModalHandler,
    isReleaseModal,
  } = useGlobalStore((state) => state);
  const { Panel } = Collapse;
  const { applyFiltersModalHandler, isApplyFiltersModal, } =
    useApplyFilterStore();
  const [activeRow, setActiveRow] = useState(null);
  // const [expand,setExpand]=useState(false);
  const Option = Select.Option;

  useEffect(() => {
    setPLoader(true)
    getDepartmentData(departmentsHandler, Api, () => { setPLoader(false) });
    return (() => {
      setPLoader(true)
      getDepartmentData(departmentsHandler, Api, () => { setPLoader(false) });
    })
  }, []);

  const refresh = () => {
    if (tabs === "List view team data") {
      if (ids?.list !== undefined) {
        setSecLoader(true)
        getCandidateList((res) => {
          setIds({ ...ids, list: res });
          setSecLoader(false);
        }, ids?.teamIds, Tooltip, IoMdContact, Dropdown, DropdownHandler, AiOutlineMore)
      }
    } else {
      if (applyFilterData) {
        setLoader(true)
        onSaveFilter()
        setLoader(false)

      }
      console.log("its here inside refresh");
      setLoader(true)
      getTeamByDepartment(teamsByDepartment, deptId, Api, () => { setLoader(false) });
    }
  }


  useEffect(() => {
    if (deptId) {

      setLoader(true)
      getTeamByDepartment(teamsByDepartment, deptId, Api, () => { setLoader(false) });

    }
    return (() => {
      applyFilterDataHandler([])
    })
  }, [deptId]);
  useEffect(() => {
    getSecDeptAndTeam((res) => { setDepts(res?.allDepartments) })
  }, [])
  useEffect(() => {
    viewClass.updateView();
    getTeamApi(getTeamDataHandler, Api, () => { });
    // setPLoader(false)

    return (() => {
      getTeamApi(getTeamDataHandler, Api, () => { });
      // setPLoader(false)
    })
  }, []);

  const colorCodes = {
    "Deployed": "#24C850",
    "On Job Training": "#FFCF21",
    "InActive": "",
    "Active": "",
    "Joined": "#B33DC6",
    "Training": "#C04FCA",
    "Released": "#9B9DA2",
    "On Notice": "#FD8600",
    "Exit": "#FF3434",
  }
  const [pageNo, setPageNo] = useState(1)
  const handelPagination = (data, page = 1) => {
    const itemsPerPage = 10;
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const pageData = data.slice(startIndex, endIndex);

    return pageData;
  };

  const onDeleteTeam = () =>{
    setloaderD(true)
    deleteTeam({teamId:team?.teamId},()=>{
      setloaderD(false)
      setDeleteModal(false)
      // getTeamApi(getTeamDataHandler, Api, () => { });
      setPLoader(true)
      getDepartmentData(departmentsHandler, Api, () => { setPLoader(false) });
      setLoader(true)
      getTeamByDepartment(teamsByDepartment, deptId, Api, () => { setLoader(false) });
    },()=>{
      setloaderD(false)
      setDeleteModal(false)
    })
  }


  const [items, setItem] = useState([
    {
      key: "Logs",
      label: <div style={{ cursor: 'pointer' }}
      > Task</div>
    },
    {
      key: "Calnedar",
      label: <div style={{ cursor: 'pointer' }}
      > Calendar</div>
    },
    (viewClass.manager) && {
      key: "Release",
      label: <div style={{ cursor: 'pointer' }}
      > Release</div>
    },
  ])


  useEffect(() => {

    if (!applyFilterData?.release) {
      setItem([
        {
          key: "Logs",
          label: <div style={{ cursor: 'pointer' }}
          > Task</div>
        },
        {
          key: "Calnedar",
          label: <div style={{ cursor: 'pointer' }}
          > Calendar</div>
        },
      ])
    } else {
      console.log("insideE")
      setItem([
        {
          key: "Logs",
          label: <div style={{ cursor: 'pointer' }}
          > Task</div>
        },
        {
          key: "Calnedar",
          label: <div style={{ cursor: 'pointer' }}
          > Calendar</div>
        },
        {
          key: "Release",
          label: <div style={{ cursor: 'pointer' }}
          > Release</div>
        },
      ])
    }
  }, [applyFilterData?.isCoManager, applyFilterData?.release])

  /* useEffect(()=>{
    if(viewClass.manager){
      setItem([
          ...items,
          {
              key:"Release",
              label: <div style={{cursor:'pointer'}}
               > Release</div>
          },
         
      ])
  }
  },[items]) */

  const DropdownHandler = (key, record) => {

    if (key?.key === "Logs") {
      setRender({ id: record?.id, page: 2 })
    }
    else if (key?.key === "Calnedar") {

      setPage("calender");
      setCalenderData(record);
    }
    else if (key?.key === "Release") {
      releaseModalHandler(record);
      setMemberId(record?.id);
    }
  }

  const Colums = useCallback((item, id, teamsByDep) => {
    return [
      {
        title: (
          <div
            className={classes.teamHeader}


          >
            <div>
              <span style={{ color: "#162432", fontWeight: 400, }}>Team :</span>
              <span style={{ color: "#162432", fontWeight: 700, }}>{item?.teamName}</span>
              {
                item?.hod &&
                <>

                  <button
                    className={classes.editOutlined}
                    onClick={() => {
                      editTeamModalHandler(true);
                      setTeam(item)
                    }}
                  >
                    <img src={edit_black_icon} />
                    <div style={{ color: "#0086FF", fontSize: 14, marginLeft: 5 }}>Edit</div>
                  </button>
                  <button
                    className={classes.editOutlined}
                    style={{ width: "5rem", display: "flex", alignItems: "center" }}
                    onClick={() => {
                      console.log("triggered306");
                      setDeleteModal(true)
                      // editTeamModalHandler(true);
                      setTeam(item)
                    }}
                  >
                    <MdDeleteOutline color="red" />
                    <div style={{ color: "red", fontSize: 14, marginLeft: 0 }}>Delete</div>
                  </button>

                </>
              }

            </div>
            <div>
              {/*  <a
                style={{ color: "#000000", fontWeight: 400 }}
                onClick={() => {
                  downloadModalHandler(true);
                  setTeam(item);
                }}
              >
                {" "}
                Download{" "}
                <img
                  style={{
                    width: "1rem",
                    height: "1rem",
                    marginLeft: "0.5rem",
                  }}
                  src={downloadIconBlack}
                />
              </a> */}
              <div
                style={{ color: "#000000", fontWeight: 400 }}
                onClick={() => {
                  downloadModalHandler(true);
                  setTeam(item);
                }}
              >


                <img
                  style={{
                    width: "1rem",
                    height: "1rem",
                    marginLeft: "0.5rem",
                  }}
                  src={downloadIconBlack}
                />
                <span style={{ marginLeft: 5, color: "#0086FF" }}>Download</span>
              </div>
              {item?.hod ?
                <button
                  style={{ backgroundColor: "#FFFFFF", border: "1px solid #0086FF", color: "#0086FF", borderRadius: 5 }}
                  onClick={() => {
                    // console.log(item,"meeeemmmmmm")
                    addMembersModalHandler(item);
                  }}
                >
                  +Add Member
                </button> : null}
            </div>
          </div>
        ),
        children: [
          {
            title: "Associate",
            key: "name",
            width: "20%",
            dataIndex: "name",
            render: (text, record) => {
              console.log("recordee", record);
              return (
                <>
                  <div style={{ display: "flex", flexDirection: "row", gap: "0.4rem" }}>
                    <img
                      src={UserIcon}
                      style={{ width: "3rem", height: "3rem", borderRadius: "50%" }}
                    />
                    <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                      <Tooltip title={record?.name}>
                        <div style={{ fontSize: "0.85rem", fontWeight: "600", textOverflow: "ellipsis", width: "7vw", overflow: "hidden", height: 20, whiteSpace: "nowrap", }}>
                          {record?.name}
                        </div>
                        <span style={{ textOverflow: "ellipsis", width: "12vw", overflow: "hidden", height: 20, whiteSpace: "nowrap" }}>{record?.uid}</span>
                      </Tooltip>
                    </div>
                  </div>
                  {/*  <div>
                    <Progress
                      percent={record?.calculation?.loading}
                      showInfo={true}
                      strokeColor="#39CA6C"
                      strokeWidth={3.5}
                      />
                  </div> */}
                  {/*  <div>
                          <span>Planned Hours: {record?.calculation?.plannedHour}</span>
                      </div> */}
                </>
              );
            },
          },
          {
            title: "Contact Details",
            key: "",
            dataIndex: "",
            width: "15%",
            render: (text, record) => {
              return (
                <div>
                  <Tooltip title={`${text?.email} | ${text?.phone}`}>
                    <div style={{ textOverflow: "ellipsis", width: "40%", textOverflow: "ellipsis", overflow: "hidden", height: 20, whiteSpace: "nowrap", width: "80%", }}>{text?.email}</div>

                    <span>{text?.phone}</span>
                  </Tooltip>
                </div>
              );
            },
          },
          {
            title: "Status",
            key: "status",
            dataIndex: "status",
            width: "13%",
            render: (text, record) => {

              return (
                <Tooltip title={record?.status}>
                  <div style={{ display: "flex", backgroundColor: colorCodes[record?.status], borderRadius: 10, alignItems: "center", justifyContent: "center", width: "80%", height: 30, }}>
                    <span style={{ fontSize: 10, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", width: "50%", alignItems: "center", justifyContent: "center" }}>{record?.status}</span>
                  </div>

                </Tooltip>
              );
            },
          },

          {
            title: "Designation",
            key: "designation",
            dataIndex: "designation",
            width: "15%",
            render: (text, record) => {
              return (
                <Tooltip title={record?.designation}>
                  <div style={{ width: "7rem", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                    <span >{record?.designation}</span>
                  </div>
                </Tooltip>
              );
            },
          },
          {
            title: "Planned Hours",
            key: "plannedHours",
            dataIndex: "plannedHours",
            render: (text, record) => {
              return (
                <div>
                  <span>{record?.calculation?.plannedHour}</span>
                </div>
              );
            },
          },
          {
            title: "Progress",
            key: "Progress",
            dataIndex: "Progress",
            width: "13%",
            render: (text, record) => {
              return (
                <div style={{ height: 40, width: 40, display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <CircularProgressbar value={record?.calculation?.loading} text={`${record?.calculation?.loading}%`} />
                </div>
              );
            },
          },
          {
            title: "Actions",
            key: "actions",
            width: "13%",
            dataIndex: "actions",
            render: (text, record) => {
              console.log(record, "teamsByDepteamsByDepteamsByDep");
              let items = [
                {
                  key: "Logs",
                  label: <div style={{ cursor: 'pointer' }}
                  > Task</div>
                },
                {
                  key: "Calnedar",
                  label: <div style={{ cursor: 'pointer' }}
                  > Calendar</div>
                },
                (record?.release) && {
                  key: "Release",
                  label: <div style={{ cursor: 'pointer' }}
                  > Release</div>
                },
              ]

              /*  if (Object.keys(applyFilterData)?.length && applyFilterData?.release) {
                 items = [
                   {
                     key: "Logs",
                     label: <div style={{ cursor: 'pointer' }}
                     > Task</div>
                   },
                   {
                     key: "Calnedar",
                     label: <div style={{ cursor: 'pointer' }}
                     > Calendar</div>
                   },
                   {
                     key: "Release",
                     label: <div style={{ cursor: 'pointer' }}
                     > Release</div>
                   },
                 ]
               } else if (Object.keys(applyFilterData)?.length && applyFilterData?.release === false) {
                 items = [
                   {
                     key: "Logs",
                     label: <div style={{ cursor: 'pointer' }}
                     > Task</div>
                   },
                   {
                     key: "Calnedar",
                     label: <div style={{ cursor: 'pointer' }}
                     > Calendar</div>
                   }
                 ]
               } */
              return (
                <>
                  <Dropdown trigger="hover" menu={{ items, onClick: (key) => { DropdownHandler(key, record) } }}><div ><AiOutlineMore style={{ fontSize: '25px', fontWeight: '600' }} /></div></Dropdown>
                </>
              );
            },
          },
        ],
      },
    ];
  }, [applyFilterData?.isCoManager, applyFilterData?.release]);

  const ColumnsReleased = [
    {
      title: (
        <div className={classes.teamHeader}>
          <div>
            <h4>Released</h4>
          </div>
        </div>
      ),
      children: [
        {
          title: "Associate",
          key: "name",
          dataIndex: "name",

          render: (text, record) => {
            console.log("contacthhhhRR", record);

            return (
              <div style={{ display: "flex", flexDirection: "row", gap: "0.4rem", }}>
                <img src={UserIcon} style={{ width: "3rem", height: "3rem", borderRadius: "50%" }} />
                <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                  <Tooltip title={record?.name}>
                    <div style={{ fontSize: "0.85rem", fontWeight: "600", textOverflow: "ellipsis", width: "80%", overflow: "hidden", height: 20, whiteSpace: "nowrap" }}>
                      {record?.name}
                    </div>
                    <span>{record?.uid}</span>

                  </Tooltip>

                </div>
              </div>
            );
          },
        },
        {
          title: "Contact Details",
          key: "",
          dataIndex: "",
          render: (text, record) => {
            return (
              <Tooltip title={text?.email}>
                <div>
                  <div style={{ textOverflow: "ellipsis", width: "40%", textOverflow: "ellipsis", overflow: "hidden", height: 20, whiteSpace: "nowrap", width: "80%", }}>{text?.email}</div>

                  <span>{text?.phone}</span>
                </div>
              </Tooltip>
            );
          },
        },

        {
          title: "Status",
          key: "status",
          dataIndex: "status",
          render: (text, record) => {
            return (
              <div style={{ display: "flex", backgroundColor: colorCodes[record?.status], borderRadius: 10, alignItems: "center", justifyContent: "center", width: "70%", height: 30 }}>
                <Tooltip title={record?.status}>
                  <span style={{ fontSize: 10, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", width: "50%", alignItems: "center", justifyContent: "center" }}>{record?.status}</span>
                </Tooltip>
              </div>
            );
          },
        },
        {
          title: "Designation",
          key: "designation",
          dataIndex: "designation",
          render: (text, record) => {
            return (
              <Tooltip title={record?.designation}>
                <div style={{ width: "7rem", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                  <span >{record?.designation}</span>
                </div>
              </Tooltip>
            );
          },
        },
        {
          title: "Actions",
          key: "actions",
          width: "10%",
          dataIndex: "actions",
          render: (text, record) => {
            let items = [{

              key: "Calnedar",
              label: <div style={{ cursor: 'pointer' }}
              > Calendar</div>

            }]
            return (
              <>
                <Dropdown trigger="hover" menu={{ items, onClick: (key) => { DropdownHandler(key, record = { ...record, "released": true }) } }}><AiOutlineMore style={{ fontSize: '25px', fontWeight: '600' }} /></Dropdown>
              </>
            );
          },
        },
        /*  {
           title: "Actions",
           key: "actions",
           dataIndex: "actions",
           render: (text, record) => {
             return (
               <div className={classes.actions}>
                 <div
                   className={classes.calenderNav}
                   style={{ width: "1.5rem", height: "1.5rem" }}
                   onClick={() => {
                     setPage("calender");
                     setCalenderData(record);
                   }}
                 >
                   <img src={calender_brown_icon} />
                 </div>
               </div>
             );
           },
         }, */
      ],
    },
  ];

  return (
    <>
      {
        pageLoader ?
          <>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}>
              <Spin />
            </div>
          </>
          :
          <>
            {
              render.page === 1 ?
                <>
                  <>
                    {page === "homePage" && (
                      <div className={classes.container}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>

                          <h2>Active Teams</h2>


                          <div style={{ width: width < 700 ? "60%" : "40%", display: "flex", justifyContent: "flex-end", }}>

                            {width < 700 ?
                              tabs !== "List view team data" ?
                                <div
                                  style={{ backgroundColor: "#FFFFFF", borderRadius: 5, color: "#162432", height: width < 700 ? 30 : 40, marginRight: 20, width: 50, display: "flex", justifyContent: "center", alignItems: "center" }} onClick={() => applyFiltersModalHandler(true)}
                                >  <img src={Filter} style={{ height: "100%", width: "100%" }} />
                                </div>
                                :
                                <SearchCompo onSearch={setSearch} 
                                //value={search} 
                                isDisabled={(ids?.list === undefined && (!ids?.list?.length || ids?.list?.length))} placeholder="Search by associate name" setSearch={
                                  (sval) => {

                                    setSecLoader(true);

                                    getCandidateList((res) => {
                                      setIds({ ...ids, list: res });
                                      setSecLoader(false);
                                    }, ids?.teamIds, Tooltip, IoMdContact, Dropdown, DropdownHandler, AiOutlineMore, sval)
                                  }
                                } style={{ width: "30vw", marginRight: 10, height: "2.2rem" }} />
                              :
                              tabs !== "List view team data" ?
                                <button style={{ width: "30%", backgroundColor: "#FFFFFF", border: "1px solid #162432", borderRadius: 5, color: "#162432", height: 36, marginRight: 20 }} onClick={() => applyFiltersModalHandler(true)}>
                                  Filter By
                                </button>
                                :
                                <SearchCompo onSearch={setSearch} 
                                //value={search} 
                                isDisabled={(ids?.list === undefined && (!ids?.list?.length || ids?.list?.length))} placeholder="Search by associate name" setSearch={
                                  (sval) => {

                                    setSecLoader(true);
                                    getCandidateList((res) => {
                                      setIds({ ...ids, list: res });
                                      setSecLoader(false);
                                    }, ids?.teamIds, Tooltip, IoMdContact, Dropdown, DropdownHandler, AiOutlineMore, sval)
                                  }
                                } style={{ width: "25vw", marginRight: 10, height: "2.2rem" }} />
                            }
                            {(viewClass.superAdmin || viewClass.hod) && !(applyFilterData?.isCoManager) ? <button style={{ backgroundColor: "#0086FF", borderRadius: 5, color: "#FFFFFF", width: width < 700 ? "5rem" : "30%", border: 0, height: width < 700 ? 40 : null }} onClick={() => addTeamModalHandler(true)}>
                              +Add Team
                            </button> : null}

                          </div>

                        </div>
                        <div style={{ width: "100%", height: "fit-content", marginTop: 15, display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: width < 700 ? "column" : "row" }}>
                          <div style={{ width: width < 700 ? "80%" : 200 }}>
                            <div style={{ gap: "0.5rem", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                              <div onClick={() => { setTabs("Team") }} style={{ display: "flex", flexDirection: "column", fontSize: "16ppx" }}>
                                <div style={{ color: tabs === "Team" ? "#0086FF" : "#A6A9B8", cursor: "pointer", fontSize: width < 700 ? "19px" : "16px" }}>
                                  Team
                                </div>
                                {
                                  tabs === "Team" ?
                                    <div style={{ height: 1, border: "2px solid #0086FF", borderRadius: 5, }}></div>
                                    :
                                    <div style={{ height: 1 }}></div>
                                }
                              </div>
                              <div onClick={() => { setTabs("List view team data") }} style={{ display: "flex", flexDirection: "column", fontSize: "16px" }}>
                                <div style={{ color: tabs === "List view team data" ? "#0086FF" : "#A6A9B8", cursor: "pointer", fontSize: width < 700 ? "19px" : "16px" }}>
                                  List view team data
                                </div>
                                {
                                  tabs === "List view team data" ?
                                    <div style={{ height: 1, border: "2px solid #0086FF", borderRadius: 5 }}></div>
                                    :
                                    <div style={{ height: 1 }}></div>
                                }
                              </div>
                            </div>
                          </div>
                          <div>
                            {
                              tabs === "List view team data" ?
                                <>
                                  {
                                    width < 700 ?
                                      <>
                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginTop: 10 }}>
                                          <div >

                                            <Select
                                              style={{ width: 150, marginLeft: 5 }}
                                              size="medium"
                                              allowClear={true}
                                              value={ids?.deptId}

                                              placeholder="Select Department"
                                              onChange={(e) => {

                                                setIds({ teamIds: [], deptId: e })
                                                getTeamsList((res) => {
                                                  setTeamsDD(res?.allTeams)
                                                }, e)

                                              }}>
                                              {depts?.map((item, index) => {
                                                return (
                                                  <Option value={item?.id} key={index}>{item?.name}</Option>
                                                )
                                              })}
                                            </Select>

                                          </div>
                                          <div style={{ display: "flex", alignItems: "center" }}>

                                            <Select
                                              style={{ width: 200, marginLeft: 5 }}
                                              size="medium"
                                              allowClear={true}
                                              maxTagCount={'responsive'}
                                              mode='multiple'
                                              showSearch={false}
                                              value={ids?.teamIds}
                                              placeholder="Select Team "
                                              onChange={(e) => {


                                                setIds({ ...ids, teamIds: e })
                                                if (!e?.length)
                                                  setIds({ ...ids, list: undefined, teamIds: e })

                                              }}>
                                              {teamsDD?.map((item, index) => {
                                                return (
                                                  <Option value={item?.id} key={index}>{item?.name}</Option>
                                                )
                                              })}
                                            </Select>
                                          </div>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "flex-end", width: "100%", marginTop: 15 }}>
                                          <ResetButton
                                            disabled={!ids?.teamIds?.length}
                                            style={{ height: 32, borderRadius: 3, backgroundColor: "#0086FF" }}

                                            onClick={() => {

                                              setSecLoader(true)
                                              getCandidateList((res) => {
                                                setIds({ ...ids, list: res });
                                                setSecLoader(false);
                                              }, ids?.teamIds, Tooltip, IoMdContact, Dropdown, DropdownHandler, AiOutlineMore)
                                            }}
                                            title="Save"
                                          />

                                        </div>
                                      </>
                                      :
                                      <>
                                        <div style={{ display: "flex", alignItems: "center", gap: "2rem" }}>

                                          <div >

                                            <Select
                                              style={{ width: 150, marginLeft: 5 }}
                                              size="medium"
                                              allowClear={true}
                                              value={ids?.deptId}

                                              placeholder="Select Department"
                                              onChange={(e) => {

                                                setIds({ teamIds: [], deptId: e })
                                                getTeamsList((res) => {
                                                  setTeamsDD(res?.allTeams)
                                                }, e)

                                              }}>
                                              {depts?.map((item, index) => {
                                                return (
                                                  <Option value={item?.id} key={index}>{item?.name}</Option>
                                                )
                                              })}
                                            </Select>

                                          </div>
                                          <div style={{ display: "flex", alignItems: "center" }}>

                                            <Select
                                              style={{ width: 200, marginLeft: 5, height: 33 }}
                                              size="medium"
                                              allowClear={true}
                                              mode='multiple'
                                              showSearch={false}
                                              value={ids?.teamIds}
                                              maxTagCount={'responsive'}
                                              placeholder="Select Team "
                                              onChange={(e) => {
                                                setIds({ ...ids, teamIds: e })
                                                if (!e?.length)
                                                  setIds({ ...ids, list: undefined, teamIds: e })

                                              }}>
                                              {teamsDD?.map((item, index) => {
                                                return (
                                                  <Option value={item?.id} key={index}>{item?.name}</Option>
                                                )
                                              })}
                                            </Select>
                                          </div>
                                          <div style={{ display: "flex" }}>
                                            <ResetButton
                                              disabled={!ids?.teamIds?.length}
                                              style={{ height: 32, borderRadius: 3, backgroundColor: "#0086FF" }}
                                              // icon={<img src={reset_icon} />}    
                                              onClick={() => {
                                                setSecLoader(true)
                                                getCandidateList((res) => {
                                                  setIds({ ...ids, list: res });
                                                  setSecLoader(false)
                                                }, ids?.teamIds, Tooltip, IoMdContact, Dropdown, DropdownHandler, AiOutlineMore)
                                              }}
                                              title="Save"
                                            />

                                          </div>

                                        </div>
                                      </>
                                  }

                                </>

                                :
                                <div></div>
                            }
                          </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: 10 }}>
                          {tabs !== "List view team data" ?
                            applyFilterData?.startDate ? <div className={classes.dateRange}>
                              <h4>Date Range</h4>
                              <h4>{applyFilterData?.startDate} to {applyFilterData?.endDate}</h4>
                            </div> : <div></div> : null}


                        </div>

                        {
                          tabs === "Team" ?
                            <>
                              {
                                (
                                  <div className={classes.teamDiv}>
                                    {
                                      departments?.allDepartments?.length || applyFilterData?.teamDetails !== undefined ?
                                        <Collapse
                                          expandIcon={(e) => {
                                            return (
                                              <img src={!e.isActive ? PluseIcon : MinusIcon} />
                                            )

                                          }


                                          }
                                          expandIconPosition="end"
                                          accordion={true}
                                          activeKey={deptId}
                                          style={{ display: 'flex', flexDirection: 'column', gap: '10px', background: "#F8F8F8", padding: '16px 20px', borderRadius: '0px' }}
                                          onChange={(data) => {
                                            if (applyFilterData?.teamDetails === undefined) {
                                              setActiveRow(activeRow === data ? null : data);
                                              setDeptId(data[0]);
                                              console.log("ACTIVE ROW", data[0])
                                            }
                                          }}
                                        >


                                          {
                                            (Object.keys(applyFilterData).length && applyFilterData?.filter) ?
                                              <>
                                                {

                                                  applyFilterData?.allDepartments?.filter((item) => item?.id === deptId)?.map((val, index) => (
                                                    <>

                                                      <Panel
                                                        key={val?.id}
                                                        value={val?.id}
                                                        style={{ border: '1px solid #1624321a', background: '#FAFAFA', borderRadius: '10px', boxShadow: '0px 4px 8px #16243230', minHeight: '3rem', }}
                                                        header={<h4>Department: {val?.name}</h4>}
                                                      >
                                                        {!teamsByDep?.length &&
                                                          teamsByDepartment([{ ...applyFilterData?.teamDetails, teamId: applyFilterData?.teamDetails?.id, teamName: applyFilterData?.teamDetails?.name }])}

                                                        {teamsByDep?.filter(item => applyFilterData?.teamDetails?.id ? applyFilterData?.teamDetails?.id === item.teamId : item?.teamId !== applyFilterData?.teamDetails?.id)?.map((item, id) => {

                                                          let data = [];
                                                          item?.members?.forEach((val, id) => {
                                                            data.push({ ...val, teamId: item?.teamId, })
                                                          })

                                                          return (
                                                            <div className={classes.release} style={{ border: "1.5px solid #D4D4D4", padding: 10 }}>
                                                              {width < 700 ?
                                                                <>

                                                                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                    <div style={{ display: "flex", width: "80%" }}>
                                                                      <h4 style={{ color: "#162432", fontWeight: 500, width: '30%' }}>Team :</h4>
                                                                      <h4 style={{ color: "#162432", fontWeight: 700, width: '50%', overflow: "hidden" }}>{item?.teamName}</h4>
                                                                    </div>
                                                                    <div>

                                                                      {
                                                                        !(applyFilterData?.isCoManager) &&
                                                                        <button
                                                                          className={classes.editOutlined}

                                                                          onClick={() => {
                                                                            editTeamModalHandler(true);
                                                                            setTeam(item)
                                                                          }}
                                                                        >
                                                                          <img style={{ height: 15, width: 15 }} src={edit_black_icon} />

                                                                        </button>

                                                                      }
                                                                    </div>



                                                                  </div>
                                                                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>

                                                                    {(viewClass.superAdmin || viewClass.hod) && !(applyFilterData?.isCoManager) ?
                                                                      <button
                                                                        style={{ backgroundColor: "#FFFFFF", border: "1px solid #0086FF", color: "#0086FF", borderRadius: 5, height: 20 }}
                                                                        onClick={() => {
                                                                          // console.log(item,"meeeemmmmmm")
                                                                          addMembersModalHandler(item);
                                                                        }}
                                                                      >
                                                                        +Add Member
                                                                      </button> : null}

                                                                    <div
                                                                      style={{ color: "#000000", fontWeight: 400, marginTop: 10 }}
                                                                      onClick={() => {
                                                                        downloadModalHandler(true);
                                                                        setTeam(item);
                                                                      }}
                                                                    >


                                                                      <img
                                                                        style={{
                                                                          width: "1rem",
                                                                          height: "1rem",
                                                                          marginLeft: "0.5rem",
                                                                        }}
                                                                        src={downloadIconBlack}
                                                                      />
                                                                    </div>
                                                                  </div>
                                                                  {handelPagination(applyFilterData?.teamDetails !== undefined ? applyFilterData?.teamDetails?.members : data, pageNo)?.length ?
                                                                    handelPagination(applyFilterData?.teamDetails !== undefined ? applyFilterData?.teamDetails?.members : data, pageNo)?.map((item, index) => (
                                                                      <MobileCard
                                                                        headerLeft={
                                                                          (
                                                                            <>
                                                                              <div style={{ display: "flex", flexDirection: "row", gap: "0.4rem" }}>
                                                                                <img
                                                                                  src={UserIcon}
                                                                                  style={{ width: "3rem", height: "3rem", borderRadius: "50%" }}
                                                                                />
                                                                                <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                                                                                  <span style={{ fontSize: "0.85rem", fontWeight: "600", display: "flex", flexWrap: "wrap", wordWrap: "break-word", wordBreak: "break-all" }}>
                                                                                    {item?.name}
                                                                                  </span>
                                                                                  <span>{item?.uid}</span>
                                                                                </div>
                                                                              </div>
                                                                            </>
                                                                          )
                                                                        }
                                                                        headerRight={
                                                                          (
                                                                            <div style={{ height: "fit-content" }}>
                                                                              <Dropdown trigger="hover" menu={{ items, onClick: (key) => { DropdownHandler(key, item) } }}><div ><AiOutlineMore style={{ fontSize: '25px', fontWeight: '600' }} /></div></Dropdown>
                                                                            </div>
                                                                          )
                                                                        }
                                                                        body={
                                                                          (
                                                                            <>
                                                                              <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                                <div style={{ width: "30%", color: "#B1B0B0" }}>Email : </div>
                                                                                <div title={item?.email} style={{ width: "30%", display: "flex", justifyContent: "flex-start", flexWrap: "wrap", wordWrap: "break-word", wordBreak: "break-all" }}>{item?.email}</div>
                                                                              </div>
                                                                              <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                                <div style={{ width: "30%", color: "#B1B0B0" }}>Phone : </div>
                                                                                <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item?.phone}</div>
                                                                              </div>
                                                                              <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                                <div style={{ width: "30%", color: "#B1B0B0" }}>Role : </div>
                                                                                <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item?.role}</div>
                                                                              </div>
                                                                              <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                                <div style={{ width: "30%", color: "#B1B0B0" }}>Status : </div>
                                                                                <div style={{ display: "flex", backgroundColor: colorCodes[item?.status], borderRadius: 10, alignItems: "center", justifyContent: "center", width: "60%", height: 30 }}>
                                                                                  <span style={{ fontSize: 10 }}>{item?.status}</span>
                                                                                </div>
                                                                              </div>
                                                                              <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                                <div style={{ width: "30%", color: "#B1B0B0" }}>Designation : </div>
                                                                                <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item?.designation}</div>
                                                                              </div>
                                                                              <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                                <div style={{ width: "30%", color: "#B1B0B0" }}>Planned Hours : </div>
                                                                                <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item?.calculation?.plannedHour}</div>
                                                                              </div>
                                                                              <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                                <div style={{ width: "30%", color: "#B1B0B0" }}>Progress : </div>
                                                                                <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>  <Progress percent={item?.loading} color="blue" /></div>
                                                                                {/* <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item?.loading}</div> */}
                                                                              </div>
                                                                            </>
                                                                          )
                                                                        }
                                                                      />
                                                                    ))
                                                                    :
                                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                      <NoData />
                                                                    </div>
                                                                  }
                                                                  <Divider />
                                                                  <div className={classes.teamHeader}>
                                                                    <div>
                                                                      <h4>Released</h4>
                                                                    </div>
                                                                  </div>
                                                                  <>
                                                                    {item?.releasedMembers?.length ?
                                                                      item?.releasedMembers?.map((item, index) => {
                                                                        let items = [{
                                                                          key: "Calnedar",
                                                                          label: <div style={{ cursor: 'pointer' }}
                                                                          > Calendar</div>

                                                                        }]
                                                                        return (
                                                                          <MobileCard
                                                                            headerLeft={
                                                                              (
                                                                                <>
                                                                                  <div style={{ display: "flex", flexDirection: "row", gap: "0.4rem" }}>
                                                                                    <img
                                                                                      src={UserIcon}
                                                                                      style={{ width: "3rem", height: "3rem", borderRadius: "50%" }}
                                                                                    />
                                                                                    <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                                                                                      <span style={{ fontSize: "0.85rem", fontWeight: "600", }}>
                                                                                        {item?.name}
                                                                                      </span>
                                                                                      <span>{item?.uid}</span>
                                                                                    </div>
                                                                                  </div>
                                                                                </>
                                                                              )
                                                                            }
                                                                            headerRight={(
                                                                              <div style={{ height: "fit-content" }}>
                                                                                <>
                                                                                  <Dropdown trigger="hover" menu={{ items, onClick: (key) => { DropdownHandler(key, item = { ...item, "released": true }) } }}><AiOutlineMore style={{ fontSize: '25px', fontWeight: '600' }} /></Dropdown>
                                                                                </>
                                                                              </div>
                                                                            )

                                                                            }
                                                                            body={
                                                                              (
                                                                                <>
                                                                                  <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                                    <div style={{ width: "30%", color: "#B1B0B0" }}>Email : </div>
                                                                                    <div title={item?.email} style={{ width: "60%", display: "flex", justifyContent: "flex-start", flexWrap: "wrap", wordWrap: "break-word", wordBreak: "break-all" }}>{item?.email}</div>
                                                                                  </div>
                                                                                  <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                                    <div style={{ width: "30%", color: "#B1B0B0" }}>Phone : </div>
                                                                                    <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item?.phone}</div>
                                                                                  </div>
                                                                                  <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                                    <div style={{ width: "30%", color: "#B1B0B0" }}>Role : </div>
                                                                                    <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item?.role}</div>
                                                                                  </div>
                                                                                  <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                                    <div style={{ width: "30%", color: "#B1B0B0" }}>Status : </div>
                                                                                    <div style={{ display: "flex", backgroundColor: colorCodes[item?.status], borderRadius: 10, alignItems: "center", justifyContent: "center", width: "60%", height: 30 }}>
                                                                                      <span style={{ fontSize: 10 }}>{item?.status}</span>
                                                                                    </div>
                                                                                  </div>
                                                                                  <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                                    <div style={{ width: "30%", color: "#B1B0B0" }}>Designation : </div>
                                                                                    <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item?.designation}</div>
                                                                                  </div>


                                                                                </>
                                                                              )
                                                                            }
                                                                          />
                                                                        )
                                                                      }
                                                                      )
                                                                      :
                                                                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                        <NoData />
                                                                      </div>
                                                                    }
                                                                  </>
                                                                </>
                                                                : <Table
                                                                  style={{ width: "100%" }}
                                                                  dataSource={handelPagination(applyFilterData?.teamDetails !== undefined ? applyFilterData?.teamDetails?.members : data, pageNo)}
                                                                  columns={Colums(item, index, teamsByDep)?.map((col) => ({
                                                                    ...col,
                                                                    align: "center",
                                                                  }))}
                                                                  scroll={{ y: 350 }}
                                                                  pagination={{
                                                                    position: "bottomRight",
                                                                    total: applyFilterData?.teamDetails !== undefined ? applyFilterData?.teamDetails?.members?.length : data?.length,
                                                                    size: "small",
                                                                    onChange: (page, pageSize) => {
                                                                      setPageNo(page)
                                                                    },

                                                                    // showSizeChanger: true,
                                                                    showTotal: (total, range) =>
                                                                      `${range[0]}-${range[1]} of ${total} items`,
                                                                  }}
                                                                  rowKey={({ s_no }) => s_no}
                                                                />}
                                                              {
                                                                !(width < 700) &&
                                                                <>
                                                                  <Divider />
                                                                  <Table
                                                                    dataSource={item?.releasedMembers}
                                                                    columns={ColumnsReleased}
                                                                    scroll={{ y: 350 }}
                                                                    /*  pagination={{
                                                                       position: "bottomRight",
                                                                       total:item?.releasedMembers?.length,
                                                                       size: "small",
                                                                       onChange: (page, pageSize) => {
                                                                         setPageNo(page)
                                                                       },
                                                                   
                                                                       // showSizeChanger: true,
                                                                       showTotal: (total, range) =>
                                                                         `${range[0]}-${range[1]} of ${total} items`,
                                                                     }} */
                                                                    rowKey={({ s_no }) => s_no}
                                                                  />
                                                                </>
                                                              }
                                                            </div>
                                                          );
                                                        })}
                                                      </Panel>
                                                    </>
                                                  ))
                                                }
                                              </>
                                              :
                                              <>

                                                {
                                                  departments?.allDepartments?.map((val, index) => (
                                                    <>
                                                      {console.log(val, "valllaaaaassss")}
                                                      <Panel
                                                        key={val?.id}
                                                        value={val?.id}
                                                        style={{ border: '1px solid #1624321a', background: '#FAFAFA', borderRadius: '10px', boxShadow: '0px 4px 8px #16243230', minHeight: '3rem', }}
                                                        header={<h4>Department: {val?.name}</h4>}
                                                      >
                                                        {
                                                          loader ?
                                                            <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}>
                                                              <Spin />
                                                            </div> :
                                                            <>
                                                              {teamsByDep?.filter(item => applyFilterData?.teamDetails?.id ? applyFilterData?.teamDetails?.id === item.teamId : item?.teamId !== applyFilterData?.teamDetails?.id)?.map((item) => {

                                                                let data = [];
                                                                item?.members?.forEach((val, id) => {
                                                                  data.push({ ...val, teamId: item?.teamId })
                                                                })
                                                                console.log(data, "00000000000000");
                                                                return (
                                                                  <div className={classes.release} style={{ border: "1.5px solid #D4D4D4", padding: 10 }}>
                                                                    {width < 700 ?
                                                                      <>

                                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                          <div style={{ display: "flex", width: "80%" }}>
                                                                            <h4 style={{ color: "#162432", fontWeight: 500, width: '30%' }}>Team :</h4>
                                                                            <h4 style={{ color: "#162432", fontWeight: 700, width: '50%', overflow: "hidden" }}>{item?.teamName}</h4>
                                                                          </div>
                                                                          <button
                                                                            className={classes.editOutlined}
                                                                            onClick={() => {
                                                                              editTeamModalHandler(true);
                                                                              setTeam(item)
                                                                            }}
                                                                          >
                                                                            <img style={{ height: 15, width: 15 }} src={edit_black_icon} />
                                                                            <span style={{ marginLeft: 5 }}>Edit b</span>

                                                                          </button>




                                                                        </div>
                                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>

                                                                          {(viewClass.superAdmin || viewClass.hod) && !(departments?.isCoManager) ?
                                                                            <button
                                                                              style={{ height: "fit-content", backgroundColor: "#FFFFFF", border: "1px solid #0086FF", color: "#0086FF", borderRadius: 5, padding: 5 }}
                                                                              onClick={() => {
                                                                                // console.log(item,"meeeemmmmmm")
                                                                                addMembersModalHandler(item);
                                                                              }}
                                                                            >
                                                                              +Add Member
                                                                            </button> : null}

                                                                          <div
                                                                            style={{ color: "#000000", fontWeight: 400 }}
                                                                            onClick={() => {
                                                                              downloadModalHandler(true);
                                                                              setTeam(item);
                                                                            }}
                                                                          >


                                                                            <img
                                                                              style={{
                                                                                width: "1rem",
                                                                                height: "1rem",
                                                                                marginLeft: "0.5rem",
                                                                              }}
                                                                              src={downloadIconBlack}
                                                                            />
                                                                            <span style={{ marginLeft: 5, color: "#0086FF" }}>Download</span>
                                                                          </div>
                                                                        </div>
                                                                        {
                                                                          applyFilterData?.teamDetails !== undefined ? applyFilterData?.teamDetails?.members : data?.length ?
                                                                            applyFilterData?.teamDetails !== undefined ? applyFilterData?.teamDetails?.members : data.map((item, index) => (
                                                                              <MobileCard
                                                                                headerLeft={
                                                                                  (
                                                                                    <>
                                                                                      <div style={{ display: "flex", flexDirection: "row", gap: "0.4rem" }}>
                                                                                        <img
                                                                                          src={UserIcon}
                                                                                          style={{ width: "3rem", height: "3rem", borderRadius: "50%" }}
                                                                                        />
                                                                                        <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                                                                                          <span style={{ fontSize: "0.85rem", fontWeight: "600", flexWrap: "wrap", wordBreak: "break-all" }}>
                                                                                            {item?.name}
                                                                                          </span>
                                                                                          <span>{item?.uid}</span>
                                                                                        </div>
                                                                                      </div>
                                                                                    </>
                                                                                  )
                                                                                }
                                                                                headerRight={
                                                                                  (
                                                                                    <div style={{ height: "fit-content" }}>
                                                                                      <Dropdown trigger="hover" menu={{ items, onClick: (key) => { DropdownHandler(key, item) } }}><div ><AiOutlineMore style={{ fontSize: '25px', fontWeight: '600' }} /></div></Dropdown>
                                                                                    </div>
                                                                                  )
                                                                                }
                                                                                body={
                                                                                  (
                                                                                    <>
                                                                                      <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                                        <div style={{ width: "30%", color: "#B1B0B0" }}>Email : </div>
                                                                                        <div title={item?.email} style={{ width: "60%", display: "flex", justifyContent: "flex-start", flexWrap: "wrap", wordWrap: "break-word", wordBreak: "break-all" }}>{item?.email}</div>
                                                                                      </div>
                                                                                      <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                                        <div style={{ width: "30%", color: "#B1B0B0" }}>Phone : </div>
                                                                                        <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item?.phone}</div>
                                                                                      </div>
                                                                                      <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                                        <div style={{ width: "30%", color: "#B1B0B0" }}>Role : </div>
                                                                                        <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item?.role}</div>
                                                                                      </div>
                                                                                      <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                                        <div style={{ width: "30%", color: "#B1B0B0" }}>Status : </div>
                                                                                        <div style={{ display: "flex", backgroundColor: colorCodes[item?.status], borderRadius: 10, alignItems: "center", justifyContent: "center", width: "60%", height: 30 }}>
                                                                                          <span style={{ fontSize: 10 }}>{item?.status}</span>
                                                                                        </div>
                                                                                      </div>
                                                                                      <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                                        <div style={{ width: "30%", color: "#B1B0B0" }}>Designation : </div>
                                                                                        <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item?.designation}</div>
                                                                                      </div>
                                                                                      <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                                        <div style={{ width: "30%", color: "#B1B0B0" }}>Planned Hours : </div>
                                                                                        <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item?.calculation?.plannedHour}</div>
                                                                                      </div>
                                                                                      <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                                        <div style={{ width: "30%", color: "#B1B0B0" }}>Progress : </div>
                                                                                        <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>  <Progress percent={item?.loading} color="blue" /></div>

                                                                                        {/* <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item?.loading}</div> */}
                                                                                      </div>
                                                                                    </>
                                                                                  )
                                                                                }
                                                                              />
                                                                            ))
                                                                            :
                                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                              <NoData />
                                                                            </div>
                                                                        }
                                                                        <Divider />
                                                                        <div className={classes.teamHeader}>
                                                                          <div>
                                                                            <h4>Released</h4>
                                                                          </div>
                                                                        </div>
                                                                        <>
                                                                          {
                                                                            item?.releasedMembers?.length ?
                                                                              item?.releasedMembers?.map((item, index) => {
                                                                                let items = [{

                                                                                  key: "Calnedar",
                                                                                  label: <div style={{ cursor: 'pointer' }}
                                                                                  > Calendar</div>

                                                                                }]
                                                                                return (
                                                                                  <MobileCard
                                                                                    headerLeft={
                                                                                      (
                                                                                        <>
                                                                                          <div style={{ display: "flex", flexDirection: "row", gap: "0.4rem" }}>
                                                                                            <img
                                                                                              src={UserIcon}
                                                                                              style={{ width: "3rem", height: "3rem", borderRadius: "50%" }}
                                                                                            />
                                                                                            <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                                                                                              <span style={{ fontSize: "0.85rem", fontWeight: "600", }}>
                                                                                                {item?.name}
                                                                                              </span>
                                                                                              <span>{item?.uid}</span>
                                                                                            </div>
                                                                                          </div>
                                                                                        </>
                                                                                      )
                                                                                    }
                                                                                    headerRight={

                                                                                      (
                                                                                        <div style={{ height: "fit-content" }}>
                                                                                          <>
                                                                                            <Dropdown trigger="hover" menu={{ items, onClick: (key) => { DropdownHandler(key, item = { ...item, "released": true }) } }}><AiOutlineMore style={{ fontSize: '25px', fontWeight: '600' }} /></Dropdown>
                                                                                          </>
                                                                                        </div>
                                                                                      )

                                                                                    }
                                                                                    body={
                                                                                      (
                                                                                        <>
                                                                                          <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                                            <div style={{ width: "30%", color: "#B1B0B0" }}>Email : </div>
                                                                                            <div title={item?.email} style={{ width: "60%", display: "flex", justifyContent: "flex-start", flexWrap: "wrap", wordWrap: "break-word", wordBreak: "break-all" }}>{item?.email}</div>
                                                                                          </div>
                                                                                          <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                                            <div style={{ width: "30%", color: "#B1B0B0" }}>Phone : </div>
                                                                                            <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item?.phone}</div>
                                                                                          </div>
                                                                                          <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                                            <div style={{ width: "30%", color: "#B1B0B0" }}>Role : </div>
                                                                                            <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item?.role}</div>
                                                                                          </div>
                                                                                          <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                                            <div style={{ width: "30%", color: "#B1B0B0" }}>Status : </div>
                                                                                            <div style={{ display: "flex", backgroundColor: colorCodes[item?.status], borderRadius: 10, alignItems: "center", justifyContent: "center", width: "60%", height: 30 }}>
                                                                                              <span style={{ fontSize: 10 }}>{item?.status}</span>
                                                                                            </div>
                                                                                          </div>
                                                                                          <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                                                                                            <div style={{ width: "30%", color: "#B1B0B0" }}>Designation : </div>
                                                                                            <div style={{ width: "60%", display: "flex", justifyContent: "flex-start" }}>{item?.designation}</div>
                                                                                          </div>
                                                                                        </>
                                                                                      )
                                                                                    }
                                                                                  />
                                                                                )
                                                                              }


                                                                              )
                                                                              :
                                                                              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                                <NoData />
                                                                              </div>
                                                                          }
                                                                        </>
                                                                      </>
                                                                      : <Table
                                                                        style={{ width: "100%" }}
                                                                        dataSource={handelPagination(applyFilterData?.teamDetails !== undefined ? applyFilterData?.teamDetails?.members : data, pageNo)}
                                                                        columns={Colums(item, index, teamsByDep)?.map((col) => ({
                                                                          ...col,
                                                                          align: "center",
                                                                        }))}
                                                                        scroll={{ y: 350 }}
                                                                        pagination={{
                                                                          position: "bottomRight",
                                                                          total: applyFilterData?.teamDetails !== undefined ? applyFilterData?.teamDetails?.members?.length : data?.length,
                                                                          size: "small",
                                                                          onChange: (page, pageSize) => {
                                                                            setPageNo(page)
                                                                          },

                                                                          // showSizeChanger: true,
                                                                          showTotal: (total, range) =>
                                                                            `${range[0]}-${range[1]} of ${total} items`,
                                                                        }}
                                                                        rowKey={({ s_no }) => s_no}
                                                                      />}
                                                                    {
                                                                      !(width < 700) &&
                                                                      <>
                                                                        <Divider />
                                                                        <Table
                                                                          dataSource={item?.releasedMembers}
                                                                          columns={ColumnsReleased}
                                                                          scroll={{ y: 350 }}
                                                                          /*  pagination={{
                                                                             position: "bottomRight",
                                                                             pageSize: 25,
                                                                             size: "small",
                                                                             pageSizeOptions: [10, 30, 50, 100],
                                                                             showSizeChanger: true,
                                                                             showTotal: (total, range) =>
                                                                               `${range[0]}-${range[1]} of ${total} items`,
                                                                           }} */
                                                                          rowKey={({ s_no }) => s_no}
                                                                        />
                                                                      </>
                                                                    }
                                                                  </div>
                                                                );
                                                              })}

                                                            </>
                                                        }
                                                      </Panel>
                                                    </>
                                                  ))
                                                }
                                              </>
                                          }
                                        </Collapse>
                                        :
                                        <NoData />
                                    }
                                  </div >
                                )
                              }
                            </>
                            :
                            tabs === "List view team data" ?
                              <div style={{ marginTop: 10 }}>
                                <ListViewTeamData secLoader={secLoader} ids={ids} />
                              </div>
                              :
                              <></>
                        }
                        <div></div>
                        
                        {isAddTeamModal && <AddTeamModal
                          initialDepartment={departments?.allDepartments?.filter(item => item.id === parseInt(deptId))?.[0]}
                          team={team}
                          refresh={refresh}
                        />}
                        {isEditTeamModal && <AddTeamModal
                          initialDepartment={departments?.allDepartments?.filter(item => item.id === parseInt(deptId))?.[0]}
                          team={team}
                          refresh={refresh}
                        />}
                        {isAddMembersModal && <AddMembersModal
                          department={departments?.allDepartments?.filter(item => item.id === parseInt(deptId))?.[0]}
                          refresh={refresh}
                        />}
                        {isApplyFiltersModal && <ApplyFiltersModal
                          isApplied={applyFilterData?.teamDetails !== undefined}
                          applyFilterDataHandler={applyFilterDataHandler}
                          applyFilterData={applyFilterData}
                          getApplyFilterData={getApplyFilterData}
                          role={role}
                          setRole={setRole}
                          deptId={deptId}
                          setDeptId={setDeptId}
                          managerId={managerId}
                          setManagerId={setManagerId}
                          teamId={teamId}
                          setTeamId={setTeamId}
                          setLoader={setLoader}
                        />}
                        {isReleaseModal && <ReleaseModal
                          team={teamsByDep}
                          memberId={memberId}
                          deptId={deptId}
                          department={applyFilterData?.departmentDetails}
                          role={role}
                          refresh={refresh}
                          setLoader={setLoader}
                          teamsByDepartment={teamsByDepartment}
                        />}
                        {
                          deleteModal&&
                          <ModalCompo closableRight={true}
                          header={<div
                            style={{
                              color: "#FF3434",
                              font: "normal 600 20px/30px Poppins",
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                              width: "100%",
                              justifyContent: "center",
                              // marginBottom: "10px",
                            }}
                          >
                            {/* <img src={deleteIcon} /> */}
                            <MdDeleteOutline color="red" />
                            <div> Delete</div>
                          </div>}
                          open={deleteModal !== undefined}
                          onCancel={() => {
                            setDeleteModal(false);
                          }}
                          closable={false}
                          contentStyle={{ width: width > 700 && "850px", top: "25vh", padding: "13px", borderRadius: "8px", margin: width < 700 && 15 }}
                        >
                          <div className={classes.confirmationModalMainDiv} style={{
                            width: "100%", alignItems: "center",
                            display: "flex", flexDirection: "column", justifyContent: "center"
                          }}>
                  
                            <div
                              style={{
                                border: "1px solid #F1F1F1",
                                width: "100%",
                                marginBottom: "20px",
                              }}
                            ></div>
                            <div
                              style={{ font: "normal 590 17px/27px Poppins", color: "#162432" }}
                            >
                           
                              Are you sure you want to delete{" "}{team?.teamName}
                             
                           
                            </div>
                            {/* <div style={{display:'flex',justifyContent:'center',alignItem:'center',gap:'1rem',font:"normal 500 24px/36px Poppins",color:"#162432"}}><div> web access</div></div> */}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItem: "center",
                                gap: "1.5rem",
                                marginTop: "3rem",
                                marginBottom: "1.5rem", height: "2.1rem"
                              }}
                            >
                              <ButtonCompo
                                title={"Yes"}
                                disabled={loaderD}
                                style={{ backgroundColor: "#0086FF", color: "#fff", width: "5rem" }}
                                onClick={() => {
                                  onDeleteTeam();
                                }}
                              ></ButtonCompo>
                              <ButtonCompo
                                title={"No"}
                              
                                style={{ border: "1px solid #0086FF", color: "#0086FF", width: "5rem" }}
                                onClick={() => {
                                  setDeleteModal(false);
                                }}
                              ></ButtonCompo>
                            </div>
                          </div>
                        </ModalCompo>
                        }
                       
                        <Modal
                          show={downloadModal}
                          closableRight={true}
                          // style={{}}
                          onCancel={() => downloadModalHandler(false)}
                          header={
                            <div className={classes.downloadHeader}>
                              <h2 style={{ fontSize: "1.1rem" }}>Download</h2>
                            </div>
                          }
                        >
                          {downloadModal && (
                            <Form
                              onFinish={(event) => {
                                // console.log("EVENT DOWNLOAD", team);
                                if (event["downloadType"] === "Leave History") {
                                  downloadLeaveHistory(team?.teamId, Api, { startDate: applyFilterData?.startDate, endDate: applyFilterData?.endDate });
                                }
                                if (event["downloadType"] === "Leave Summary") {
                                  downloadLeaveSummery(team?.teamId, Api, { startDate: applyFilterData?.startDate, endDate: applyFilterData?.endDate });
                                }
                                if (event["downloadType"] === "Team Data") {
                                  downloadTeamData(team?.teamId, Api, { startDate: applyFilterData?.startDate, endDate: applyFilterData?.endDate });
                                }
                                downloadModalHandler(false);
                                form.resetFields(["downloadType"] === undefined);
                              }}
                              form={form}
                              style={{ padding: "1rem 2rem 2rem 2rem", width: "70vw" }}
                            >
                              {console.log(applyFilterData, 'applyFilterDataapplyFilterDataapplyFilterDataapplyFilterData000')}
                              <Form.Item
                                style={{ width: width < 700 ? '100%' : "20%", margin: width < 700 ? '0 auto 20 auto' : "0 auto 2rem auto", display: 'flex' }}
                                name="downloadType"
                                rules={[{ required: true, message: "Select an option!" }]}
                                initialValue={"Leave History"}
                              >
                                <Radio.Group
                                  style={{
                                    display: "flex",
                                    flexDirection: width < 700 ? 'row' : "column",
                                    alignItems: width < 700 ? 'center' : 'start',
                                    gap: width < 700 ? '0rem' : "1rem",
                                  }}

                                >
                                  <Radio value="Leave History" >Leave History</Radio>
                                  <Radio value="Leave Summary">Leave Summary</Radio>
                                  <Radio value="Team Data" >Team Data</Radio>
                                </Radio.Group>
                              </Form.Item>

                              <div
                                style={{
                                  display: "flex",
                                  gap: "1rem",
                                  justifyContent: "center",
                                }}
                              >
                                <ModalPrimaryButton htmlType="submit">
                                  Download
                                </ModalPrimaryButton>
                                <ModalSecondaryButton
                                  onCancel={() => {
                                    downloadModalHandler(false);
                                    form.resetFields(["downloadType"] === undefined);
                                  }}
                                >
                                  Cancel
                                </ModalSecondaryButton>
                              </div>
                            </Form>
                          )}
                        </Modal>
                      </div>
                    )}
                    {page === "calender" && (
                      <Calender calenderData={calenderData} setPage={setPage} />
                    )}
                  </>
                </> :
                <>
                  <EmployeeSummaryTeam data={render} setRender={setRender} />
                </>
            }
          </>
      }


    </>

  );
};
export default Teams;