import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Login from "../pages/Login/login";
import Forgot from "../pages/Login/forgotPassword";
import CheckMail from "../pages/Login/checkMail";
import PasswordReset from "../pages/Login/passwordReset";
import SetNewPassword from "../pages/Login/setNewPassword";
import MainPage from "../pages/ResourceManagement/mainPage";
import HomePage from "../pages/Homepage/homepage";
import Dashboard from "../pages/ResourceManagement/SubComponents/Dashboard/dashboard";
import PMDashboard from '../pages/ProjectManagement/Dashboard/Dashboard';
import PMDepartment from '../pages/ProjectManagement/Departments/Departments';
import Employees from "../pages/ResourceManagement/SubComponents/Employees/employees";
import Feedback from "../pages/ResourceManagement/SubComponents/Feedback/feedback";
import DepartmentApproval from "../pages/ResourceManagement/SubComponents/Department_Approval/department-approval";
import ProjectMainPage from "../pages/ProjectManagement/mainPage";
import Teams from "../pages/ProjectManagement/Team/Team";
import Projects from "../pages/ProjectManagement/Projects/Projects";
import Logs from "../pages/ProjectManagement/Logs/Logs";
import Leave from "../pages/ProjectManagement/Leave/Leave";
import Release from "../pages/ProjectManagement/Release/Release";
import MigrateManager from "../pages/ProjectManagement/MigrateManager/MigrateManager";
import AuthLayout from "../pages/AuthLayout";
import Calender from "../pages/ProjectManagement/Team/Calender/Calender";
import LogsMain from "../pages/ProjectManagement/Logs/LogsMain";
import AttendanceMainPage from "../pages/AttendancePortal/mainPage";
import Authentication from "../pages/AttendancePortal/Authentication/authentication";
// import ForgotPunch from "../pages/AttendancePortal/ForgotPunch/forgotpunch";
import AttendanceLeave from "../pages/AttendancePortal/Leave/main";
import AttendanceLogs from "../pages/AttendancePortal/Logs/logs";
import AttendanceLogsCalendar from "../pages/AttendancePortal/Logs/calendarIconPage";
import CommonComponent from "../pages/AttendancePortal/ForgotandOutdoorPunch/forgotAndOutdoorPunch";
import Settings from "../pages/AttendancePortal/Settings/settings";
import Users from "../pages/AttendancePortal/Users/users";
import FeedbackManagement from "../pages/FeedbackManagement/FeedbackManagement";
import FbRound from "../pages/FeedbackManagement/feedbackRound";
import FbPreview from "../pages/FeedbackManagement/feedbackpreview";
import FbPublish from "../pages/FeedbackManagement/FeedbackPublish";
import AllRequest from "../pages/Notification/AllRequest";
import Newsletter from "../pages/Newsletter/mainPage";
import Attendance from "../pages/Attendance/MainPage";
import PrivateRoute from "./PrivateRoute";
import AuthenticationConfirm from "../pages/AuthenticationConfirmPage/authenticationConfirm";
import useStore from "../pages/store";
import Api from "../services/Api";
import TrainingEssentialDocuments from "../pages/Training_essentialDocuments";
import EditProfile from "../pages/ResourceManagement/SubComponents/Employees/EditProfile";
import HealthInsurance from "../pages/HealthInsurance/HealthInsurance";
import FillProfile from "../pages/ResourceManagement/SubComponents/Employees/FillProfile";
import CeoDesk from "../pages/Ceo-Desk/ceoDesk";
import Appraisal from "../pages/Appraisal/mainPage";
import Admin from "../pages/Appraisal/admin/Users/users";
import AppraisalCycle from "../pages/Appraisal/admin/AppraisalCycle/appraisal-cycle";
import UserProfile from "../pages/userProfile/userProfile";
import ThankYou from "../pages/ResourceManagement/SubComponents/Employees/ThankYou";
import EmployeeSummaryTeam from "../pages/ProjectManagement/Logs/employSummaryteams";
import Team from "../pages/Appraisal/admin/Team/team";
import AssociateDetails from "../pages/Appraisal/admin/Users/associateDetails";
import AssociateHome from "../pages/Appraisal/associate/associate-home"
import AssociateList from "../pages/Appraisal/manager/associate-list"
import Manager from "../pages/Appraisal/manager/manager"
import viewClass from "../services/ViewRole";
import ManagerAppraisalForm from "../pages/Appraisal/manager/manager-appraisal-form";
import AssociateRank from "../pages/Appraisal/manager/associate-rank";
import PerformanceMatrix from "../pages/Appraisal/manager/performance-matrix";
import AppraisalPrivateRoute from "../pages/Appraisal/AppraisalPrivateRoute";

import EditRender from "../pages/ResourceManagement/SubComponents/Employees/EditRender";
 import NotFound from "../pages/NoData/noData";
import NotificationRequest from "../pages/Notification/NotificationRequest";
import FPDashboard from "../pages/Ceo-Desk/Dashboard/Dashboard";
import FPDepartment from "../pages/Ceo-Desk/Department/Department";
import FPAssociates from "../pages/Ceo-Desk/Associates/Associates";
import FeedbackMainPage from "../pages/Ceo-Desk/MainPage";
import AlternatePrivateRoute from "../pages/Appraisal/AlternatePrivateRoute";
import PDFReader from "./PDFReader/PDFReader";
import RejectRequest from "../pages/RejectRequest";
import ApproveRequest from "../pages/ApproveRequest";
import VerifyEmailPage from "../pages/VerifyEmailPage";
import { SECRET_KEY, decryptData } from "../services/fileCode";
import Feed from "../pages/Login/Feed/Feed";
import CoolLightbox from "./LightBox/LightBox";
import ReactivationAccount from "../pages/Login/ReactivationAccount";
import Magazine from "../pages/Login/Feed/Magazine";
import SignInModal from "../pages/Login/signInModal";
import AttendanceYearlyLogs from "../pages/AttendancePortal/YearlyLogs/YearlyLogs";
// import ConferenceIndex from "../pages/conference";
// import Room from "../pages/conference/conferenceRoom";
// import NotFound from "./NotFoundPage";
// conference/conferenceRoom
const PMTRoutes = () => {

  // const { token } = useStore(state=>state.token);


 let u=localStorage.getItem('user');
 let user;
 if(u){
     user=decryptData(u,SECRET_KEY);
 }
 console.log("USERUSER",user)
  // useEffect(() => {
  //   if (token) {
  //     Api.header(token);
  //   }
  // }, [token]);

  return (
    <Routes>
      <Route path="*" element={<NotFound/>}/>
      {/* <Route path="/meet/brigosha" element={<ConferenceIndex/>} />  */}
      {/* <Route path="/room" element={<Room/>} />  */}
      <Route path="/login" element={<Login />} />
      <Route path="/login/signin" element={<SignInModal />} />
      <Route path="/magazine" element={<Magazine/>}/>
      <Route path="/forgotPassword" element={<Forgot />} />
      <Route path="/checkMail" element={<CheckMail />} />
      <Route path="/passwordReset" element={<PasswordReset />} />
      <Route path="/auth-auth/checktoken" element={<SetNewPassword />} />
      <Route path="/profileDetails" element={<FillProfile />} />
      <Route path="/ThankYou" element={<ThankYou />} />
      {/* <Route path="/employDetails" element={<EditProfile data={{id:10}} />} /> */}
      <Route path="/pdfReader" element={<PDFReader/>}/>
      <Route path="/mobile-auth/verify" element={<AuthenticationConfirm />} />
      <Route path="/rejectRequest" element={<RejectRequest />} />
      <Route path="/acceptRequest" element={<ApproveRequest />} />
      <Route path="/verifyEmail" element={<VerifyEmailPage />} />
      <Route path="/authorizeReactivation" element={<ReactivationAccount />} />


      <Route element={<AuthLayout />}>
      <Route path="/media" element={<CoolLightbox />} />
      <Route path="/" element={<Navigate to={user?.authRole==="Associate"?"/homePage":"/login"}/>} />
      <Route index path="/homePage" element={<PrivateRoute><HomePage /></PrivateRoute>} />
      <Route index path="/homePage/Feed" element={<PrivateRoute><Feed /></PrivateRoute>} />
      <Route path="rmanagement" element={<PrivateRoute><MainPage/></PrivateRoute>}>
        <Route index path="dashboard" element={<Dashboard />} />
        <Route path="employees" element={<Employees />} />
        <Route path="feedback" element={<Feedback />} />
        <Route path="department-approval" element={<DepartmentApproval />} />
        <Route path="edit-associate" element={<EditRender />} />
        
      </Route>

      <Route path="project" element={<PrivateRoute><ProjectMainPage /></PrivateRoute>}>
        <Route index path="allRequest" element={<AllRequest/>}/>
        <Route index path="dashboard" element={<PMDashboard />} />
        <Route index path="department" element={<PMDepartment />} />
        <Route path="teams" element={<Teams/>} />
        {/* <Route index path="teams/calender" element={<Calender/>}/> */}
        <Route path="projects" element={<Projects/>} />
        <Route path="logs" element={<LogsMain/>} />
        <Route path="leave" element={<Leave/>} />
        <Route path="release" element={<Release/>} />
        <Route path="migrate_manager" element={<MigrateManager/>} />
        <Route path="summary" element={<EmployeeSummaryTeam/>}/>
      </Route>

      <Route path="notification" element={<PrivateRoute><NotificationRequest/></PrivateRoute>}></Route>

      <Route  path="attendancePortal" element={<PrivateRoute><AttendanceMainPage/></PrivateRoute>} >
        <Route index path="authentication" element={<Authentication />} />
        <Route  path="forgot-punch" element={<CommonComponent />} />
        <Route  path="leave" element={<AttendanceLeave />} />
        <Route  path="attendance-logs" element={<AttendanceLogs />} />
        <Route path="attendance-yearly-logs" element={<AttendanceYearlyLogs />} />
        <Route  path="attendance-logs/calendar/:associateId" element={<AttendanceLogsCalendar />} />
        <Route  path="outdoor-punch" element={<CommonComponent />} />
        <Route  path="settings" element={<Settings />} />
        <Route  path="users" element={<Users />} />
        <Route  path="authentication" element={<Authentication />} />
      </Route>

      <Route path="/announcement" element={<PrivateRoute><Newsletter screenType="announcement"/></PrivateRoute>} />
      
      {/* <Route path="/ceoDesk" element={<PrivateRoute><CeoDesk /></PrivateRoute>} >
          <Route index path="dashboard" element={<FPDashboard/>}/>
          <Route  path="department" element={<FPDepartment/>}/>
          <Route  path="associates" element={<FPAssociates/>}/>
      </Route> */}

      <Route path="/ceoDesk" element={<PrivateRoute><FeedbackMainPage /></PrivateRoute>} >
          <Route index path="dashboard" element={<CeoDesk/>}/>
          <Route path="summary" element={<FPDashboard/>}/>
          <Route  path="department" element={<FPDepartment/>}/>
          <Route  path="associates" element={<FPAssociates/>}/>
      </Route>

      <Route path="/feedbackmanagement" element={<PrivateRoute><FeedbackManagement/></PrivateRoute>}/>
      <Route path="/fbRound" element={<PrivateRoute><FbRound/></PrivateRoute>}/>
      <Route path="/fbPreview" element={<PrivateRoute><FbPreview/></PrivateRoute>}/>
      <Route path="/fbPublish" element={<PrivateRoute><FbPublish/></PrivateRoute>}/>
      <Route path="newsletter" element={<PrivateRoute><Newsletter screenType="newsletter"/></PrivateRoute>}/>
      <Route path="health_insurance" element={<PrivateRoute><HealthInsurance/></PrivateRoute>}/>
      <Route path="attendance" element={<PrivateRoute><Attendance /></PrivateRoute>}/>
      <Route path="user/userProfile" element={<PrivateRoute><UserProfile/></PrivateRoute>}/>
      <Route path="documents" element={<PrivateRoute><TrainingEssentialDocuments></TrainingEssentialDocuments></PrivateRoute>}>
      </Route>


{/* 

    All the private route from appraisal will be removed temporaryly.
    Privateroute is checking for user data exisitence. 
    
    Instead alternatePrivateRoute will be replace because it'll check only for token. 

*/}

      {/* <Route path="appraisal" element={<PrivateRoute><Appraisal/></PrivateRoute>}> */}
      <Route path="appraisal" element={<AlternatePrivateRoute><Appraisal/></AlternatePrivateRoute>}>

        <Route>
          {/* <Route index path="admin/users" element={<AlternatePrivateRoute><AppraisalPrivateRoute role={"Admin"}><Admin/></AppraisalPrivateRoute></AlternatePrivateRoute>}/> */}

          <Route index path="admin/users" element={<AlternatePrivateRoute><AppraisalPrivateRoute role={"Admin"}><Admin/></AppraisalPrivateRoute></AlternatePrivateRoute>}/>
          <Route  path="admin/team" element={<AlternatePrivateRoute><AppraisalPrivateRoute role={"Admin"}><Team/></AppraisalPrivateRoute></AlternatePrivateRoute>}/>
          <Route  path="admin/appraisalLink" element={<AlternatePrivateRoute><AppraisalPrivateRoute role={"Admin"}><AppraisalCycle/></AppraisalPrivateRoute></AlternatePrivateRoute>}/>
          <Route  path="admin/associateDetails" element={<AlternatePrivateRoute><AppraisalPrivateRoute role={"Admin"}><AssociateDetails/></AppraisalPrivateRoute></AlternatePrivateRoute>}/>
          <Route  path="admin/roleLock" element={<AlternatePrivateRoute><AppraisalPrivateRoute role={"Admin"}></AppraisalPrivateRoute></AlternatePrivateRoute>}/>
        </Route>
      
        <Route path="manager" >
          {/* <Route index element={<PrivateRoute><Manager/></PrivateRoute>}/> */}
          <Route index element={<AlternatePrivateRoute><AppraisalPrivateRoute role={"Manager"} ><Manager/></AppraisalPrivateRoute></AlternatePrivateRoute>}/>
          <Route path="associate-list" element={<AlternatePrivateRoute><AppraisalPrivateRoute role={"Manager"} ><AssociateList/></AppraisalPrivateRoute></AlternatePrivateRoute>}/>
          <Route path="associate-Rank" element={<AlternatePrivateRoute><AppraisalPrivateRoute role={"Manager"} ><AssociateRank/></AppraisalPrivateRoute></AlternatePrivateRoute>}/>
          <Route path="appraisal-form" element={<AlternatePrivateRoute><AppraisalPrivateRoute role={"Manager"} ><ManagerAppraisalForm/></AppraisalPrivateRoute></AlternatePrivateRoute>}/>
          <Route path="performance-matrix" element={<AlternatePrivateRoute><AppraisalPrivateRoute role={"Manager"} ><PerformanceMatrix/></AppraisalPrivateRoute></AlternatePrivateRoute>}/>
        </Route>

        <Route path="associate">
          {/* <Route index element={<PrivateRoute><AppraisalPrivateRoute role={"Associate"}><AssociateHome/></AppraisalPrivateRoute></PrivateRoute>} /> */}
          <Route index element={<AlternatePrivateRoute><AppraisalPrivateRoute role={"Associate"}><AssociateHome/></AppraisalPrivateRoute></AlternatePrivateRoute>} />
        </Route>
      </Route>
      </Route>
      {/* <Route path="*" element={<NotFound />} /> */}
    </Routes>

  );
};

export default PMTRoutes;
