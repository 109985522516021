import React from 'react';

const NotFound = ({text}) => {
  return (
    <div style={{display:"flex", justifyContent:"center",alignItems:"center", width:"100%", height:"60vh",flexDirection:"column" }}>
      <h1>404 - Not Found</h1>
      <p>{text?text:"Sorry, the page you are looking for does not exist."}</p>
    </div>
  );
};

export default NotFound;