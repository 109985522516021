import React, { useEffect } from 'react';
import { BrowserRouter as Router, } from 'react-router-dom';
import PMTRoutes from './components/PMTRoutes';
import useStore from '../src/pages/store';
import Api from './services/Api';
import { SECRET_KEY, decryptData } from './services/fileCode';
import viewClass from './services/ViewRole';
import './index.css';
function App() {
  
  const {user} = useStore(state=>state);

  useEffect(() => {
    let tt=localStorage.getItem('token');
    if(tt){
    let token=decryptData(tt,SECRET_KEY)
    if (token) {
      Api.header(token);
    }
  }
  }, [localStorage.getItem('token')]);
useEffect(()=>{
  viewClass.updateView();
},[user])
  useEffect(()=>{
    console.log("line26");
  },[])
// useEffect(() => {
//   console.log("sssToken__1",token)
//   if(!token){
//     setToken(null);
//     setUser({});
//      window.localStorage.clear()
//     //  window.location.replace('/')
//    }
//  },[]);

  return (
    <Router>
      {/* <Header /> */}
      <PMTRoutes/>
    </Router>
  );
}

export default App;
