import Api from "../../../services/Api";


const colorCodes = {
    'New': "#B33DC6",
    'Ongoing': "#33A0FF",
    'Over Due': "#F46A9B",
    'Completed': "#24C850",
    'On Hold': "#EDBF33",
    'Suspended': "#878787",
}

export const getFilterValues = (setter, type, value = 0, values = null, roleName = null) => {
    const params = {};

    if (type === "department") {
        Api.get(`/project-logs/logFilterData`)
            .params(params)
            .send((response) => {
                if (response) {
                    setter("department", response)
                }
            });
    } else if (type === "role") {
        Api.get(`/project-logs/logFilterData?departmentId=${value}`)
            .params(params)
            .send((response) => {
                if (response) {
                    setter("role", response?.roles)
                }
            });
    } else if (type === "manager") {
        Api.get(`/project-logs/logFilterData?role=${value}&departmentId=${values?.departmentId}`)
            .params(params)
            .send((response) => {
                if (response) {
                    console.log(response, "resssssssssssssssssssssss");
                    setter("manager", response)
                    setter("team", response?.teams)
                }
            });
    } else if (type === "team") {
        console.log("hello090999", values);
        Api.get(`/project-logs/logFilterData?managerId=${value}&role=${roleName}&departmentId=${values?.departmentId}`)
            .params(params)
            .send((response) => {
                if (response) {
                    console.log(response, "jfjhbhfsgkbk");
                    setter("team", response?.teams)
                }
            });
    } else if (type === "departmentAttendance") {
        Api.get(`/attendance-logs/filterDepartments`)
            .params(params)
            .send((response) => {
                if (response) {
                    setter("department", response)
                }
            });
    } else if (type === "teamAttendance") {
        Api.get(`/attendance-logs/filterTeams?departmentId=${value}`)
            .params(params)
            .send((response) => {
                if (response) {
                    setter("team", response)
                }
            });
    }
}



export const getLogsData = (callBack, params = null,) => {
    Api.get(`/project-logs`)
        .params(params)
        .send((response) => {

            if (typeof response !== undefined) {
                callBack(response);

            }
        })
}


export const getTaskLogs = (setter, params, successCallback=null) => {
    Api.get(`/project-logs/all-logs`)
        .params(params)
        .send((response) => {
            if (response) {
                setter('TableData', response?.tableData)
                if(successCallback){
                    successCallback(response?.meta)
                }
            }
        });
}
export const getTaskLogsteam = (setter, params) => {
    Api.get(`/project-logs/all-tasks`)
        .params(params)
        .send((response) => {
            if (response) {
                let temp = [];
                response?.tableData?.forEach(element => {
                    temp.push({
                        ...element, 
                        onTimeD:(
                            <>
                            <div style={{   padding:5, borderRadius:5, fontWeight:600, fontSize:12}}>
                                {element?.ontimeDelivery?"Yes":"No"}
                            </div>
                            </>
                        ),
                        statusM: (
                        <>
                            <div style={{ backgroundColor: colorCodes[element?.status] , padding:5, borderRadius:5, fontWeight:600, fontSize:12}}>
                                {element?.status}
                            </div>
                        </>)
                    })

                });

                setter('TableData',temp)
            }
        });
}

export const postTaskLog = (params, callback) => {
    Api.post('/project-logs')
        .params(params)
        .send((res) => {
            console.log('r e s p o n s e', res);
            if (res !== undefined && res?.show?.type !== "error") {
                callback(true)
            }
            else {
                callback(false)
            }
        })
}

export const DownLoadLogs = (params, callback) => {
    Api.get("/project-logs").params(params).send(res => {
        if (res !== undefined) {
            callback(res?.file);
        }
    })
}


export const DownloadData = (params, callback, ref) => {
    Api.get(`/project-logs/all-${ref}`)
        .params(params)
        .send((response) => {
            if (response !== undefined) {
                callback(response?.file)
            }
        });
}

export const postLogEffort = (params, callback) => {
    Api.post(`/project-tasklogs/log?date=${params?.date}&logHours=${params?.logHrs}&userId=${params?.userId}&taskId=${params?.taskId}`).params({}).send(res => {
        if (callback) {
            callback();
        }
        if (res) {
        }
    })
}