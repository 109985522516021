import React, { useCallback, useEffect, useState ,useRef} from "react";
import classes from './Feed.module.css';
import our_journey_img from '../../../assets/our-journey-img.svg';
import mobility_image from '../../../assets/mobility_image.png';
import telimatics_image from '../../../assets/telematics_image.png';
import hiring_status_icon from '../../../assets/hiring_status_icon.svg';
import rejected_icon from '../../../assets/rejected_icon.svg';
import three_d_lider_image from '../../../assets/3d_lidar_image.png';
import digital_prod_image from '../../../assets/digital_prod_image.png';
import tickGreen from "../../../assets/tickgreen.svg";
import short_icon from '../../../assets/short_icon.png';
import youtube_video_icon from '../../../assets/youtube_video_icon.png';
import youtube_icon from '../../../assets/youtubeColorIcon.svg';
import browser_icon from '../../../assets/browser_icon.svg';
import twitter_icon from '../../../assets/twitter-xColorIcon.svg';
import linkedin_icon from '../../../assets/linkedinColoricon.svg';
import message_icon from '../../../assets/message_icon.png';
import lidarCar from '../../../assets/lidarCar.svg';
import { useLoginStore } from "../store";
import CEOMessageModal from "./CEOMessageModal";
import Api from "../../../services/Api";
import VideoModal from "./VideoModal";
import axios from "axios";
import AllEvents from "./AllEvents";
import { Spin, Typography } from "antd";
import MagazineModal from "./MagazineModal";
import AllVideos from "./AllVideos";
import EventModal from "./EventMdoal";
import AllNewsletter from "./AllNewsletter";
import NewsletterModal from "./NewsletterModal";
import useLoginStorageStore from "../loginStorageStore";
import { data } from "../../Homepage/components/menuData";
import { SECRET_KEY, decryptData } from "../../../services/fileCode";
import moment from "moment";
import Notification from "../../../services/Notification";
import { useWindowDimensions } from "../../../components/windowDimention/windowDimension";
import { useNavigate } from "react-router-dom";
import ButtonCompo from "../../../design/ButonCompo";
import credentials2 from "../../../credentials2";


export const StatusBar = ({ canStatus, status,slot }) => {
 
    return (
        <div className={classes.statusBar} style={{ paddingTop: "0.2rem" }}>
            <div style={{ border: status?.submit ? "0" : "1.5px solid #CACACA", width: "2vmin", height: "2vmin", borderRadius: "50%", background: status?.submit ? "#26DE81" : "#fff", display: "flex", alignItems: "center" }}>
                {status?.submit && <img src={tickGreen} style={{ width: "2vmin", height: "2vmin" }} />}
            </div>

            {status?.withdraw&&<div style={{ height: "3.5rem", width: "1.5px", background: "#FC5C65" }}></div>}
            {status?.withdraw&&<div style={{ border: "0", width: "2vmin", height: "2vmin", borderRadius: "50%", display: "flex", alignItems: "center" }}>
              <img src={rejected_icon} style={{ width: "2vmin", height: "2vmin" }} /> 
            </div>}

            {!status?.withdraw&&<div style={{ height: "3.5rem", width: "1.5px", background: status?.notShortlist ? "#FC5C65" : status?.shortlist ? "#26DE81" : "#CACACA" }}></div>}
            {!status?.withdraw&&<div style={{ border: status?.shortlist ? "0" : status?.notShortlist ? "0" : "1.5px solid #CACACA", width: "2vmin", height: "2vmin", borderRadius: "50%", background: status?.shortlist ? "#26DE81" : "#fff", display: "flex", alignItems: "center" }}>
                {status?.notShortlist ? <img src={rejected_icon} style={{ width: "2vmin", height: "2vmin" }} /> : status?.shortlist ? <img src={tickGreen} style={{ width: "2vmin", height: "2vmin" }} /> : ""}
            </div>}
            {slot?.reverse()?.map((item,index)=>(
                <>
                <div style={{ height: "3.5rem", width: "1.5px", background: item?.interviewStatus === "Cancelled" ?"#FC5C65": "#26DE81"  }}></div>
                <div style={{  width: "2vmin", height: "2vmin", borderRadius: "50%",  display: "flex", alignItems: "center" }}>
                {item?.interviewStatus === "Cancelled"?<img src={rejected_icon} style={{ width: "2vmin", height: "2vmin" }} />:  <img src={tickGreen} style={{ width: "2vmin", height: "2vmin" }} />}
            </div>
            
                </>
            ))}
            {/* <div style={{ height: "3.5rem", width: "1.5px", background: status?.progress ? "#26DE81" : "#CACACA" }}></div>
            <div style={{ border: status?.progress ? "0" : "1.5px solid #CACACA", width: "2vmin", height: "2vmin", borderRadius: "50%", background: status?.progress ? "#26DE81" : "#fff", display: "flex", alignItems: "center" }}>
                {status?.progress && <img src={tickGreen} style={{ width: "2vmin", height: "2vmin" }} />}
            </div> */}
            {!status?.notShortlist&&!status?.withdraw&&<div style={{ height: "3.5rem", width: "1.5px", background: status?.reject ? "#FC5C65" : status?.select ? "#26DE81" : "#CACACA" }}></div>}
            {!status?.notShortlist&&!status?.withdraw&&<div style={{ border: status?.select ? "0" : status?.reject ? "0" : "1.5px solid #CACACA", width: "2vmin", height: "2vmin", borderRadius: "50%", background: status?.select ? "#26DE81" : "#fff", display: "flex", alignItems: "center" }}>
                {/* {status?.select&&<img src={tickGreen} style={{width:"2vmin",height:"2vmin"}}/>} */}
                {status?.reject ? <img src={rejected_icon} style={{ width: "2vmin", height: "2vmin" }} /> : status?.select ? <img src={tickGreen} style={{ width: "2vmin", height: "2vmin" }} /> : ""}
            </div>}

        </div>
    )
}

const Feed = React.memo(({ setActiveTab, candidate }) => {
    const { ceoMessageModalHandler, videoModalHandler, videoModal, ceoMessageModal,

        newsletterModal,
        newsletterModalHandler,
        setNewsletter,
        setEvent,
        limit, current,
        hiringStatusUpdater,
        updateDeatails,
        magazineData,
        magazineDataHandler,
    } = useLoginStore();
    const { isLogin } = useLoginStorageStore();
    const [videos, setVideos] = useState();
    const [shorts, setShorts] = useState();
    const [video, setVideo] = useState();
    const [loading, setLoading] = useState(false);
    const [newsletters, setNewsletters] = useState();
    // const [newsletter,setNewsletter]=useState();
    const [magazine, setMagazine] = useState();
    // const [event,setEvent]=useState();
    const [magazineModal, setMagazineModal] = useState(false);
    const [page, setPage] = useState({ page: 1, events: false, videos: false, newsletter: false });
    const [canStatus, setCanStatus] = useState();
    const [cultureData, cultureDataHandler,] = useState();
    const [eventModal, eventModalHandler] = useState(false);
    const {width}=useWindowDimensions();
    const navigate=useNavigate()
  
    const cultureEvents = cultureData?.length > 2 ? cultureData?.slice(0, 3) : cultureData;
    let u = localStorage.getItem('user');
    let user;
    if (u) {
        user = decryptData(u, SECRET_KEY);
    }

    const proficiency = [
        {
            image: three_d_lider_image,
            title: "Mobility solutions"
        },
        /* {
            image: mobility_image,
            title: "3D-Lidar perception and tracking"
        }, */
        {
            image: telimatics_image,
            title: "Telematics platform solutions"
        },
        {
            image: digital_prod_image,
            title: "Digital product engineering"
        },
    ]

    console.log("FEED COMPO", videos)
    // useEffect(() => {
    //     const handlePopState = () => {
    //       // Reload the page when the back button is clicked
    //       window.location.reload();
    //     };

    //     // Add a listener for the popstate event
    //     window.addEventListener('popstate', handlePopState);

    //     // Clean up the listener when the component unmounts
    //     return () => {
    //       window.removeEventListener('popstate', handlePopState);
    //     };
    //   }, []);

    const getDays = (date) => {
        const givenDate = new Date(date);

        // Get today's date
        const todayDate = new Date();

        // Calculate the difference in days
        const differenceInDays = Math.floor((todayDate - givenDate) / (1000 * 60 * 60 * 24));

        // Convert days to months (assuming 30 days per month for simplicity)
        return differenceInDays;
    }

    const getMonth = (date) => {
        const givenDate = new Date(date);

        // Get today's date
        const todayDate = new Date();

        // Calculate the difference in days
        const differenceInDays = Math.floor((todayDate - givenDate) / (1000 * 60 * 60 * 24));

        // Convert days to months (assuming 30 days per month for simplicity)
        const calculatedDifferenceInMonths = Math.floor(differenceInDays / 30);
        return calculatedDifferenceInMonths;
    }
    // https://controlpanel.brigosha.com prod url
    //https://testassociate.brigosha.com test url
    useEffect(() => {
        axios.get('https://controlpanel.brigosha.com/api/public-apis/allEvents')
            .then(response => {
                cultureDataHandler(response?.data);
            })
            .catch(error => {
            })

        axios.get('https://controlpanel.brigosha.com/api/public-apis/allEmagazine')
            .then(res => {
                setMagazine(res?.data);
                magazineDataHandler(res?.data);
            })
            .catch(error => {
            })

        Api.get(`/resource-youtube/getYoutubeVideosForWeb?&short=${true}`)
            .send((res, error) => {
                console.log("VideosRes", res)
                setShorts(res);
            });
        Api.get(`/resource-public/allNewsletters`).send((res) => {
            setNewsletters(res?.data);
        })
        if (candidate) {
            Api.get(`/hiring-mobile/getCurrentStatus?email=${user?.email}`).send(res => {
                setCanStatus(res);
            })
        }
    }, [])

    useEffect(() => {
        setLoading(true);
        Api.get(`/resource-youtube/getYoutubeVideosForWeb?page=${current}&limit=${limit}&short=${false}`)
            .send((res, error) => {
                console.log("VideosRes", res)
                setVideos(res);
                setLoading(false);
            });
    }, [limit, current])

//canStatus?.hiringStatus === "Old"

    const status = {
        submit:canStatus?.hiringStatus === "Old"||(canStatus?.interviewSlots&&canStatus?.interviewSlots?.length>0)||canStatus?.hiringStatus === "New" || canStatus?.hiringStatus === "Shortlisted" || canStatus?.hiringStatus === "Selected" || canStatus?.hiringStatus === "Rejected" || canStatus?.hiringStatus === "In Progress" || canStatus?.hiringStatus === "Not Shortlisted",
        shortlist: canStatus?.hiringStatus === "Shortlisted"||(canStatus?.interviewSlots&&canStatus?.interviewSlots?.length>0)||canStatus?.hiringStatus === "Selected" || canStatus?.hiringStatus === "Rejected" || canStatus?.hiringStatus === "In Progress",
        withdraw:canStatus?.hiringStatus === "Old",
        notShortlist: canStatus?.hiringStatus === "Not Shortlisted",
        progress: canStatus?.hiringStatus === "Selected" || canStatus?.hiringStatus === "Rejected" || canStatus?.hiringStatus === "In Progress",
        select: canStatus?.hiringStatus === "Selected",
        reject: canStatus?.hiringStatus === "Rejected",
    }
    const textAreaRef = useRef(null);
    const textToCopy = 'https://loginv2.brigosha.com/magazine'
    const copyToClipboard = () => {
        if (textAreaRef.current) {
          textAreaRef.current.select();
          document.execCommand('copy');
        }
      };
      const slotMessage=(status)=>{
        let mess;
        switch(status)
        {
            case "Completed":
                mess="you have successfully completed this round. We will shortly let you know about the further process"
                break;
            case "Cancelled":
                 mess="Due to certain reason this round has been cancelled. We will shortly let you know about the further process"
                 break;
            case "Scheduled":
                 mess="This round has been scheduled for you."
                break;
            case "Skipped":
                mess="This round has been skipped."
                break;  
            case "Feedback-Pending":
                mess="Feedback for this round is still pending."
                break;  
                    
        }
        return mess;
      }
      const getDetails = (sourceId) => {
        // setloader(true)
        Api.get(`/hiring-mobile/appliedJobData?sourceId=${sourceId}`)
            .send((res, error) => {
                if (res) {
                    updateDeatails(res)
                    // setloader(false)
                }
            });
    }


    return (
        <>
            {loading ? <Spin className={classes.spin} /> : <>
                {page?.page === 1 && <div className={classes.feed}>
                    <div>
                        <div className={classes.section1}>
                            <h3>Our Culture</h3>
                            <span>Our values and practices help bring our diverse team together to create a healthy and friendly work environment.</span>
                            <div className={classes.cultureEvents}>
                                {cultureEvents?.map((item, index) => {
                                    return (
                                        <div onClick={() => {
                                             eventModalHandler(true);
                                            // navigate('/media');
                                            setEvent(item);
                                        }}>
                                            <img src={item?.thumbnailPath} />
                                            <span style={{textAlign:"center"}}>{item?.title}</span>
                                        </div>
                                    )
                                })}
                            </div>

                            <button className={classes.viewAll} onClick={() => {
                                setPage({ page: 2, events: true, videos: false, newsletter: false });
                            }}>View all</button>

                            <div className={classes.proficiency}>
                                <span>We are known for our Proficiency in</span>
                                <div>
                                    {proficiency?.map((item, index) => {
                                        return (
                                            <div className={classes.proficiencyItem}>
                                                <img src={item.image} />
                                                <span>{item?.title}</span>
                                            </div>
                                        )
                                    })}
                                </div>


                            </div>
                            <button className={classes.visitWebButton}
                                onClick={() => {
                                    window.open("https://www.brigosha.com/", "_blank")?.focus()
                                }}
                            >Explore brigosha expertise</button>

                            <div className={classes.shorts}>
                                <div>
                                    <img src={short_icon} />
                                    <span>Shorts</span>
                                </div>
                                <div className={classes.shortItems}>
                                    {shorts?.items?.map((item, index) => {
                                        return (
                                            <div className={classes.eachShort} onClick={() => {
                                                setVideo(item);
                                                videoModalHandler(true);
                                            }}>
                                                <img src={item.thumbnail} />
                                                <div>
                                                    <Typography.Text ellipsis={{ tooltip: true }} style={{ width: width>700?"15vw":"80vw", color: "#fff", fontWeight: 600, fontSize: "1rem" }}>
                                                        {item.title}
                                                    </Typography.Text>

                                                    <span>{item.statistics.viewCount + " views"}</span>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>

                            </div>

                            <div className={classes.newsletter}>
                                <span>Newsletter</span>
                                <div className={classes.newsletterItems}>
                                    {newsletters?.slice(0, 3)?.map((item, index) => {
                                        return (
                                            <div className={classes.eachNews} onClick={() => {
                                                newsletterModalHandler(true);
                                                setNewsletter(item);
                                            }}>
                                                <div>
                                                    <img src={item.image} />
                                                </div>
                                                <div>
                                                    <span>{item.author}</span>
                                                    <span>{item.createdAt.split("T0")?.[0]}</span>
                                                </div>
                                                <Typography.Text style={{ width:width>700? "15vw":"80vw", fontSize: "1rem", fontWeight: "700" }} ellipsis={{ tooltip: true, }}>
                                                    {item.title}
                                                </Typography.Text>


                                                <span style={{ fontWeight: "1.5vmin", fontWeight: "500" }}>{item.content.length > 90 ? item.content.substring(0, 90) + "..." : item.content} {item.content.length > 90 ? <span style={{ color: "#1089FF", marginLeft: "0.5vw" }}>Continue reading</span> : ""}</span>


                                            </div>
                                        )
                                    })}

                                </div>

                            </div>
                            <button className={classes.viewAll} onClick={() => {
                                setPage({ page: 2, events: false, videos: false, newsletter: true });
                            }}>View all</button>



                        </div>
                        <div className={classes.section2} >
                            {canStatus?.job?.id && <section className={classes.hiringStatus} style={{ cursor: 'pointer' }} onClick={() => {setActiveTab(2);hiringStatusUpdater(2,()=>{ getDetails(canStatus?.id)},"Feed") }}>
                                <div>
                                    <img src={hiring_status_icon} />
                                    <span>Your hiring status</span>
                                </div>
                                <div>
                                    <StatusBar canStatus={canStatus} status={status}  slot={canStatus?.interviewSlots}/>
                                    <div className={classes.status}>
                                        <div style={{ height: "4.5rem" }}>
                                            <span style={{ color: status?.submit ? "#26DE81" : "#000" }}>Submitted</span>
                                            <Typography.Paragraph ellipsis={{ tooltip: true, rows: 2 }}><span>{`Congratulations! You have successfully submitted your job application for ${canStatus?.job?.title} on ${moment(canStatus?.createAt)?.format('DD/MM/YYYY')}`}</span></Typography.Paragraph>
                                        </div>
                                        {status?.withdraw&&<div style={{ height: "4.5rem" }}>
                                            <span style={{ color:"#FC5C65"  }}>Withdrawn</span>
                                            <Typography.Paragraph ellipsis={{ tooltip: true, rows: 2 }}>
                                                <span>Your Application for this job has been withdrawn.</span></Typography.Paragraph>
                                        </div>}
                                        {!status?.withdraw&&<div style={{ height: "4.5rem" }}>
                                            <span style={{ color: canStatus?.hiringStatus === "Not Shortlisted" ? "#FC5C65" : status?.shortlist ? "#26DE81" : "#000" }}>{canStatus?.hiringStatus === "Not Shortlisted" ? "Not Shortlisted" : "Shortlist"}</span>
                                            <Typography.Paragraph ellipsis={{ tooltip: true, rows: 2 }}>
                                                <span>{status?.shortlist ? `Congratulations on being shortlisted! Your skills and experience have captured our attention, and we’d like to invite you to the next stage of our evaluation process for ${canStatus?.job?.title}  ` :
                                                    status?.notShortlist ? `Thank you for your interest in ${canStatus?.job?.title}. We’ve carefully reviewed all applicants and, although your qualifications are impressive, we’ve chosen a candidate whose background more closely fits the role.`
                                                        :
                                                        "..."}</span></Typography.Paragraph>
                                        </div>}
                                        {/* <div style={{ height: "4.5rem" }}>
                                            <span style={{ color: status?.progress ? "#26DE81" : "#000" }}>In-progress</span>
                                            <Typography.Paragraph ellipsis={{ tooltip: true, rows: 2 }}>
                                                <span>{status?.progress ? "Best wishes as you advance through the hiring process. Your talents make you a valuable candidate!" : "..."}</span>
                                            </Typography.Paragraph>
                                        </div> */}
                                         {canStatus?.interviewSlots?.reverse()?.map((item,index)=>{
                                            return(
                                                <div key={index} style={{ height: "4.5rem" }}>
                                                    <span style={{ color: item?.interviewStatus === "Cancelled" ? "#FC5C65" : item?.interviewStatus === "Completed"? "#26DE81" : "#000" }}>
                                                        {"Round" +item.round+`(${ item?.interviewStatus})`}</span>
                                                        <Typography.Paragraph ellipsis={{ tooltip: true, rows: 2 }}>
                                                <span>{slotMessage(item?.interviewStatus)}</span></Typography.Paragraph>
                                                </div>

                                            )
                                        })}
                                        {!status?.notShortlist&&!status?.withdraw&&<div>
                                            <span style={{ color: canStatus?.hiringStatus === "Rejected" ? "#FC5C65" : status?.select ? "#26DE81" : "#000" }}>{canStatus?.hiringStatus === "Rejected" ? "Rejected" : "Selected"}</span>
                                            <Typography.Paragraph ellipsis={{ tooltip: true, rows: 2 }}>
                                                <span >{status?.select ? `We are thrilled to inform you that you have been selected for ${canStatus?.job?.title} at brigosha. Your impressive qualifications, experience, and enthusiasm stood out among an exceptional pool of candidates.` :
                                                    status?.reject ? `We wanted to express our gratitude for ${canStatus?.job?.title}  at brigosha. After careful consideration and a thorough review of your interview(s), we regret to inform you that the hiring team has decided to proceed with other candidates whose qualifications more closely align with our current requirements. `
                                                        :
                                                        "..."}</span>
                                            </Typography.Paragraph>
                                        </div>}
                                       
                                    </div>
                                </div>

                            </section>}
                            <section className={classes.videos}>
                                <div>
                                    <img src={youtube_video_icon} />
                                    <span>Videos</span>
                                </div>
                                <div className={classes.videoItems}>
                                    {videos?.items?.map((item, index) => {
                                        return (
                                            <div className={classes.eachVideo}
                                                onClick={() => {
                                                    setVideo(item);
                                                    videoModalHandler(true);
                                                }}
                                            >
                                                <div>
                                                    <img src={item.thumbnail} />
                                                </div>
                                                <div>
                                                    <Typography.Paragraph style={{ margin: "0", fontSize: "0.9rem", fontWeight: "700" }} ellipsis={{ tooltip: true, rows: 2 }}>
                                                        {item.title}
                                                    </Typography.Paragraph>
                                                    <div>
                                                        <span>{item.statistics.viewCount + " views"}</span>
                                                        <span>{
                                                            getMonth(moment(item.publishedTime).format('YYYY-MM-DD')) > 1 ? `
                                                    ${getMonth(moment(item.publishedTime).format('YYYY-MM-DD'))} months ago` :
                                                                getDays(moment(item.publishedTime).format('YYYY-MM-DD')) > 13 ?
                                                                    Math.floor(getDays(moment(item.publishedTime).format('YYYY-MM-DD')) / 7) + ' weeks ago' :
                                                                    getDays(moment(item.publishedTime).format('YYYY-MM-DD')) > 1 ?
                                                                        getDays(moment(item.publishedTime).format('YYYY-MM-DD')) + " days ago" :
                                                                        getDays(moment(item.publishedTime).format('YYYY-MM-DD')) > 0 ?
                                                                            '1 day ago' :
                                                                            "Today"
                                                        }</span>
                                                    </div>
                                                </div>

                                            </div>
                                        )
                                    })}
                                </div>
                                <button className={classes.viewAll} onClick={() => {
                                    setPage({ page: 2, events: false, videos: true, newsletter: false });
                                }}>View all</button>
                            </section>
                            <section className={classes.ceoMessage} onClick={() => {
                                ceoMessageModalHandler(true);
                            }}>
                                <div>
                                    <img src={message_icon} />
                                </div>
                                <div>
                                    <span>A message from our CEO</span>
                                    <span>brigosha has come a long way in establishing for itself a consistent and positive reputation across its business of providing
                                        world class automotive embedded solutions and is today well known for its quality and time assurances.
                                        Founded on the core value of agility, transparency, quality and integrity, we can’t thank enough each and e…
                                        <span style={{ color: "#1089FF", marginLeft: "0.5vw" }}>Continue reading</span></span>
                                </div>

                            </section>
                          {/*   <section className={classes.lidarCard}>
                                    <img src={lidarCar} />
                                    <div  style={{font:"normal normal 600 24px/29px Figtree" , color:'#161616',textAlign:'center',marginTop:'2rem'}} >
                                    <span>
                                    Join us in shaping the future of LIDAR technology!
                                    </span> 
                                    </div>
                                    <div style={{textAlign:'center',font:"normal normal normal 18px/22px Figtree",color:'#5E5E5E',marginTop:'0.5rem'}}>
                                        <span>Be a part of a vibrant community dedicated to driving innovation and progress in this dynamic field.</span>
                                    </div>
                                <ButtonCompo title={'Visit Lidar Community'} style={{background:'#1089ff',color:'#fff',marginTop:'2rem',width:'95%',fontSize:'18px'}}
                                onClick={()=>{
                                    let lidarUrl=credentials2?.lidarUrl
                                    if(isLogin){
                                        let lt=localStorage.getItem("token");
                                        if(lt){
                                            let lidarToken=decryptData(lt,SECRET_KEY);
                                            
                                            window.open(`${lidarUrl}/verify?token=${lidarToken}`, "_blank")
                                        }
                                    }
                                    else{
                                        window.open(`${lidarUrl}/verify?token=`, "_blank")
                                    }
                                }}

                                 />
                            </section> */}
                            <section className={classes.magazine}>
                                <span>E-Magazine</span>
                                <div>

                                    <img src={magazine?.[0]?.coverImage} onClick={() => { 
                                        //   setMagazineModal(true) ;
                                         navigate('/magazine');
                                        }} />
                                </div>
                                <textarea
                                    style={{ position: 'absolute', left: '-9999px' }}
                                    ref={textAreaRef}
                                    readOnly
                                    value={textToCopy}
                                />
                                
                                <button className={classes.viewAll}
                                    onClick={() => {
                                        copyToClipboard()
                                        window.alert('Link copied to clipboard!');
                                        // fetch(magazine?.[0]?.emagazine).then(response => {
                                        //     response.blob().then(blob => {
                                        //         const fileURL = window.URL.createObjectURL(blob);
                                        //         let alink = document.createElement('a');
                                        //         alink.href = fileURL;
                                        //         alink.download = magazine?.[0].title + '.pdf';
                                        //         alink.click();
                                        //     })
                                        // })
                                    }}
                                >Share</button>
                                
                            </section>

                            {!isLogin && <section className={classes.signup}>
                                <div>
                                    <img src={our_journey_img} />
                                </div>
                                <div>

                                    <span>Join us!</span>
                                    <span>Begin your journey with us today and stay updated.</span>

                                    <button className={classes.viewAll} style={{ margin: "0" }} onClick={() => {
                                        setActiveTab(5);
                                    }}>Sign up</button>
                                </div>

                            </section>}




                        </div>
                    </div>
                    <div className={classes.footer}>
                        <div>
                            <span>Check us on:</span>
                            <img src={twitter_icon} onClick={() => { window.open('https://twitter.com/Brigosha', '_blank').focus(); }} />
                            <img src={linkedin_icon} onClick={() => { window.open('https://www.linkedin.com/company/brigosha/mycompany/', '_blank').focus(); }} />
                            <img src={youtube_icon} onClick={() => { window.open('https://www.youtube.com/@BRIGOSHA', '_blank').focus(); }} />
                            <img src={browser_icon} onClick={() => { window.open('https://www.brigosha.com/', '_blank').focus(); }} />

                        </div>
                        <div>
                            <span>Copyright 2023, brigosha technologies</span>
                        </div>

                    </div>

                    {ceoMessageModal && <CEOMessageModal />}
                    {videoModal && <VideoModal video={video} />}
                </div>}
                {page.page === 2 && page.events &&
                    <AllEvents events={cultureData} setPage={setPage} />
                }
                {page.page === 2 && page.videos &&
                    <AllVideos videos={videos} setPage={setPage} />
                }
                {page.page === 2 && page.newsletter &&
                    <AllNewsletter newsletters={newsletters} setPage={setPage} />
                }
                <MagazineModal magazineModal={magazineModal} setMagazineModal={setMagazineModal} magazine={magazine} />

                {eventModal && <EventModal eventModalHandler={eventModalHandler} eventModal={eventModal} />}
                {newsletterModal && <NewsletterModal />}
            </>}

        </>
    )
})
export default Feed;